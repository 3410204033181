<template>
  <modal-dialog 
    title="Import Analysis" 
    confirm-button-text="Import" 
    :value="value" 
    :action="importAnalysis" 
    @input="$emit('input', $event)" 
    @clear="clearDialog"
  >
    <b-alert
      class="mt-3"
      variant="danger"
      :show="!!message"
    >
      {{ message }}
    </b-alert>
    <b-form-group>
      <b-form-file
        id="analysis-zip"
        v-model="analysisZip"
        class="mt-3"
        placeholder="Choose a Kinetics analysis zip file or drop it here..."
        drop-placeholder="Drop zip file here..."
        :state="state($v.analysisZip)"
      />
      <b-form-invalid-feedback :state="state($v.analysisZip)">
        {{ validationMessage($v.analysisZip) }}
      </b-form-invalid-feedback>
    </b-form-group>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import validationState from '../js/mixins/validationState'
import api from '../api'
import { required } from 'vuelidate/lib/validators'
import getErrorMessage from '../js/getErrorMessage'
import {assetTypes } from '../js/options/assetType'

export default {
  props:{
    value: { type: Boolean }
  },
  data () {
    return {
      analysisZip: null,
      error: false,
      message: null
    }
  },
  validations: {
    analysisZip: {
      required
    }
  },
  methods: {
    async importAnalysis () {
      //Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.message = null;
      this.error = false;

      try {
        let analysis = await api.postFile('/import', this.analysisZip, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (analysis.data.assetType == assetTypes.servicing) {
         this.$router.push({ name: 'Servicing', params: { id: analysis.data.id }});
        } else {
          this.$router.push({ name: 'Inputs', params: { id: analysis.data.id }});
        }

        this.message = 'Analysis imported.';
        this.analysisZip = null;
        this.$nextTick(() => { this.$v.$reset() });
      } catch (err) {
        this.error = true;
        this.message = getErrorMessage(err, 'Error importing analysis.')
        this.$nextTick(() => { this.$v.$reset() });
        return false;
      }
    },
    clearDialog () {
      this.message = null;
      this.analysisZip = null;
    }
  },
  components: {
    ModalDialog
  },
  mixins: [validationState]
}
</script>