<template>
  <div class="scenarios">
    <b-nav
      class="modern-tabs scenario-tabs"
      pills
      v-if="showScenarioTabs"
    >
      <b-nav-item :to="{ name: 'Rates' }">
        Rate Forecasts
      </b-nav-item>
      <b-nav-item :to="{ name: 'HPI' }">
        HPI Forecasts
      </b-nav-item>
      <b-nav-item 
        v-if="showUnemployment"
        :to="{ name: 'Unemployment' }"
      >
        Unemployment Forecasts
      </b-nav-item>
      <b-nav-item :to="{ name: 'Manage' }">
        Scenario Manager
      </b-nav-item>
    </b-nav>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { runTypes } from '../js/options/runType'

export default {
  computed: {
    ...mapState(['activeAnalysis']),
    showScenarioTabs () {
      return this.activeAnalysis.runType == runTypes.loanDynamics && this.activeAnalysis.assetType != "AutoLoans";
    },
    showUnemployment () {
      return this.activeAnalysis.runType == runTypes.loanDynamics && this.activeAnalysis.assetType != "MultiFamilyLoans";
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      if (to.name == "Scenarios" && vm.activeAnalysis.runType == runTypes.loanDynamics && vm.activeAnalysis.assetType === "AutoLoans") {
        next({ name: 'Unemployment', params: to.params });
      } else if (to.name == "Scenarios" && vm.activeAnalysis.runType == runTypes.loanDynamics) {
        next({ name: 'Rates', params: to.params });
      } else {
        next();
      }
    })
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name == "Scenarios") return false;
    else next();
  }
}
</script>
<style scoped>
  .modern-tabs.scenario-tabs {
    background-color: #f5f5f5b4;
    margin-bottom: 3px;
  }

  .modern-tabs.scenario-tabs > .nav-item > .nav-link.router-link-exact-active,
  .dark-theme .modern-tabs.scenario-tabs > .nav-item > .nav-link.router-link-exact-active {
    color: white;
    background-color: green;
    border-radius: 0;
    border: 1px solid transparent;
    border-bottom: none;
    font-weight: 500;
    font-size:  1em; 
  }

  .modern-tabs.scenario-tabs > .nav-item > .nav-link {
    padding: 3px 30px 3px 30px;
    font-size: 1em;
    border-left: 1px solid #e6e6e6;
    font-weight: 500;
    border-radius: 0;
    background-color: white;
  }

  .modern-tabs.scenario-tabs > .nav-item{
    margin: 0 0;
  }

  .modern-tabs.scenario-tabs > .nav-item > .nav-link:hover:not(.router-link-exact-active) {
    border-bottom: none;
    background-color: #efeff0;
  }

  .dark-theme .modern-tabs.scenario-tabs {
    background-color: #292b30;
  }
  
  .dark-theme .modern-tabs.scenario-tabs > .nav-item > .nav-link {
    background-color: #292b30;
    border-left: 1px solid #1c1d20;
  }
  
  .dark-theme .modern-tabs.scenario-tabs > .nav-item > .nav-link:hover:not(.router-link-exact-active) {
    border-bottom: 1px solid #202225;
    background-color: #202225;
  }

  .scenarios {
    position: relative;
    top: -19px;
    left: -20px;
  }
  
  .scenario-divider{
    margin: 0;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    border-top: 1px solid #e6e6e6
  }
</style>
