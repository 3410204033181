<template>
  <modal-dialog 
    title="Rename Custom Points" 
    confirm-button-text="Confirm" 
    :value="value"
    :action="renamePenaltyPointSet" 
    @input="$emit('input', $event)" 
    @clear="clearDialog"
  >
    <b-alert
      class="mt-3"
      variant="danger"
      :show="!!message"
    >
      {{ message }}
    </b-alert>
    <b-form-group>
      <b-form-input
        id="scenario-name"
        v-model.trim="newPointsName"
        class="mt-3"
        :placeholder="inputPlaceholder"
        :state="state($v.newPointsName)"
      />
      <b-form-invalid-feedback :state="state($v.newPointsName)">
        {{ validationMessage($v.newPointsName) }}
      </b-form-invalid-feedback>
    </b-form-group>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import validationState from '../js/mixins/validationState'
import { mapState, mapMutations } from 'vuex';
import api from '../api'
import { required } from 'vuelidate/lib/validators'
import getErrorMessage from '../js/getErrorMessage'

export default {
  props: {
    value: { type: Boolean },
    points: { type: Object, required: true }
  },
  data () {
    return {
      newPointsName: null,
      error: false,
      message: null
    }
  },
  computed: {
    ...mapState(["activeAnalysis"]),

    inputPlaceholder () {
      return `Enter a new name for the '${this.points.penaltyPointSetName}' custom points...`;
    }
  },
  validations: {
    newPointsName: {
      required
    }
  },
  methods: {
    ...mapMutations(['setSavePending', 'setSaving', 'doneSaving', 'errorSaving', 'setPortfolioGridDirty']),
    async renamePenaltyPointSet () {
      //Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.setSavePending();
      this.message = null;
      this.error = false;

      try {
        this.setSaving();
        await api.updatePenaltyPointSet(this.activeAnalysis.id, this.points.penaltyPointSetId, {
          penaltyPointSetName: this.newPointsName,
        });
        this.newPointsName = null;
        this.$nextTick(() => { this.$v.$reset() });
        // Reload portfolio grid on reactivation to include new points in Custom Points dropdown
        this.setPortfolioGridDirty(true);

        this.$emit('points-updated');
      } catch (err) {
        this.errorSaving();
        this.error = true;
        this.message = getErrorMessage(err, 'An error occurred renaming the custom points.')
        this.$nextTick(() => { this.$v.$reset() });
        return false;
      }
      this.doneSaving();
    },
    clearDialog () {
      this.message = null;
      this.newPointsName = null;
      this.$v.$reset()
    }
  },
  components: {
    ModalDialog
  },
  mixins: [validationState]
}
</script>