<template>
  <div class="autocomplete">
    <input
      type="text"
      @input="onChange"
      @click="onChange"
      v-model="search"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      @submit.prevent
      :class="{invalid: state == false}"
    >
    <ul
      id="autocomplete-results"
      v-show="isOpen"
      class="autocomplete-results"
      :class="{ 'no-results': results.length == 0 }"
    >
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result }}
      </li>
    </ul>
  </div>
</template>

<script>
import { thistle } from 'color-name';

  export default {
    name: 'SearchAutocomplete',
    props: {
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      value: {
        type: String
      },
      state: {
        type: Boolean
      }
    },
    data() {
      return {
        isOpen: true,
        results: [],
        search: this.value,
        arrowCounter: -1,
      };
    },
    watch: {
      items: function (value, oldValue) {
        if (value.length !== oldValue.length) {
          this.results = value;
        }
      },
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      setResult(result) {
        this.search = result;
        this.$emit('input', this.search);
        this.isOpen = false;
      },
      filterResults() {
        if (this.search == null)
          this.results = this.items;
        else
          this.results = this.items.filter((item) => {
            return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          });
      },
      onChange() {
        this.$emit('input', this.search);
        this.filterResults();
        this.isOpen = true;
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1;
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        }
      },
      onEnter() {
        if (this.arrowCounter >= 0)
          this.search = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;
      },
    },
  };
</script>

<style scoped>
  .autocomplete {    
    position: relative;
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    width: 100%;
  }
  input.invalid {
    border: 1px solid #dc3545;
  }
  input:focus,
  input:focus-visible,
  input:focus-within {
    border-color: rgb(128,189,255);
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    outline: none;
  }
  .autocomplete-results {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    cursor: default;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #888;
    overflow: auto;
    position: absolute;
    width: 100%;
  }
  .autocomplete-results.no-results {
    display: none;
  }
  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 0rem 1.75rem 0rem 0.75rem;
    cursor: pointer;
  }
  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #007bff;
    color: white;
  }
</style>