<template>
  <div>
    <b-nav
      class="modern-tabs"
      tabs
    >
      <b-nav-item :to="{ name: 'Settings' }">
        General
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'ServicingSettings' }"
        v-if="showServicingSettings"
      >
        Servicing Defaults
      </b-nav-item>
      <b-nav-form class="ml-auto">
        <div class="modern-tab-buttons">
          <save-status />
          <b-button
            title="Back to Analysis Manager"
            @click="exitSettings"
          >
            <font-awesome-icon
              icon="arrow-alt-circle-left"
              class="button-icon"
            />Back
          </b-button>
        </div>
      </b-nav-form>
    </b-nav>
    <div class="modern-tab-content scrollable theme-item">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
    <error-dialog
      :show="!!saveError"
      title="Save Error"
      error-message="An error occurred while saving your changes."
      :on-ok="clearSaveError"
    />
  </div>
</template>

<script>
import ErrorDialog from './ErrorDialog.vue'
import SaveStatus from './SaveStatus.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['saveError', 'licenseInfo']),
    showServicingSettings () {
      return this.licenseInfo.modules.filter(f => f.id == "MSRKinetics").length > 0;
    }
  },
  methods: {
    ...mapMutations(['clearSaveError']),

    async exitSettings() {
      this.$router.push({ name: 'AnalysisManager'});
    }
  },
  components: {
    ErrorDialog,
    SaveStatus
  }
}
</script>
