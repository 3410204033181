function parseToken(token) {
  if (!token || token.indexOf('.') == -1) {
    return {};
  }

  let x = JSON.parse(atob(token.split('.')[1]));

  return x;
}

function tokenValid(token) {
  if (!token || token.indexOf('.') == -1) {
    return false;
  }

  let x = JSON.parse(atob(token.split('.')[1]));

  return true;
}


export { parseToken, tokenValid }