import GridWithODataSource from './GridWithODataSource'
//import GridWithArraySource from './GridWithArraySource'
import SheetEditor from './SheetEditor';

export function createGrid(gridElement, options) {
  if (typeof options.collection === 'string') {
    return new GridWithODataSource(gridElement, options);
  } else {
    //return new GridWithArraySource(gridElement, options);
    return new SheetEditor(gridElement, options);
  }
}