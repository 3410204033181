import { quoteTypeOptions } from '../options/quoteType'
import { assetTypes } from '../options/assetType'
import { vehicleTypeOptions, vehicleMakeOptions } from '../options/vehicleOptions'
import { delinquencyOptions, indexOptions, indexOptionsLdm, documentationOptions, occupancyOptions, loanPurposeOptions,
  propertyTypeOptions, tuneStringOptions, originationChannelOptions, issuerOptions, issuerOptionsAgency,
  issuerOptionsNonAgency, stateOptions, trendedCreditOptions, prepayPenaltyStructureOptions } from '../options/loanOptions'
import api from '../../api'
import positionColumns from './positionColumns'
import { runTypes } from '../options/runType'

export default {
  title: 'Portfolio',
  entitySet: 'Positions',
  entityType: (analysis) => {
    if (analysis.assetType == 'Servicing' && analysis.positionFormat == 'Pool') {
      return 'Kinetics.Data.Entities.ServicingPoolPosition';
    } else if (analysis.assetType == 'Servicing') {
      return 'Kinetics.Data.Entities.ServicingLoanPosition';
    } else if (analysis.assetType == 'AutoLoans') {
      return 'Kinetics.Data.Entities.AutoLoanPosition';
    } else if ((analysis.assetType == 'SingleFamilyLoans' || analysis.assetType == 'AgencyPools') && analysis.positionFormat == 'Pool') {
      return 'Kinetics.Data.Entities.MortgagePoolPosition';
    } else if (analysis.assetType == 'SingleFamilyLoans') {
      return 'Kinetics.Data.Entities.MortgageLoanPosition';
    } else if (analysis.assetType == 'MultiFamilyLoans') {
      return 'Kinetics.Data.Entities.MultiFamilyLoanPosition';
    }else {
      return null;
    }
  },
  dataMaps: async (analysis, agencyOnly=false, nonAgencyOnly=false, isMultiFamily=false, customPenaltyPointSets=[]) => {
    let maps = [{column: 'quoteType', options: quoteTypeOptions.filter(o => o.value != 'QuoteInFile') }];

    if (analysis.assetType == assetTypes.servicing) {
      let msrAssumptions = await api.loadMsrAssumptions(analysis.id);
      let msrTypes = msrAssumptions.map(m => ({ value: m.name, text: m.name }));

      maps.push({ column: 'servicingAssumptionSet', options: msrTypes });
    }

    if (analysis.assetType == assetTypes.autoLoans) {
      maps.push({ column: 'vehicleType', options: vehicleTypeOptions });
      maps.push({ column: 'vehicleMake', options: vehicleMakeOptions });
    } else {
      maps.push({ column: 'delinquency', options: delinquencyOptions });
      maps.push({ column: 'documentation', options: documentationOptions });
      maps.push({ column: 'occupancy', options: occupancyOptions });
      maps.push({ column: 'loanPurpose', options: loanPurposeOptions });
      maps.push({ column: 'propertyType', options: propertyTypeOptions });
      maps.push({ column: 'tuneString', options: tuneStringOptions });
      maps.push({ column: 'originationChannel', options: originationChannelOptions });
      maps.push({ column: 'state', options: stateOptions });
      maps.push({ column: 'trendedCredit', options: trendedCreditOptions });

      if (analysis.runType == runTypes.loanDynamics) {
        maps.push({ column: 'index', options: indexOptionsLdm });
        if (agencyOnly || isMultiFamily) maps.push({ column: 'issuer', options: issuerOptionsAgency });
        else if (nonAgencyOnly) maps.push({ column: 'issuer', options: issuerOptionsNonAgency });
        else maps.push({ column: 'issuer', options: issuerOptions });

        if (isMultiFamily) {
          maps.push({ column: 'prepayPenaltyStructure', options: prepayPenaltyStructureOptions });
          maps.push({ column: 'penaltyPointSetName', options: customPenaltyPointSets.map(p => ({ value: p.penaltyPointSetName, text: p.penaltyPointSetName })) });
        }
      }
      else if (analysis.runType == runTypes.riskValDynamics) {
        maps.push({ column: 'index', options: indexOptions });
      }
    }

    return maps;
  },
  editOptions: {
    keys: ['id'],
    allowAddNew: true,
    allowDelete: true,
    allowSave: true
  },
  itemCreator: (analysis) => () => {
      if (analysis.assetType == 'Servicing' && analysis.positionFormat == 'Pool') {
        return { positionType: 'ServicingPoolPosition' };
      } else if (analysis.assetType == 'Servicing') {
        return { positionType: 'ServicingLoanPosition' };
      } else if (analysis.assetType == 'AutoLoans') {
        return { positionType: 'AutoLoanPosition' };
      } else if ((analysis.assetType == 'SingleFamilyLoans' || analysis.assetType == 'AgencyPools') && analysis.positionFormat == 'Pool') {
        return { positionType: 'MortgagePoolPosition' };
      } else if (analysis.assetType == 'SingleFamilyLoans') {
        return { positionType: 'MortgageLoanPosition' };
      } else if (analysis.assetType == 'MultiFamilyLoans') {
        return { positionType: 'MultiFamilyLoanPosition' };
      } else {
        return {};
      }
  },
  columns (analysis) {
    return positionColumns(analysis);
  }
};