<template>
  <span
    v-b-tooltip.hover.right
    :title="title"
  >
    <font-awesome-icon
      :icon="icon"
      size="sm"
      @click="$emit('click')"
    />
  </span>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: 'cloud-download-alt' },
    title: { type: String, default: 'Download example file' }
  }
}
</script>
<style scoped>
span {
  cursor: pointer;
}
</style>