<template>
  <file-select-wrapper
    ref="fileSelect"
    :class="wrapperClass"
    :disabled="disabled"
    :multiple="multiple"
    :file-types="fileTypes"
    @input="$emit('input', $event)"
    @error="$emit('error', $event)"
  >
    <slot>
      <span
        tabindex="0"
        class="icon-button theme-item"
      >
        <font-awesome-icon
          icon="cloud-upload-alt"
          class="input-files-icon"
          title="Import File"
          size="lg"
        />
      </span>
    </slot>
  </file-select-wrapper>
</template>
  
<script>
import FileSelectWrapper from './FileSelectWrapper.vue'

export default {
  components: {
    FileSelectWrapper
  },
  props: {
    disabled: { type: Boolean, default: false },
    wrapperClass: { type: String, default: '' },
    multiple: { type: Boolean, default: false },
    fileTypes: { type: Array, default: () => ['txt', 'tab', 'tsv'] }
  },
  methods: {
    clear () {
      this.$refs.fileSelect.clear();
    }
  }
}
</script>

<style>
input[type=file]:disabled+span.icon-button {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>