<template>
  <div>
    <modal-dialog
      ref="dlg"
      title="File Layout Help"
      confirm-button-text="Close"
      :ok-only="true"
      dialog-size="xl"
    >
      <file-layout
        :file="file"
      />
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import FileLayout from './FileLayout.vue'

export default {
  props: {
    file: { type: Object, required: true }
  },
  methods: {
    async showDialog () {
      this.$refs.dlg.showDialog();
    }
  },
  components: {
    ModalDialog,
    FileLayout
  }
}
</script>
