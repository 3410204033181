<template>
  <scenarios-container 
    :forecast-group="forecastGroup"
    :forecast-types="forecastTypes"
    :chart-title="chartTitle"
  />
</template>

<script>
import ScenariosContainer from './ScenariosContainer.vue'

export default {
  props: {
    id: { type: [String, Number], required: true }
  },
  data () {
    return {
      forecastGroup: 'Hpi',
      forecastTypes: [
        {
          name: 'NationalHpi',
          header: 'HPI',
          default: true
        }
      ],
      chartTitle: 'Home Price Index Scenarios'
    }
  },
  components: {
    ScenariosContainer
  }
}
</script>