<template>
  <div class="scenario-manager">
    <b-navbar
      toggleable="sm"
    >
      <b-navbar-nav>
        <b-nav-form>
          <div class="modern-tab-buttons">
            <b-button
              variant="outline-secondary"
              @click="showScenarioDialog=true"
            >
              <font-awesome-icon :icon="['far','plus-square']" />
              Add Scenario
            </b-button>
            <b-button
              variant="outline-secondary"
              v-b-tooltip.hover.bottom
              title="Create a forecast from a template or import from a text file"
              @click="showImportLdmScenarioDialog=true"
            >
              <font-awesome-icon :icon="['fa','file-import']" />
              Import Scenarios
            </b-button>
            <b-button
              variant="outline-secondary"
              v-b-tooltip.hover.bottom
              title="Export user-defined scenarios into a text file"
              @click="exportScenarios"
              :disabled="scenarios.length <= 1"
            >
              <font-awesome-icon icon="file-export" />
              Export Scenarios
            </b-button>
          </div>
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>
    <div>
      <table class="table b-table table-compact table-modern theme-item">
        <thead>
          <tr>
            <th width="30%">
              Enabled
            </th>
            <th>Scenario</th>
            <th />
          </tr>
        </thead>
        
        <tbody>
          <scenario-row-edit
            v-for="(s) in scenarios"
            :key="s.id"
            :scenario="s"
            @tuningClick="tuningClick"
          />
        </tbody>
      </table>
    </div>
    <div />
    <add-scenario-dialog 
      v-model="showScenarioDialog"
      :scenarios="scenarios"
    />
    <import-ldm-scenario-dialog
      v-model="showImportLdmScenarioDialog"
      :file="files.find(f => f.inputType == 'ScenarioImportModel')"
      @scenarios-updated="initScenarios"
    />
    <tuning-values-dialog 
      id="tuning-modal"
      v-model="showTuningScenarioDialog"
      :dialog-title="dialogTitle"
      :load-tunings="loadTunings"
      :save-tunings="saveTunings"
      message="Note: These tunings will override all global tunings."
    />
  </div>
</template>

<script>
import ScenarioRowEdit from './ScenarioRowEdit.vue'
import AddScenarioDialog from './AddScenarioDialog'
import ImportLdmScenarioDialog from './ImportLdmScenarioDialog'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import TuningValuesDialog from './TuningValuesDialog'
import api from '../api'

export default {
  data(){
    return{
      showScenarioDialog: false,
      showImportLdmScenarioDialog: false,
      showTuningScenarioDialog: false,
      selectedScenario: null,
      dialogTitle: null
    }
  },
  computed: {
    ...mapState([ 'activeAnalysis', 'scenarios', 'scenarioData']),
    ...mapGetters(['scenarioHeaderItems', 'fileSet']),
    files () {
      return this.fileSet.filter(f => f.enabled && !f.hidden && f.fileType=='Scenario');
    },
  },
  async mounted() {
    await this.getScenarios();
  },
  props: {
  },
  methods:{
    ...mapMutations([ 'addExport']),
    ...mapActions([ 'getScenarios']),
    tuningClick (scenario) {
      this.selectedScenario = scenario.scenarioId;
      this.dialogTitle = `Tuning values for scenario ${scenario.scenarioName}`
      this.showTuningScenarioDialog = true;
    },
    async loadTunings () {
      return await api.getTuningValues(this.activeAnalysis.id, this.selectedScenario);
    },
    async saveTunings (tuningData) {
      await api.addTuningValues(tuningData, this.activeAnalysis.id, this.selectedScenario);
      await this.getScenarios();
    },
    async initScenarios () {
      await this.getScenarios();
    },
    exportScenarios () {
      this.addExport({
        analysisId: this.activeAnalysis.id,
        exportMessage: `Exporting scenarios for ${this.activeAnalysis.name}...`,
        errorMessage: `Error exporting scenarios for ${this.activeAnalysis.name}`,
        promise: this.$http.post(`/analyses/${this.activeAnalysis.id}/export/Scenarios`)
      });
    }
  },
  components: {
    ScenarioRowEdit,
    AddScenarioDialog,
    ImportLdmScenarioDialog,
    TuningValuesDialog
  }
}
</script>

<style>
  .scenario-manager table {
    margin-left: 10px;
    max-width: 1000px;
  }
 .scenario-manager p {
   margin: 0;
 }
 .scenario-manager .form-group {
   margin: 0;
 }
</style>

