import externals from '../js/externals'
import { settingsToCalcs } from '../api/calcsMapper'

let dateConverter = (val) => {
  let dateCheck = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
  if (typeof val === 'string' && dateCheck.test(val)) {
    return new Date(val);
  } else {
    return val;
  }
}

const msrDefaultValues = {
  name: 'default',
  costPerLoan: '',
  costPerD1: '',
  costPerD2: '',
  costPerD3: '',
  costPerD4: '',
  costPerD5: '',
  costPerSeriouslyDelinq: '',
  costPerForeclosure: '',
  costInflation: '',
  advancesLeverage: '',
  advancesFinanceRate: '0',
  advancesFinanceRateFloat: false,
  recaptureMonths: '',
  recapturePercent: '',
  escrowRateEarned: '0',
  escrowRateEarnedFloat: true,
  escrowRatePaid: '0',
  escrowRatePaidFloat: false,
  escrowInflation: '',
  scheduleFloatDays: '',
  prepayFloatDays: '',
  paymentFloatRateEarned: '0',
  paymentFloatRateFloat: true,
  incomePerLoan: '',
  incomePerPayment: '',
  baseServicing: '',
  advancesD: false,
  advancesS: false,
  costToServiceOverride: false,
  financingAdvOverride: false,
  earningsOverride: false,
  excessServicingOverride: false,
  premiumRecaptureOverride: false,
}

function initCalcs() {
  return {
    enableValueAndRiskMetrics: true,
    MIonly: externals.initMIonly(),
    b5calcs: {
      premscale: 60,
      CC_r: 4,
      CC_confidence: [95, 99, 99.5, 99.6],
      CC_R: [10, 15, 20],
    },
    cashflows: {
      columnSet: 14,
      showLoanLevel: false,
      showScenarios: false,
      showMonte: false,
      showMonteAvg: true,
    },
    showpayup: externals.initshowpayup(),
    showLDM: externals.initshowLDM(),
    payupHorizon: 36,
    showFcAnalysis: false,
    showprice: externals.initshowprice(),
    showMSR: externals.initshowMSR(),
    showscenprice: externals.initshowscenprice(),
    showoas: externals.initshowoas(),
    showyld: externals.initshowyld(),
    showzspread: externals.initshowzspread(),

    msr: {
      defaultValues: msrDefaultValues,
      assumptions: []
    },

    showinterestRateRisk: false,
    interestRateRisk: {
      showeffdur: externals.initshowdurations(),
      showspreaddur: externals.initshowdurations(),
      showVega: externals.initshowdurations(),
      showycdur: false && externals.initshowdurations(),
      showparshock: false && externals.initshowparshock(),
      showkeydur: externals.initshowdurations()
    },
    get hasInterestRateRiskSelection () {
      return this.showinterestRateRisk && 
        (this.interestRateRisk.showeffdur ||
        this.interestRateRisk.showspreaddur ||
        this.interestRateRisk.showVega ||
        this.interestRateRisk.showycdur ||
        this.interestRateRisk.showparshock ||
        this.interestRateRisk.showkeydur);
    },

    showmodelRisk: false,
    modelRisk: {
      showppdur: externals.initshowdurations(),
      showrefidur: externals.initshowdurations(),
      showturndur: externals.initshowdurations(),
      showslidedur: externals.initshowdurations(),
      showltv: externals.initshowdurations(),
      showfico: externals.initshowdurations()
    },
    get hasModelRiskSelection () {
      return this.showmodelRisk && 
        (this.modelRisk.showppdur ||
        this.modelRisk.showrefidur ||
        this.modelRisk.showturndur ||
        this.modelRisk.showslidedur ||
        this.modelRisk.showltv ||
        this.modelRisk.showfico);
    },

    showhpi: false,

    durationshifts: {
      oasdur: 5,
      effdur: 5,
      keydur: 5,
      ltvslide: 5,
      ficoslide: 50,
      hpidrift: 0.5,
      hpidiff0: 0.5,
      ppdur: 5,
      spreaddur: 5,
      showVega: 1,
      slidedur: 5,
    },
    showtunings: externals.initshowtunings(),
    tuningType: 0,
    useCustomHpaTuningFile: false,
    useCustomTuningFiles: false,
    tunings: {
      tunecd: 1,
      tunedc: 1,
      tuneds: 1,
      tunedt: 1,
      tunesc: 1,
      tunest: 1,
      tuneMDR_scale: 1,
      tunepl_td: 1,
      tunepl_ts: 1,
      tunepl_tc: 1,
      tunesev_td: 1,
      tunesev_ts: 1,
      tunesev_tc: 1,
      tuneLTV_slide: 0,
      tuneFICO_slide: 0,
      tuneSeverity_scale: 1,
      tunesato_residual: 0,
      PPtunerefi_factor: 1,
      PPtuneturnover_factor: 1,
      PPtuneslide_factor: 1,
      PPetunescale_tuning: 1,
      SmmTuneAge: 1,
      SmmTuneSteep: 1,
      SmmTuneLag: 1,
      SmmTuneBurnout: 1,
      TuneSeasonality: 1,
      SmmTunePremiumSteepness: 0
    },
    showcashflows: false,
    showB5: externals.initshowB5(),
    showcredit: externals.initshowcredit(),
    creditRepricing: false,
    showCCAR: false,
    showExternalHpaAndUnemp: false,
    scenarioIrAdjust: false,
    scenarioLocalityAdjust: false,
    scenarioAddSeasonality: false,
    stressTestRepricing: false,
    stressTestPrice: true,
    useHpaZipMap: false,
    useUnempZipMap:false,
    showMCcredit: false,
    showrepricing: false,
    tradedate: new Date(),

    assumptions: {
      quoteType: externals.initquotetype(),
      quote: 0,
      settleDate: new Date(),
      excessServicingQuote: 0
    },

    useHedgeFile: false,
    hedgeAssumptions: {
      quoteType: "QuoteInFile",
      quote: 0,
      settleDate: new Date()
    },
    hedgeAveragingType: 0,
  
    paths: externals.initPaths(),
    //numhandles: 32,
    numthreads: 6,
    supportCMO: externals.initsupportCMO(), // Pools and Loans
    defaultdef: externals.initdefaultdef(), // D120
    intexmode: externals.initintexmode(), // D120
    intexgroupfile: 'Need to choose group file', // D120
    intexnumgroups: 200, // D120
    irprocess: externals.initirprocess(), // 
    irprocessShift: 0,
    TBAflag: externals.initTBAflag(), // flagTBA for payup only
    customCommand: "",
    GUIflavor: externals.GUIflavor,
    enableCommandsFile: false,
    xmlresults: false,
    xmlinputs: false,
    enableDefaultScenario: true,
    maxForecastMonths: null,
    smmMdrDefinition: null,
    delinquencyCalculationMethod: null,
    curveType: null,
    oasBenchmark: null,
    val(arg) {
      return externals.val(arg, this);
    },
    toCommands() {
      return calcsToCommands(this);
    },
    assign(calcs) {

      // Create msr defaults
      if (calcs && calcs.msr && calcs.msr.defaultValues === undefined) {
        calcs.msr.defaultValues = this.msr.defaultValues;
      }

      Object.assign(this, calcs);

      updateProps(this);

      // Create default msr assumptions
      if (this.msr.assumptions.length === 0) {
        this.msr.assumptions.push(this.msr.defaultValues);
      }

      // Convert dates
      this.assumptions.settleDate = dateConverter(this.assumptions.settleDate);
      this.hedgeAssumptions.settleDate = dateConverter(this.hedgeAssumptions.settleDate);
      this.tradedate = dateConverter(this.tradedate);

      // Always calc zspread
      this.showzspread = true;

      return this;
    },
    get hasScenarioGrid() {
      return this.val('showB5') ||
        this.val('showcredit');
    }
  }
}

function createCalcs(analysis) {
  let calcs = initCalcs();
  return calcs.assign(settingsToCalcs(analysis.settings, analysis.runType));
}

function updateProps(calcs) {
  if (calcs.msr.assumptions === undefined) {
    calcs.msr.assumptions = [{
      id: 'default',
      costInflation: calcs.msr.costInflation,
      escrowInflation: calcs.msr.escrowInflation,
      costPerLoan: calcs.msr.costPerLoan,
      costPerD1: calcs.msr.costPerD1,
      costPerD2: calcs.msr.costPerD2,
      costPerD3: calcs.msr.costPerD3,
      costPerD4: calcs.msr.costPerD4,
      costPerD5: calcs.msr.costPerD5,
      costPerSeriouslyDelinq: calcs.msr.costPerSeriouslyDelinq,
      costPerForeclosure: calcs.msr.costPerForeclosure,
      prepayFloatDays: calcs.msr.prepayFloatDays,
      advancesLeverage: calcs.msr.advancesLeverage,
      incomePerLoan: calcs.msr.incomePerLoan,
      scheduleFloatDays: calcs.msr.scheduleFloatDays,
      escrowRateEarned: calcs.msr.escrowRateEarned,
      escrowRateEarnedFloat: calcs.msr.escrowRateEarnedFloat,
      escrowRatePaid: calcs.msr.escrowRatePaid,
      escrowRatePaidFloat: calcs.msr.escrowRatePaidFloat,
      paymentFloatRateEarned: calcs.msr.paymentFloatRateEarned,
      paymentFloatRateFloat: calcs.msr.paymentFloatRateFloat,
      recaptureMonths: calcs.msr.recaptureMonths,
      advancesFinanceRate: calcs.msr.advancesFinanceRate,
      advancesFinanceRateFloat: calcs.msr.advancesFinanceRateFloat,
    }];
  }
}

export { initCalcs, msrDefaultValues, createCalcs };
