import { ListBox } from '@grapecity/wijmo.input';
import { CellRange } from '@grapecity/wijmo.grid'

// Find a column in the grid based on name
let findCol = (grid, colName) => {
  let cols = grid.sheets.length ? grid.sheets[0].columnCount : 0;

  for (let col = 0; col < cols; col++) {
    if (grid.getCellValue(0, col, false) == colName) {
      return grid.columns[col];
    }
  }

  return null;
}

let updateColumn = (grid, colName) => {
  let column = findCol(grid, colName);

  if (column) {
    // Format cells
    let rng = new CellRange(1, column.index, grid.rows.length, column.index);
    grid.refreshRange(rng);
  }

  return column
}
// Create a drop down based on options param
export default class DropDownEditor
{
  constructor(grid, colName, options, showTextEditor = false) {
    let column = findCol(grid, colName);
    let listName = `wj-gridlist-${colName}`;

    this._visible = false;

    // Create an element to host the dropdown list
    this._el = document.createElement("div");
    this._el.id = listName;
    this._el.style.position = 'absolute';
    this._el.style.display = 'none';
    this._el.style.maxHeight = '128px';
    this._el.style.minWidth = '180px';
    this._el.style.zIndex = '1500';

    grid.hostElement.parentElement.appendChild(this._el);

    // Create the dropdown list
    let listbox = new ListBox(`#${listName}`, {
      displayMemberPath: 'text',
      selectedValuePath: 'value',
      itemsSource: options
    });

    let activeCell = null;

    // Update grid cell when an item is selected
    listbox.selectedIndexChanged.addHandler(() => {
      if (listbox.selectedItem) {
        grid.setCellData(activeCell.row, activeCell.col, listbox.selectedItem.value);
      }
    });
    
    // Check for header edits
    grid.cellEditEnded.addHandler((s, e) => {
      if (e.row == 0) {
        column = updateColumn(grid, colName);
      }
    });

    grid.columnChanged.addHandler(() => {
      column = updateColumn(grid, colName);
    });

    grid.undoManager.addHandler(() => {
      column = updateColumn(grid, colName);
    });

    grid.formatterCollection.add(colName, (panel, row, col, cell) => {
      if (column && row > 0 && col == column.index) {
        // Create a dropdown button
        let btn = document.createElement("button");
        btn.classList = 'wj-btn wj-btn-glyph wj-right wj-elem-dropdown';
        btn.onclick = (e) => {
          e.stopPropagation();

          if (!this._visible) {
            let rect = cell.getBoundingClientRect();

            activeCell = {
              row,
              col
            };
           
            // Set the selected item
            let val = grid.getCellValue(row, col);
            listbox.selectedItem = options.find(o => val != "" && o.value == val);

            // Show the dropdown
            this.show(rect.top - 20, rect.left - 3, rect.width + 3);
  
            if (showTextEditor) {
              grid.startEditing(false, row, col);
            } else {
              let rng = new CellRange(row, col, row, col);
              grid.select(rng, false);
            }
          } else {
            this.hide();
          }
        }

        let downArrow = document.createElement("span");
        downArrow.classList = 'wj-glyph-down';

        btn.appendChild(downArrow);

        cell.appendChild(btn);
      }
    });

    grid.beginningEdit.addHandler((s,e) => {
      let isDel = e.data && e.data.key == "Delete";

      if (column && e.row > 0 && !showTextEditor && e.col == column.index && !isDel) {
        e.cancel = true;
      }
    })

    document.addEventListener('click', () => { this.hide() });
  }

  show (top, left, width) {
    this._el.style.top = top + 'px';
    this._el.style.left = left + 'px';
    this._el.style.width = width + 'px';
    this._el.style.display = 'block';
    this._visible = true;
  }

  hide () {
    if (this._visible) {
      this._el.style.display = 'none';
      this._visible = false;
    }
  }
}