function tsv(data) {
  let str = '';

  if (Array.isArray(data[0])) {
    let cols = data[0].length;

    for (let r = 0; r < data.length; r++) {
      for (let c = 0; c < cols; c++) {
        str += data[r][c];
  
        if (c == cols - 1) {
          str += '\n';
        } else {
          str += '\t';
        }
      }
    }
  } else if (typeof data[0] === 'object') {
    let header = Object.keys(data[0]).map(h => h);
    let cols = header.length;

    // Header row
    for (let c = 0; c < header.length; c++) {
        str += header[c];

        if (c < header.length - 1) {
          str += '\t';
        }
    }
    str += '\n';

    // Data
    for (let r = 0; r < data.length; r++) {
      for (let c = 0; c < cols; c++) {
        str += data[r][header[c]];
  
        if (c == cols - 1) {
          str += '\n';
        } else {
          str += '\t';
        }
      }
    }
  } else if (typeof data[0] === 'string') {
    data.forEach(x => {
      str += x + '\n';
    })
  }

  return str;
}

function convertObjectArray(data) {
  if (Array.isArray(data) && typeof data[0] === 'object' && !Array.isArray(data[0])) {
    let arr = [];
    let header = Object.keys(data[0]).map(h => h);
    let cols = header.length;

    // Header row
    arr.push(header);

    // Data
    for (let r = 0; r < data.length; r++) {
      let row = []
      for (let c = 0; c < cols; c++) {
        row.push(data[r][header[c]]);
      }
      arr.push(row);
    }

    return arr;
  } else if (Array.isArray(data) && typeof data[0] === 'string') {
    return data.map(x => [x]);
  } else {
    return data;
  }
}

const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export { tsv, convertObjectArray, delay }