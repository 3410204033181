<template>
  <div
    id="msr-editor"
    class="container-fluid"
  >
    <grid-editor
      name="MSR Assumptions"
      :columns="columns"
      :grid-data="assumptions"
      :new-row-values="defaultValues"
      :validations="validations"
      @row-edit-ended="$emit('row-edit-ended')"
      @delete-rows="$emit('delete-rows', $event)"
    />
  </div>
</template>

<script>
import GridEditor from './GridEditor.vue'
import { msrDefaultValues } from '../js/calcs'
import { validations } from '../js/validation/msrAssumptionValidations'
import { unique } from '../js/validation/validators'

export default {
  components: {
    GridEditor
  },
  props: {
    assumptions: { type: Array, required: true }
  },
  data () {
    return {
      defaultValues: msrDefaultValues,
      columns: [
        { header: 'Servicing Assumption Set', binding: 'name', dataType: 'String', required: true },
        { header: 'Cost Per Loan', binding: 'costPerLoan', dataType: 'Number' },
        { header: 'Cost Per D1', binding: 'costPerD1', dataType: 'Number' },
        { header: 'Cost Per D2', binding: 'costPerD2', dataType: 'Number' },
        { header: 'Cost Per D3', binding: 'costPerD3', dataType: 'Number' },
        { header: 'Cost Per D4', binding: 'costPerD4', dataType: 'Number' },
        { header: 'Cost Per D5', binding: 'costPerD5', dataType: 'Number' },
        { header: 'Cost Per S', binding: 'costPerSeriouslyDelinq', dataType: 'Number' },
        { header: 'Cost Per Foreclosure', binding: 'costPerForeclosure', dataType: 'Number' },
        { header: 'Cost Inflation', binding: 'costInflation', dataType: 'Number' },
        { header: 'Advances Leverage', binding: 'advancesLeverage', dataType: 'Number' },
        { header: 'Advances D', binding: 'advancesD', dataType: 'Boolean' },
        { header: 'Advances S', binding: 'advancesS', dataType: 'Boolean' },
        { header: 'Advances Rate', binding: 'advancesFinanceRate', dataType: 'Number' },
        { header: 'Advances Float', binding: 'advancesFinanceRateFloat', dataType: 'Boolean' },
        { header: 'Escrow Earned Rate', binding: 'escrowRateEarned', dataType: 'Number' },
        { header: 'Escrow Earned Float', binding: 'escrowRateEarnedFloat', dataType: 'Boolean' },
        { header: 'Escrow Paid Rate', binding: 'escrowRatePaid', dataType: 'Number' },
        { header: 'Escrow Paid Float', binding: 'escrowRatePaidFloat', dataType: 'Boolean' },
        { header: 'Escrow Inflation', binding: 'escrowInflation', dataType: 'Number' },
        { header: 'Schedule Float Days', binding: 'scheduleFloatDays', dataType: 'Number' },
        { header: 'Prepay Float Days', binding: 'prepayFloatDays', dataType: 'Number' },
        { header: 'Float Earned Rate', binding: 'paymentFloatRateEarned', dataType: 'Number' },
        { header: 'Float Earned Flag', binding: 'paymentFloatRateFloat', dataType: 'Boolean' },
        { header: 'Income Per Loan', binding: 'incomePerLoan', dataType: 'Number' },
        { header: 'Income Per Payment', binding: 'incomePerPayment', dataType: 'Number' },
        { header: 'Base Servicing', binding: 'baseServicing', dataType: 'Number' },
        { header: 'Months Recapture', binding: 'recaptureMonths', dataType: 'Number' },
        { header: 'Percent Recapture', binding: 'recapturePercent', dataType: 'Number' },
      ],
      validations: { 
        ...validations,
        name: {
          unique: unique('name')
        }
      }
    };
  }
}
</script>

<style>
#msr-editor {
  height: calc(100vh - 300px);
}

.wj-new-sheet {
  display: none!important;
}
</style>
