const JWT_TOKEN_KEY = "kinetics_token";
const REFRESH_TOKEN_KEY = "kinetics_refresh_token";

export default {
  setTokens (tokens) {
    localStorage.setItem(JWT_TOKEN_KEY, tokens.jwtToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refreshToken);
  },
  clearTokens () {
    localStorage.removeItem(JWT_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
  getTokens () {
    let jwtToken = localStorage.getItem(JWT_TOKEN_KEY);
    let refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    return {
      jwtToken,
      refreshToken
    };
  },
  getJwtToken () {
    return localStorage.getItem(JWT_TOKEN_KEY);
  },
  getRefreshToken () {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }
}