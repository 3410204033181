<template>
  <div>
    <collapsible-section
      section-title="Cash Flows"
      :value="enableCashFlows"
      @input="$emit('update:enableCashFlows', $event)"
    >
      <b-form-checkbox
        v-if="!enableB5"
        id="checkbox-cashflows-showmonte-avg"
        :checked="averagedMcCashFlows"
        :disabled="disabled || stressTestOnly"
        @input="$emit('update:averagedMcCashFlows', $event)"
      >
        Averaged MC Cash Flows
      </b-form-checkbox>
      <b-form-checkbox
        v-if="!enableB5"
        id="checkbox-cashflows-showmonte"
        :checked="mcCashFlows"
        :disabled="disabled || allDisabled"
        @input="$emit('update:mcCashFlows', $event)"
      >
        Monte Carlo Cash Flows
      </b-form-checkbox>
      <b-form-checkbox
        :checked="scenarioCashFlows"
        :disabled="disabled || !scenariosEnabled"
        @input="$emit('update:scenarioCashFlows', $event)"
      >
        Scenario Cash Flows
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-cashflows-loanlevel"
        :checked="loanLevelCashFlows"
        :disabled="disabled || (stressTestOnly && !showScenarios)"
        @input="$emit('update:loanLevelCashFlows', $event)"
      >
        Loan Level Cash Flows
      </b-form-checkbox>

      <b-form-group
        class="pt-2"
        label="Cash Flow Columns"
        label-for="cashflow-columns"
        title="Select cash flow columns, each selections includes those above"
      >
        <b-select
          id="cashflow-columns"
          :value="cashFlowColumnSet"
          :options="columnSetOptions"
          size="sm"
          :disabled="disabled"
          @input="$emit('update:cashFlowColumnSet', $event)"
        />
      </b-form-group>
    </collapsible-section>
  </div>
</template>

<script>
import CollapsibleSection from './CollapsibleSection.vue'

export default {
  components: {
    CollapsibleSection
  },
  props: {
    enableCashFlows: { type: Boolean, required: true },
    averagedMcCashFlows: { type: Boolean, required: true },
    mcCashFlows: { type: Boolean, required: true },
    scenarioCashFlows: { type: Boolean, required: true },
    loanLevelCashFlows: { type: Boolean, required: true },
    cashFlowColumnSet: { type: Number, required: true },
    enableB5: { type: Boolean, required: true },
    enableStressTests: { type: Boolean, required: true },
    enableScenarioGrid: { type: Boolean, required: true },
    enableMonteCarlo: {type: Boolean, required: true },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      columnSetOptions: [
        { value: 10, text: "Basic fields" },
        // {value: 11, text: 'CPct, DPct and SPct'}, // Combined with #2
        { value: 14, text: "Basic delinquency and transitions" },
        // {value: 13, text: '+ MIBal, MIPrem and MIRIF'},
        //{ value: 14, text: "+ Prime, Mtg30 and LocHPI" },
        { value: 15, text: "All fields, including granular delinquency fields" }
      ]
    };
  },
  computed: {
    scenariosEnabled () {
      return this.enableB5 || this.enableScenarioGrid || this.enableStressTests;
    },

    stressTestOnly () {
      return !this.enableMonteCarlo && !this.enableScenarioGrid;
    },

    allDisabled () {
      return !this.enableMonteCarlo && !this.enableScenarioGrid && !this.enableStressTests;
    }
  },
  watch: {
    scenariosEnabled (val) {
      if (!val) {
        this.$emit('update:showScenarios', false);
      }
    },

    stressTestOnly(val) {
      if (val) {
        this.$emit('update:showMonteAvg', false);
        this.$emit('update:showLoanLevel', false);
      }
    },

    allDisabled (val) {
      if (val) {
        this.$emit('update:showMonteAvg', false);
        this.$emit('update:showMonte', false);
        this.$emit('update:showLoanLevel', false);
        this.$emit('update:showScenarios', false);
      }
    },

    showScenarios (val) {
      if (!val) {
        this.$emit('update:showScenarios', false);
      }
    }
  }
};
</script>
