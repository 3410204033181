import { DataType, culture, setAriaLabel } from "@grapecity/wijmo"
import { ComboBox } from "@grapecity/wijmo.input"

/**
 * Work around to show correct options in the filter conditions drop down for columns with datamaps
 */
export default function(el, label) {
  var col = this._filter.column;
  var filterVals = culture.FlexGridFilter;
  var op = filterVals.stringOperators;
  var datamap = this._filter.dataMap || col.dataMap;

  if (col.dataType == DataType.Date) {
    op = filterVals.dateOperators;
  } else {
    if (col.dataType != DataType.Number || datamap) {
      // Changed this if statement
      if (col.dataType == DataType.Boolean || datamap) {
        op = filterVals.booleanOperators;
      }
    } else {
      op = filterVals.numberOperators;
    }
  }

  var result = new ComboBox(el, {
    itemsSource : op,
    displayMemberPath : "name",
    selectedValuePath : "op"
  });

  return setAriaLabel(result.inputElement, label), result;
}