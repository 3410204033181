export default {
  mounted () {
    // autofocus and select any text in the first input of the modal
    this.$root.$on('bv::modal::shown', (evt, modalId) => {
      let el = document.querySelector(`#${modalId} input:enabled`);

      if (el) {
        setTimeout(() => {
          el.focus();
          el.select();
        }, 100);
      }
    })
  }
}