<template>
  <div>
    <b-form-checkbox
      :checked="value"
      @input="$emit('input', $event)"
      @change="valueChanged"
    >
      {{ label }}
    </b-form-checkbox>
    <div
      v-if="value"
      class="pl-4"
    >
      <file-select 
        :file-name="fileName"
        :allow-add-new="allowAddNew"
        :allow-delete="allowDelete"
      />
    </div>
  </div>
</template>

<script>
import FileSelect from './FileSelect.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'FileSelectWithCheckbox',
  props: {
    label: { type: String, required: true },
    value: { type: Boolean, required: true },
    fileName: { type: String, required: true },
    allowAddNew: { type: Boolean, default: true },
    allowDelete: { type: Boolean, default: true }
  },
  methods: {
    ...mapMutations(["toggleFile"]),

    valueChanged (event) {
      this.toggleFile({ fileName: this.fileName, enabled: event });
      this.$emit('change', event);
    }
  },
  components: {
    FileSelect
  }
}
</script>