<template>
  <div class="calcs-container">
    <div class="calcs-section">
      <collapsible-section
        section-title="Analysis Settings"
        css-class="calcs-panel"
        hide-toggle
      >
        <date-picker
          id="first-forecast-date"
          v-model="$v.settings.firstForecastDate.$model"
          label="First Forecast Date"
          :label-cols="5"
          :state="state($v.settings.firstForecastDate)"
        >
          <b-form-invalid-feedback :state="state($v.settings.firstForecastDate)">
            {{ validationMessage($v.settings.firstForecastDate) }}
          </b-form-invalid-feedback>
        </date-picker>

        <b-form-group
          label="Max Forecast Months"
          label-for="max-forecast-months"
          title="Maximum number of months to forecast"
          :label-cols="5"
        >
          <b-form-input
            id="max-forecast-months"
            v-model="$v.settings.maxForecastMonths.$model"
            type="text"
            class="field-sm"
            size="sm"
            autocomplete="off"
            :state="state($v.settings.maxForecastMonths)"
          />
          <b-form-invalid-feedback :state="state($v.settings.maxForecastMonths)">
            {{ validationMessage($v.settings.maxForecastMonths) }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-btn
            v-if="hasTunings"
            @click="showTuningsDialog = true"
          >
            Edit Global Tunings...
          </b-btn>
        </b-form-group>

        <b-form-group
          v-if="activeAnalysis.assetType == assetTypes.singleFamilyLoans"
        >
          <b-form-checkbox
            v-if="appConfig.copyAdcoDataFiles"
            v-model="settings.useCustomTuningFiles"
            :checked="settings.useCustomTuningFiles"
            @change="enableTuningFiles"
          >
            Use Custom Tuning Files
          </b-form-checkbox>
        </b-form-group>
        
        <b-form-group>
          <div v-if="settings.useCustomTuningFiles && appConfig.copyAdcoDataFiles">
            <label>Tuning Conditions File</label>
            <file-select 
              :file-name="fileNames.tuningConditions"
              :hide-create-edit="true"
            />

            <div class="pt-2">
              <label>Tuning Values File</label>
              <file-select 
                :file-name="fileNames.tuningValues"
                :hide-create-edit="true" 
              />
            </div>
          </div>
        </b-form-group>
      </collapsible-section>
    </div>

    <div
      v-if="activeAnalysis.assetType != assetTypes.autoLoans"
      class="calcs-section"
    >
      <calculations-ldm-output
        v-bind.sync="settings.resultSettings"
      />
    </div>

    <div :class="advancedSettingsClasses">
      <calculations-ldm-advanced-settings 
        v-bind.sync="settings"
        :v="$v"
      />
    </div>

    <tuning-values-dialog 
      id="global-tuning-modal"
      v-model="showTuningsDialog"
      dialog-title="Global Tuning Values"
      :load-tunings="loadTunings"
      :save-tunings="saveTunings"
      message="Note: Scenario level tunings from the Scenarios > Scenario Manager tab will override global tunings."
    />
  </div>
</template>

<script>
import store from '../store'
import CalculationsLdmOutput from './CalculationsLdmOutput.vue'
import CalculationsLdmAdvancedSettings from './CalculationsLdmAdvancedSettings.vue'
import CollapsibleSection from './CollapsibleSection.vue'
import DatePicker from './DatePicker.vue'
import TuningValuesDialog from './TuningValuesDialog.vue'
import { required, integer, between  } from 'vuelidate/lib/validators'
import { date } from '../js/validation/validators'
import validationState from '../js/mixins/validationState'
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'
import { assetTypes } from '../js/options/assetType'
import { fileNames } from '../js/fileInfo'
import FileSelect from './FileSelect.vue'

export default {
  data () {
    return {
      assetTypes,
      fileNames,
      settings: store.state.activeAnalysis.settings,
      showTuningsDialog: false
    }
  },
  validations: {
    settings: {
      firstForecastDate: {
        required,
        date
      },
      maxForecastMonths: {
        integer,
        between: between(1, 600)
      }
    }
  },
  computed: {
    ...mapState(['activeAnalysis', 'isValidating', 'appConfig']),
    ...mapGetters(['isLdmSingleFamily']),

    advancedSettingsClasses () {
      return {
        'calcs-section': true,
        'calcs-section-2x': this.activeAnalysis.assetType != assetTypes.autoLoans
      };
    },
    hasTunings () {
      return this.activeAnalysis.assetType == assetTypes.singleFamilyLoans || 
        this.activeAnalysis.assetType == assetTypes.multiFamilyLoans;
    }
  },
  methods: {
    ...mapMutations(['setAnalysisModified', 'addValidationStatus', 'setScenarioGridDirty', 'toggleFile']),
    ...mapActions(['autoSave']),

    formatDateField (date) {
      return moment(date, 'MM/DD/YYYY').toISOString(true);
    },
    onSave () {
      this.setScenarioGridDirty(true);
    },
    loadTunings () {
      return this.settings.tunings;
    },
    saveTunings (tunings) {
      this.settings.tunings = tunings;
    },
    async enableTuningFiles(enabled) {
      this.toggleFile({ fileName: fileNames.tuningConditions, enabled });
      this.toggleFile({ fileName: fileNames.tuningValues, enabled });
    }
  },
  watch: {
    settings: {
      handler (settings) {
        this.setAnalysisModified();
        this.autoSave({ saveAction: 'saveAnalysisSettings', isValid: !this.$v.settings.$invalid, id: this.activeAnalysis.id, data: settings, onSave: this.onSave });
      },
      deep: true
    },
    isValidating (val) {
      if (val === true) {
        this.$v.$touch();

        this.addValidationStatus({
          tab: 'Calculations',
          item: 'analysis-settings-panel',
          isValid: !this.$v.$invalid
        });
      }
    },
  },
  components: {
    CalculationsLdmOutput,
    CalculationsLdmAdvancedSettings,
    CollapsibleSection,
    DatePicker,
    TuningValuesDialog,
    FileSelect
  },
  mixins: [validationState]
}
</script>

<style scoped>
@media (min-width: 1024px) {
  .calcs-container {
    display: flex;
    flex-wrap: wrap;
  }
  .calcs-section {
    width: 475px;
    padding: 10px;
  }

  .calcs-section-2x {
    width: 950px;
  }
}
</style>
<style>
.calcs-panel {
  height: 100%;
}
</style>