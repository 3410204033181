<template>
  <modal-dialog
    title="Choose Report Fields"
    @input="$emit('input', $event)"
    v-model="openModal"
    dialog-size="xl"
    cancel-disabled="true"
  >
    <!-- :action="createAnalysis"
    @input="$emit('input', $event)" -->

    <template #footer="{ ok, cancel }">
      <b-form-group
        class="btn-container-left"
      >
        <b-btn
          :disabled="disableButtons || isAllFieldsUnchecked"
          class="btn-revert"
          title="Undo your current changes to this report"
          @click="onRevertChanges()"
        >
          Revert changes
        </b-btn>
        <b-btn
          :disabled="disableButtons || isAllFieldsUnchecked"
          variant="primary"
          title="Save this report configuration for all analyses for this module"
          @click="onDefaultSave(ok)"
        >
          Save as default
        </b-btn>
      </b-form-group>
      
      
      <span class="errorMessage">{{ isAllFieldsUnchecked && items.length > 1 ? 'Please select at least one field' : '' }}</span>
      <span class="helpText">{{ disableButtons ? disabledMessage : '' }}</span>

      <b-btn @click="cancel()">
        Cancel
      </b-btn>

      <b-btn
        :disabled="disableButtons || isAllFieldsUnchecked"
        variant="primary"
        @click="onSave(ok)"
      >
        Save
      </b-btn>
    </template>
    <b-col
      lg="6"
      sm="12"
      class="px-0"
    >
      <b-form-group
        label-for="filter-input"
        class="mb-4"
      >
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to search fields..."
            autocomplete="off"
          />

          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
              variant="secondary"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-table-simple
      bordered
      small
      striped
      hover
      sticky-header="calc(100vh - 300px)"
    >
      <b-thead>
        <b-tr>
          <b-th
            v-for="field in fields"
            :key="field.key"
          >
            {{ field.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <draggable
        v-model="items"
        group="items"
        handle=".handle"
        tag="tbody"
        @change="onRowReorder"
      >
        <b-tr
          v-for="element in items.filter(i => i.show)"
          :key="element.fieldId"
        >
          <b-td
            class="handle-container"
          >
            <div
              class="handle-cell"
              :class="filterActive ? 'handle-disabled':'handle'"
            >
              <font-awesome-icon
                icon="grip-vertical"
                class="ml-auto"
              />
            </div>
          </b-td>
          <b-td>
            <b-form-checkbox
              :checked="element.selected"
              @change="checkboxClick($event, element)"
              :class="element.enabled ? 'fieldsCheckBox':'fieldsCheckBoxDisabled'"
              :disabled="!element.enabled"
            />
          </b-td>
          <b-td>{{ element.label }}</b-td>
          <b-td>{{ element.description }}</b-td>
        </b-tr>
      </draggable>
    </b-table-simple>
  </modal-dialog>
</template>

<script>
import ModalDialog from "./ModalDialog.vue";
import validationState from "../js/mixins/validationState";

import api from "../api";
import { mapState } from "vuex";
import Draggable from "vuedraggable";

export default {
  props: {
    value: { type: Boolean },
    reportId: { type: Number }
  },
  data() {
    return {
      fields: [
        { key: "blank", label: "" },
        { key: "selected", label: "Show" },
        { key: "label", label: "Field" },
        { key: "description", label: "Field Description" }
      ],
      items: [],
      changedFields: [],
      reportFields: [],
      openModal: false,
      disabledMessage:'',
      filter: '',
      filterActive: false
    };
  },
  computed: {
    ...mapState(["activeAnalysis"]),
    disableButtons() {
      return this.changedFields.length == 0;
    },
    isAllFieldsUnchecked(){
      return !this.items.find(item => (item.selected && item.enabled))
    }
  },
  watch: {
    async value() {
      this.openModal = this.value;
      if (!this.value) {
        this.resetModal();
      } else {
        await this.getReportFields();
      }
    },
    filter(newValue) {
      if (newValue.length > 0) {
        this.filterActive = true;
        this.items = this.items.map(i => {
          i.show = ((i.label.toLowerCase().indexOf(newValue.toLowerCase()) > -1) || (i.description.toLowerCase().indexOf(newValue.toLowerCase()) > -1));
          return i;
        })
      } else {
        this.filterActive = false;
        this.items.forEach(i => i.show = true);
      }
    }
  },
  validations: {},
  methods: {
    resetModal() {
      this.changedFields = [];
      this.items = [];
      this.filter = '';
      this.filterActive = false;
    },
    async getReportFields() {
      this.reportFields = await api.getReportFields(
          this.reportId,
          this.activeAnalysis.id
        );
      this.items = this.reportFields
        .sort((a, b) => a.displayOrder - b.displayOrder)
        // .filter(field => field.enabled)
        .map((field) => {
          let row = {};
          this.fields.forEach((column) => {
            row[column.key] = field[column.key];
        });
        row.fieldId = field.fieldId;
        row.displayOrder = field.displayOrder;
        row.enabled = field.enabled;
        row.show = true;
        return row;
      });
    },
    async onSave(ok) {
      if (this.changedFields.length > 0) {
        await api.saveFieldsData(
          this.reportId,
          this.activeAnalysis.id,
          this.changedFields
        );
        this.$emit("refreshResults");
      }
      ok();
    },
    async onDefaultSave(ok) {
      if (this.changedFields.length > 0) {
        await api.saveDefaultFieldsData(
          this.reportId,
          this.activeAnalysis.id,
          this.changedFields
        );
        this.$emit("refreshResults");
      }
      ok();
    },
    async onRevertChanges() {
      this.resetModal();
      await this.getReportFields();
    },
    checkboxClick(value, element) {
      let index = this.items.findIndex(
        (item) => item.fieldId === element.fieldId
      );
      this.items[index].selected = value;
      this.addElementToChanged(element.fieldId, value, element.displayOrder);
      if (this.changedFields.length==0) {
        this.disabledMessage='No Changes'
      }
    },
    addElementToChanged(fieldId, selected, displayOrder) {
      const index = this.changedFields.findIndex(
        (item) => fieldId === item.fieldId
      );
      if (index > -1) {
        let changedField = this.changedFields[index];
        let reportField = this.reportFields.find(
          (item) => item.fieldId === fieldId
        );
        if (
          reportField.selected === selected &&
          reportField.displayOrder === displayOrder
        ) {
          this.changedFields.splice(index, 1);
        } else {
          this.changedFields[index] = {
            fieldId: fieldId,
            selected: selected,
            displayOrder: displayOrder,
          };
        }
      } else {
        this.changedFields.push({
          fieldId: fieldId,
          selected: selected,
          displayOrder: displayOrder,
        });
      }
    },
    onRowReorder(data) {
      let { oldIndex, newIndex } = data.moved;
      if (oldIndex < newIndex) {
        this.items[newIndex].displayOrder =
          this.items[newIndex - 1].displayOrder;
        for (let i = oldIndex; i < newIndex; i++) {
          this.items[i].displayOrder -= 1;
        }
        for (let i = oldIndex; i <= newIndex; i++) {
          let { fieldId, selected, displayOrder } = this.items[i];
          this.addElementToChanged(fieldId, selected, displayOrder);
        }
      } else {
        this.items[newIndex].displayOrder =
          this.items[newIndex + 1].displayOrder;
        for (let i = newIndex + 1; i <= oldIndex; i++) {
          this.items[i].displayOrder += 1;
        }
        for (let i = newIndex; i <= oldIndex; i++) {
          let { fieldId, selected, displayOrder } = this.items[i];
          this.addElementToChanged(fieldId, selected, displayOrder);
        }
      }
    },
  },
  components: {
    ModalDialog,
    Draggable,
  },
  mixins: [validationState],
};
</script>
<style scoped>
th:nth-child(1),
td:nth-child(1) {
  width: 5%;
}

th:nth-child(2),
td:nth-child(2) {
  width: 5%;
}

th:nth-child(3),
td:nth-child(3) {
  width: 20%;
}
.handle-container {
  vertical-align: middle;
}
.handle-cell {
  color: #424a52;
  padding: .2rem .6rem;
  text-align: center;
}
.handle {
  cursor: grab;
}
.handle-disabled {
  opacity: 0.5;
}
.fieldsCheckBox {
  text-align: center;
}
.fieldsCheckBoxDisabled {
  text-align: center;
  opacity: 0.5;
}
.errorMessage {
  color: #c91a2b;
}
.helpText {
  color:#a1693e;
}
.btn-revert {
  margin-right: 0.25rem;
}
</style>