import { RestCollectionView } from '@grapecity/wijmo.rest';
import { copy, asString } from '@grapecity/wijmo';

export class RestCollectionViewPoints extends RestCollectionView {
  _key;

  constructor(pointsOptions, options) {
    super();

    // ** overrides
    this.getItems = pointsOptions.getItems;
    this.patchItem = pointsOptions.patchItem;

    copy(this, options);
  }

  /**
   * Gets or the name of the key field.
   *
   * Key fields are required for update operations (add/remove/delete).
   */
  get key() {
    return this._key;
  }
  set key(value) {
    this._key = asString(value);
  }
}
