import { FlexGrid, KeyAction, DataMap, CellRangeEventArgs, CellRange } from '@grapecity/wijmo.grid'
import { UndoStack } from '@grapecity/wijmo.undo';
import { default as GridMenu, gridContext } from './GridMenu'
import GridValidator from './GridValidator'

export default class {
  constructor () {
    this._grid = null;
    this.itemCount = 0;
    this.filtered = false;
    this._clip = null;
    this._undoStack = null;
    this._onSave = null;
    this._gridChanged = null;
    this._newItemCreator = null;
  }

  _initGrid(gridElement, collectionView, options) {
    // Create grid
    let hasCols = options.columns && options.columns.length > 0;
    let hasColGroups = options.columnGroups && options.columnGroups.length > 0;

    let cols = hasCols ? options.columns.filter(c => c.visible !== false).map(c => ({
      binding: c.binding,
      header: c.header,
      isRequired: c.isRequired,
      isReadOnly: c.isReadOnly,
      dataType: c.dataType,
      format: c.format,
      cssClass: c.cssClass ? c.cssClass : ''
    })) : [];

    let colGroups = hasColGroups ? options.columnGroups.filter(c => c.visible !== false).map(c => ({
      binding: c.binding,
      header: c.header,
      isRequired: c.isRequired,
      isReadOnly: c.isReadOnly,
      dataType: c.dataType,
      format: c.format,
      cssClass: c.cssClass ? c.cssClass : '',
      columns: c.columns ? c.columns.map(d => {
        let {validations, ...rest} = d;
        return rest;
      }) : []
    })) : [];

    let gridOptions = {
      autoGenerateColumns: !hasCols,
      itemsSource: collectionView,
      allowAddNew: options.allowAddNew || false,
      keyActionTab: KeyAction.Cycle,
      allowSorting: options.allowSorting,
      allowResizing: options.hasOwnProperty('allowResizing') ? options.allowResizing : true,
      allowDragging: options.hasOwnProperty('allowDragging') ? options.allowDragging : true,
      selectionChanging: options.hasOwnProperty('selectionChanging') ? options.selectionChanging : null,
      selectionChanged: options.hasOwnProperty('selectionChanged') ? options.selectionChanged : null,
      stickyHeaders: options.hasOwnProperty('stickyHeaders') ? options.stickyHeaders : false,
      formatItem: options.formatItem
    };

    if (hasCols) {
      gridOptions.columns = cols;
    }
    
    if (hasColGroups) {
      gridOptions.columnGroups = colGroups;
    }

    let grid = new FlexGrid(gridElement, gridOptions);

    this._newItemCreator = options.itemCreator;

    // Store handlers
    this._gridChanged = options.gridChanged;
    this._onSave = options.onSave;
    this._rowsDeleted = options.rowsDeleted;
    this._copied = options.copied;
    this._pasted = options.pasted;

    // Set item count
    this.itemCount = options.allowAddNew ? grid.rows.length - 1 : grid.rows.length;

    // Update item count when collection changes
    collectionView.collectionChanged.addHandler(() => {
      this.itemCount = options.allowAddNew ? grid.rows.length - 1 : grid.rows.length;
      this.filtered = !!collectionView.filterDefinition;
      this._callHandler(options.collectionChanged, [this.itemCount, this.filtered]);
    });

    // Loaded flag
    grid.loadedRows.addHandler(() => {
      this.loading = false;
      this._callHandler(options.loaded);
    });

    // Handle paging events
    collectionView.pageChanging.addHandler((s, e) => {
      this._callHandler(options.pageChanging, [s, e])
    });

    collectionView.pageChanged.addHandler((s, e) => {
      this._callHandler(options.pageChanged, [s, e]);
    });

    // Set column widths
    grid.columns.forEach(c => {
      c.width = options.hasOwnProperty('colWidth') ? options.colWidth : 180;
      c.width = options.hasOwnProperty('dateColWidth') && c.binding == 'date' ? options.dateColWidth : c.width;
      c.width = options.hasOwnProperty('ageColWidth') && c.binding == 'age' ? options.ageColWidth : c.width;
    });

    grid.cellEditEnded.addHandler((g, e) => {
      if (!e.cancel) {
        this._callHandler(options.gridChanged);
      }
    });

    grid.rowEditEnded.addHandler((g, e) => {
      if (!e.cancel) {
        this._callHandler(options.rowEditEnded);
      }
    });

    grid.copied.addHandler(() => {
      this._callHandler(options.copied);
    });

    grid.pasted.addHandler(() => {
      this._callHandler(options.pasted);
      this._callHandler(options.gridChanged);
    });

    // Init undo / redo
    this._undoStack = new UndoStack(gridElement, {
      maxActions: 50,
      stateChanged: s => {
        this._callHandler(options.canUndo, [s.canUndo]);
        this._callHandler(options.canRedo, [s.canRedo]);
      }
    });
    this._undoStack.undoingAction.addHandler((s, e) => {
      this._callHandler(options.undoing, [e]);
    });
    this._undoStack.undoneAction.addHandler((s, e) => {
      this._callHandler(options.undo, [e]);
    });
    this._undoStack.redoingAction.addHandler((s, e) => {
      this._callHandler(options.redoing, [e]);
    });
    this._undoStack.redoneAction.addHandler((s, e) => {
      this._callHandler(options.redo, [e]);
    });

    this._grid = grid;
    this._gridValidator = null;

    return grid;
  }

  _initMenu (insertCmd, deleteCmd, options) {
    // Init menu
    let menuItems = [];

    // Insert Rows menu item
    if (options.allowAddNew && insertCmd) {
      menuItems.push({ 
        header: 'New Row', context: gridContext.rowHeader, cmd: insertCmd
      });
    }

    // Delete Rows menu item
    if (options.allowDelete && deleteCmd) {
      menuItems.push({ 
        header: 'Delete Rows', context: gridContext.rowHeader, cmd: deleteCmd
      });
    }

    // Create the grid menu
    var menuElement = document.createElement('div');
    new GridMenu(menuElement, this._grid, menuItems);
  }

  _bindDataMaps (grid, options) {
    const dataMapper = (maps) => {
      maps && maps.forEach(m => {
        let col = grid.columns.getColumn(m.column);
        if (col) {
          col.dataMap = new DataMap(m.options, 'value', 'text');
        }
      });
    }
    
    if (options.dataMaps instanceof Promise) {
      options.dataMaps.then((maps) => {
        dataMapper(maps);
      });
    } else {
      dataMapper(options.dataMaps);
    }
  }

  _initValidators (grid, options) {
    if (options.validations) {
      this._gridValidator = new GridValidator(grid, options.validations);
    } else if (options.columns) {
      // Check columns collection for validations
      let hasValidations = false;
      let validations = options.columns.reduce((acc, val) => {
        if (val.validations) {
          acc[val.binding] = val.validations;
          hasValidations = true;
        }
        return acc;
      }, {});

      if (hasValidations) {
        this._gridValidator = new GridValidator(grid, validations);
      }
    } else if (options.columnGroups) {
      // Check column groups collections for validations
      let columns = [];
      options.columnGroups.forEach(c => {
        if (c.columns) {
          c.columns.forEach(d => columns.push(d));
        }
      });
      let hasValidations = false;
      let validations = columns.reduce((acc, val) => {
        if (val.validations) {
          acc[val.binding] = val.validations;
          hasValidations = true;
        }
        return acc;
      }, {});

      if (hasValidations) {
        this._gridValidator = new GridValidator(grid, validations, true);
      }
    }
  }

  _callHandler(handler, params) {
    if (handler && typeof handler === 'function') {
      params ? handler(...params) : handler();
    }
  }

  _rowValid(row) {
    if (this._grid.itemValidator) {
      const cols = this._grid.columns.length;

      for (let c = 0; c < cols; c++) {
        if (this._grid.itemValidator(row, c, false) != null) {
          return false;
        }
      }

      return true;
    }
  }

  isValid () {
    // No easy way to check if valid
    // Could loop through all row and columns
    return true;
  }

  insertRow () {
    // let item = this._newItemCreator ? this._newItemCreator() : {};
    // let idx = this._grid.selectedRows.length ?
    //   this._grid.selectedRows[0].index : this._grid.rows.length;
   
    // this.insertNew(item, idx);
    // this.commitNew();

    // this._callHandler(this._gridChanged);

    // Move to add row template at bottom
    this._grid.select(new CellRange(this._grid.rows.length - 1, 0), true);
  }

  insertNew (item, idx) {
    let grid = this._grid;
    let cv = this._collectionView;

    // Insert a new row
    cv.deferUpdate(() => {
      cv._newItem = item;
      cv.sourceCollection.splice(idx, 0, item);
    });

    cv.onCollectionChanged();

    // Call rowAdded handlers for undo functionality
    grid.onRowAdded(new CellRangeEventArgs(grid.cells, grid.selection));
  }

  commitNew () {
    let cv = this._collectionView;
    let value = cv._newItem;

    if (value != null) {
      if (cv.trackChanges) {
        var i = cv._chgEdited.indexOf(value);
        if (i > -1) {
          cv._chgEdited.removeAt(i);
        }
        if (cv._chgAdded.indexOf(value) < 0) {
          cv._chgAdded.push(value);
        }
      }
      cv._newItem = null;
      cv.onCollectionChanged();
    }
  }

  deleteSelectedRows () {
    let grid = this._grid;
    let items = grid.selectedRows.map(r => r.dataItem);

    items.forEach(item => {
      // Get current index after and previous deletes
      let idx = grid.rows.findIndex(r => r.dataItem == item);

      // Send onDeletingRow event for Undo functionality
      let rng = new CellRange(idx, 0, idx, grid.columns.length - 1);
      grid.onDeletingRow(new CellRangeEventArgs(grid.cells, rng));

      // Remove the row
      this._collectionView.remove(item);
      this._collectionView.onCollectionChanged();
    });

    grid.select(-1, -1);
    this._callHandler(this._rowsDeleted);
    this._callHandler(this._gridChanged);
  }

  copy () {
    this._clip = this._grid.getClipString() + "\r\n";
    this._callHandler(this._copied);
  }

  paste () {
    this._grid.setClipString(this._clip);

    // Deselect and re-select range
    // This is so the grid detect the change
    const rng = this._grid.selectedRanges[0];
    this._grid.select(new CellRange(this._grid.rows.length-1, 0), false);
    this._grid.select(rng, false);

    this._callHandler(this._pasted);
    this._callHandler(this._gridChanged);
  }

  undo () {
    this._undoStack.undo();
    this._callHandler(this._gridChanged);
  }

  redo () {
    this._undoStack.redo();
    this._callHandler(this._gridChanged);
  }

  clearUndoStack () {
    this._undoStack.clear();
  }

  save (p) {
    this._grid.select(-1,-1);
    this._undoStack.clear();

    if (typeof this._onSave === 'function') {
      let saved = this._onSave(this._collectionView.sourceCollection);

      if (saved instanceof Promise) {
        return saved;
      }
    }

    return p ? p : Promise.resolve(true);
  }
}