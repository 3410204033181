import Vue from 'vue'
import Router from 'vue-router'
import AppTitle from './components/AppTitle.vue'
import AnalysisManager from './components/AnalysisManager.vue'
import Login from './components/Login.vue'
import UserManager from './components/UserManager.vue'
import SetPassword from './components/SetPassword.vue'
import PasswordReset from './components/PasswordReset.vue'
import AccountManager from './components/AccountManager.vue'
import UsernameLookup from './components/UsernameLookup.vue'

import store from './store'
import tokenStore from './js/tokenStore'
import isElectron from 'is-electron'

import AnalysisEditorHeader from './components/AnalysisEditorHeader.vue'
import AnalysisEditor from './components/AnalysisEditor.vue'
import InputFiles from './components/InputFiles.vue'
import Portfolio from './components/Portfolio.vue'
import MsrAssumptions from './components/MsrAssumptions.vue'
import Points from './components/Points.vue'
import Scenarios from './components/Scenarios.vue'
import ScenarioRates from './components/ScenarioRates.vue'
import ScenarioHpi from './components/ScenarioHpi.vue'
import ScenarioUnemployment from './components/ScenarioUnemployment.vue'
import ScenarioManager from './components/ScenarioManager.vue'
import Calculations from './components/Calculations.vue'
import Results from './components/Results.vue'
import FlexGridFileEditor from './components/FlexGridFileEditor.vue'
import AnalysisODataEditor from './components/AnalysisODataEditor.vue'

import Settings from './components/Settings.vue'
import SettingsApplication from './components/SettingsApplication.vue'
import SettingsMsrDefaults from './components/SettingsMsrDefaults.vue'

// Lazy loaded components
// NOTE: These will not work when using the {PUBLIC_PATH} token
// NOTE: Lazy loading AnalysisEditor Causes UI delay on first navigation to Analysis Editor
// const AnalysisEditorHeader = () => import(/* webpackChunkName: "analysis-editor" */ './components/AnalysisEditorHeader.vue')
// const AnalysisEditor = () => import(/* webpackChunkName: "analysis-editor" */ './components/AnalysisEditor.vue')
// const InputFiles = () => import(/* webpackChunkName: "analysis-editor" */ './components/InputFiles.vue')
// const MsrAssumptions = () => import(/* webpackChunkName: "analysis-editor" */ './components/MsrAssumptions.vue')
// const Calculations = () => import(/* webpackChunkName: "analysis-editor" */ './components/Calculations.vue')
// const Results = () => import(/* webpackChunkName: "analysis-editor" */ './components/Results.vue')
// const FlexGridFileEditor = () => import(/* webpackChunkName: "grid-editor" */ './components/FlexGridFileEditor.vue')
// const AnalysisODataEditor = () => import(/* webpackChunkName: "grid-editor" */ './components/AnalysisODataEditor.vue')
// const Settings = () => import(/* webpackChunkName: "settings" */ './components/Settings.vue')
// const SettingsApplication = () => import(/* webpackChunkName: "settings" */ './components/SettingsApplication.vue')
// const SettingsMsrDefaults = () => import(/* webpackChunkName: "settings" */ './components/SettingsMsrDefaults.vue')

Vue.use(Router)

const router = new Router({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: config.publicPath,
  routes: [
    {
      path: '/',
      name: 'AnalysisManager',
      components: {
        default: AnalysisManager,
        headerContent: AppTitle
      },
      meta: { title: 'Analysis Manager' }
    },
    {
      path: '/edit/:id',
      components: {
        default: AnalysisEditor,
        headerContent: AnalysisEditorHeader
      },
      props: {
        default: true,
        headerContent: true
      },
      children: [
        {
          path: '',
          name: 'Inputs',
          component: InputFiles,
          props: true
        },
        {
          path: 'portfolio',
          name: 'Portfolio',
          component: Portfolio,
          props: true
        },
        {
          path: 'servicing',
          name: 'Servicing',
          component: MsrAssumptions,
          props: true
        },
        {
          path: 'points',
          name: 'Points',
          component: Points,
          props: true
        },
        {
          path: 'scenarios',
          name: 'Scenarios',
          component: Scenarios,
          props: true,
          children: [
            {
              path: 'rates',
              name: 'Rates',
              component: ScenarioRates,
              props: true
            },
            {
              path: 'hpi',
              name: 'HPI',
              component: ScenarioHpi,
              props: true
            },
            {
              path: 'unemployment',
              name: 'Unemployment',
              component: ScenarioUnemployment,
              props: true
            },
            {
              path: 'manage',
              name: 'Manage',
              component: ScenarioManager,
              props: true
            }
          ]
        },
        {
          path: 'calculations',
          name: 'Calculations',
          component: Calculations,
          props: true
        },
        {
          path: 'results',
          name: 'Results',
          component: Results,
          props: true
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      components: {
        default: Login,
        headerContent: AppTitle
      }
    },
    {
      path: '/users',
      name: 'UserManager',
      components: {
        default: UserManager,
        headerContent: AppTitle
      },
      meta: { title: 'Manage Users' }
    },
    {
      path: '/settings',
      components: {
        default: Settings,
        headerContent: AppTitle
      },
      children: [
        {
          path: '',
          name: 'Settings',
          component: SettingsApplication,
          props: true,
          meta: { title: 'Application Settings' },
        },
        {
          path: 'servicing',
          name: 'ServicingSettings',
          component: SettingsMsrDefaults,
          props: true,
          meta: { title: 'Application Settings' },
        }
      ]
    },
    {
      path: '/setpassword',
      name: 'SetPassword',
      components: {
        default: SetPassword,
        headerContent: AppTitle
      }
    },
    {
      path: '/passwordreset',
      name: 'PasswordReset',
      components: {
        default: PasswordReset,
        headerContent: AppTitle
      }
    },
    {
      path: '/account',
      name: 'Account',
      meta: { title: 'Account' },
      components: {
        default: AccountManager,
        headerContent: AppTitle
      }
    },
    {
      path: '/usernamelookup',
      name: 'UsernameLookup',
      components: {
        default: UsernameLookup,
        headerContent: AppTitle
      }
    },

    {
      path: '/edit/:analysisId/:route',
      name: 'ODataEditor',
      component: AnalysisODataEditor,
      props: true,
      meta: { hideHeader: true }
    },

    {
      path: '/edit/:analysisId/files/:path',
      name: 'FileEditor',
      component: FlexGridFileEditor,
      props: true,
      meta: { hideHeader: true }
    },

    {
      path: '/edit/:analysisId/grid-editor',
      name: 'GridEditor',
      component: FlexGridFileEditor,
      props: true,
      meta: { hideHeader: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const isSecureRoute = (path) => {
    let p = path ? path.toLowerCase() : "";
    return p != '/login' && !p.includes('/setpassword') && p != '/passwordreset' && p != '/usernamelookup';
  };
  const redirectToLogin = () => {
    // Redirect to Login to get credentials
    if (!store.getters.isLoggedIn && isSecureRoute(to.path)) {
      next('/Login');
    } else {
      next();
    }
  };
  const checkLogin = () => {
    // Check localStorage for auth token
    if (!store.getters.isLoggedIn && isSecureRoute(to.path)) {
      try {
        let tokens = tokenStore.getTokens();
        let server = store.state.authentication.server || localStorage.getItem('kinetics_server');

        if (tokens && tokens.jwtToken) {
          store.commit('setAuth', Object.assign({}, tokens, { server }));
        }
      }
      catch {
        // Error getting credentials from localStorage, just display login
      }
    }

    // Check license info
    if (store.getters.isLoggedIn && isSecureRoute(to.path) && !store.state.licenseInfo.hasLicenseKey) {
      store.dispatch('getLicense').then(redirectToLogin);
    } else {
      redirectToLogin();
    }
  };

  if (store.state.authentication.enabled === null) {
    if (isElectron()) {
      // Set default url
      let server = 'http://localhost:8008';
      store.commit('setAuth', { server });
      store.dispatch('getAuthSettings')
        .catch((e) => {
          // Could not contact server
          // Set to require auth
          store.commit('setAuth', { enabled: true });
          checkLogin();
        })
        .then(() => {
          localStorage.setItem('kinetics_server', server);
          checkLogin();
        });
    } else {
      store.commit('setAuth', { enabled: true });
      checkLogin();
    }
  } else {
    checkLogin();
  }
});

export default router
