<template>
  <div>
    <collapsible-section
      section-title="Scenario Analysis"
      hide-toggle
    >
      <collapsible-options
        :value="enableScenarioGrid"
        @input="enableScenarioGridWithPrices($event)"
        title="20 Scenario Grid" 
        @change="setRepricing($event && scenarioGridRepricing, fileNames.scenarios, fileNames.scenarioGridRepricing)"
        collapsible
      >
        <b-btn
          class="mt-2 mr-2 mb-2"
          variant="secondary"
          @click="stressTestClicked=false;showImportScenarioDialog=true"
        >
          Import Scenarios
        </b-btn>

        <b-btn
          class="mt-2 mb-2"
          @click="showScenarios(fileNames.scenarios)"
        >
          View Scenarios
        </b-btn>

        <file-select-with-checkbox 
          label="Enable Forward Repricing"
          :value="scenarioGridRepricing"
          @input="$emit('update:scenarioGridRepricing', $event)"
          :file-name="fileNames.scenarioGridRepricing" 
          @change="setRepricing($event, fileNames.scenarios, fileNames.scenarioGridRepricing)"
        />
      </collapsible-options>
      
      <collapsible-options
        :value="enableStressTests"
        @input="enableStressTestWithPrices($event)"
        title="Stress Test and User-Defined Scenarios" 
        @change="setRepricing($event && stressTestRepricing, fileNames.stressScenarios, fileNames.stressTestRepricing)"
        collapsible
      >
        <b-btn
          class="mt-2 mr-2 mb-2"
          variant="secondary"
          @click="stressTestClicked=true;showImportScenarioDialog=true"
        >
          Import Scenarios
        </b-btn>

        <b-btn
          class="mt-2 mb-2"
          @click="showScenarios(fileNames.stressScenarios)"
        >
          View Scenarios
        </b-btn>

        <file-select-with-checkbox 
          label="Enable Forward Repricing"
          :value="stressTestRepricing"
          @input="$emit('update:stressTestRepricing', $event)"
          :file-name="fileNames.stressTestRepricing" 
          @change="setRepricing($event, fileNames.stressScenarios, fileNames.stressTestRepricing)"
        />
      </collapsible-options>

      <!-- This is not currently functional!!
        <collapsible-options
        v-if="enable('showB5')"
        v-model="calcs.showB5" 
        title="Show B5 measure (shortfall - premiums)" 
        :collapsible="true"
        @input="updateB5"
      >
        <div
          v-if="calcs.showB5"
          class="pl-4 pt-1 pb-3"
        >
          <b5-fields v-model="calcs.b5calcs" />
        </div>
      </collapsible-options> -->

      <collapsible-options
        :value="fieldConfigMetaData.enableExternalHpaAndUnemployment"
        @input="$emit('update-field-config', { enableExternalHpaAndUnemployment: $event })"
        title="External HPA and Unemployment" 
        collapsible
      >
        <b-form-group
          label="HPA/Unemp IR Adjustment"
          label-for="stress-test-ir-adjust"
          :label-cols="5"
        >
          <b-select
            id="stress-test-ir-adjust"
            class="field-md"
            :value="scenarioIrAdjust"
            @input="$emit('update:scenarioIrAdjust', $event)"
            :options="scenarioIrAdjustOptions"
            size="sm"
          />
        </b-form-group>
        <b-tooltip 
          id="ir-adjust-tooltip"
          target="stress-test-ir-adjust"
          triggers="hover"
        >
          <div class="mb-1">
            None - Uses HPA files specified but does not employ the AD&amp;Co model to adjust for different HPA tunings, interest rates or random simulations.
          </div>
          <div class="mb-1">
            Off Static - Uses HPA files specified for the static (no-change) rate scenario and adjusts the HPAs for other scenarios (forward, random, tuned) using the AD&amp;Co model.
          </div>
          <div>
            Off Forward - Uses HPA files specified for the forward rate scenario and adjusts the HPAs for other scenarios (static, random, tuned) using the AD&amp;Co model.
          </div>
        </b-tooltip>

        <b-form-checkbox
          id="stress-test-local-adjust"
          :checked="scenarioLocalityAdjust"
          @input="$emit('update:scenarioLocalityAdjust', $event)"
        >
          HPA/Unemp Locality Adjustment
        </b-form-checkbox>

        <b-form-checkbox
          v-b-tooltip.hover.right
          id="stress-test-season-adjust"
          :checked="scenarioAddSeasonality"
          @input="$emit('update:scenarioAddSeasonality', $event)"
          title="Adds seasonality to the HPA files as computed by the AD&amp;Co model."
          inline
        >
          Add Seasonality to HPA
        </b-form-checkbox>

        <file-select-with-checkbox 
          label="Use HPA Zip Map"
          :value="useHpaZipMap"
          @input="$emit('update:useHpaZipMap', $event)"
          :file-name="fileNames.hpaZipMap" 
        />

        <file-select-with-checkbox 
          label="Use Unemployment Zip Map"
          :value="useUnempZipMap"
          @input="$emit('update:useUnempZipMap', $event)"
          :file-name="fileNames.unempZipMap" 
        />
      </collapsible-options>
    </collapsible-section>

    <scenario-viewer-dialog
      v-model="showScenarioDialog"
      :file-name="scenarioFileName"
      :show-reprice="enableStressTests && stressTestRepricing"
      :files-imported="filesImported"
    />

    <import-scenario-dialog 
      v-model="showImportScenarioDialog"
      :is-stress-test="stressTestClicked"
      @imported="scenariosImported($event)"
    />

    <modal-dialog
      v-model="showImportComplete"
      title="File Import Complete"
      confirm-button-text="OK"
      ok-only
    >
      <span>
        Imported {{ filesImported }} file{{ filesImported > 1 ? 's' : '' }} successfully.
      </span>
    </modal-dialog>
  </div>
</template>

<script>
import ScenarioViewerDialog from './ScenarioViewerDialog.vue'
import ImportScenarioDialog from './ImportScenarioDialog.vue'
import CollapsibleOptions from './CollapsibleOptions.vue'
import FileSelectWithCheckbox from './FileSelectWithCheckbox.vue'
import CollapsibleSection from './CollapsibleSection.vue'
import ModalDialog from './ModalDialog.vue'
import { fileNames } from '../js/fileInfo'
import { mapMutations, mapState } from 'vuex'
import api from '../api'
import { scenarioIrAdjustOptions } from '../js/options/scenarioIrAdjustOptions'


export default {
  props: {
    enableScenarioGrid: { type: Boolean, required: true },
    scenarioGridRepricing: { type: Boolean, required: true },
    enableStressTests: { type: Boolean, required: true },
    stressTestRepricing: { type: Boolean, required: true },
    scenarioIrAdjust: { type: String, required: true },
    scenarioLocalityAdjust: { type: Boolean, required: true },
    scenarioAddSeasonality: { type: Boolean, required: true },
    useHpaZipMap: { type: Boolean, required: true },
    useUnempZipMap: { type: Boolean, required: true },
    fieldConfigMetaData: { type: Object, required: true }
  },
  data () {
    return {
      fileNames,
      showScenarioDialog: false,
      scenarioFileName: fileNames.scenarios,
      showImportScenarioDialog: false,
      scenarioIrAdjustOptions,
      showImportComplete: false,
      filesImported: 0,
      stressTestClicked: false
    }
  },
  computed: {
    ...mapState(['activeAnalysis'])
  },
  methods: {
    ...mapMutations(["toggleFile"]),

    async setRepricing (enabled, scenarioTemplate, repricingFile) {
      let data = await api.getFile(this.activeAnalysis.id, scenarioTemplate);

      // Update repricing data file in scenario
      data.forEach(r => {
        r["REPRICEDataFile"] = enabled ? repricingFile : '';
      });
      
      let url = api.getFilePath(this.activeAnalysis.id, scenarioTemplate);
      await api.postFile(url, data);
    },
    showScenarios (fileName, filesImported = 0) {
      this.scenarioFileName = fileName;
      this.filesImported = filesImported;
      this.showScenarioDialog = true;
    },
    scenariosImported(evt) {
      if (evt.scenariosImported) {
        this.showScenarios(this.stressTestClicked ? this.fileNames.stressScenarios : this.fileNames.scenarios, evt.fileCount);
      } else {
        this.filesImported = evt.fileCount;
        this.showImportComplete = true;
      }
    },
    enableScenarioGridWithPrices (enable) {
      this.$emit('update:enableScenarioGrid', enable);
      this.$emit('update:scenarioGridPrice', enable);
    },
    enableStressTestWithPrices (enable) {
      this.$emit('update:enableStressTests', enable);
      this.$emit('update:stressTestPrice', enable);
    }
  },
  components: {
    ScenarioViewerDialog,
    CollapsibleOptions,
    CollapsibleSection,
    FileSelectWithCheckbox,
    ImportScenarioDialog,
    ModalDialog
  }
}
</script>

<style scoped>
h3 {
  font-size: 0.8em;
  font-weight: bold;
  margin: 15px 0 10px 0;
  padding-bottom: 3px;
  border-bottom: 1px solid #bfc0c9;
}
</style>

<style>
#ir-adjust-tooltip > .tooltip-inner {
  max-width: 400px;
}
</style>
