<template>
  <div>
    <div class="content scrollable theme-item">
      <div class="btn-container-right">
        <b-button
          variant="secondary"
          @click="showImportDialog=true"
        >
          Import Analysis
        </b-button>
        <b-button
          variant="primary"
          @click="showCreateDialog=true"
        >
          New Analysis
        </b-button>
      </div>

      <b-alert
        :show="!!errorMessage"
        variant="danger"
      >
        {{ errorMessage }}
      </b-alert>
      <div 
        class="scrollable theme-item"
        id="analyses-table"
      >
        <b-table 
          table-class="table-modern theme-item"
          :items="analyses" 
          :fields="fields" 
          :busy="loading" 
          tbody-tr-class="clickable" 
          :show-empty="true" 
          empty-text="No analyses found. Create a new analysis to get started." 
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          @row-clicked="openAnalysis"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner
                class="align-middle"
                small
              />
              <strong> Loading...</strong>
            </div>
          </template>

          <template #head(bulk_delete)>
            <icon-button
              icon="check-square"
              title="Select All Analyses"
              class="btn-select-all"
              @click="selectAllAnalyses"
            />

            <icon-button
              :disabled="selectedAnalyses.length==0"
              icon="minus-square"
              title="Deselect All Analyses"
              class="btn-deselect-all"
              @click="selectedAnalyses=[]"
            />

            <icon-button
              :disabled="selectedAnalyses.length==0"
              icon="trash"
              title="Delete Selected Analyses"
              class="btn-bulk-delete"
              @click="showBulkDeleteDialog=true"
            />
          </template>

          <template #cell(bulk_delete)="data">
            <b-form-checkbox
              v-model="selectedAnalyses"
              title="Select Analysis"
              :value="data.item"
            />
          </template>

          <template #cell(statusLabel)="data">
            <b-badge :variant="getStatusVariant(data.item.executionStatus)">
              {{ getStatusText(data.item.executionStatus) }}
            </b-badge>
          </template>

          <!-- Removed because this functionality is not implemented
        <template v-slot:cell(retention)="data">
          <b-badge v-if="data.item.persist" variant="success">Permanent</b-badge>
          <b-badge v-else variant="warning">30 Days</b-badge>
        </template> -->

          <template #cell(actions)="data">
            <b-dropdown
              text="..."
              :variant="variant"
              toggle-class="analysis-menu-btn"
              no-caret
              right
            >
              <b-dropdown-item @click="openAnalysis(data.item)">
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click="cloneAnalysis(data.item)">
                Duplicate
              </b-dropdown-item>
              <b-dropdown-item @click="exportAnalysis(data.item)">
                Export
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDelete(data.item)">
                Delete
              </b-dropdown-item>
            <!-- <b-dropdown-item @click="setPersist(data.item.id, !data.item.persist)">{{data.item.persist ? 'Keep for 30 days' : 'Keep permanently'}}</b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-if="analyses.length>0"
          v-model="pagination.currentPage"
          :total-rows="analyses.length"
          :per-page="pagination.perPage"
          @change="changePagination"
          hide-ellipsis
        />
      </div>
    
      <create-analysis-dialog v-model="showCreateDialog" />
      <import-analysis-dialog v-model="showImportDialog" />
      <clone-analysis-dialog
        v-model="showCloneDialog"
        :selected-analysis="clickedAnalysis"
      />
      <license-key-modal v-model="showLicenseDialog" />

      <modal-dialog
        v-model="showDeleteDialog"
        title="Delete Analysis"
        :prompt="`Are you sure you want to delete the analysis '${clickedAnalysis && clickedAnalysis.name}'?`"
        :action="deleteAnalysis"
        error-message="An error occurred deleting the analysis."
        confirm-button-text="Delete"
      />
      <modal-dialog
        v-model="showBulkDeleteDialog"
        title="Delete Analyses"
        :action="bulkDeleteAnalyses"
        error-message="An error occurred deleting the analyses."
        confirm-button-text="Delete"
      >
        Are you sure you want to delete the following analyses?
        <ul>
          <li
            v-for="analysis in selectedAnalyses"
            :key="analysis.id"
          >
            {{ analysis.name }}
          </li>
        </ul>
      </modal-dialog>
      <modal-dialog
        v-model="showLicenseExpiredDialog"
        title="License Expired"
        confirm-button-text="OK"
        ok-only
      >
        <span>Your license key has expired. Please check for a new license key in the 
          <a
            href="https://www.ad-co.com/downloads"
            target="_blank"
          >AD&amp;Co Client Portal</a> or contact <a href="mailto:support@ad-co.com">support@ad-co.com</a>.
        </span>
      </modal-dialog>
    </div>
  </div>
</template>

<script>
import formatters from '../js/formatters'
import analysisStatus from '../js/mixins/analysisStatus'
import api, { apiVersion } from '../api'
import { mapActions, mapMutations, mapState } from 'vuex';

import CreateAnalysisDialog from  './CreateAnalysisDialog.vue'
import ImportAnalysisDialog from  './ImportAnalysisDialog.vue'
import CloneAnalysisDialog from './CloneAnalysisDialog.vue'
import LicenseKeyModal from './LicenseKeyModal.vue'
import ModalDialog from './ModalDialog.vue'
import { delay } from '../api/util';
import moment from 'moment';
import {assetTypes } from '../js/options/assetType'
import IconButton from './IconButton.vue'

const CONNECTION_ERROR_MSG = "An error occurred connecting to the Kinetics Service";

export default {
  data () {
    return {
      analyses: [],
      selectedAnalyses: [],
      clickedAnalysis: null,
      loading: false,
      errorMessage: null,
      fields: [
        { key: 'bulk_delete', label: '', class: 'text-center d-table-cell'},
        { key: 'id', label: 'Analysis ID', sortable: true, class: 'text-center col-width-10' },
        { key: 'name', label: 'Analysis Name', sortable: true, class: 'col-width-30' },
        { key: 'statusLabel', label: 'Status', sortable: false, class: apiVersion == 1 ? 'd-none' : 'd-none d-md-table-cell' },
        { key: 'createDate', label: 'Create Date', sortable: true, formatter: 'formatDateString', class: 'd-none d-lg-table-cell' },
        { key: 'modifiedDate', label: 'Last Modified Date', sortable: true, formatter: 'formatDateString', class: 'd-none d-xl-table-cell' },
        //{ key: 'retention', label: 'Retention', class: 'text-center d-none d-lg-table-cell' },
        { key: 'actions', label: '', class: 'table-actions text-center col-width-10' }
      ],
      showCreateDialog: false,
      showImportDialog: false,
      showCloneDialog: false,
      showDeleteDialog: false,
      showBulkDeleteDialog: false,
      showLicenseDialog: false,
      showLicenseExpiredDialog: false,
      pagination: {
        perPage: 25,
        currentPage: 1
      },
      poll: false
    }
  },
  async mounted () {
    this.loading = true;

    this.checkLicense();

    // Get list of analyses
    let success = await this.getAnalyses();

    // Begin polling for analysis changes
    if (success) {
      this.pollAnalyses();
    }
    this.pagination.currentPage = this.analysisPage;
    if(this.analysisPageScrollPosition) {
      const analysesTable = document.querySelector('#analyses-table');
      analysesTable.scrollTop = this.analysisPageScrollPosition;
    }
    this.updateAnalysisPageScrollPosition(0)
    this.updateAnalysisPage(1)
  },
  beforeRouteLeave (to, from, next) {
    this.poll = false;
    next();
  },
  computed: {
    ...mapState(['theme', 'checkedLicense', 'licenseInfo', 'analysisPage','analysisPageScrollPosition']),

    variant () {
      return this.theme == 'light-theme' ? 'dark' : 'light';
    },

    currentPage(){
      return this.pagination.currentPage;
    }
  },
  methods: {
    ...mapActions(['getLicense']),
    ...mapMutations(['addExport','updateAnalysisPage','updateAnalysisPageScrollPosition']),

    async pollAnalyses () {
      this.poll = true;

      while (this.poll) {
        try {
          await delay(5000);

          // Make sure we are still polling
          if (!this.poll) {
            break;
          }

          this.analyses = await api.getAnalyses();
          this.errorMessage = false;
        } catch {
          // TODO: verify network error before displaying this error message
          this.errorMessage = CONNECTION_ERROR_MSG;
          this.poll = false;
        }
      }
    },

    async getAnalyses() {
      this.loading = true;

      try {
        this.analyses = await api.getAnalyses();
        this.errorMessage = false;
        this.loading = false;
        return true;
      } catch {
        // TODO: verify network error before displaying this error message
        this.errorMessage = CONNECTION_ERROR_MSG;
        this.loading = false;
        return false;
      }
    },
    confirmDelete (analysis) {
      this.clickedAnalysis = analysis;
      this.showDeleteDialog = true;
    },
    async deleteAnalysis() {
      await api.deleteAnalysis(this.clickedAnalysis.id);
      await this.getAnalyses();
    },
    async bulkDeleteAnalyses() {
      for (let i=0; i < this.selectedAnalyses.length; i++) {
        await api.deleteAnalysis(this.selectedAnalyses[i].id);
      }
      this.selectedAnalyses = [];
      await this.getAnalyses();
    },
    openAnalysis (analysis) {
      if (analysis.assetType == assetTypes.servicing) {
        this.$router.push({ name: 'Servicing', params: { id: analysis.id }});
      } else if (analysis.runType == 'LoanDynamics') {
        this.$router.push({ name: 'Portfolio', params: { id: analysis.id }});
      } else {
        this.$router.push({ name: 'Inputs', params: { id: analysis.id }});
      }
      const analysesTable = document.querySelector('#analyses-table');
      this.updateAnalysisPageScrollPosition(analysesTable.scrollTop)
    this.updateAnalysisPage(this.currentPage)
    },
    async exportAnalysis(analysis) {
      this.addExport({
        analysisId: analysis.id,
        exportMessage: `Exporting analysis "${analysis.name}"...`,
        errorMessage: `Error exporting analysis "${analysis.name}"`,
        promise: this.$http.post(`/analyses/${analysis.id}/export`)
      });
    },
    cloneAnalysis (analysis) {
      this.clickedAnalysis = analysis;
      this.showCloneDialog = true;
    },
    setPersist(analysisId, persist) {
      api.updateAnalysis({
        id: analysisId,
        persist
      });

      let analysis = this.analyses.find(h => h.id == analysisId)
      analysis.persist = persist
    },
    selectAllAnalyses() {
      let skip = (this.pagination.currentPage - 1)*this.pagination.perPage;
      let count = Math.min(25, this.analyses.length - skip);
      let currentPageAnalyses = []
      for(let i = skip; currentPageAnalyses.length < count; i++) currentPageAnalyses.push(this.analyses[i]);
      this.selectedAnalyses = currentPageAnalyses;
    },
    changePagination() {
      this.selectedAnalyses = [];
      const analysesTable = document.querySelector('#analyses-table');
      analysesTable.scrollTop = 0;
    },
    checkLicense() {
      // Show dialog for missing license key
      if (!this.licenseInfo.hasLicenseKey) {
        this.showLicenseDialog = true;
      }

      // Show dialog for expired license key
      let dt = moment();
      if (this.licenseInfo.hasLicenseKey &&
          this.licenseInfo.modules.every(m => dt.isAfter(m.licenseExpiration))) {
        this.showLicenseExpiredDialog = true;
      }
    }
  },
  components: {
    CreateAnalysisDialog,
    ImportAnalysisDialog,
    CloneAnalysisDialog,
    LicenseKeyModal,
    ModalDialog,
    IconButton
  },
  mixins: [formatters, analysisStatus]
}
</script>

<style>
.table > tbody > tr > td.table-actions {
  padding: 9px 15px;
}

.table.b-table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 999;
}

.table-actions .analysis-menu-btn {
  padding: 0 10px 8px 10px;
  line-height: 1;
  font-size: 1.2em;
}
</style>