export default {
  title: 'Custom Points',
  entitySet: 'PenaltyPoint',
  columns: [
    { binding: 'age', header: 'Age', format: 'MM-yyyy', isReadOnly: false, desc: 'Loan age', example: '0', type: 'int', isRequired: true },
    { binding: 'point', header: 'Point', desc: 'Penalty point value', example: '3', type: 'double', isRequired: true },
    { binding: 'penaltyPointSetName', header: 'Penalty Point Set Name', desc: 'Name of penalty point set', example: 'Test Points', type: 'string', isRequired: true },
  ],
  editOptions: {
    keys: ['id'],
    allowAddNew: true,
    allowDelete: true,
    allowSave: true
  },
  defaultSort: [
    { property: 'penaltyPointSetName' },
    { property: 'age' }
  ]
}