import { httpRequest, RequestErrorEventArgs, isFunction } from '@grapecity/wijmo'

/**
 * Work around to send authorize header when commitChanges is called
 * Work around to limit batch size to 1000 operations
 */

function sendbatches(results, callback) {
  var self = this;
  let p = new Promise((resolve, reject) => {
    while (results.length > 0)
    {
      var match = (new Date).getTime().toString();
      // Split results into batches of 1000
      var items = results.splice(0, 1000);
  
      httpRequest(this._getServiceUrl() + "$batch", {
        method : "POST",
        requestHeaders : {
          "Content-Type" : 'multipart/mixed; boundary="' + match + '"',
          // BUGFIX: Add custom headers
          ...self.requestHeaders
        },
        data : this._encodeBatch(items, match),
        success : function() {
          if (results.length == 0) {
            resolve();
            self.clearChanges();
            // Don't reload all the data
            //self.load();
          }
        },
        error : function(error) {
          if (self.onError(new RequestErrorEventArgs(error))) {
            let error = "HttpRequest Error: " + error.status + " " + error.statusText;
            reject(error);
          }
        },
        complete : function(errorValue) {
          if (results.length == 0 && isFunction(callback)) {
            callback(errorValue);
          }
        }
      });
    }
  });

  return p;
}

export default function (callback) {
  var self = this;
  if (this.deferCommits) {
    var results = [];
    this.itemsEdited.forEach(function(e) {
      results.push({
        method : "PATCH",
        url : self._getWriteUrl(e),
        data : self._convertToDbFormat(e)
      });
    });
    this.itemsAdded.forEach(function(e) {
      results.push({
        method : "POST",
        url : self._getWriteUrl(),
        data : self._convertToDbFormat(e)
      });
    });
    this.itemsRemoved.forEach(function(metrics) {
      results.push({
        method : "DELETE",
        url : self._getWriteUrl(metrics)
      });
    });
    
    if (results.length) {
      return sendbatches.call(this, results, callback);
    }
  }
}