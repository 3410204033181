<template>
  <div
    id="grid-container"
    style="height: 100%;"
  >
    <wj-flex-grid
      id="grid-editor" 
      :initialized="initGrid" 
      :allow-add-new="true" 
      :allow-sorting="false" 
      :frozen-columns="1"
      :auto-generate-columns="false"
      :items-source="gridData"
    >
      <wj-flex-grid-column
        v-for="col in columns"
        :key="col.binding"
        :header="col.header"
        :binding="col.binding"
        :data-type="col.dataType"
        :is-required="col.required || false"
      />
    </wj-flex-grid>
    <div
      class="wijmo-menu"
      style="display: none;"
    />
  </div>
</template>

<script>
import "@grapecity/wijmo.vue2.grid";
import { KeyAction } from '@grapecity/wijmo.grid';
import { default as GridMenu, gridContext } from '../js/gridExtensions/GridMenu'
import DropDownEditor from '../js/gridExtensions/DropDownEditor'
import GridValidator from '../js/gridExtensions/GridValidator'

export default {
  props: {
    name: { type: String, default: 'data' },
    columns: { type: Array, required: true },
    gridData: { type: Array, required: true },
    newRowValues: { type: Object, required: true },
    validations: { type: Object, required: false },
    selectEditors: { type: Array, required: false }
  },
  data () {
    return {
      newItemCount: 0
    }
  },
  methods: {
    initGrid (flex) {
      flex.keyActionTab = KeyAction.Cycle;
      flex.autoSizeColumns(0, flex.columns.length, false);

      flex.collectionView.newItemCreator = () => {
        this.newItemCount += 1;
        return Object.assign({}, this.newRowValues, { id: 0, name: `new type ${this.newItemCount}` });
      }

      flex.rowEditEnded.addHandler(() => {
        this.$emit('row-edit-ended');
      });

      new GridMenu('#grid-container .wijmo-menu', flex, 
      [
        { 
          header: 'Insert Row', context: gridContext.rowHeader, cmd: () => { 
            let row = Object.assign({}, this.newRowValues, { msrType: 'new type' })

            this.gridData.splice(flex.selectedRows[0].index, 0, row);
            flex.collectionView.refresh();
          }
        },
        { 
          header: 'Delete Rows', context: gridContext.rowHeader, cmd: () => { 
            const endRow = flex.selectedRows[flex.selectedRows.length-1];
            this.$emit('delete-rows', { startIndex: flex.selectedRows[0].index, endIndex: endRow.index });
            flex.collectionView.refresh();
          } 
        }
      ]);

      if (this.validations) {
        new GridValidator(flex, this.validations);
      }

      if (this.selectEditors) {
        this.selectEditors.forEach(s => {
          new DropDownEditor(flex, s.columnName, s.options);
        });
      }
    }
  }
}
</script>