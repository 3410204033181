export default {
  title: 'Scenario',
  entitySet: 'Scenario',
  columns: [
    { binding: 'date', header: 'Date', format: 'MM-yyyy', isReadOnly: false, desc: 'Forecast date', example: '2021-05', type: 'string', isRequired: true },
    { binding: 'forecast', header: 'Forecast', desc: 'Forecast value', example: '3.7', type: 'double', isRequired: true },
    { binding: 'scenarioName', header: 'Scenario Name', desc: 'Scenario name', example: 'Test Scenario', type: 'string', isRequired: false },
    { binding: 'forecastType', header: 'Forecast Type', desc: 'Type of forecast', example: 'Swap10Yr', type: 'string', isRequired: true }
  ],
  editOptions: {
    keys: ['id'],
    allowAddNew: true,
    allowDelete: true,
    allowSave: true
  },
  defaultSort: [
    { property: 'scenarioName' },
    { property: 'date' }
  ]
}