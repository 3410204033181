<template>
  <modal-dialog 
    title="Create User" 
    confirm-button-text="Create" 
    :action="createUser" 
    @clear="clearDialog"
    :value="value"
    @input="$emit('input', $event)"
    :error-message="errorMessage"
    @confirmed="$emit('confirmed')"
  >
    <b-form-group
      label="Email Address"
      label-for="email"
    >
      <b-form-input
        id="email-address"
        v-model="$v.newUser.emailAddress.$model"
        :state="state($v.newUser.emailAddress)"
        required
        autofocus
        autocomplete="off"
      />
      <b-form-invalid-feedback :state="state($v.newUser.emailAddress)">
        Valid email address is required
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Username"
      label-for="name"
    >
      <b-form-input
        id="username"
        v-model="$v.newUser.userName.$model"
        :state="state($v.newUser.userName)"
        required
        autocomplete="off"
      />
      <b-form-invalid-feedback :state="state($v.newUser.userName)">
        {{ validationMessage($v.newUser.userName) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label-for="send-registration-email">
      <b-form-checkbox
        id="send-registration-email"
        v-model="newUser.sendRegistrationEmail"
        @change="clearPassword"
      >
        Send registration email - user will set password
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      v-if="!newUser.sendRegistrationEmail"
      label="Password"
      label-for="password"
    >
      <b-form-input
        id="password"
        v-model="$v.newUser.password.$model"
        :state="state($v.newUser.password)"
        type="password"
        autocomplete="new-password"
      />
      <b-form-invalid-feedback :state="state($v.newUser.password)">
        A password of at least {{ $v.newUser.password.$params.minLength.min }} characters is required
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-if="!newUser.sendRegistrationEmail"
      label="Confirm Password"
      label-for="confirm-password"
    >
      <b-form-input
        id="confirm-password"
        v-model="$v.newUser.confirmPassword.$model"
        :state="state($v.newUser.confirmPassword)"
        type="password"
        autocomplete="off"
      />
      <b-form-invalid-feedback :state="state($v.newUser.confirmPassword)">
        Passwords must match
      </b-form-invalid-feedback>
    </b-form-group>
    
    <b-form-group label="Module Permissions">
      <b-form-checkbox-group
        v-model="newUser.modules"
        :options="moduleOptions"
        :state="state($v.newUser.modules)"
      />
      <b-form-invalid-feedback :state="state($v.newUser.modules)">
        Select at least one module
      </b-form-invalid-feedback>
    </b-form-group>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'
import api from '../api'
import getErrorMessage from '../js/getErrorMessage'

export default {
  props:{
    value: { type: Boolean }
  },
  data() {
    return {
      newUser: {
        userName: null,
        emailAddress: null,
        sendRegistrationEmail: null,
        password: null,
        confirmPassword: null,
        modules: []
      },
      errorMessage: 'Unknown error creating user.',
      modules: []
    };
  },
  async mounted () {
    this.modules = await api.getModules();
  },
  computed: {
    moduleOptions () {
      return this.modules.map((m) => ({ value: m.id, text: m.name }));
    }
  },
  validations: {
    newUser: {
      userName: { required, minLength: minLength(4) },
      emailAddress: { required, email },
      password: { minLength: minLength(8) },
      confirmPassword: { sameAsPassword: sameAs(function() { return this.newUser.password }) },
      modules: { required }
    }
  },
  methods: {
    async createUser() {
      this.$v.$touch();

      //console.log(this.newUser.modules);

      if (this.$v.newUser.$invalid) {
        return false;
      }

      try {
        if(this.newUser.sendRegistrationEmail) {
          await api.createUser({username: this.newUser.userName, email: this.newUser.emailAddress, modules: this.newUser.modules });
        }
        else {
          await api.createUser({username: this.newUser.userName, email: this.newUser.emailAddress, password: this.newUser.password, modules: this.newUser.modules });
        }
        
        this.$emit('user-created');
        return true;
      } 
      catch(error) {
        this.errorMessage = getErrorMessage(error, 'Unknown error creating user.');
        throw err;
      }
      
    },
    clearDialog () {
      this.newUser.userName = null;
      this.newUser.emailAddress = null;
      this.newUser.password = null;
      this.newUser.confirmPassword = null;
      this.newUser.sendRegistrationEmail = false;

      if (this.modules.length == 1) {
        this.newUser.modules = [this.modules[0].id];
      } else {
        this.newUser.modules = [];
      }
      
    },
    clearPassword () {
      this.newUser.password = null;
      this.newUser.confirmPassword = null;
    }
  },
  components: {
    ModalDialog
  },
  mixins: [validationState]
}
</script>