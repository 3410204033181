<template>
  <div>
    <b-form-file
      v-model="licKeyFile"
      class="mt-3"
      placeholder="Choose a license key file or drop it here..."
      drop-placeholder="Drop license key file here..."
    />
    <b-alert
      class="mt-3"
      :variant="msgVariant"
      :show="!!message"
    >
      {{ message }}
    </b-alert>
    <b-btn
      v-if="!hideUpdateButton"
      class="mt-3"
      @click="updateLicense"
      :disabled="!licKeyFile"
    >
      Update
      <b-spinner  
        v-if="loading" 
        small
      />
    </b-btn>
  </div>
</template>

<script>
import api from '../api'
import { mapMutations } from 'vuex'
import getErrorMessage from '../js/getErrorMessage'

export default {
  props: {
    hideUpdateButton: { type: Boolean, default: false }
  },
  data () {
    return {
      licKeyFile: null,
      message: null,
      error: false,
      loading: false,
    }
  },
  computed: {
    msgVariant () {
      return this.error ? 'danger' : 'success';
    }
  },
  methods: {
    ...mapMutations(['setLicense']),

    async updateLicense () {
      this.message = null;
      this.error = false;
      this.loading = true;

      // Verify a file is selected
      if (!this.licKeyFile) {
        this.error = true;
        this.message = "Please select a license key file.";
        this.loading = false;
        return { error: this.error, message: this.message };
      }

      try {
        let resp = await api.postFile('/license', this.licKeyFile, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.setLicense(resp.data);

        if (resp.data.hasLicenseKey) {
          this.message = "License key updated."
          this.$emit('lic-key-updated');
          this.licKeyFile = null;
        } else {
          throw "Error updating license key.";
        }
        this.loading = false;
      } catch (err) {
        this.error = true;
        this.loading = false;
        this.message = getErrorMessage(err, "Error updating license key.");
      }
      
      return { error: this.error, message: this.message };
    }
  },
  watch: {
    message() {
      setTimeout(() => { this.message = null }, 5000);
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
}
</style>