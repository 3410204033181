import { FlexSheet } from '@grapecity/wijmo.grid.sheet'
import { default as GridMenu, gridContext } from './GridMenu'

export default class {
  constructor(gridElement, options) {
    this._grid = null;
    this.itemCount = 0;
    this.filtered = false;
    this._clip = null;
    this._onSave = options.onSave;
    this._gridChanged = options.gridChanged;

    // Create FlexSheet
    let grid = new FlexSheet(gridElement);
    grid.isTabHolderVisible = false;
    
    // Setup undo / redo handlers
    grid.undoStack.undoStackChanged.addHandler((s) => {
      this._callHandler(options.canUndo, [s.canUndo]);
      this._callHandler(options.canRedo, [s.canRedo]);
      this._setItemCount(grid, options.collectionChanged);
    });

    // Load grid data
    this.loadData(options.collection)
      .then(() => {
        // Set grid count
        this.itemCount = grid.rows.length;
        this._callHandler(options.collectionChanged, [this.itemCount, false]);
      });

    this._grid = grid;

    // Set up context menus
    let insertCmd = () => this.insertRow();
    let deleteCmd = () => this.deleteSelectedRows();
    let colInsertCmd = () => this.insertColumn();
    let colDeleteCmd = () => this.deleteColumns();
    this._initMenu({ insertCmd, deleteCmd, colInsertCmd, colDeleteCmd } , options);

    // Update item count when collection changes
    grid.rowChanged.addHandler(() => {
      this._setItemCount(grid, options.collectionChanged);
      this._callHandler(options.gridChanged);
    });

    grid.cellEditEnded.addHandler(() => {
      this._callHandler(options.gridChanged);
    });

    grid.filter.filterApplied.addHandler(() => {
      this._setItemCount(grid, options.collectionChanged);
    })
  }

  _setItemCount (grid, collectionChangedHandler) {
    this.filtered = grid.filter.filterDefinition != '{"filters":[]}';
    this.itemCount = this.filtered ? grid.rows.filter(r => r.isVisible).length : grid.rows.length;
    this._callHandler(collectionChangedHandler, [this.itemCount, this.filtered]);
  }

  _initDataset (collection) {
    if (typeof collection === 'function') {
      let data = collection();

      if (data instanceof Promise) {
        // Async function call
        return data;
      } else {
        // Sync function call
        return new Promise((resolve) => {
          resolve(data);
        });
      }
    } else {
      // Handle array
      return new Promise((resolve) => {
        resolve(collection);
      });
    }
  }
  
  _initMenu (commands, options) {
    // Init menu
    let menuItems = [];

    // Insert Rows menu item
    if (options.allowAddNew && commands.insertCmd) {
      menuItems.push({ 
        header: 'Insert Row', context: gridContext.rowHeader, cmd: commands.insertCmd
      });
    }

    // Delete Rows menu item
    if (options.allowDelete && commands.deleteCmd) {
      menuItems.push({ 
        header: 'Delete Rows', context: gridContext.rowHeader, cmd: commands.deleteCmd
      });
    }

    // Insert column menu item
    if (commands.colInsertCmd) {
      menuItems.push({ 
        header: 'Insert Column', context: gridContext.columnHeader, cmd: commands.colInsertCmd
      });
    }

    // Delete columns menu item
    if (commands.colDeleteCmd) {
      menuItems.push({ 
        header: 'Delete Columns', context: gridContext.columnHeader, cmd: commands.colDeleteCmd
      });
    }

    // Create the grid menu
    var menuElement = document.createElement('div');
    new GridMenu(menuElement, this._grid, menuItems);
  }

  _callHandler(handler, params) {
    if (handler && typeof handler === 'function') {
      params ? handler(...params) : handler();
    }
  }

  async loadData (collection) {
    let data = await this._initDataset(collection);

    if (data.length && data[0] instanceof Array) {
      this._loadArray(data);
    } else if (data.columns || data[0] instanceof Object) {
      this._loadObject(data);
    } else {
      throw 'Invalid grid data.';
    }
  }
  
  _loadArray (data) {
    let rows = data.length || 2;
    let cols = data.length && data[0].length || 3;

    this._grid.addUnboundSheet('Data', rows, cols);

    // Set column widths
    this._grid.columns.forEach(c => {
      c.width = 180;
    });

    if (data.length) {
      // Add Headers
      this._grid.deferUpdate(() => {
        for (let col = 0; col < this._grid.columns.length; col++) {
          this._grid.setCellData(0, col, data[0][col]);
        }
      });

      this._grid.deferUpdate(() => {
        for (let row = 1; row < this._grid.rows.length; row++) {
          for (let col = 0; col < this._grid.columns.length; col++) {
              this._grid.setCellData(row, col, data[row][col]);
          }
        }
      });
    } else {
      this.message = 'No data in file';
    }
  }

  _loadObject (data) {
    let header = data.columns || Object.keys(data[0]).map(h => h);

    let rows = data.length || 1;
    let cols = header.length || 3;

    this._grid.addUnboundSheet('Data', rows + 1, cols);
    
    // Set column widths
    this._grid.columns.forEach(c => {
      c.width = 180;
    });

    this._grid.deferUpdate(() => {
      // Header row
      for (let col = 0; col < header.length; col++) {
          this._grid.setCellData(0, col, header[col]);
      }
    });

    if (data.length) {
      this._grid.deferUpdate(() => {
        // Data
        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < header.length; col++) {
                this._grid.setCellData(row + 1, col, data[row][header[col]]);
            }
        }
      });
    } else {
      this.message = 'No data in file';
    }
  }

  getGridData () {
    let rows = this._grid.sheets[0].rowCount;
    let cols = this._grid.sheets[0].columnCount;
    let data = [];

    for (let r = 0; r < rows; r++) {
      let row = [];

      for (let c = 0; c < cols; c++) {
        row.push(this._grid.getCellValue(r, c, false));
      }

      data.push(row);
    }

    return data;
  }

  insertRow () {
    this._grid.insertRows(this._grid.selectedRows[0].index, 1)
  }

  deleteSelectedRows () {
    this._grid.deleteRows();
  }

  insertColumn () {
    this._grid.insertColumns();
  }

  deleteColumns () {
    this._grid.deleteColumns();
  }

  copy () {
    this._clip = this._grid.getClipString() + "\r\n";
  }

  paste () {
    this._grid.setClipString(this._clip);
    this._callHandler(this._gridChanged);
  }

  undo () {
    this._grid.undoStack.undo();
    this._callHandler(this._gridChanged);
  }

  redo () {
    this._grid.undoStack.redo();
    this._callHandler(this._gridChanged);
  }

  async save () {
    this._grid.select(-1,-1);
    this._grid.undoStack.clear();
    this._grid.undoStack.onUndoStackChanged();

    if (typeof this._onSave === 'function') {
      let saved = this._onSave(this.getGridData());

      if (saved instanceof Promise) {
        return saved;
      }
    }

    return Promise.resolve(true);
  }
}