<template>
  <div>
    <div
      class="file-select"
      :class="{ invalid }"
    >
      <div class="file-status">
        <span class="file-type">{{ fileType }}</span>
        <b-badge
          v-if="!uploading"
          class="ml-2 mr-2"
          :variant="getVariant(fileList[fileName].status)"
        >
          {{ getLabel(fileList[fileName].status) }}
        </b-badge>
        <upload-progress
          v-else
          :progress="fileList[fileName].uploadProgress"
          @cancel-upload="cancelUpload(fileList[fileName].uploadCancelToken)"
        />
      </div>

      <div
        v-if="!uploading"
        class="action-buttons"
      >
        <file-select-wrapper 
          ref="fileSelect" 
          :disabled="disabled"
          @input="uploadFile(activeAnalysis.id, fileList[fileName], $event, uploadCanceled)"
        >
          <span :class="fileBrowseLinkStyle">
            <font-awesome-icon
              icon="cloud-upload-alt"
              title="Import"
              class="upload-icon"
              :class="{ invalid }"
              size="lg"
            />
          </span>
        </file-select-wrapper>
        <icon-button
          v-if="fileSelected && !hideCreateEdit"
          icon="edit"
          title="Edit File"
          :class="iconButtonStyle"
          @click="editFile"
        />
        <icon-button
          v-else-if="fileList[fileName].hasTemplate && !hideCreateEdit" 
          icon="plus-square" 
          title="Create from template" 
          :class="iconButtonStyle" 
          @click="editFile"
        />

        <icon-button
          v-if="fileSelected" 
          icon="times-circle" 
          title="Remove File" 
          class="theme-item" 
          @click="confirmDelete"
        />
      </div>
    </div>
    <b-form-invalid-feedback :state="!invalid">
      File selection required
    </b-form-invalid-feedback>

    <modal-dialog
      ref="confirmDeleteDialog"
      title="Delete Data"
      :prompt="`Are you sure you want to delete the ${ fileType } data?`"
      :action="deleteData"
      error-message="An error occurred deleting the data."
      confirm-button-text="Delete"
    />
  </div>
</template>

<script>
import FileSelectWrapper from './FileSelectWrapper.vue'
import UploadProgress from './UploadProgress.vue'
import IconButton from './IconButton.vue'
import ModalDialog from './ModalDialog.vue'
import { fileStatus } from "../js/fileInfo"
import fileUpload from '../js/mixins/fileUpload'
import fileStatusMixin from '../js/mixins/fileStatus'
import { mapState, mapMutations } from 'vuex'
import api from '../api'

export default {
  name: 'FileSelect',
  props: {
    fileName: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    allowAddNew: { type: Boolean, default: true },
    allowDelete: { type: Boolean, default: true },
    hideCreateEdit: { type: Boolean, default: false }
    
  },
  data () {
    return {
      selectedFile: {},
      editorData: [],
      fileStatus
    }
  },
  computed: {
    ...mapState(['activeAnalysis', 'fileList']),

    fileSelected () {
      return this.fileList[this.fileName].status != fileStatus.missing;
    },
    uploading () {
      return this.fileList[this.fileName].status == fileStatus.uploading;
    },
    invalid () {
      return this.fileList[this.fileName].status == fileStatus.missing &&
             this.fileList[this.fileName].dirty;
    },
    selectedFileName () {
      let file = this.fileList[this.fileName];
      return file.status == fileStatus.missing ? 'No file selected' : this.fileName;
    },
    fileType () {
      return this.fileList[this.fileName].fileType;
    },
    savePath () {
      return api.getFilePath(this.activeAnalysis.id, this.fileName);
    },
    fileBrowseLinkStyle () {
      return {
        'icon-button': true,
        'theme-item': true,
        disabled: this.disabled
      }
    },
    iconButtonStyle () {
      return {
        'ml-auto': true,
        disabled: this.disabled
      }
    }
  },
  methods: {
    ...mapMutations(['updateFile']),

    assignFile (evt) {
      if (evt) {
        this.selectedFile = evt;

        this.updateFile({
          fileName: this.fileName,
          fileInfo: {
            originalName: evt.name,
            status: fileStatus.selected,
            size: evt.size,
            modifiedDate: evt.lastModifiedDate,
            file: evt
          }
        });
      }
    },
    updateFileStatus () {
      this.updateFile({
        fileName: this.fileName,
        fileInfo: {
          originalName: this.fileName,
          status: fileStatus.posted
        }
      });
    },
    async editFile () {
      let status = this.fileList[this.fileName].status;

      if (status != fileStatus.posted) {
        // Create from template
        let inputType = this.fileList[this.fileName].inputType
        await api.post(`analyses/${this.activeAnalysis.id}/templates/${inputType}`)
      }

      // Get file path
      let folder = this.fileList[this.fileName].folder;
      let path = folder ? `${folder}/${this.fileName}` : this.fileName;

      this.$router.push({ 
        name: 'FileEditor', 
        params: { analysisId: this.activeAnalysis.id, path, returnPath: this.$route.path, allowAddNew: this.allowAddNew, allowDelete: this.allowDelete }
      });
    },
    getId() {
      let id = Math.ceil(Math.random() * 1000);
      return 'file-editor-' + id;
    },
    uploadCanceled () {
      this.$nextTick(() => {
        this.$refs.fileSelect.clear();
      });
    },
    confirmDelete () {
      this.$refs.confirmDeleteDialog.showDialog();
    },
    async deleteData () {
      await this.removeFile(this.activeAnalysis.id, this.fileList[this.fileName], this.$refs.fileSelect);
    }
  },
  components: {
    FileSelectWrapper,
    UploadProgress,
    IconButton,
    ModalDialog
  },
  mixins: [fileUpload, fileStatusMixin]
}
</script>

<style>
.file-select {
  padding: 3px 5px;
  border: 1px dashed #7c7c7c;
  display: flex;
  align-items: center;
}

.file-select.invalid {
  border-color: #dc3546;
  background-color: #dc35461f;
}

.file-select .file-name {
  padding-right: 10px;
  font-size: 0.9em;
}

.file-select .upload-icon.invalid {
  color: #3b2527;
}

.file-select > .action-buttons {
  flex: 0 0 auto;
}

.file-select > .file-status {
  flex: 1;
}

.file-select > .file-status > .file-type {
  font-size: 0.7em;
  font-weight: bold;
}
</style>

