import httpClient from '../httpClient'
import { tsvParse } from 'd3-dsv'
import { convertObjectArray } from './util'
import { saveAs } from 'file-saver'

export default {
  async getAnalyses () {
    let resp = await httpClient.get('/analyses');
    return resp.data;
  },
  async getAnalysis (id) {
    let resp = await httpClient.get(`/analyses/${id}`);
    return resp.data;
  },
  async deleteAnalysis (id) {
    await httpClient.delete(`/analyses/${id}`);
  },
  async updateAnalysis (analysis) {
    await httpClient.patch(`/analyses/${analysis.id}`, analysis);
  },
  async createAnalysis (analysis) {
    let resp = await httpClient.post('/analyses', analysis);

    return resp.data;
  },
  async getFieldsData (id) {
    let resp = await httpClient.get(`/reports/${id}`);
    return resp.data;
  },
  async saveFieldsData (reportId, analysisId, data) {
    let resp = await httpClient.patch(`/reports/${reportId}?analysisId=${analysisId}`,data);
    return resp.data;
  },
  async saveDefaultFieldsData (reportId, analysisId, data) {
    let resp = await httpClient.patch(`/reports/${reportId}?analysisId=${analysisId}&saveAsDefault=true`,data);
    return resp.statusText;
  },
  async cloneAnalysis (id, analysis) {
    let resp = await httpClient.post(`/analyses/${id}/clone`, analysis);

    return resp.data;
  },
  async getAnalysisStatus (id) {
    let resp = await httpClient.get(`/analyses/${id}/status`);

    return resp.data;
  },
  async saveAnalysisSettings (id, settings) {
    await httpClient.put(`/analyses/${id}/settings`, settings);
  },
  async saveMsrAssumptions (id, assumptions) {
    await httpClient.put(`/analyses/${id}/msrassumptions`, assumptions);
  },
  async loadMsrAssumptions (id) {
    let resp = await httpClient.get(`/analyses/${id}/msrassumptions`);
    return resp.data;
  },
  async loadAnalysisSettings (id) {
    let resp = await httpClient.get(`/analyses/${id}/settings`);
    return  resp.data;
  },
  getFilePath (id, fileName, folder) {
    if (!fileName && !folder) {
      return `/analyses/${id}/files`;
    } else if (!fileName) {
      return `/analyses/${id}/files/${folder}`;
    }
    return folder ? `/analyses/${id}/files/${folder}/${fileName}` : `/analyses/${id}/files/${fileName}`;
  },
  async getFile (id, fileName, folder, formatter) {
    let path = this.getFilePath(id, fileName, folder);
    return await this.get(path, { formatter });
  },
  async getCashFlowFile (id, formatter, selectedCashFlows,selectedLoanId,selectedPath) {
    try{
      selectedCashFlows = selectedCashFlows?.replace(/\s+/g, '')?.replace('-','');
      selectedLoanId = encodeURIComponent(selectedLoanId);
      let path = `/analyses/${id}/results/cashflows?cashflowType=${selectedCashFlows}${selectedLoanId? `&loanId=${(selectedLoanId)}` :''}${selectedPath? `&pathId=${selectedPath}` :''}`;
      
      let fileResp = await httpClient.get(path);
      
      // Calculate Turnover
      fileResp.data.forEach(d => {
        d.turnover = d.mrr - d.refi;
      });

      return fileResp.data
    }
    catch (err) {
      if (err.response.status = 404) {
      throw err;
      }
    }
    
  },
  async get (path, options) {
    try {
      let fileResp = await httpClient.get(path);

      if (fileResp.status == 200) {
        let data =  options && options.formatter ? tsvParse(fileResp.data, options.formatter) : tsvParse(fileResp.data)
        return data;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  },
  async getResults (analysisId, collectionName, limit, columns, aggregate, reportId) {
    let queryStr = limit ? `?limit=${limit}` : '';

    if (reportId) {
      queryStr += `${queryStr.length ? '&' : '?'}reportId=${reportId}`;
    }

    if (aggregate) {
      queryStr += `${queryStr.length ? '&' : '?'}aggregate=true`;
    }

    if (columns) {
      queryStr += queryStr.length ? '&' : '?';
      queryStr += columns.map(c => `columns=${c.binding}`).join("&")

      // Always include cashFlowType
      if (!columns.some(c => c.binding == "cashFlowType")) {
        queryStr += "&columns=cashFlowType";
      }
      
      // Always include assetType
      if (!columns.some(c => c.binding == "assetType")) {
        queryStr += "&columns=assetType";
      }
    }

    try {
      let resp = await httpClient.get(`/analyses/${analysisId}/results/${collectionName}${queryStr}`);
      return resp.data;
    } catch (err) {
      if (err.response.status = 404) {
        return [];
      }
      throw err;
    }

  },
  async postFile (url, file, config) {
    let axiosConf = config || {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    
    if (file instanceof File) {
      let formData = new FormData();
      formData.append('file', file);

      return await httpClient.post(url, formData, axiosConf);
    } else if(Array.isArray(file) && file.every(f => f instanceof File)) {
      let formData = new FormData();

      file.forEach(f => {
        formData.append('files', f);
      });
      
      return await httpClient.post(url, formData, axiosConf);
    } else {
      let data = file || [];
      return await httpClient.post(url, { data: convertObjectArray(data) });
    }
  },
  async post (path, data) {
    await httpClient.post(path, { data });
  },
  async deleteFile (id, fileName, folder) {
    let path = this.getFilePath(id, fileName, folder);

    try {
      await httpClient.delete(path);
    } catch (error) {
      // Ignore file not found
      if (error.response.status != 404) {
        throw error;
      }
    }
    
  },
  async renameFile (id, fileName, folder, name) {
    let path = this.getFilePath(id, fileName, folder);
    
    await httpClient.patch(path, { newName: name });
  },
  async copyTuningConditionsFile (id) {
    await httpClient.post(`/analyses/${id}/tuning-files/conditions`);
  },
  async copyTuningValuesFile (id) {
    await httpClient.post(`/analyses/${id}/tuning-files/values`);
  },
  async RemoveTuningFiles (id) {
    await httpClient.post(`/analyses/${id}/tuning-files/delete`);
  },
  async createFolder (id, folder) {
    let path = this.getFilePath(id, '', folder);
    await httpClient.post(path);
  },
  async runAnalysis (id) {
    await httpClient.post(`/analyses/${id}/run`);
  },
  async listFiles (id, folder) {
    let path = this.getFilePath(id, '', folder);
    let response = await httpClient.get(path);
    return response.data;
  },
  async downloadFile (id, fileName, folder) {
    // TODO: This will buffer the entire file in memory. Need better solution to stream file to disk.
    let response = await httpClient.get(this.getFilePath(id, fileName, folder), { responseType: 'blob' })
    let target = fileName.indexOf('.') === -1 ? `${fileName}.txt` : fileName;

    saveAs(response.data, target);
  },
  async stopAnalysis (id) {
    let response = await httpClient.post(`/analyses/${id}/stop`, { responseType: 'blob' })

    return response.status == 200;
  },
  async getCurrentUser () {
    let response = await httpClient.get('account');
    return response;
  },
  async usernameLookup (usernameLookuoModel) {
    let response = await httpClient.post('account/usernamelookup',usernameLookuoModel);
    return response;
  },
  async changePassword (changePasswordModel) {
    let response = await httpClient.put('account/password',changePasswordModel);
    return response;
  },
  async addTuningValues (tuningData, analysisId, scenarioId) {
    let response = await httpClient.put(`/analyses/${analysisId}/scenarios/${scenarioId}/tunings`,tuningData);
    return response;
  },
  async getTuningValues (analysisId,scenarioId) {
    let response = await httpClient.get(`/analyses/${analysisId}/scenarios/${scenarioId}/tunings`);
    return response.data;
  },
  async changeUserPassword (adminChangePasswordModel) {
    let response = await httpClient.put(`users/${adminChangePasswordModel.Id}/password`, adminChangePasswordModel);
    return response;
  },
  async getUsers () {
    let response = await httpClient.get(`users`);
    return response.data;
  },
  async createUser (user) {
    await httpClient.post('users', user)
  },
  async updateUser (user) {
    await httpClient.patch(`/users/${user.id}`, user)
  },
  async getEmailSettings () {
    let response = await httpClient.get(`settings/email`);
    return response.data;
  },
  async getUserSettings () {
    let response = await httpClient.get('settings');
    return response.data;
  },
  async updateEmailSettings (emailSettings) {
    await httpClient.patch('settings/email', emailSettings);
  },
  async getServicingSettings () {
    let response = await httpClient.get('settings/servicing');
    return response.data.servicingSettings;
  },
  async createServicingSettings (settings) {
    let response = await httpClient.post('settings/servicing', settings);
    return response.data;
  },
  async createOrUpdateServicingSettings (settings) {
    let response = await httpClient.put('settings/servicing', settings);
    return response.data;
  },
  async updateServicingSettings (settings) {
    await httpClient.patch('settings/servicing', settings);
  },
  async deleteServicingSettings (settings) {
    await httpClient.delete(`settings/servicing/${settings.id}`);
  },
  async updateUserSettings (userSettings) {
    await httpClient.patch('settings', userSettings);
  },
  async authenticate (credentials) {
    let response = await httpClient.post('/auth', credentials);
    return response;
  },
  async setPassword (setPasswordModel) {
    let response = await httpClient.post('account/password', setPasswordModel);
    return response;
  },
  async resetPassword (resetPasswordModel) {
    let response = await httpClient.post('account/resetpassword', resetPasswordModel);
    return response;
  },
  async getInputsList (id) {
    let response = await httpClient.get(`/analyses/${id}/inputs`);
    return response.data;
  },
  async getRatesList () {
    let response = await httpClient.get(`/rates`);
    return response.data;
  },
  async loadRates (id, dateKey) {
    await httpClient.put(`/rates/${dateKey}?analysisId=${id}`);
  },
  async getModelDataDates (id) {
    let response = await httpClient.get(`/modeldata/${id}`);
    return response.data;
  },
  async odataQuery (id, collection, params) {
    let queryString = params ? '?' + Object.entries(params).filter(([k, v]) => !!k && !!v).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&') : '';
    let url = `/Analyses(${id})/${collection}${queryString}`;
    let baseURL = httpClient.defaults.baseURL.replace('api', 'odata');
    let response = await httpClient.get(url, { baseURL });
    
    return response.data;
  },
  async getLoanIds(id) {
    let response = await httpClient.get(`/analyses/${id}/results/LoanIds`);
    return response.data;
  },
  async getInputScenarios(id) {
    let response = await httpClient.get(`/analyses/${id}/scenarios/`);
    return response.data;
  },
  async getAllInputScenarios(id, forecastType) {
    let response = await httpClient.get(`/analyses/${id}/forecasts/${forecastType}`);
    return response.data;
  },
  async getForecastGroup(id, forecastGroup) {
    let response = await httpClient.get(`/analyses/${id}/forecasts/group/${forecastGroup}`);
    return response.data;
  },
  async createScenario(id, scenario) {
    let response = await httpClient.post(`/analyses/${id}/scenarios`, scenario);
    return response.data;
  },
  async copyScenario(id, data) {
    let response = await httpClient.post(`/analyses/${id}/scenarios/scenario/copy`, data);
    return response.data;
  },
  async copyScenarioFromTemplate(id, template, scenarioName) {
    let response = await httpClient.post(`/analyses/${id}/scenarios/${template}/${scenarioName}`);
    return response.data;
  },
  async updateScenario(id, scenarioId, scenario) {
    await httpClient.patch(`/analyses/${id}/scenarios/${scenarioId}`, scenario);
  },
  async deleteScenario(id, scenarioId) {
    await httpClient.delete(`/analyses/${id}/scenarios/${scenarioId}`);
  },
  async deleteForecast(id, scenarioId, forecastType) {
    await httpClient.delete(`/analyses/${id}/scenarios/${scenarioId}/forecasts/${forecastType}`);
  },
  async getForecasts(id, scenarioId, forecastType) {
    let response = await httpClient.get(`/analyses/${id}/scenarios/${scenarioId}/forecasts/${forecastType}`);
    return response.data;
  },
  async getDefaultForecast(id, forecastType, options) {
    let response = await httpClient.get(`/analyses/${id}/forecasts/default/${forecastType}`, options);
    return response.data;
  },
  async getFlatForecast(id, forecastType) {
    let response = await httpClient.get(`/analyses/${id}/forecasts/flat/${forecastType}`);
    return response.data;
  },
  async addScenarioForecast(id, scenarioId, forecastType, forecast) {
    await httpClient.post(`/analyses/${id}/scenarios/${scenarioId}/forecasts/${forecastType}`, forecast);
  },
  async updateScenarioForecast(id, scenarioId, forecastType, forecast) {
    await httpClient.put(`/analyses/${id}/scenarios/${scenarioId}/forecasts/${forecastType}`, forecast);
  },
  async getScenarios(id) {
    let response = await httpClient.get(`/analyses/${id}/results/scenarios`);
    return response.data;
  },
  async getPenaltyPointSets(id) {
    let response = await httpClient.get(`/analyses/${id}/penalty-points`);
    return response.data;
  },
  async getPenaltyPointSet (id, penaltyPointSetId) {
    let response = await httpClient.get(`/analyses/${id}/penalty-points/${penaltyPointSetId}`);
    return response.data;
  },
  async getPenaltyPointSetDefault(id) {
    let response = await httpClient.get(`/analyses/${id}/penalty-points/default`);
    return response.data;
  },
  async createPenaltyPointSet(id, penaltyPointSet) {
    await httpClient.post(`/analyses/${id}/penalty-points`, penaltyPointSet);
  },
  async updatePenaltyPointSet(id, penaltyPointSetId, penaltyPointSet) {
    await httpClient.patch(`analyses/${id}/penalty-points/${penaltyPointSetId}`, penaltyPointSet);
  },
  async updatePenaltyPoints(id, penaltyPointSetId, points) {
    await httpClient.put(`analyses/${id}/penalty-points/${penaltyPointSetId}`, points);
  },
  async deletePenaltyPointSet(id, penaltyPointSetId) {
    await httpClient.delete(`/analyses/${id}/penalty-points/${penaltyPointSetId}`);
  },
  async checkLicense () {
    let response = await httpClient.get('/license');
    return response.data;
  },
  async getModules () {
    let response = await httpClient.get('/license/modules');
    return response.data;
  },
  async sendTestEmail (emailSettings) {
    let response = await httpClient.post('settings/email/test', emailSettings);
    return response;
  },
  async getLatestModelDataDate () {
    let response = await httpClient.get('/modeldata');
    return response.data;
  },
  async getApplicationVersion () {
    let response = await httpClient.get('/versions');
    return response.data;
  },
  async addPositions (analysisId, positions) {
    let response = await httpClient.post(`/analyses/${analysisId}/inputs/positions`, positions);
    return response.data;
  },
  updatePositions (analysisId, positions) {
    return httpClient.patch(`/analyses/${analysisId}/inputs/positions`, positions);
  },
  async deletePositions (analysisId, positions) {
    if (positions) {
      return httpClient.post(`/analyses/${analysisId}/inputs/positions:delete`, positions);
    } else{
      return httpClient.delete(`/analyses/${analysisId}/inputs/positions`);
    }
  },
  async deleteInput (analysisId, route) {
    return httpClient.delete(`/analyses/${analysisId}/inputs/${route}`);
  },
  async getReports (moduleId, analysisId) {
    let url = '/reports';

    // Add analysisId query param
    if (moduleId) {
      url = `${url}?moduleId=${moduleId}`
    }

    // Add analysisId query param
    if (analysisId) {
      url = `${url}${ moduleId ? '&' : '?'}analysisId=${analysisId}`
    }

    let response = await httpClient.get(url);
    return response.data;
  },
  async getReportFields (reportId, analysisId) {
    let url = `/reports/${reportId}`;

    // Add analysisId query param
    if (analysisId) {
      url = `${url}?analysisId=${analysisId}`
    }

    let response = await httpClient.get(url);
    return response.data;
  },
  async loadDefaultScenarios (analysisId, isStressTest) {
    let route = isStressTest ? 'stresstest' : '20scenariogrid';
    await httpClient.delete(`/analyses/${analysisId}/scenarios/${route}`);
  },
  async getAuthSettings () {
    let response = await httpClient.get('/settings/authentication');
    return response.data.enableAuthentication;
  },
  async getConfiguration () {
    let response = await httpClient.get('configuration');
    return response.data;
  }
}