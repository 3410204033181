<template>
  <div>
    <span>AD&amp;Co Data Files Date: {{ modelDataDate }}</span>
    <b-form-file
      v-model="dataFileZip"
      class="mt-3"
      placeholder="Choose a AD&amp;Co data zip file or drop it here..."
      drop-placeholder="Drop zip file here..."
    />
    <b-alert
      class="mt-3"
      :variant="msgVariant"
      :show="!!message"
    >
      {{ message }}
    </b-alert>
    <b-btn
      class="mt-3"
      @click="updateDataFiles"
      :disabled="!dataFileZip"
    >
      Update
    </b-btn>
  </div>
</template>

<script>
import api from '../api'
import getErrorMessage from '../js/getErrorMessage'

export default {
  data () {
    return {
      dataFileZip: null,
      error: false,
      message: null,
      modelDataDate: ''
    }
  },
  async mounted () {
    await this.getModelDataDate();
  },
  computed: {
    msgVariant () {
      return this.error ? 'danger' : 'success';
    }
  },
  methods: {
    async updateDataFiles () {
      this.message = null;
      this.error = false;

      // Verify a file is selected
      if (!this.dataFileZip) {
        this.error = true;
        this.message = "Please select a zip file containing AD&Co Data Files.";
        return;
      }

      try {
        await api.postFile('/modeldata', this.dataFileZip, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

        this.message = 'AD&Co Data files updated.';
        this.dataFileZip = null;
      } catch (err) {
        this.error = true;
        this.message = getErrorMessage(err, 'Error updating AD&Co Data Files.')
      }

      await this.getModelDataDate();
    },
    async getModelDataDate () {
      try {
        let resp = await api.getLatestModelDataDate();
        this.modelDataDate = `${resp.dateKey.substring(0, 4)}-${resp.dateKey.substring(4, 6)}`;
      } catch {
        this.modelDataDate = 'Error retrieveing model data date';
      }
    }
  },
  watch: {
    message() {
      setTimeout(() => { this.message = null }, 5000);
    }
  }
}
</script>