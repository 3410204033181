import moment from 'moment'

let fmtDate = (date, format = 'M/DD/YYYY h:mm A') => {
  let m = moment(date);

  if (m.isValid()) {
    return m.format(format);
  } else {
    return '';
  }
}

let fmtSize = (bytes) => {
  if (bytes == null || bytes == '' || isNaN(bytes) || bytes < 0) {
    return '';
  }

  if (bytes >= 1024 * 1024) {
    return Math.ceil(bytes / 1024 / 1024) + ' MB';
  } else if (bytes >= 1024) {
    return Math.ceil(bytes / 1024) + ' KB';
  } else {
    return bytes + ' bytes';
  }
}

export default {
  methods: {
    formatDateString(date) {
      return fmtDate(date);
    },
    formatSizeString(bytes) {
      return fmtSize(bytes);
    },
    format(val, fmt) {
      let floatVal = parseFloat(val);

      if (fmt.substring(0,1) == 'n' && floatVal) {
        let p = fmt.substring(1);
        let value= floatVal.toLocaleString('en-US', { minimumFractionDigits: p, maximumFractionDigits: p });
        if(parseFloat(value)===0 &&value.substr(0,1)==='-' ){
          value= value.substr(1,1)
        }
        return value
      } else if (fmt.substring(0,1) == 'c' && floatVal) {
        return floatVal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      } else if (fmt.substring(0,1) == 'p' && floatVal) {
        let q = fmt.substring(1);
        return floatVal.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: q, maximumFractionDigits: q } ).slice(0, -1);
      } else {
        return val;
      }
    }
  },
  filters: {
    formatDate(date) {
      return fmtDate(date, 'M/DD/YYYY h:mm A');
    },
    formatShortDate(date) {
      return fmtDate(date, 'M/DD/YYYY');
    },
    toKilobytes(bytes) {
      return fmtSize(bytes);
    }
  }
}

export { fmtDate }