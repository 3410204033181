import { quoteTypeOptions } from '../options/quoteType'
import { delinquencyOptions, indexOptions, documentationOptions, occupancyOptions, loanPurposeOptions, propertyTypeOptions, tuneStringOptions } from '../options/loanOptions'
import positionColumns from './positionColumns'

export default {
  title: 'TBA Positions',
  entitySet: 'HedgePositions',
  entityType: (analysis) => 'Kinetics.Data.Entities.TbaPoolPosition',
  dataMaps: async () => {
    let maps = [
      { column: 'quoteType', options: quoteTypeOptions.filter(o => o.value != 'QuoteInFile') },
      { column: 'delinquency', options: delinquencyOptions },
      { column: 'index', options: indexOptions },
      { column: 'documentation', options: documentationOptions },
      { column: 'occupancy', options: occupancyOptions },
      { column: 'loanPurpose', options: loanPurposeOptions },
      { column: 'propertyType', options: propertyTypeOptions },
      { column: 'tuneString', options: tuneStringOptions }
    ];
    
    return maps;
  },
  editOptions: {
    keys: ['id'],
    allowAddNew: true,
    allowDelete: true,
    allowSave: true
  },
  itemCreator: (analysis) => () => {
    return { positionType: 'TbaPoolPosition' };
  },
  columns (analysis) {
    return positionColumns(analysis, true);
  }
};