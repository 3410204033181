<template>
  <div class="dialog-header">
    <h3 class="header-item">
      {{ title }}
    </h3>
    <div class="header-item">
      <icon-button
        v-b-tooltip.hover.left.v-light
        class="ml-auto close-btn"
        icon="times"
        size="lg"
        title="Close"
        theme-class="dark"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import IconButton from './IconButton.vue'

export default {
  components: {
    IconButton
  },
  props: {
    title: {type: String }
  }
}
</script>

<style scoped>
.dialog-header {
  display: flex;
  align-items: flex-start;
  background-color: #16191C;
  color: #fff;
  padding: 10px;
  min-height: 55px;
}

.dialog-header > .header-item {
  font-family: 'Montserrat', sans-serif;
  flex: 1;
  display: flex;
  margin: 0;
  line-height: 30px;
}

.close-btn {
  padding: 1px 6px;
  margin: 0;
}
</style>