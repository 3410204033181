<template>
  <div class="analysis-editor">
    <b-alert
      :show="loadError"
      variant="danger"
      class="mt-4 ml-4 mr-4"
    >
      An error occurred loading the analysis.
      <b-button
        v-b-tooltip.hover
        title="Return to Analysis Manager"
        class="float-right"
        variant="primary"
        @click="closeAnalysis"
      >
        Back
      </b-button>
    </b-alert>

    <loading-indicator v-if="loading" />
    <b-nav
      v-else-if="!loadError && activeAnalysis"
      class="modern-tabs"
      tabs
    >
      <b-nav-item
        v-if="showMsr"
        :to="{ name: 'Servicing' }"
      >
        Servicing
      </b-nav-item>
      <b-nav-item
        v-if="!showLdm"
        :to="{ name: 'Inputs' }"
      >
        <span>Inputs</span>
      </b-nav-item>
      <b-nav-item
        v-else
        :to="{ name: 'Portfolio' }"
      >
        <span>Portfolio</span>
      </b-nav-item>
      <b-nav-item
        v-if="showPoints"
        :to="{ name: 'Points' }"
      >
        Points
      </b-nav-item>
      <b-nav-item
        class="scenarioLink"
        v-if="showLdm"
        :to="{ name: 'Scenarios' }"
      >
        Scenarios
      </b-nav-item>
      <b-nav-item :to="{ name: 'Calculations' }">
        Calculations
      </b-nav-item>
      <b-nav-item :to="{ name: 'Results' }">
        Results
      </b-nav-item>
      <b-nav-form class="ml-auto">
        <div class="modern-tab-buttons">
          <save-status />
          <b-button
            v-if="analysisRunning || analysisQueued"
            variant="primary"
            :disabled="analysisStatus.stopping"
            @click="stopAnalysis"
          >
            <font-awesome-icon
              icon="stop"
              class="button-icon"
            />{{ analysisStatus.stopping ? 'Stopping...' : 'Stop Analysis' }}
          </b-button>
          <b-button
            v-else
            variant="primary"
            @click="runAnalysis"
            :disabled="isValidating"
          >
            <font-awesome-icon
              icon="play"
              class="button-icon"
            />Run Analysis
          </b-button>
          <!-- <b-dropdown variant="primary" split @click="runAnalysis" v-if="!analysisRunning && canRerunHedges" :disabled="!analysisValid">
            <template slot="button-content">
              <font-awesome-icon icon="play" class="button-icon"></font-awesome-icon>Run Analysis
            </template>
            <b-dropdown-item @click="runAnalysis">Run Full Analysis</b-dropdown-item>
            <b-dropdown-item @click="runHedges">Rerun Hedges Only</b-dropdown-item>
          </b-dropdown> -->

          <b-button
            v-b-tooltip.hover.bottom
            title="Back to Analysis Manager"
            @click="closeAnalysis"
          >
            <font-awesome-icon
              icon="arrow-alt-circle-left"
              class="button-icon"
            />Back
          </b-button>
        </div>
      </b-nav-form>
    </b-nav>

    <div
      v-if="!loading && !loadError && activeAnalysis"
      class="modern-tab-content"
    >
      <keep-alive>
        <router-view :run-type="activeAnalysis.runType" />
      </keep-alive>
    </div>

    <b-modal
      id="confirm-close"
      title="Uploads in progress"
      ok-title="Close Analysis"
      @ok="cancelUploadsAndClose"
    >
      There are one or more uploads in progress. Are you sure you want to cancel all uploads and close the analysis?
    </b-modal>

    <error-dialog
      :show="!!saveError"
      title="Save Error"
      error-message="An error occurred while saving your changes."
      :on-ok="clearSaveError"
    />
  </div>
</template>

<script>
import LoadingIndicator from './LoadingIndicator.vue'
import ErrorDialog from './ErrorDialog.vue'
import SaveStatus from './SaveStatus.vue'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { statusCodes } from '../js/options/statusCodes'
import externals from '../js/externals'
import api from '../api'
import { delay } from '../api/util'
import { assetTypeOptions, assetTypes } from '../js/options/assetType'
import { runTypes } from '../js/options/runType'

export default {
  props: {
    id: { type: [String, Number], required: true }
  },
  data () {
    return {
      loading: true,
      loadError: false,
      stopPolling: false,
      assetTypes
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      
      // Load config if not yet loaded
      await vm.loadAppConfig();

      // Load the analysis
      await vm.loadEditor();

      if (to.name == "Inputs" && vm.activeAnalysis.runType == runTypes.loanDynamics) {
        next({ name: 'Portfolio', params: to.params });
      } else {
        next();
      }
    })
  },
  beforeDestroy () {
    this.stopPolling = true;
  },
  computed: {
    ...mapState(['validationStatus', 'activeAnalysis', 'saveError', 'isValidating', 'appConfig']),
    ...mapGetters(['analysisValid', 'uploadInProgress', 'analysisStatus', 'analysisRunning', 'analysisQueued']),

    showMsr () {
      return this.activeAnalysis.assetType == "Servicing";
    },
    showPoints () {
      return this.activeAnalysis.assetType == assetTypes.multiFamilyLoans
    },
    showLdm () {
      return this.activeAnalysis.runType == runTypes.loanDynamics;
    },
    canRerunHedges () {
      return this.analysisStatus.executionStatus == statusCodes.success;
    }
  },
  methods: {
    ...mapActions(['endValidate', 'flushAutoSave', 'cancelUploads', 'getAnalysisStatus', 'loadAnalysis', 'getInputsList', 'getAppConfig']),
    ...mapMutations(['updateFile', 'beginValidate', 'clearValidationStatus', 'setAnalysisLastRun', 'setAnalysisStatus', 'setAnalysisResultsUpdated', 'clearSaveError']),

    async loadEditor () {
      // Load analysis data
      try {
        await this.loadAnalysis(this.id);
        
        // Dynamically set flavor
        let flavor = assetTypeOptions.find(o => o.value == this.activeAnalysis.assetType).flavor;
        
        if (this.activeAnalysis.runType == 'LoanDynamics') {
          flavor = "LDM"
        }
        
        externals.GUIflavor = flavor;

        this.clearValidationStatus();
        await this.getInputsList();
      } catch (err) {
        this.loading = false;
        this.loadError = true;
        // eslint-disable-next-line
        console.log(err);
      }

      if (this.analysisStatus.executionStatus == statusCodes.running) {
        this.pollAnalysisStatus();
      }

      this.loading = false;
    },

    setTab (tabName) {
      if (this.$route.name != tabName) {
        this.$router.replace({ name: tabName });
      }
    },

    async pollAnalysisStatus (checkResults = true) {
      this.stopPolling = false;

      // Check status and wait for completion
      await delay(500);
      await this.getAnalysisStatus();

      while ((this.analysisRunning || this.analysisQueued) && !this.analysisStatus.canceled && !this.stopPolling) {
        await delay(2000);
        await this.getAnalysisStatus();
      }

      if (!this.stopPolling && checkResults) {
        this.setAnalysisResultsUpdated(true);
      }
      
    },
    async validate () {
      this.beginValidate();
      await this.endValidate();

      // Show first tab with a validation error
      if (!this.analysisValid) {
        let errors = this.validationStatus.filter(s => s.isValid === false);

        if (errors.length) {
          this.setTab(errors[0].tab);
        }
      }

      return this.analysisValid;
    },
    async runAnalysis () {
      let valid = await this.validate();

      if (!valid) {
        this.setAnalysisStatus({ executionStatus: statusCodes.notRun });
        return;
      }

      this.setAnalysisStatus({
        executionStatus: statusCodes.running,
        stopping: false,
        statusText: ''
      });

      this.setAnalysisResultsUpdated(false);
      
      this.setTab('Results');
      
      try {

        // Wait for all uploads to complete
        if (this.uploadInProgress) {
          this.setAnalysisStatus({ statusTitle: "Waiting for uploads to complete..." });

          while (this.uploadInProgress) {
            await delay(500);
          }
        }

        this.setAnalysisStatus({ statusTitle: "Starting analysis..." });

        // Flush any pending saves
        await this.flushAutoSave();
        
        // Run the analysis
        await api.runAnalysis(this.activeAnalysis.id);
        this.setAnalysisLastRun();

        await this.pollAnalysisStatus(true);
      } catch (err) {
        this.setAnalysisStatus({ 
          executionStatus: statusCodes.error,
          statusText: err.response.data.message || 'An error occurred running your analysis'
        });
      }

      this.setAnalysisStatus({ 
        stopping: false,
        canceled: false
      });

    },
    async runHedges () {
      alert('Sorry this functionality is not implemented yet.')
    },
    updateFileList (evt) {
      let item = this.files.find(f => f.fileName == evt.fileName);

      item.file = evt.file;
      item.status = evt.status;
    },
    async closeAnalysis () {
      this.flushAutoSave();
      if (this.uploadInProgress) {
        this.$bvModal.show('confirm-close');
      } else {
        this.$router.push('/')
          .catch(err => { 
            // false means navigation has been cancelled
            if (err !== false) {
              console.log(err);
            }
          });
      }
    },
    cancelUploadsAndClose () {
      this.cancelUploads();
      this.$router.push('/');
    },
    async stopAnalysis () {
      this.setAnalysisStatus({ stopping: true });

      let stopped = await api.stopAnalysis(this.activeAnalysis.id);
      this.stopPolling = true;

      if (stopped) {
        this.setAnalysisStatus({ executionStatus: statusCodes.notRun });
      }
    },
    cancelAnalysis () {
      this.setAnalysisStatus({ canceled: true });
    },
    async loadAppConfig () {
      if (this.appConfig == null) {
        await this.getAppConfig();
      }
    }
  },
  components: {
    LoadingIndicator,
    ErrorDialog,
    SaveStatus
  }
}
</script>

<style scoped>
#app > .analysis-editor {
  overflow: visible;
}
 .scenarioLink >a.router-link-active {
    border: none !important;
    border-bottom: 4px solid #2a6e8c !important;
  }
</style>