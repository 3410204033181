import { getValidationMessage } from '../validation/validationMessages'
import { pushParams, popParams } from 'vuelidate/lib/params'

// Adapt Vuelidate validators to work for wijmo grid
export default class GridValidator
{
  constructor (grid, validations, colGroups=false) 
  {
    this._validators = this.createValidators(validations, colGroups);
    this._grid = grid

    // Validate on getError
    // grid.collectionView.getError = (item, property) => {
    //   return this.validateItem(item, property);
    // };

    grid.itemValidator = (row, col, parsing) => {
      let c = grid.columns[col];
      let item = {};

      if (parsing && grid.activeEditor) {
        item[c.binding] = grid.activeEditor.value;
      } else {
        item = grid.rows[row].dataItem;
      }

      return this.validateItem(item, c.binding);
    };
  }

  createValidators (validations, colGroups) {
    let validators = {};

    for (let prop in validations) {
      if (validations.hasOwnProperty(prop)) {

        // Get validations for prop
        for (let validatorType in validations[prop]) {
          if (validations[prop].hasOwnProperty(validatorType) && 
            typeof validations[prop][validatorType] === 'function') {

              if (!validators[prop]) {
                validators[prop] = [];
              }

              validators[prop].push(new GridFieldValidator(prop, validations[prop][validatorType], validatorType, colGroups));
          }
        }
      }
    }
    return validators;
  }

  validateItem (item, property) {
    // Ignore blank rows
    if (!item || item.constructor.name == '_NullValue') {
      return null;
    }

    let propValidators = this._validators[property]

    if (propValidators) {
      for (let v of propValidators) {
        let result = v.validate(item, this._grid.itemsSource);

        //console.log(`grid validate: ${property} ${item && item[property]} ${result}`)

        if (result) {
          return result;
        }
      }
    }

    return null;
  }
}

class GridFieldValidator
{
  static crossFieldValidators = ['requiredIfArm'];

  constructor (property, validator, validatorType, colGroups=false) {
    this.validatorType = validatorType;
    this.property = property;
    this.validator = validator;
    this.colGroups = colGroups;
    this.crossFieldValidation = GridFieldValidator.crossFieldValidators.find(v => v === validatorType) != undefined;
  }

  validate (item, gridData) {
    if (!item) {
      return null
    }

    pushParams();
    let val;
    if (this.colGroups && Object.keys(item).length > 1) {
      val = item[this.property.split('.')[0]][this.property.split('.')[1]]
    }
    else
      val = item[this.property]
    
    let valid;
    if (this.crossFieldValidation)
      valid = this.validator(item, gridData);
    else
      valid = this.validator(val, gridData);

    let p = popParams();
    let params = p && p.$sub && p.$sub.length ? p.$sub[0] : {};

    return valid ? null : getValidationMessage(this.validatorType, params);
  }
}
