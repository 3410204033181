import createOptionsMap from '../options/createOptionsMap';

const assetTypeOptions = [
  { value: 'Servicing', text: "Servicing", flavor: "MSR" },
  { value: 'AutoLoans', text: "Auto Loans", flavor: "AUTO" },
  { value: 'SingleFamilyLoans', text: "Single Family Loans", flavor: "LK" },
  { value: 'StructuredSecurities', text: "Structured Securities", flavor: "CMO" },
  { value: 'Guarantees', text: "Guarantees", flavor: "MI" },
  { value: 'MultiFamilyLoans', text: "Multi Family Loans", flavor: "LDM" },
  { value: 'AgencyPools', text: "AgencyPools", flavor: "Payup" },
];

const assetTypes = createOptionsMap(assetTypeOptions);

export { assetTypeOptions, assetTypes };