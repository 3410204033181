<template>
  <modal-dialog 
    :id="id"
    :title="dialogTitle" 
    confirm-button-text="Save" 
    :value="value" 
    :action="addTuningValues"
    :hide-errors="true" 
    @input="$emit('input', $event)" 
    :dialog-size="modalSize"
  >
    <template #footer="{ ok, cancel }">
      <b-form-group
        class="btn-container-left"
      >
        <b-btn
          @click="setDefaults()"
        >
          Reset to Defaults
        </b-btn>
      </b-form-group>
      
      <b-btn @click="cancel()">
        Cancel
      </b-btn>

      <b-btn
        :disabled="disableOk"
        variant="primary"
        @click="ok()"
      >
        Save
      </b-btn>
    </template>
    <b-container>
      <b-row
        :class="{rowCenter: tunings.length == 1}"
      >
        <b-col
          v-for="(grp, grpIdx) in tunings"
          :key="`grp-${grpIdx}`"
          :xl="colSizeXl"
          :lg="colSizeLg"
        >
          <b-form-group
            class="group-label"
            :label="grp.title"
            label-class="font-weight-bold text-center"
          >
            <b-form-group
              v-for="item in grp.fields"
              :key="item.key"
              :label-for="item.key"
              label-cols="7"
              label-size="sm"
            >
              <template #label>
                {{ item.label }}
                <span
                  v-if="item.helpText"
                  v-b-tooltip.hover.right
                  :title="item.helpText"
                ><font-awesome-icon icon="question-circle" /></span>
              </template>
              <b-form-input
                class="tuning-group-input"
                :id="item.key"
                type="number"
                v-model="inputData[item.field]"
                @input="validate($v.inputData[item.field])"
                :state="state($v.inputData[item.field])"
                step="0.1"
              />
              <b-form-invalid-feedback :state="state($v.inputData[item.field])">
                {{ validationMessage($v.inputData[item.field]) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <p 
      v-if="message"
      class="message"
    >
      {{ message }}
    </p>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import { mapState } from 'vuex'
import moment from 'moment'
import { required, between, decimal  } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'

export default {
  props:{
    value: { type: Boolean, required: true },
    dialogTitle: { type: String, default: 'Tunings' },
    loadTunings: { type: Function, required: true },
    saveTunings: { type: Function, required: true },
    id: { type: String, default: '' },
    message: { type: String, default: '' }
  },
  data () {
    return {
      singleFamilyTunings: [
        {
          title: "Delinquency Transitions and Defaults",
          fields: [
            { field:'TuneMdr', label:"MDR Scale", max: 10000, default: 1, helpText: 'Multiplicative scale on MDR Output.  Note: will not affect transition rates.' },
            { field:'TuneCd', label:"Tune C-D", max: 10000, default: 1, helpText: 'Tune the C-D transition' },
            { field:'TuneDc', label:"Tune D-C", max: 10000, default: 1, helpText: 'Tune the D-C transition' },
            { field:'TuneDs', label:"Tune D-S", max: 10000, default: 1, helpText: 'Tune the D-S transition' },
            { field:'TuneDt', label:"Tune D-T", max: 10000, default: 1, helpText: 'Tune the D-T transition' },
            { field:'TuneSt', label:"Tune S-T", max: 10000, default: 1, helpText: 'Tune the S-T transition' },
            { field:'TuneSc', label:"Tune S-C", max: 10000, default: 1, helpText: 'Tune the S-C transition' },
          ]
        },
        {
          title: "Probability of Loss",
          fields: [
            { field:'TuneProbLossTd', label:"Probability T from D", max: 10000, default: 1, helpText: 'Scale Probability of Loss from D Termination.'  },
            { field:'TuneProbLossTs', label:"Probability T from S", max: 10000, default: 1, helpText: 'Scale Probability of Loss from S Termination.'  },
            { field:'TuneProbLossTc', label:"Probability T from C", max: 10000, default: 1, helpText: 'Scale Probability of Loss from C Termination.'  },
          ]
        },
        {
          title: "Severity of Loss",
          fields: [
            { field:'TuneSeverity',label:"Severity Scale", max: 10000, default: 1, helpText: 'Multiplicative scale on loss severity.'  },
            { field:'TuneLossMagnitudeTd',label:"Severity T from D", max: 10000, default: 1, helpText: 'Scale Loss Severity for defaults coming from D.'  },
            { field:'TuneLossMagnitudeTs',label:"Severity T from S", max: 10000, default: 1, helpText: 'Scale Loss Severity for defaults coming from S.'  },
            { field:'TuneLossMagnitudeTc',label:"Severity T from C", max: 10000, default: 1, helpText: 'Scale Loss Severity for defaults coming from C.'  },
          ]
        },
        {
          title: "Prepayments",
          fields: [
            { field:'TuneMrr', label:"MRR Scale", max: 10000, default: 1, helpText: 'Multiplicative scale on MRR output. Note: will not affect underlying transition rates.' },
            { field:'SmmTuneScale', label:"Prepay Scale", max: 10000, default: 1, helpText: 'Scale both refinancing and turnover component of C-T. Differs from Tune C-T in that it affects burnout.  Multiplicative.  Bigger means faster prepay.'  },
            { field:'SmmTuneRefi', label:"Refi Factor", max: 10000, default: 1, helpText: 'Scale refi component of C-T.  Bigger means faster prepays.' },
            { field:'SmmTuneTurnOver', label:"Turnover Factor", max: 10000, default: 1, helpText: 'Scale turnover component of C-T.  Bigger means faster prepays.'  },
            { field:'SmmTuneSlide', label:"Slide Factor (bps)", min: -100, max: 10000, default: 0, helpText: 'Slide C-T S-Curve by adding X bp to the market rate.  Positive means slower speeds.'  },
          ]
        },
        {
          title: "SMM",
          fields: [
            { field:'SmmTuneAge', label:"Age", max: 10000, default: 1, helpText: 'Speed-up/Slow-down C-T aging ramp. Bigger means a longer aging ramp/slower prepays. For example a value of 2 would extend an existing aging ramp of 24 to 48 months.' },
            { field:'SmmTuneSteep', label:"Steep", max: 10000, default: 1, helpText: 'Scale C-T S-Curve steepness.  Bigger means a steeper S-Curve; Values greater than 1 will increase speeds for premiums and decrease speeds for discounts.' },
            { field:'SmmTuneLag', label:"Lag", max: 10000, default: 1, helpText: 'Adjust how long it takes for changes in interest rates to affect refi incentive. Multiplicative. Bigger means more lag.'  },
            { field:'SmmTuneBurnout', label:"Burnout", max: 10000, default: 1, helpText: 'Scale prepay rate for passive borrowers. Bigger means faster speeds (e.g. slower burnout), Smaller means slower speeds (faster burnout).' },
          ]
        },
        {
          title: "Incentive",
          fields: [
            { field:'SmmTunePremiumSteepness', label:"Premium Steepness", min: 0, max: 5, default: 1, helpText: 'Adjust the effect of premium behavior.' },
            { field:'SmmTunePremiumOrigin', label:"Premium Origin", min: -10, max: 10, default: 0, helpText: 'Adjust the premium tuning origin point. Will only change results when the Premium Steepness tuning is a value other than 1.' },
            { field:'SmmTunePremiumMidpoint', label:"Premium Midpoint", min: 1, max: 10, default: 4, helpText: 'Adjust the premium tuning midpoint (absolute distance from Premium Origin). Will only change results when the Premium Steepness tuning is a value other than 1.' },
            { field:'SmmTunePremiumShape', label:"Premium Shape", min: 0.1, max: 10, default: 2, helpText: 'Adjust the premium tuning shape. Will only change results when the Premium Steepness tuning is a value other than 1.' },
            { field:'SmmTuneDiscountSteepness', label:"Discount Steepness", min: 0, max: 2, default: 1, helpText: 'Adjust the effect of discount behavior.' },
            { field:'SmmTuneDiscountOrigin', label:"Discount Origin", min: -10, max: 10, default: 0, helpText: 'Adjust the discount tuning origin point. Will only change results when the Discount Steepness tuning is a value other than 1.' },
            { field:'SmmTuneDiscountMidpoint', label:"Discount Midpoint", min: 1, max: 10, default: 3, helpText: 'Adjust the discount tuning midpoint (absolute distance from Discount Origin). Will only change results when the Discount Steepness tuning is a value other than 1.' },
            { field:'SmmTuneDiscountShape', label:"Discount Shape", min: 0.1, max: 10, default: 1.5, helpText: 'Adjust the discount tuning shape. Will only change results when the Discount Steepness tuning is a value other than 1.' },
          ]
        },
        {
          title: "Other",
          fields: [
            { field:'TuneWaoLtvSlide', label:"LTV Slide", min: -100, max: 10000, default: 1, helpText: 'Tune Weighted Average LTV by stretching difference between its value and a midpoint.  > 1.0 stretches, < 1.0 contracts.' },
            { field:'TuneFicoSlide', label:"FICO Slide", min: -100, max: 10000, default: 1, helpText: 'Tune FICO/Credit Score by stretching difference between its value and a midpoint.  > 1.0 stretches, < 1.0 contracts.'  },
            { field:'TuneSatoResidual', label:"SATO Residual", max: 10000, default: 1, helpText: 'Tune SATO residual.  Direct multiplier on the SATO residual value.'  },
            { field:'TuneSeasonality', label:"Seasonality", max: 999, default: 1, helpText: 'Tune Seasonal Effect in both C-D and C-T.' },
          ]
        }
      ],
      multifamilyTunings: [
        {
          title: "Prepayments and Defaults",
          fields: [
            { field:'TuneMrr', label:"MRR Scale", max: 10000, default: 1, helpText: 'Multiplicative scale on MRR output. Note: will not affect underlying transition rates.' },  
            { field:'TuneMdr', label:"MDR Scale", max: 10000, default: 1, helpText: 'Multiplicative scale on MDR Output.  Note: will not affect transition rates.' },
          ]
        },
      ],
      inputData: {},
      isDataEdited: false
    }
  },
  created() {
    // Initialize inputData with field properties
    this.setDefaults();
    
    // Load saved tuning values
    this.$root.$on('bv::modal::show', this.loadTuningData);
  },
  beforeDestroy() {
    this.$root.$off('bv::modal::show', this.loadTuningData);
  },
  validations() {
    let validations = this.tuningFields.reduce((v, item) => {
      let min = 'min' in item ? item.min : -100;
      let max = 'max' in item ? item.max : 10000;
      v[item.field] = {
        required,
        decimal,
        between: between(min, max)
      };
      return v;
    }, {});
    
    return { inputData: validations };
  },
  computed: {
    ...mapState(['activeAnalysis']),

    disableOk () {
      return this.$v.$invalid || !this.isDataEdited;
    },
    modalSize () {
      return this.activeAnalysis.assetType == 'MultiFamilyLoans' ? 'md' : 'xl';
    },
    colSizeXl () {
      return this.activeAnalysis.assetType == 'MultiFamilyLoans' ? 12 : 4;
    },
    colSizeLg () {
      return this.activeAnalysis.assetType == 'MultiFamilyLoans' ? 12 : 6;
    },
    tunings () {
      return this.activeAnalysis.assetType == 'MultiFamilyLoans' ? this.multifamilyTunings : this.singleFamilyTunings;
    },
    tuningFields () {
      return this.tunings.map(g => g.fields).flat();
    }
  },
  methods: {
    async loadTuningData (bvEvent, modalId) {
      if (modalId == this.id) {
        let tuningData = await this.loadTunings();
        
        this.setDefaults();

        // Populate fields with saved values
        tuningData.forEach(item => {
          this.inputData[item.parameter] = item.values[0];
        });
        this.isDataEdited = false;
      }
    },
    async addTuningValues () {
      let startDate = moment(this.activeAnalysis.settings.firstForecastDate, 'MM/DD/YYYY').toISOString(true);
      let tuningData = [];

      Object.keys(this.inputData)?.forEach(ele => {
        var item = this.tuningFields.find(t => t.field == ele);
        if (this.inputData[ele] && item &&
           this.inputData[ele] != item.default) {
          tuningData.push({
            "startDate": startDate,
            "parameter": ele,
            "values": [this.inputData[ele]]
          })
        }
      });

      if (tuningData.length > 0 || this.isDataEdited) {
        await this.saveTunings(tuningData);
      }
    },
    validate (item) {
      this.isDataEdited = true;
      item.$touch();
    },
    setDefaults () {
      this.tuningFields.forEach(item => {
        this.$set(this.inputData, item.field, item.default);
      });
      this.isDataEdited = true;
    }
  },
  components: {
    ModalDialog,
  },
  mixins: [validationState]
}
</script>

<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}
.group-label {
  margin: 0 1.5rem 2rem 1.5rem;
}
.tuning-error-message {
  color: red;
}
.rowCenter {
  justify-content: center;
}
.message {
  padding-left: 30px;
  font-weight: bold;
  font-size: 0.9em;
}
</style>