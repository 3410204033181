<template>
  <div>
    <div class="container-fluid">
      <loading-indicator v-if="gridLoading" />
      <div 
        :id="`grid-${forecastGroup}`" 
        class="scenario-grid"
      />
      <modal-dialog
        ref="invalidSelectionDialog"
        title="Invalid Fill Range Selection"
        error-message="An error occurred."
        confirm-button-text="Dismiss"
        :ok-only="true"
      >
        <ul>
          <li
            v-for="message in fillRangeErrorMessages"
            :key="message"
          >
            {{ message }}
          </li>
        </ul>
      </modal-dialog>
    </div>
    <rename-scenario-dialog
      v-model="showRenameScenarioDialog"
      :scenario="scenarioToRename"
      @scenarios-updated="$emit('scenarios-updated')"
    />
    <modal-dialog
      ref="confirmDeleteScenarioDialog"
      title="Delete Forecast"
      :prompt="`Are you sure you want to delete the ${forecastToDelete.forecastType} forecast ${forecastToDelete.scenarioName}?`"
      :action="deleteForecast"
      error-message="An error occurred deleting the forecast."
      confirm-button-text="Delete"
    />
  </div>
</template>

<script>
import LoadingIndicator from './LoadingIndicator'
import ScenarioGrid from "../js/gridExtensions/ScenarioGrid"
import { attachGridHeaderEventHandler, gridHeaderFormatter } from "../js/gridExtensions/ScenarioGridHeader"
import ModalDialog from './ModalDialog.vue'
import RenameScenarioDialog from './RenameScenarioDialog.vue'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import api from "../api";
import { DataType } from '@grapecity/wijmo'
import moment from 'moment'
import { decimal, required } from 'vuelidate/lib/validators'
import { runTypes } from '../js/options/runType'
import { calcsToSettings } from '../api/calcsMapper'

export default {
  props: {
    forecastGroup: { type: String, required: true },
    forecastTypes: { type: [Array], required: true }
  },
  data() {
    return {
      gridLoading: true,
      columns: [],
      columnMetaData: [],
      defaultColumns: [
        { header: "Date", binding: "date", format: "MM-yyyy", isReadOnly: true }
      ],
      fillRangeErrorMessages: [],
      newForecastAdded: false, 
      scenarioDeleted: false,
      forecastToDelete: {},
      forecastToExport: {},
      showRenameScenarioDialog: false,
      scenarioToRename: {},
      reloadOnActivation: false,
      deactivated: false
    };
  },
  async mounted() {
    this.defaultColumns.push({
      header: "AD&Co Default",
      columns: this.forecastTypes.filter(t => t.default).map(f => ({ binding: `AD&Co Default.${f.name}`, header: f.header, isReadOnly: true, dataType: DataType.Number }))});

    await this.loadGrid();
  },
  computed: {
    ...mapState(["activeAnalysis", "scenarioPreviewData", "scenarios", "isValidating", "scenarioGridDirty"]),
    ...mapGetters(["calcs"]),
    isAutoAnalysis() {
      return this.activeAnalysis.runType == runTypes.loanDynamics && this.activeAnalysis.assetType === 'AutoLoans';
    },
    forecastNames() {
      return this.forecastTypes.map(t => t.name);
    },
    forecastHeaders() {
      let res = {};
      this.forecastTypes.forEach(t => {
        res[t.name] = t.header;
      })
      return res;
    }
  },
  methods: {
    ...mapMutations(['setScenarioData', 'updateValidationStatus', 'setAnalysisModified', 'addExport', 'setScenarioGridDirty']),
    ...mapActions(['autoSaveItem', 'getScenarios', 'saveAnalysisSettings']),

    async loadGrid (data={}) {
      let {scenarioDeleted,newForecastAdded} = data
      this.scenarioDeleted=scenarioDeleted;
      this.newForecastAdded=newForecastAdded;

      this.gridLoading = true;
      this.columns = [];
      this.columnMetaData = [];
      if(this.$options.grid) this.$options.grid._grid.dispose();

      this.columns = this.defaultColumns.slice(0);
      this.defaultColumns.forEach(c => {
        if (c.columns) {
          this.columnMetaData.push(...c.columns.map(x => ({ isDefault: true, isDefaultScenario: x.binding.startsWith('AD&Co Default') })));
        } else {
          this.columnMetaData.push({ isDefault: true });
        }
      });

      this.scenarios.forEach((s) => {
        if (s.isDefaultScenario) {
          return;
        }
      
        let col = {
          header: s.scenarioName,
          columns: []
        }
        this.forecastNames.filter(t => s.forecastTypes.includes(t)).forEach(f => {
          col.columns.push({
            header: this.forecastHeaders[f],
            binding: `_${s.scenarioId}.${f}`,
            isReadOnly: !_.isEmpty(this.scenarioPreviewData) ? this.scenarioPreviewData.baseScenarioId == s.scenarioId : false,
            isRequired: false,
            dataType: DataType.Number,
            validations: { required, decimal }
          });
          this.columnMetaData.push(Object.assign({}, { forecastType: f }, s));
        });
        if (col.columns.length > 0) {
          this.columns.push(col);
        }
      });

      if (!_.isEmpty(this.scenarioPreviewData)) {
        let baseScenarioId = this.scenarioPreviewData.baseScenarioId;
        let baseScenarioName = baseScenarioId ? this.scenarios.filter(s => s.scenarioId == baseScenarioId)[0].scenarioName : "AD&Co Default";
        let idx = this.columns.findIndex(c => c.header == baseScenarioName);
        this.columns.splice(idx+1, 0, {
          header: this.scenarioPreviewData.scenarioName,
          columns: [
            {
              header: this.scenarioPreviewData.baseForecastType,
              binding: this.scenarioPreviewData.scenarioName,
              isReadOnly: true,
              cssClass: 'preview',
              dataType: DataType.Number
            }
          ]            
        });
        this.columnMetaData.splice(idx+1, 0, { isPreview: true });
      }

      let gridOptions = {
        analysisId: this.activeAnalysis.id,
        loaded: () => { this.gridLoading = false },
        getItems: this.getItems,
        patchItem: this.patchItem,
        selectionChanged: this.selectionChanged,
        stickyHeaders: true,
        formatItem: gridHeaderFormatter.bind(this)
      }

      gridOptions.columnGroups = this.columns;

      this.$options.grid = new ScenarioGrid(`#grid-${this.forecastGroup}`, gridOptions);
      attachGridHeaderEventHandler(this.$options.grid._grid, {
        delete: (data) => {
          this.forecastToDelete = this.getColumnMetaData(data.column);
          this.confirmDeleteForecast();
        },
        edit: (data) => {
          this.scenarioToRename = this.getColumnMetaData(data.column);
          this.showRenameScenarioDialog = true;
        },
        toggle: (data) => {
          this.toggleScenario(this.getColumnMetaData(data.column), data.element.checked);
        },
        export: (data) => {
          this.forecastToExport = this.getColumnMetaData(data.column);
          this.exportForecast();
        }
      });
    },
    getColumnMetaData (column) {
      let col = this.columnMetaData[column];

      if (col) {
        return this.columnMetaData[column];
      } else {
        return null;
      }
    },
    confirmDeleteForecast () {
      this.$refs.confirmDeleteScenarioDialog.showDialog();
    },
    exportForecast() {
      this.addExport({
        analysisId: this.activeAnalysis.id,
        exportMessage: `Exporting ${this.forecastToExport.forecastType} forecast for ${this.forecastToExport.scenarioName}...`,
        errorMessage: `Error exporting ${this.forecastToExport.forecastType} forecast for ${this.forecastToExport.scenarioName}`,
        promise: this.$http.post(`/analyses/${this.activeAnalysis.id}/export/Scenarios/${this.forecastToExport.scenarioId}/${this.forecastToExport.forecastType}`)
      });
    },
    async deleteForecast () {
      await api.deleteForecast(this.activeAnalysis.id, this.forecastToDelete.scenarioId, this.forecastToDelete.forecastType);
      this.$emit('scenarios-updated', { scenarioDeleted: true });
    },
    async toggleScenario (scenario, val) {
      const toggle = async () => {
        if (scenario.isDefaultScenario) {
          let settings = calcsToSettings(this.calcs);
          settings.enableDefaultScenario = val;
          
          await this.saveAnalysisSettings({
            id: this.activeAnalysis.id,
            data: settings
          });
        } else {
          if (scenario.scenarioId) {
            await api.updateScenario(this.activeAnalysis.id, scenario.scenarioId, {
              scenarioName: scenario.scenarioName,
              enabled: val
            });
            scenario.enabled = val;
          }
        }
        this.setAnalysisModified();
        await this.getScenarios();
        this.$emit('scenario-toggled');
      };

      this.autoSaveItem({ itemName: 'ScenarioGrid', isValid: true, onSave: toggle, waitTime: 100 });
    },
    async getItems() {
      let res = [];
      let startDate = null;
      let insert = true;

      // Get all default forecasts
      for (const f of this.forecastTypes) {
        if (f.default) {
          let forecast = await api.getDefaultForecast(this.activeAnalysis.id, f.name);
          
          if (!startDate) {
            startDate = moment(forecast.startDate);
          }

          forecast.values.forEach((v, i) => {
            if (insert) {
              let row = {
                'date': startDate.toDate(),
                'AD&Co Default': {}
              }
              row['AD&Co Default'][f.name] = v;
              startDate.add(1, 'months');
              res.push(row);
            } else {
              res[i]['AD&Co Default'][f.name] = v;
            }
          });
          insert = false;
        }
      }

      // Reset start date to first day of default forecast
      startDate = moment(res[0].date);
      let scenarioGroupData = await api.getForecastGroup(this.activeAnalysis.id, this.forecastGroup);

      let errorScenarios = []
      if (this.scenarioDeleted || this.newForecastAdded) {
        for (const s of this.scenarios) {
          if(!s.isDefaultScenario && !this.validateScenarios(s.scenarioId)){
            errorScenarios.push(s.scenarioName)
          } 
        }
        if(this.newForecastAdded) errorScenarios.pop()
      }

      for (let scenario of scenarioGroupData) {
          let forecastStartDate = moment(scenario.forecast.startDate);
          
          res.filter(r => r['date'].getTime() < forecastStartDate.toDate().getTime()).forEach(r => {
              if (!(`_${scenario.scenarioId}` in r)) r[`_${scenario.scenarioId}`] = {};
              r[`_${scenario.scenarioId}`][scenario.forecast.type] = '';
          });

          let monthsOffset = Math.max(startDate.diff(forecastStartDate, 'months'), 0);

          res.filter(r => r['date'].getTime() >= forecastStartDate.toDate().getTime()).forEach((r,i) => {
            if ((this.scenarioDeleted || this.newForecastAdded) && errorScenarios.length > 0 && errorScenarios.includes(scenario.scenarioName)) {
              errorScenarios.forEach(senario => {
                r[scenario.scenarioName] = this._scenarioGridData.find(e=> r['date'].getTime() == e.date.getTime())[scenario]
              })
            } else {
              if (i+monthsOffset >= scenario.forecast.values.length) {
                return;
              }
              else {
                if (!(`_${scenario.scenarioId}` in r)) r[`_${scenario.scenarioId}`] = {};
                r[`_${scenario.scenarioId}`][scenario.forecast.type] = scenario.forecast.values[i+monthsOffset];
              }
            }
          });

          res.filter(r => r['date'].getTime() >= moment(startDate).add(scenario.forecast.values.length - monthsOffset, 'months').toDate().getTime()).forEach((r,i) => {
              if (!(`_${scenario.scenarioId}` in r)) r[`_${scenario.scenarioId}`] = {};
              r[`_${scenario.scenarioId}`][scenario.forecast.type] = '';
          });
      }

      if (!_.isEmpty(this.scenarioPreviewData)) {
        let startDate = moment(this.scenarioPreviewData.forecast.startDate);

        res.filter(r => r['date'].getTime() >= startDate.toDate().getTime()).forEach((r,i) => {
            r[this.scenarioPreviewData.scenarioName] = this.scenarioPreviewData.forecast.values[i];
        });
      }

      this._scenarioGridData = res;
      this.scenarioDeleted = false;
      this.newForecastAdded = false;
      this.setScenarioData({
        data: this._scenarioGridData,
        forecastGroup: this.forecastGroup
      });
      this.validateScenarios();

      return res;
    },
    validateScenarios(scenarioId) {
      let invalidScenarios = [];
      let isValid = true;
      let isScenarioValid = true;
      for (const s of this.scenarios) {
        if (s.isDefaultScenario) continue;
        if (scenarioId && (s.scenarioId !==scenarioId)) continue;
        isScenarioValid = true;
        s.forecastTypes.filter(t => this.forecastNames.includes(t)).forEach(f => {
          isScenarioValid =  !this._scenarioGridData.some(r => !(`_${s.scenarioId}` in r) || !(f in r[`_${s.scenarioId}`]) || !(r[`_${s.scenarioId}`][f] || r[`_${s.scenarioId}`][f] === 0));
        });
        
        if (!isScenarioValid) {
          isValid = false;
          invalidScenarios.push(s.scenarioId);
        }
      }

      this.updateValidationStatus({
        tab: 'Scenarios',
        item: 'scenario-grid',
        invalidScenarios,
        isValid
      });

      return isValid;
    },
    async patchItem(item) {
      let grid = this.$options.grid._grid;
      let range = grid.selectedRanges[0];
      let selectedColStart = range.col > 1 ? range.col-1 : null
      let selectedColEnd = range.col2 > 1 ? range.col2-1: null
      
      if(selectedColStart > selectedColEnd) {
        [selectedColStart, selectedColEnd] = [selectedColEnd, selectedColStart]
      }
      
      let selectedScenarios = []
      if (selectedColStart) {
        selectedScenarios.push(parseInt(grid.getColumn(selectedColStart+1).binding.split('.')[0].slice(1)))
        if (selectedColEnd && selectedColEnd !== selectedColStart) {
          for(let i=selectedColStart+1; i<=selectedColEnd; i++){
            selectedScenarios.push(parseInt(grid.getColumn(i+1).binding.split('.')[0].slice(1)))
          }
        }
      }
      
      const saveScenarios = () => {
        let p = [];      
        for (const s of this.scenarios) {
          if (s.isDefaultScenario) continue;
          if (selectedScenarios.length > 0 && !selectedScenarios.includes(s.scenarioId)) continue;
          if (!this.validateScenarios(s.scenarioId)) continue;
          
          let date = new Date(this._scenarioGridData[0]['date']);
          date.setUTCHours(0,0,0,0);

          this.forecastTypes.forEach(t => {
            let forecast = {
              startDate: date,
              values: []
            };
            this._scenarioGridData.forEach(r => {
              if (r[`_${s.scenarioId}`][t.name] || r[`_${s.scenarioId}`][t.name] ===0) {
                forecast.values.push(r[`_${s.scenarioId}`][t.name])
              }
            });
            p.push(api.updateScenarioForecast(this.activeAnalysis.id, s.scenarioId, t.name, forecast));
          });
        }
        
        this.validateScenarios()
        return Promise.all(p);
      };

      let isValidColumn = false;
      for (const s of this.scenarios) {
        if(selectedScenarios.length > 0 && !selectedScenarios.includes(s.scenarioId)) continue;
        if(this.validateScenarios(s.scenarioId))
          isValidColumn= true
      }
      
      if(isValidColumn){
        this.autoSaveItem({ itemName: 'ScenarioGrid', isValid: true, onSave: saveScenarios })
      }

      this.validateScenarios()
    },
    async fillRange() {
      this.fillRangeErrorMessages = [];
      let validSelection = true;
      let grid = this.$options.grid._grid;
      let range = grid.selectedRanges[0];
      let firstRow = Math.min(range.row, range.row2);
      let lastRow = Math.max(range.row, range.row2);
      let firstValue = grid.getCellData(firstRow, range.col);
      let lastValue = grid.getCellData(lastRow, range.col);

      if (range.columnSpan > 1) {
        this.fillRangeErrorMessages.push("Multiple columns selected.");
        validSelection = false;
      }
      
      if (range.col <= 1 || range.col2 <= 1) {
        this.fillRangeErrorMessages.push("Invalid column(s) selected.");
        validSelection = false;
      }
      
      if (firstRow == lastRow) {
        this.fillRangeErrorMessages.push("Only one row selected.");
        validSelection = false;
      }
      
      if (firstValue == undefined) {
        this.fillRangeErrorMessages.push("Unknown first value.");
        validSelection = false;
      }

      if (lastValue == undefined) {
        this.fillRangeErrorMessages.push("Unknown last value.");
        validSelection = false;
      }
      
      for (let i = firstRow+1; i < firstRow + range.rowSpan - 1; i++) {
        if (grid.getCellData(i, range.col) != undefined) {
          this.fillRangeErrorMessages.push("Value found within range.");
          validSelection = false;
          break;
        }
      }

      if (!validSelection) {
        this.$refs.invalidSelectionDialog.showDialog();
        return;
      }

      let increment = (lastValue - firstValue) / (lastRow - firstRow);
      let n = firstValue + increment;
      for (let i = firstRow+1; i < firstRow + range.rowSpan - 1; i++) {
        grid.setCellData(i, range.col, n);
        n+=increment;
      }
      await this.patchItem({});
      this.setScenarioData({
        data: this._scenarioGridData,
        forecastGroup: this.forecastGroup
      });
    },
    selectionChanged() {
      let grid = this.$options.grid._grid;
      let range = grid.selectedRanges[0];

      if (range.col != range.col2 || range.col < 1 || !range.isValid) return;

      let firstRow = Math.min(range.row, range.row2);
      let lastRow = Math.max(range.row, range.row2);
      let startDate = grid.getCellData(firstRow, 0);
      let endDate = grid.getCellData(lastRow, 0);

      let col = grid.getColumn(range.col);
      let binding = col._binding._parts[0];
      let forecastType = col._binding._parts[1];

      this.$emit('cells-selected', {
        startDate,
        endDate,
        scenarioId: binding == 'AD&Co Default' ? null : binding.slice(1),
        forecastType
      })
    }
  },
  async activated () {
    this.deactivated = false;
    if (this.scenarioGridDirty || this.reloadOnActivation) {
      if (this.$options.grid) await this.loadGrid();
      this.setScenarioGridDirty(false);
      this.reloadOnActivation = false;
    }
  },
  deactivated () {
    this.deactivated = true;
  },
  watch: {
    scenarioGridDirty: {
      async handler(val) {
        if (val && this.deactivated) {
          this.reloadOnActivation = true;
        } else if (val && !this.deactivated) {
          if (this.$options.grid) await this.loadGrid();
          this.setScenarioGridDirty(false);
        }
      }
    },
    scenarioPreviewData: async function(newValue) {
      if(!_.isEmpty(newValue)) await this.loadGrid();
    },
    isValidating (val) {
      if (val === true) {
        this.validateScenarios();
      }
    }
  },
  components: {
    LoadingIndicator,
    ModalDialog,
    RenameScenarioDialog
  }
};
</script>

<style scoped>
.wj-content {
  border-radius: 0 0 4px 4px;
  border-right: none;
  width: auto;
}
</style>

<style>
.scenario-grid.wj-flexgrid .wj-state-sticky .wj-header {
    opacity: 1.0;
}

.scenario-grid.wj-flexgrid .wj-header-alt {
  display: flex;
  align-items: center;
  justify-content: safe flex-end;
  -webkit-box-align: center;
}

.scenario-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>