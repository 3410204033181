<template>
  <div class="h-100">
    <collapsible-section
      section-title="Advanced Settings"
      css-class="calcs-panel"
      hide-toggle
    >
      <div :class="contentClasses">
        <div
          v-if="activeAnalysis.assetType == assetTypes.singleFamilyLoans"
          class="calcs-2-col"
        >
          <b-form-group
            v-if="hasDefaultDefinitionOptions && activeAnalysis.assetType != assetTypes.autoLoans && activeAnalysis.assetType != assetTypes.multiFamilyLoans"
            label="Default Definition"
            label-for="default-definition"
            title="Set termination condition for delinquent loans"
            :label-cols="6"
          >
            <b-select
              id="default-definition"
              :value="defaultDefinition"
              @input="$emit('update:defaultDefinition', $event)"
              :options="defaultDefinitionOptions"
              size="sm"
            />
          </b-form-group>
          
          <b-form-group
            label="SMM/MDR Definition"
            label-for="smm-mdr-definition"
            title="Set mapping of states/transitions to SMM and MDR"
            :label-cols="6"
          >
            <b-select
              id="smm-mdr-definition"
              :value="smmMdrDefinition"
              @input="$emit('update:smmMdrDefinition', $event)"
              :options="smmMdrDefinitionOptions"
              size="sm"
            />
          </b-form-group>

          <b-form-group
            label="Delinquency Calculation Method"
            label-for="delinquency-calculation-method"
            title="Set delinquency definition"
            :label-cols="6"
          >
            <b-select
              id="delinquency-calculation-method"
              :value="delinquencyCalculationMethod"
              @input="$emit('update:delinquencyCalculationMethod', $event)"
              :options="delinquencyCalculationMethodOptions"
              size="sm"
            />
          </b-form-group>

          <b-form-group
            label="Curve Type"
            label-for="curve-type"
            title="Set interest rate curve type"
            :label-cols="6"
          >
            <b-select
              id="curve-type"
              :value="curveType"
              @input="$emit('update:curveType', $event)"
              :options="curveTypeOptions"
              size="sm"
            />
          </b-form-group>
        </div>

        <div :class="columnClasses">
          <b-form-group
            label="AD&amp;Co Data Files Month/Year"
            label-for="model-data"
            title="Month and year of AD&amp;Co Data files to use for this analysis"
            :label-cols="6"
          >
            <b-form-select
              id="model-data"
              :value="modelDataDate"
              @input="$emit('update:modelDataDate', $event)"
              class="field-sm"
              :options="modelDataDates"
              size="sm"
              @change="v.$touch()"
            />
          </b-form-group>

          <b-form-group
            label="Custom Command"
            label-for="custom-command"
            title="Additional commands to send to the model"
          >
            <b-form-input
              id="custom-command"
              :value="customCommand"
              @input="$emit('update:customCommand', $event)"
              type="text"
              size="sm"
              autocomplete="off"
            />
          </b-form-group>

          <div class="pt-3">
            <div>
              <b-form-checkbox 
                v-b-tooltip.right.hover
                :checked="outputXmlInputs"
                @change="$emit('update:outputXmlInputs', $event)"
                title="Enable XML input logging for LDM. The file ADCO_ModelInputs.txt will be written to the analysis ppmodel folder. WARNING: These files can be large. It is recommended to limit the number of positions run with this option enabled."
                inline
              >
                Output XML Inputs
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox 
                v-b-tooltip.right.hover
                :checked="outputXmlResults"
                @change="$emit('update:outputXmlResults', $event)"
                title="Enable XML results logging for LDM. The file ADCO_ModelResults.txt will be written to the analysis ppmodel folder. WARNING: These files can be large. It is recommended to limit the number of positions run with this option enabled."
                inline
              >
                Output XML Results
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </collapsible-section>
  </div>
</template>

<script>
import CollapsibleSection from './CollapsibleSection.vue'
import { mapState, mapGetters } from 'vuex'
import api from '../api'
import { defaultDefinitionOptions } from '../js/options/defaultDefinition'
import { smmMdrDefinitionOptions } from '../js/options/smmMdrDefinition'
import { delinquencyCalculationMethodOptions } from '../js/options/delinquencyCalculationMethod'
import { curveTypeOptions } from '../js/options/curveType'
import { assetTypes } from '../js/options/assetType'
import { fileNames } from '../js/fileInfo'

export default {
  props: {
    modelDataDate: { type: String, required: true },
    defaultDefinition: { type: String, required: true },
    smmMdrDefinition: { type: String, required: true },
    delinquencyCalculationMethod: { type: String, required: true },
    curveType: { type: String, required: true },
    useCustomTuningFiles: { type: Boolean, required: true },
    customCommand: { type: String, default: '' },
    outputXmlInputs: { type: Boolean, required: true },
    outputXmlResults: { type: Boolean, required: true },
    v: { type: Object, required: true }
  },
  data () {
    return {
      modelDataDates: [],
      defaultDefinitionOptions,
      smmMdrDefinitionOptions,
      delinquencyCalculationMethodOptions,
      curveTypeOptions,
      assetTypes,
      fileNames
    };
  },
  async mounted () {
    let dates = await api.getModelDataDates(this.activeAnalysis.id);
    this.modelDataDates = dates.map(r => ({ value: r.dateKey, text: `${r.month < 10 ? '0' + r.month : r.month}/${r.year}` }));
  },
  computed: {
    ...mapState(['activeAnalysis', 'licenseInfo']),
    ...mapGetters(['hasDefaultDefinitionOptions']),

    contentClasses () {
      return {
        'calcs-content': this.activeAnalysis.assetType != assetTypes.autoLoans
      };
    },

    columnClasses () {
      return {
        'calcs-2-col': this.activeAnalysis.assetType != assetTypes.autoLoans
      };
    }
  },
  components: {
    CollapsibleSection
  }
}
</script>

<style scoped>
@media (min-width: 1024px) {
  .calcs-content {
    display: flex;
  }
  .calcs-2-col {
    width: calc(50% - 10px);
  }
  .calcs-2-col:first-of-type {
    margin-right: 20px;
  }
}
</style>