export const ldmVectors = [
  { prop: 'crr', name: 'CRR', selected: true },
  { prop: 'mrr', name: 'MRR' },
  { prop: 'cdr', name: 'CDR' },
  { prop: 'mdr', name: 'MDR' },
  { prop: 'cpr', name: 'CPR' },
  { prop: 'smm', name: 'SMM' },
  { prop: 'postTermBalance', name: 'Post Termination Balance', defaultAxis: 'y2' },
  { prop: 'lossSeverity', name: 'Loss Severity' },
  { prop: 'expectedLoss', name: 'Expected Loss' },
  { prop: 'dollarDefault', name: 'Dollar Default', defaultAxis: 'y2'},
  { prop: 'dollarLoss', name: 'Dollar Loss', defaultAxis: 'y2'},
  { prop: 'cumPrepay', name: 'Cumulative Prepay'},
  { prop: 'cumDefault', name: 'Cumulative Default'},
  { prop: 'cumLoss', name: 'Cumulative Loss'},
  { prop: 'cumAmortization', name: 'Cumulative Amortization'},
  { prop: 'current', name: 'Current'},
  { prop: 'delinquent', name: 'Delinquent'},
  { prop: 'seriouslyDelq', name: 'Seriously Delinquent'},
  { prop: 'terminated', name: 'Terminated'},
  { prop: 'delq60Plus', name: 'Delinquent 60 Plus' },
  { prop: 'delq90Plus', name: 'Delinquent 90 Plus'},
  { prop: 'delq180Plus', name: 'Deqlinquent 180 Plus'},
  { prop: 'delq0Days', name: 'Delinquent 0 Days'},
  { prop: 'delq30Days', name: 'Delinquent 30 Days'},
  { prop: 'delq60Days', name: 'Delinquent 60 Days'},
  { prop: 'delq90Days', name: 'Delinquent 90 Days'},
  { prop: 'delq120Days', name: 'Delinquent 120 Days'},
  { prop: 'delq150Days', name: 'Delinquent 150 Days'},
  { prop: 'seriouslyDelq1', name: 'Seriously Delinquent 1'},
  { prop: 'seriouslyDelq2', name: 'Seriously Delinquent 2'},
  { prop: 'reo', name: 'REO'},
  { prop: 'cToC', name: 'CtoC'},
  { prop: 'cToD', name: 'CtoD'},
  { prop: 'cToS', name: 'CtoS'},
  { prop: 'cToT', name: 'CtoT'},
  { prop: 'dToC', name: 'DtoC'},
  { prop: 'dToD', name: 'DtoD'},
  { prop: 'dToS', name: 'DtoS'},
  { prop: 'dToT', name: 'DtoT'},
  { prop: 'sToC', name: 'StoC'},
  { prop: 'sToD', name: 'StoD'},
  { prop: 'sToS', name: 'StoS'},
  { prop: 'sToT', name: 'StoT'},
  { prop: 'cBal', name: 'CBal', defaultAxis: 'y2'},
  { prop: 'dBal', name: 'DBal', defaultAxis: 'y2'},
  { prop: 'sBal', name: 'SBal', defaultAxis: 'y2'},
  { prop: 'twoYrForecast', name: '2 Year', defaultAxis: 'y2'},
  { prop: 'tenYrForecast', name: '10 Year', defaultAxis: 'y2'},
  { prop: 'indexForecast', name: 'Index', defaultAxis: 'y2'},
  { prop: 'wacForecast', name: 'WAC', defaultAxis: 'y2'},
  { prop: 'ccy30Forecast', name: 'CCY30', defaultAxis: 'y2'},
  { prop: 'ccy15Forecast', name: 'CCY15', defaultAxis: 'y2'},
  { prop: 'ccy5Forecast', name: 'CCY5', defaultAxis: 'y2'},
  { prop: 'mtg30Forecast', name: 'MTG30', defaultAxis: 'y2'},
  { prop: 'mtg15Forecast', name: 'MTG15', defaultAxis: 'y2'},
  { prop: 'mtg5Forecast', name: 'MTG5', defaultAxis: 'y2'},
  { prop: 'nationalHpiForecast', name: 'HPI', defaultAxis: 'y2'},
  { prop: 'nationalUnemploymentForecast', name: 'Unemployment', defaultAxis: 'y2'},

];