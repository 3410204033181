import Vue from 'vue'
import router from './router'
import store from './store'
import httpClient from './httpClient'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import { library as faLibrary} from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faQuestionCircle, faEdit, faClone, faTrash, faSignOutAlt, faSlidersH,
    faPlay, faFileExport, faFile, faCloudUploadAlt, faSearch, faDownload, 
    faTimesCircle, faChevronRight, faChevronDown, faChevronUp, faLevelUpAlt,
    faPlusSquare, faFolder, faDoorOpen, faSyncAlt, faStop, faUndo, faRedo, 
    faPen, faBan, faFileMedical, faCheck, faTimes, faTable, faColumns,
    faUserCircle, faUsers, faFileSignature, faCog, faFilter, faGripVertical, 
    faCopy, faPaste, faMinusSquare, faWindowClose, faSave, faArchive,faPencilAlt,
    faExclamationCircle, faCloudDownloadAlt, faList, faArrowAltCircleLeft, faCheckSquare, 
    faTrashAlt, faFileImport, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faPlusSquare as faPlusSquareRegular, faClone as faCloneRegular, faTrashAlt as faTrashAltRegular } from '@fortawesome/free-regular-svg-icons'

import * as wjcCore from '@grapecity/wijmo';
import isElectron from 'is-electron'
import disableAll from './js/directives/disable-all'

Vue.use(BootstrapVue, { BTooltip: { delay: 300 }});
Vue.use(Vuelidate);

Vue.prototype.$http = httpClient;

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.directive("disable-all", disableAll);

// Load icons
faLibrary.add(faQuestionCircle, faEdit, faClone, faTrash,  faTrashAlt,faPencilAlt,
    faSignOutAlt, faPlay, faFileExport, faFile, faCloudUploadAlt, 
    faSearch, faDownload, faTimesCircle, faSave, faSlidersH,
    faWindowClose, faChevronRight, faChevronDown, faChevronUp,
    faLevelUpAlt, faPlusSquare, faFolder, faDoorOpen, faSyncAlt,
    faStop, faUndo, faRedo, faPen, faBan, faFileMedical, faCheck, 
    faTimes, faTable, faColumns, faUserCircle, faUsers,
    faFileSignature, faCog, faFilter, faGripVertical, faCopy, 
    faPaste, faMinusSquare, faArchive, faExclamationCircle, 
    faCloudDownloadAlt,faList, faArrowAltCircleLeft, faCheckSquare, 
    faPlusSquareRegular, faCloneRegular, faTrashAltRegular, faFileImport,
    faSignInAlt);

// Wijmo Unlimited distribution key
wjcCore.setLicenseKey('Andrew Davidson & Co,774879619182158#B0qW8isnOiQkIsISP3E4bPJlVw84QvpXel3ybrgDbFh6KFN6Z684cJZ5NKt4dwJzSyNENtpXcT9GdzJjWNhXNxIETtZ7bjlVdLlUM9JXR5NVUQZDRxU7RkxGUsNHTLFkRaljNvkDbzIVbWREWB9kQzVjQ946TJFWdj5GVBVUeRJndxUWQHt6LLJUMqVXT8JFVEh6a9plQUN7bhxkYvYnbJVGTn3ySYN6aWh7b5QXWSJDRvEEeDlVZwkVN0lXdadGNoxWTtl4TalGOHhmeKZmTjBTWtNkaZVENzNWWPZjRyI7bahjYv4mSws6boxUahBzVsNGR8YDSNNUci3CS7EFVPFkZYd7Yr56NF96SG94RLR7TsVXSxA5RS9GNpdWbuBHU456dj3SQ5NkRRd5crEjcwUmNmR4Km3kRlJTQ7hmd5YjdwlUMxkETlp4UyklS5tEemp6LqpXcU3UTOZVWQlzaZ5mMRJiOiMlIsIyNygzNwMjRiojIIJCLwgTOyITM9QDN0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIwEzM4gDMgATMxAjMyAjMiojI4J7QiwiIvNEImAibvNHZpZXYEBydlJHZuFkI0ISYONkIsICO5EjM8ETOxYTO7gDN7cjI0ICZJJCL355W0IyZsZmIsIiM6FjMwIjI0IiclZnIsU6csFmZ0IiczRmI1ijPs');

Vue.config.productionTip = false;

// Add custom title bar if running in electron
if (isElectron()) {
  const electronTitlebar = require('custom-electron-titlebar');
  window.addEventListener('DOMContentLoaded', () => {
    new electronTitlebar.Titlebar({
      backgroundColor: electronTitlebar.Color.fromHex('#222526'),
      titleHorizontalAlignment: 'left',
      menu: null,
      icon: './favicon.ico'
    });
  });
}

// Attach Vue app
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
