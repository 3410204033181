<template>
  <div>
    <div class="btn-container-right">
      <b-button @click="downloadTemplate">
        Download Example File
      </b-button>
    </div>
    <b-table-lite
      bordered
      small
      striped
      hover
      sticky-header="calc(100vh - 300px)"
      :fields="fields"
      :items="fileLayout"
    >
      <template #table-caption>
        <div>
          In the Required column,
          <ul>
            <li><strong>Required</strong>: Model will not run without the field containing a value.</li>
            <li><strong>Suggested</strong>: It is strongly suggested to provide suggested fields to improve the quality of results. The model will use default values if any of these fields are omitted.</li>
            <li><strong>Optional</strong>: Including optional fields improves the quality of results.</li>
          </ul>
        </div>
      </template>
    </b-table-lite>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as FileSaver from 'file-saver'

export default {
  props: {
    file: { type: Object, required: true }
  },
  data () {
    return {
      fields: [
        { key: 'fieldName', label: 'Field Name' },
        { key: 'type', label: 'Type'},
        { key: 'required', label: 'Required' },
        { key: 'desc', label: 'Field Description' },
        { key: 'example', label: 'Example'},
        { key: 'aliases', label: 'Alias'} 
      ],
      fileLayout: []
    }
  },
  computed: {
    ...mapState(['activeAnalysis']),
    templateFileName () {
    if (this.file.inputType == 'ScenarioImportModel') return 'LDM Scenario Example.txt';
    else if (this.file.inputType == 'ForecastImportModel') return 'LDM Forecast Example.txt';
    else if (this.file.inputType == 'PenaltyPointSetImportModel') return 'MFLDM Custom Points Example.txt';
    return `${this.file.inputType} Example.txt`;
  },
  },
  async mounted () {
    this.fileLayout = await this.getFileLayout();
  },
  methods: {
    async getFileLayout() {
      let cols = this.file.gridOptions.columns;
      let fields = (cols instanceof Function ? cols(this.activeAnalysis) : cols).filter(c => c.visible !== false);

      let resp = await this.$http.get(`analyses/${this.activeAnalysis.id}/inputs/mappings/${this.file.inputType}`);

      fields.map(f => {
        let mappings = resp.data.find(d => d.property.toLowerCase() == f.binding.toLowerCase());

        if (mappings) {
          f.fieldName = mappings.inputFieldNames[0];
          f.aliases = mappings.inputFieldNames.splice(1).join(', ');
          f.required = mappings.required ? 'Required' : f.required ;
        }

        return f;
      });

      return fields;
    },
    async downloadTemplate () {
      let resp = await this.$http.get(`analyses/${this.activeAnalysis.id}/templates/${this.file.inputType}`);

      let blob = new Blob([resp.data], { type: "text/plain;charset=utf-8" });
      let target = this.templateFileName;

      FileSaver.saveAs(blob, target);
    },
  }
}
</script>
