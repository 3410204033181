<template>
  <div class="h-100">
    <collapsible-section
      section-title="Results"
      css-class="calcs-panel"
      hide-toggle
    >
      <b-form-checkbox 
        :checked="true"
        :disabled="true"
      >
        {{ basicLabel }}
      </b-form-checkbox>

      <b-form-checkbox 
        :checked="cumulatives"
        @change="$emit('update:cumulatives', $event)"
      >
        {{ cumulativesLabel }}
      </b-form-checkbox>

      <b-form-checkbox 
        :checked="delqStatus"
        @change="$emit('update:delqStatus', $event)"
      >
        {{ delqStatusLabel }}
      </b-form-checkbox>

      <b-form-checkbox
        v-if="isLicensingAgencyPlus && activeAnalysis.assetType == assetTypes.singleFamilyLoans" 
        :checked="granularDelq"
        @change="$emit('update:granularDelq', $event)"
      >
        {{ granularDelqLabel }}
      </b-form-checkbox>

      <b-form-checkbox 
        :checked="transitions"
        @change="$emit('update:transitions', $event)"
      >
        {{ transitionsLabel }}
      </b-form-checkbox>

      <b-form-checkbox 
        :checked="balances"
        @change="$emit('update:balances', $event)"
      >
        {{ balancesLabel }}
      </b-form-checkbox>

      <b-form-checkbox 
        :checked="macroeconomicForecasts"
        @change="$emit('update:macroeconomicForecasts', $event)"
      >
        {{ macroeconomicForecastsLabel }}
      </b-form-checkbox>
    </collapsible-section>
  </div>
</template>

<script>
import CollapsibleSection from './CollapsibleSection.vue'
import { mapState, mapGetters } from 'vuex'
import { assetTypes } from '../js/options/assetType'

export default {
  props: {
    cumulatives: { type: Boolean, required: true },
    delqStatus: { type: Boolean, required: true },
    granularDelq: { type: Boolean, required: true },
    transitions: { type: Boolean, required: true },
    balances: { type: Boolean, required: true },
    macroeconomicForecasts: { type: Boolean, required: true }
  },
  data() {
    return {
      assetTypes
    }
  },
  computed: {
    ...mapState(['activeAnalysis']),
    ...mapGetters(['isLicensingAgencyPlus']),
    basicLabel() {
      return 'Basic (CRR, CDR, CPR, etc.)'
    },
    cumulativesLabel() {
      return 'Cumulatives (CumPrepay, CumDefault, etc.)'
    },
    delqStatusLabel() {
      if (this.activeAnalysis.assetType == assetTypes.multiFamilyLoans) return 'Delinquencies (C, T)'
      return 'Delinquencies (C, D, S, T, D60+, etc.)'
    },
    granularDelqLabel() {
      return 'Granular Delinquencies (D0, D1, D2, etc.)'
    },
    transitionsLabel() {
      if (this.activeAnalysis.assetType == assetTypes.multiFamilyLoans) return 'Transition Rates (CtoC, CtoT)'
      return 'Transition Rates (CtoD, CtoT, etc.)'
    },
    balancesLabel() {
      if (this.activeAnalysis.assetType == assetTypes.multiFamilyLoans) return 'Balances (CBal)'
      return 'Balances (CBal, DBal, SBal)'
    },
    macroeconomicForecastsLabel() {
      if (this.activeAnalysis.assetType == assetTypes.multiFamilyLoans) return 'Macroeconomic Forecasts (2Yr, 10Yr, Index, HPI, etc.)'
      return 'Macroeconomic Forecasts (2Yr, 10Yr, CCY, MTG, etc.)'
    }
  },
  components: {
    CollapsibleSection
  }
}
</script>