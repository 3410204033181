<template>
  <scenarios-container 
    :forecast-group="forecastGroup"
    :forecast-types="forecastTypes"
    :chart-title="chartTitle"
  />
</template>

<script>
import ScenariosContainer from './ScenariosContainer.vue'
import { mapState } from 'vuex'

export default {
  props: {
    id: { type: [String, Number], required: true }
  },
  data () {
    return {
      forecastGroup: 'Rate',
      rateForecastTypes: [
        {
          name: 'Swap2Yr',
          header: '2Yr',
          default: true,
          multifamily: true
        },
        {
          name: 'Swap10Yr',
          header: '10Yr',
          default: true,
          multifamily: true
        },
        {
          name: 'Ccy30Yr',
          header: 'CCY30',
          default: false,
          multifamily: false
        },
        {
          name: 'Ccy15Yr',
          header: 'CCY15',
          default: false,
          multifamily: false
        },
        {
          name: 'Ccy5Yr',
          header: 'CCY5',
          default: false,
          multifamily: false
        },
        {
          name: 'Mortgage30Yr',
          header: 'MTG30',
          default: false,
          multifamily: false
        },
        {
          name: 'Mortgage15Yr',
          header: 'MTG15',
          default: false,
          multifamily: false
        },
        {
          name: 'Mortgage5Yr',
          header: 'MTG5',
          default: false,
          multifamily: false
        },
        {
          name: 'Index',
          header: 'Index',
          default: false,
          multifamily: true
        },
        {
          name: 'Wac',
          header: 'WAC',
          default: false,
          multifamily: true
        },
        {
          name: 'CreditSpread',
          header: 'Credit Spread',
          default: false,
          multifamily: false
        }
      ],
      chartTitle: 'Rate Scenarios'
    }
  },
  computed: {
    ...mapState(['activeAnalysis']),
    forecastTypes () {
      if (this.activeAnalysis.assetType === 'MultiFamilyLoans')
        return this.rateForecastTypes.filter(f => f.multifamily);
      return this.rateForecastTypes;
    }
  },
  components: {
    ScenariosContainer
  }
}
</script>