<template>
  <div class="tunings-modal">
    <b-modal
      :id="id" 
      :size="dialogSize"
      title="Tuning Values"
      ok-title="Save"
      no-close-on-backdrop
      @ok="save"
      @cancel="revert"
      @close="revert"
    >
      <b-container>
        <b-row>
          <b-col
            v-for="(grp, grpIdx) in visibleGroups"
            :key="`grp-${grpIdx}`"
            :lg="columnScale"
          >
            <b-form-group
              class="group-label"
              :label="grp.label"
              label-class="font-weight-bold text-center"
            >
              <b-form-group
                v-for="field in grp.fields" 
                :key="field.key"
                :label-for="field.key"
                label-cols="7"
                label-size="sm"
              >
                <template #label>
                  {{ field.label }}
                  <span
                    v-if="field.helpText"
                    v-b-tooltip.hover.right
                    :title="field.helpText"
                  ><font-awesome-icon icon="question-circle" /></span>
                </template>
                    
                <b-form-input
                  :id="field.key"
                  v-model="$v.tuningValues[field.key].$model"
                  type="text"
                  size="sm"
                  :state="state($v.tuningValues[field.key])"
                />
                <b-form-invalid-feedback :state="state($v.tuningValues[field.key])">
                  {{ validationMessage($v.tuningValues[field.key]) }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <small>
        <strong>Looking for risk neutral tuning values?</strong>
        <span> See </span>
      </small>
      <a
        class="light-link"
        target="_blank"
        href="https://www.ad-co.com/reports/market-valuation-reports/risk-neutral"
      >https://www.ad-co.com/reports/market-valuation-reports/risk-neutral</a>
    </b-modal>
  </div>
</template>

<script>
import { decimal, between } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import validationState from '../js/mixins/validationState'

export default {
  props: {
    id: { type: String, required: true },
    tunings: { type: Object, required: true }
  },
  data () {
    return {
      maxColumns: 5,
      tuningValues: Object.assign({}, this.tunings),
      groups: [
        { 
          label: 'Voluntary Termination (Prepayments)', 
          fields: [
            { key: 'smmScale', label: 'SMM Scale', helpText: 'Scale both refinancing and turnover component of C-T. Differs from Tune C-T in that it affects burnout.  Multiplicative.  Bigger means faster prepay.' },
            { key: 'refiScale', label: 'Refinancing Scale', helpText: 'Scale refi component of C-T.  Bigger means faster prepays.' },
            { key: 'turnoverScale', label: 'Turnover Scale', helpText: 'Scale turnover component of C-T.  Bigger means faster prepays.' },
            { key: 'slideFactor', label: 'Slide (bps)', helpText: 'Slide C-T S-Curve by adding X bp to the market rate.  Positive means slower speeds.' },
            { key: 'smmBurnout', label: 'Burnout', helpText: 'Scale prepay rate for passive borrowers. Bigger means faster speeds (e.g. slower burnout), Smaller means slower speeds (faster burnout).' }
          ]
        },
        { 
          label: 'Voluntary Termination (Less Common)', 
          fields: [
            { key: 'smmAging', label: 'Aging', helpText: 'Speed-up/Slow-down C-T aging ramp. Bigger means a longer aging ramp/slower prepays. For example a value of 2 would extend an existing aging ramp of 24 to 48 months.' },
            { key: 'smmSteepness', label: 'Steepness', helpText: 'Scale C-T S-Curve steepness.  Bigger means a steeper S-Curve; Values greater than 1 will increase speeds for premiums and decrease speeds for discounts.' },
            { key: 'smmLag', label: 'Lag', helpText: 'Adjust how long it takes for changes in interest rates to affect refi incentive. Multiplicative. Bigger means more lag.' },
            { key: 'seasonality', label: 'Seasonality', helpText: 'Tune Seasonal Effect in both C-D and C-T.' },
            { key: 'smmTunePremiumSteepness', label: 'Premium Steepness', helpText: 'Adjust the effect of premium behavior.' }
          ]
        },
        { 
          label: 'Delinquency Transitions and Defaults', 
          fields: [
            { key: 'mdrScale', label: 'MDR Scale', helpText: 'Multiplicative scale on MDR Output.  Note: will not affect transition rates.' },
            { key: 'tuneCD', label: 'Tune C-D', helpText: 'Tune the C-D transition' },
            { key: 'tuneDC', label: 'Tune D-C', helpText: 'Tune the D-C transition' },
            { key: 'tuneDS', label: 'Tune D-S', helpText: 'Tune the D-S transition' },
            { key: 'tuneDT', label: 'Tune D-T', helpText: 'Tune the D-T transition' },
            { key: 'tuneSC', label: 'Tune S-C', helpText: 'Tune the S-C transition' },
            { key: 'tuneST', label: 'Tune S-T', helpText: 'Tune the S-T transition' }
          ]
        },
        { 
          label: 'Borrower-Data Sensitivity',
          fields: [
            { key: 'ltvSlide', label: 'LTV Slide', helpText: 'Tune Weighted Average LTV by stretching difference between its value and a midpoint.  > 1.0 stretches, < 1.0 contracts.' },
            { key: 'ficoSlide', label: 'FICO Slide', helpText: 'Tune FICO/Credit Score by stretching difference between its value and a midpoint.  > 1.0 stretches, < 1.0 contracts.' },
            { key: 'satoResidual', label: 'SATO Residual Slide', helpText: 'Tune SATO residual.  Direct multiplier on the SATO residual value.' }
          ]
        },
        { 
          label: 'Probability and Severity of Loss', 
          fields: [
            { key: 'severityScale', label: 'Severity Scale', helpText: 'Multiplicative scale on loss severity.' },
            { key: 'tuneSeverityTD', label: 'Severity T from D', helpText: 'Scale Loss Severity for defaults coming from D.' },
            { key: 'tuneSeverityTS', label: 'Severity T from S', helpText: 'Scale Loss Severity for defaults coming from S.' },
            { key: 'tuneSeverityTC', label: 'Severity T from C', helpText: 'Scale Loss Severity for defaults coming from C.' },
            { key: 'tuneProbLossTD', label: 'Probability T from D', helpText: 'Scale Probability of Loss from D Termination.' },
            { key: 'tuneProbLossTS', label: 'Probability T from S', helpText: 'Scale Probability of Loss from S Termination.' },
            { key: 'tuneProbLossTC', label: 'Probability T from C', helpText: 'Scale Probability of Loss from C Termination.' }
          ],
          hidden: this.isMsrAnalysis
        }
      ]
    }
  },
  validations: {
    tuningValues: {
      tuneCD: { decimal, between: between(-10, 10) },
      tuneDC: { decimal, between: between(-10, 10) },
      tuneDS: { decimal, between: between(-10, 10) },
      tuneDT: { decimal, between: between(-10, 10) },
      tuneSC: { decimal, between: between(-10, 10) },
      tuneST: { decimal, between: between(-10, 10) },
      mdrScale: { decimal, between: between(-10, 10) },
      tuneProbLossTD: { decimal, between: between(-10, 10) },
      tuneProbLossTS: { decimal, between: between(-10, 10) },
      tuneProbLossTC: { decimal, between: between(-10, 10) },
      tuneSeverityTD: { decimal, between: between(-10, 10) },
      tuneSeverityTS: { decimal, between: between(-10, 10) },
      tuneSeverityTC: { decimal, between: between(-10, 10) },
      ltvSlide: { decimal, between: between(-10, 10) },
      ficoSlide: { decimal, between: between(-10, 10) },
      severityScale: { decimal, between: between(-10, 10) },
      satoResidual: { decimal, between: between(-10, 10) },
      refiScale: { decimal, between: between(-10, 10) },
      turnoverScale: { decimal, between: between(-10, 10) },
      slideFactor: { decimal, between: between(-10, 10) },
      smmScale: { decimal, between: between(-10, 10) },
      smmAging: { decimal, between: between(-10, 10) },
      smmSteepness: { decimal, between: between(-10, 10) },
      smmLag: { decimal, between: between(-10, 10) },
      smmBurnout: { decimal, between: between(-10, 10) },
      seasonality: { decimal, between: between(-10, 10) },
      smmTunePremiumSteepness: { decimal, between: between(0, 5) }
    }
  },
  computed: {
    ...mapGetters(['isMsrAnalysis']),

    visibleGroups () {
      return this.groups.filter(g => g.hidden !== true);
    },
    dialogSize () {
      return this.visibleGroups.length > 4 ? 'xl' : 'lg';
    },
    columnScale () {
      return this.visibleGroups.length > 4 ? 4 : 6;
    }
  },
  watch: {
    tunings (val) {
      this.tuningValues = Object.assign({}, val);
    }
  },
  methods: {
    revert () {
      Object.assign(this.tuningValues, this.tunings);
    },
    save (evt) {
      if (this.$v.tuningValues.$invalid) {
        evt.preventDefault();
        return;
      }
      
      //console.log(this.tuningValues);
      this.$emit('save-tunings', this.tuningValues);
    }
  },
  mixins: [validationState]
}
</script>

<style scoped>
.form-group {
    margin-bottom: 0.5rem;
}

.group-label {
  margin: 0 1.5rem 2rem 1.5rem;
}
</style>

<style>
.tunings-modal legend.col-form-label {
  font-size: 0.9em;
  padding-bottom: 1rem;
}
</style>