<template>
  <header v-if="!$route.meta.hideHeader">
    <div class="logo">
      <img 
        :src="getImagePath('logo.svg')" 
        alt=""
      >
    </div>
    <div
      v-if="showMsrLogo"
      class="kinetics-logo"
    >
      <img
        v-if="theme == 'light-theme'"
        :src="getImagePath('msrk-logo.png')"
        alt=""
      >
      <img
        v-else
        :src="getImagePath('msrk-logo-w.png')"
        alt=""
      >
    </div>
    <div
      v-else-if="showAldmLogo"
      class="kinetics-logo"
    >
      <img
        v-if="theme == 'light-theme'"
        :src="getImagePath('aldm-logo-sm.png')"
        alt=""
      >
      <img
        v-else
        :src="getImagePath('aldm-logo-sm-w.png')"
        alt=""
      >
    </div>
    <div
      v-else-if="showLkLogo"
      class="kinetics-logo"
    >
      <img
        v-if="theme == 'light-theme'"
        :src="getImagePath('lk-logo.png')"
        alt=""
      >
      <img
        v-else
        :src="getImagePath('lk-logo-w.png')"
        alt=""
      >
    </div>
    <div
      v-else-if="showLdmLogo"
      class="kinetics-logo"
    >
      <img
        v-if="theme == 'light-theme'"
        :src="getImagePath('ldm-logo.png')"
        alt=""
      >
      <img
        v-else
        :src="getImagePath('ldm-logo-w.png')"
        alt=""
      >
    </div>
    <div
      v-else-if="showMfldmLogo"
      class="kinetics-logo"
    >
      <img
        v-if="theme == 'light-theme'"
        :src="getImagePath('mfldm-logo-sm.png')"
        alt=""
        class="lg-logo"
      >
      <img
        v-else
        :src="getImagePath('mfldm-logo-sm-w.png')"
        alt=""
        class="lg-logo"
      >
    </div>
    <div
      v-else-if="showPkLogo"
      class="kinetics-logo"
    >
      <img
        v-if="theme == 'light-theme'"
        :src="getImagePath('pk-logo.png')"
        alt=""
      >
      <img
        v-else
        :src="getImagePath('pk-logo-w.png')"
        alt=""
      >
    </div>
    
    <router-view name="headerContent" />
    
    <b-dropdown
      v-if="isLoggedIn"
      right
      toggle-class="icon-button theme-item"
      class="ml-auto"
    >
      <template #button-content>
        <font-awesome-icon
          icon="user-circle"
          class="fa-lg account-dropdown-icon"
        />
        <span class="account-dropdown-username">{{ displayname }}</span>
      </template>

      <b-dropdown-item
        v-if="isLoggedIn"
        to="/account"
      >
        <font-awesome-icon icon="user-circle" /><span>Account</span>
      </b-dropdown-item>
      
      <b-dropdown-divider />

      <b-dropdown-item to="/settings">
        <font-awesome-icon icon="cog" /><span>Settings</span>
      </b-dropdown-item>

      <b-dropdown-item
        v-if="isLoggedIn && isAdmin"
        to="/users"
      >
        <font-awesome-icon icon="users" /><span>User Manager</span>
      </b-dropdown-item>
      
      <b-dropdown-divider
        v-if="authentication.enabled && isLoggedIn" 
      />
      
      <b-dropdown-item
        v-if="authentication.enabled && isLoggedIn"
        @click="logout"
      >
        <font-awesome-icon icon="sign-out-alt" /><span>Log out</span>
      </b-dropdown-item>
            
      <b-dropdown-item
        v-if="!authentication.enabled"
        to="/login?remote=true"
      >
        <font-awesome-icon icon="sign-in-alt" /><span>Connect...</span>
      </b-dropdown-item>
    </b-dropdown>
  </header>
</template>

<script>
  import externals from '../js/externals'
  import assetPath from '../js/mixins/assetPath'
  import { mapState, mapGetters, mapMutations } from 'vuex';

  export default {
    props: {
      theme: { type: String, required: true }
    },
    data () {
      return {
        appTitle: externals.welcomename(),
        version: externals.SVNversion,
        helpText: null
      }
    },
    computed: {
      ...mapState(['activeAnalysis', 'licenseInfo', 'authentication']),
      ...mapGetters(['isLoggedIn','isAdmin','username','displayname']),

      showMsrLogo () {
        // Always display logo if MSR is the only module or display in editor for MSR analyses
        return (this.licenseInfo.modules.length == 1 && this.licenseInfo.modules[0].assetType == 'Servicing') ||
          (this.$route.path.startsWith('/edit/') && this.activeAnalysis && this.activeAnalysis.assetType == 'Servicing');
      },
      showAldmLogo () {
        return (this.licenseInfo.modules.length == 1 && this.licenseInfo.modules[0].assetType == 'AutoLoans') || 
          (this.$route.path.startsWith('/edit/') && this.activeAnalysis && this.activeAnalysis.assetType == 'AutoLoans');
      },
      showLkLogo () {
        return (this.licenseInfo.modules.length == 1 && this.licenseInfo.modules[0].assetType == 'SingleFamilyLoans' && this.licenseInfo.modules[0].runType == 'RiskValDynamics') || 
          (this.$route.path.startsWith('/edit/') && this.activeAnalysis && this.activeAnalysis.assetType == 'SingleFamilyLoans' && this.activeAnalysis.runType == 'RiskValDynamics');
      },
      showPkLogo () {
        return (this.licenseInfo.modules.length == 1 && this.licenseInfo.modules[0].assetType == 'AgencyPools' && this.licenseInfo.modules[0].runType == 'RiskValDynamics') || 
          (this.$route.path.startsWith('/edit/') && this.activeAnalysis && this.activeAnalysis.assetType == 'AgencyPools' && this.activeAnalysis.runType == 'RiskValDynamics');
      },
      showLdmLogo () {
        return (this.licenseInfo.modules.length == 1 && this.licenseInfo.modules[0].assetType == 'SingleFamilyLoans' && this.licenseInfo.modules[0].runType == 'LoanDynamics') || 
          (this.$route.path.startsWith('/edit/') && this.activeAnalysis && this.activeAnalysis.assetType == 'SingleFamilyLoans' && this.activeAnalysis.runType == 'LoanDynamics');
      },
      showMfldmLogo () {
        return (this.licenseInfo.modules.length == 1 && this.licenseInfo.modules[0].assetType == 'MultiFamilyLoans' && this.licenseInfo.modules[0].runType == 'LoanDynamics') || 
          (this.$route.path.startsWith('/edit/') && this.activeAnalysis && this.activeAnalysis.assetType == 'MultiFamilyLoans' && this.activeAnalysis.runType == 'LoanDynamics');
      }
    },
    methods: {
      ...mapMutations(['clearAuth', 'setAuth']),
      logout () {
        this.clearAuth();

        if (!this.authentication.remoteConnection) {
          // eslint-disable-next-line
          this.$router.push('/login').catch(err => {});
        } else {
          this.setAuth({
            enabled: false,
            server: 'http://localhost:8008',
            remoteConnection: false
          });
        }
      }
    },
    mixins: [assetPath]
  }
</script>

<style scoped>
  header {
    background-color: #16191C;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 25px 0 0;
    min-height: 55px;
  }

  .light-theme header {
    background-color: #fffffd;
  }

  header > sup {
    display: none;
  }

  header > .logo {
    display: flex;
    align-items: center;
    height: 100%;
    background: #276681;
    margin-right: 10px;
  }

  header > .logo > img {
    width: 100px;
    margin: 8px 20px;
  }

  .kinetics-logo > img {
    width: 240px;
    margin: 5px;
  }

  @media screen and (max-width: 800px) {
    .kinetics-logo > img.lg-logo {
      width: 200px;
    }
  }

  @media screen and (max-width: 767px) {
    .kinetics-logo > img.lg-logo {
      width: 240px;
    }
  }
  
  .account-dropdown-username {
    text-decoration: none;
    padding-left: 5px;
    padding-bottom: 0px;
  }
  
  header > .header-link {
    margin-right: 25px;
    display: inline-block;
    height: 16px;
    line-height: 16px;
  }

  header > .header-link:first-of-type {
    margin-left: auto;
  }

  header > .header-link:last-of-type {
    margin-right: 0;
  }

  header > .header-link > span {
    padding-left: 5px;
  }

  header > a {
    font-size: 0.9em;
    color: #efefef;
  }

  header > a:hover {
    color: #fffffd;
  }
  
  /* @media (min-width: 768px) {
    header > sup {
      display: block;
    }
  } */
</style>
<style>
  .dropdown .btn-link:hover {
    color: #fffffd;
    text-decoration: underline;
  }
  .dropdown .btn-link {
    text-decoration: none;
    font-size: 0.9em;
    color: #efefef;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
  }
</style>