<template>
  <collapsible-section
    :section-title="title"
    hide-toggle
  >
    <b-form-row>
      <b-col>
        <b-form-group
          label="Quote Type"
          :label-for="getId('quote-type')"
          :label-cols="5"
        >
          <b-select
            :id="getId('quote-type')"
            class="field-md"
            :value="quoteType"
            :options="quoteTypeOptions"
            size="sm"
            @input="$emit('update-assumptions', { quoteType: $event })"
          />
        </b-form-group>

        <b-form-group
          v-if="quoteType != 'QuoteInFile'"
          :label="quoteLabel"
          :label-for="getId('quote')"
          title="Quote value"
          :label-cols="5"
        >
          <b-form-input
            :id="getId('quote')"
            type="text"
            class="field-sm"
            size="sm"
            :value="quote"
            :state="state($v.quote)"
            @input="update('quote', $event)"
          />
          <b-form-invalid-feedback :state="state($v.quote)">
            {{ validationMessage($v.quote) }}
          </b-form-invalid-feedback>
        </b-form-group>
      
        <b-form-group
          v-if="isMsrAnalysis && !isHedgeAssumptions && quoteType == 'OAS'"
          label="Excess Servicing OAS"
          :label-for="getId('excess-servicing-quote')"
          :label-cols="5"
        >
          <b-form-input
            :id="getId('excess-servicing-quote')"
            type="text"
            class="field-sm"
            size="sm"
            :value="excessServicingQuote"
            :state="state($v.excessServicingQuote)"
            @input="update('excessServicingQuote', $event)"
          />
          <b-form-invalid-feedback :state="state($v.excessServicingQuote)">
            {{ validationMessage($v.excessServicingQuote) }}
          </b-form-invalid-feedback>
        </b-form-group>

        <date-picker
          :id="getId('settle-date')"
          label="Settle Date"
          :value="settleDate"
          :label-cols="5"
          :state="state($v.settleDate)"
          @input="update('settleDate', $event)"
        >
          <b-form-invalid-feedback :state="state($v.settleDate)">
            {{ validationMessage($v.settleDate) }}
          </b-form-invalid-feedback>
        </date-picker>
      </b-col>
    </b-form-row>
  </collapsible-section>
</template>

<script>
import DatePicker from './DatePicker.vue'
import CollapsibleSection from './CollapsibleSection.vue'
import validationState from '../js/mixins/validationState'
import { mapState, mapMutations, mapGetters } from 'vuex'
import createId from '../js/mixins/createId'
import { quoteTypeOptions } from '../js/options/quoteType'
import { required, between, decimal  } from 'vuelidate/lib/validators'
import { date, isSameOrAfter } from '../js/validation/validators'

export default {
  props: {
    title: { type: String, required: true },
    quoteType: { type: String, required: true },
    quote: { type: [String, Number], required: true },
    tradeDate: { type: [Date, String], required: true },
    settleDate: { type: [Date, String], required: true },
    excessServicingQuote: { type: [String, Number], default: 0 },
    isHedgeAssumptions: { type: [Boolean], default: false }
  },
  data () {
    return {
      quoteTypeOptions
    }
  },
  validations () {
    return {
      quote: {
        required,
        decimal,
        between: between(-1000, 10000)
      },
      settleDate: {
        required,
        date,
        isSameOrAfter: isSameOrAfter('tradeDate', (property) => this[property], "Settle Date cannot be before Trade Date")
      },
      excessServicingQuote: {
        required,
        decimal,
        between: between(-1000, 10000)
      }
    }
  },
  computed: {
    ...mapState(['isValidating', 'activeAnalysis']),
    ...mapGetters(['isMsrAnalysis']),

    quoteLabel () {
      return this.activeAnalysis.settings.payup && this.quoteType == "OAS" ? "OAS to TBA" : this.quoteTypeOptions.find(o => o.value == this.quoteType).label;
    }
  },
  methods: {
    ...mapMutations(['addValidationStatus']),

    getId (fieldName) {
      return this.createId(`${this.title}-${fieldName}`);
    },
    update (name, value) {
      this.$v[name] && this.$v[name].$touch();

      let updates = {};
      updates[name] = value;

      this.$emit('update-assumptions', updates);
    }
  },
  watch: {
    isValidating (val) {
      if (val === true) {
        this.$v.$touch();

        this.addValidationStatus({
          tab: 'Calculations',
          item: 'assumptions-panel',
          isValid: !this.$v.$invalid
        });
      }
    }
  },
  components: {
    DatePicker,
    CollapsibleSection
  },
  mixins: [validationState, createId]
}
</script>
