<template>
  <div>
    <b-form-group
      :label="label"
      :label-for="inputId"
      :label-cols="labelCols"
    >
      <flat-pickr
        :title="label"
        :id="id"
        v-model="date"
        class="form-control form-control-sm field-sm"
        :class="{ 'is-invalid': state === false }"
        :config="config"
        @input="$emit('input', $event)"
      />
      <slot />
    </b-form-group>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  components: {
    flatPickr
  },
  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
    value: { type: [Date, String], required: true },
    labelCols: { type: Number },
    state: { type: Boolean, default: true },
    monthYearOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      date: this.value,
    }
  },
  computed: {
    config() {
      let res = {
        wrap: true,
        dateFormat: 'm/d/Y',
        altFormat: 'm/d/Y',
        allowInput: true,
        altInput: true
      }

      if (this.monthYearOnly) {
        res.plugins = [
          new monthSelectPlugin({
            shorthand: true,
            altFormat: "m-Y",
            dateFormat: "Y-m",
          })
        ]
      }

      return res;
    },
    inputId () {
      return this.label
        .replace(/[^A-Za-z0-9 ]/g, '') // remove special chars
        .replace(' ', '-'); // replace space with dash
    }
  },
  watch: {
    value(newVal) {
      this.date = newVal;
    }
  }
}
</script>

<style scoped>
.form-control.flatpickr-input[readonly] {
  background-color: #fff;
}

/* Remove red X from invalid date controls - not enough room in field */
.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
  padding: 0.25rem 0.5rem;
}
</style>
