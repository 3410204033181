<template>
  <div style="overflow: auto;">
    <b-form
      class="login-form"
      @submit="onSubmit"
    >
      <h2>Login</h2>

      <b-form-group
        class="pt-3"
        label="Username"
        label-for="username"
      >
        <b-form-input
          id="username"
          v-model="$v.credentials.username.$model"
          type="text"
          :state="state($v.credentials.username)"
          autocomplete="off"
        />
        <b-link
          v-if="!remote"
          to="UsernameLookup"
          class="login-page-link"
        >
          Forgot Username?
        </b-link>
        <b-form-invalid-feedback :state="state($v.credentials.username)">
          Username is required
        </b-form-invalid-feedback>
      </b-form-group>
      
      <b-form-group
        label="Password"
        label-for="password"
      >
        <b-form-input
          id="password"
          v-model="$v.credentials.password.$model"
          type="password"
          :state="state($v.credentials.password)"
        />
        <b-link
          v-if="!remote"
          to="PasswordReset"
          class="login-page-link"
        >
          Reset Password?
        </b-link>
        <b-form-invalid-feedback :state="state($v.credentials.password)">
          Password is required
        </b-form-invalid-feedback>
      </b-form-group>

      <b-link
        v-if="!remote"
        :to="'SetPassword/?server=' + server"
        class="login-page-link"
      >
        First time user?
      </b-link>


      <div 
        v-if="!remote"
        class="clearfix"
      >
        <b-link
          v-b-toggle.login-options
          class="options-link"
        >
          <font-awesome-icon :icon="showOptions ? 'chevron-up' : 'chevron-down'" />
          {{ showOptions ? 'Hide' : 'More' }} Options
        </b-link>
      </div>

      <b-collapse
        id="login-options"
        v-model="showOptions"
      >
        <b-form-group
          label="Analysis Server"
          label-for="server"
        >
          <b-form-input
            id="server"
            v-model="server"
            type="text"
            :state="state($v.server)"
            autocomplete="off"
          />
          <b-form-invalid-feedback :state="state($v.server)">
            Analysis Server is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-collapse>

      <b-alert
        :show="showError"
        variant="danger"
      >
        {{ errorMessage }}
      </b-alert>
      <b-button
        type="submit"
        variant="primary"
        :disabled="authenticating"
      >
        <b-spinner
          v-if="authenticating"
          small
        /> Login
      </b-button>

      <b-button
        v-if="remote"
        to="/"
        class="ml-2"
      >
        Cancel
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'
import api from '../api'
import isElectron from 'is-electron'
import { setServer } from '../httpClient'

export default {
  data () {
    return {
      server: isElectron() ? 'http://localhost:8008' : window.location.origin,
      credentials: {
        username: null,
        password: null
      },
      showOptions: this.$route.query.remote == "true",
      showError: false,
      errorMessage: '',
      authenticating: false
    }
  },
  validations: {
    credentials: {
      username: {
        required
      },
      password: {
        required
      }
    },
    server: {
      required
    }
  },
  mounted () {
    let server = localStorage.getItem('kinetics_server');

    if (!this.remote && server) {
      this.server = server;
    } else if (this.remote) {
      this.server = "";
    }
  },
  computed: {
    remote () {
      return this.$route.query.remote == "true";
    }
  },
  methods: {
    ...mapMutations(['setAuth']),

    async onSubmit(evt) {
      evt.preventDefault();

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.authenticating = true;
        let server = this.cleanServerString(this.server);
        localStorage.setItem('kinetics_server', server);

        try {
          setServer(server);
          let resp = await api.authenticate(this.credentials);

          if (!resp.data.jwtToken) {
            throw "Token not found";
          }
          let data = Object.assign({}, resp.data, { server, remoteConnection: this.remote, enabled: true })
          this.setAuth(data);

          this.$router.push('/');
        } catch (err) {
          if (err.message == "Network Error") {
            this.errorMessage = `Error connecting to analysis service at ${server}`;
          } else {
            this.errorMessage = "The username or password entered is invalid.";
          }
          this.showError = true;
        }
        
        this.authenticating = false;

      } else if (this.$v.server.$error) {
        this.showOptions = true;
      }
      
    },
    cleanServerString(server) {
      let s = server.trim();
      if (!s.startsWith('https://') && !s.startsWith('http://')) {
        s = isElectron() ? 'http://' + s : 'https://' + s;
      }

      if (s.endsWith('/')) {
        s = s.slice(0, -1);
      }
      return s;
    }
  },
  mixins: [validationState]
}
</script>

<style scoped>
.login-form {
  margin: auto;
  margin-top: 40px;
  width: 400px;
}

.options-link {
  color: rgb(42, 110, 140);
  float: right;
  font-size: 0.9rem;
}

.login-page-link {
  color: rgb(42, 110, 140);
  font-size: 0.9rem;
}

.invalid-feedback {
  color: #d92638;
}
</style>
