<template>
  <div class="upload-progress">
    <b-progress height="20px">
      <b-progress-bar
        :value="progress"
        :label="label"
      />
    </b-progress>
    <icon-button
      icon="times-circle" 
      size="sm"
      title="Cancel Upload"
      icon-class="cancel-upload"
      class="ml-1 mr-0"
      @click="$emit('cancel-upload')"
    />
  </div>
</template>

<script>
import IconButton from './IconButton.vue'

export default {
  computed: {
    label () {
      return this.progress == 100 ? 'Importing...' : `Uploading (${this.progress.toFixed(1)}%)`;
    }
  },
  components: {
    IconButton
  },
  props: {
    progress: { type: Number, required: true }
  }
}
</script>

<style>
.upload-progress {
  display: flex;
  align-items: center;
}
.upload-progress .progress { 
  margin-top: 2px;
  background-color: #cecece;
  width: calc(100% - 36px);
}
.upload-progress .progress-bar > span {
  padding-left: 3px;
}
.upload-progress .cancel-upload {
  color: #7a0800;
}
.upload-progress .cancel-upload:hover {
  color: #8f0900;
}
</style>