<template>
  <modal-dialog 
    title="Add Forecast" 
    :confirm-button-text="confirmButtonText" 
    :value="value" 
    :action="addForecast"
    :hide-errors="true"
    @input="$emit('input', $event)" 
    @clear="clearDialog"
    :dialog-size="dialogSize"
    :ok-disabled="!!message"
  >
    <b-form-group>
      <b-form-radio-group
        v-model="addForecastType"
        :options="addForecastTypeOptions"
        @input="clearErrorMessages"
      />
    </b-form-group>

    <b-alert
      class="mt-3"
      variant="danger"
      :show="!!message"
    >
      {{ message }}
    </b-alert>

    <b-form-group
      label="Scenario Name"
      label-for="scenario-name"
      v-if="addForecastType == 'template' && isAutoAnalysis"
    >
      <b-form-input
        id="scenario-name"
        v-model.trim="scenarioName"
        autocomplete="off"
        :state="state($v.scenarioName)"
        autofocus
      />
      <b-form-invalid-feedback
        :state="state($v.scenarioName)"
      >
        {{ validationMessage($v.scenarioName) }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Scenario Name"
      label-for="scenario-name"
      v-else-if="!isAutoAnalysis"
    >
      <search-autocomplete
        :items="scenarioNameOptions"
        v-model="scenarioName"
        :state="state($v.scenarioName)"
      />
      <b-form-invalid-feedback
        :state="state($v.scenarioName)"
      >
        {{ validationMessage($v.scenarioName) }}
      </b-form-invalid-feedback>
      <b-form-text>
        To create a new scenario, enter a new scenario name.
      </b-form-text>
    </b-form-group>

    <b-form-group
      label="Forecast Type"
      label-for="forecast-type"
      v-if="!isAutoAnalysis"
    >
      <b-form-select
        id="forecast-type"
        class="field-md"
        :options="getForecastTypeOptions(selectedScenario)"
        :disabled="forecastTypeOptions.length == 0"
        v-model="forecastType"
        :state="state($v.forecastType)"
      />
      <b-form-invalid-feedback
        :state="state($v.forecastType)"
      >
        {{ validationMessage($v.forecastType) }}
      </b-form-invalid-feedback>
    </b-form-group>
      
    <b-form-group
      label="Forecast Template"
      label-for="forecast-template-select"
      v-if="addForecastType == 'template'"
    >
      <b-form-select
        id="forecast-template-select"
        v-model="selectedForecastTemplate"
        :options="forecastTemplateOptions"
      />
    </b-form-group>

    <b-form-group>
      <b-form-radio-group
        v-if="addForecastType == 'template' && selectedForecastTemplate == 'Flat' && forecastType != 'NationalHpi'"
        v-model="flatForecastTemplate"
        :options="flatForecastTemplateOptions"
        @input="clearErrorMessages"
      />
    </b-form-group>

    <b-form-group
      label="Forecast Value"
      label-for="forecast-value"
      v-if="addForecastType == 'template' && selectedForecastTemplate == 'Flat' && flatForecastTemplate == 'custom'"
    >
      <b-form-input
        id="forecast-value"
        v-model.trim="flatForecastValue"
        autocomplete="off"
        :state="state($v.flatForecastValue)"
        cols="6"
      />
      <b-form-invalid-feedback
        :state="state($v.flatForecastValue)"
      >
        {{ validationMessage($v.flatForecastValue) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="AD&amp;Co Forecast Month/Year"
      label-for="default-forecast-date-select"
      v-if="addForecastType == 'template' && selectedForecastTemplate=='AD&Co Default'"
    >
      <b-form-select
        id="default-forecast-date-select"
        v-model="selectedDefaultForecastDate"
        :options="modelDataDates"
        @input="clearErrorMessages"
      />
    </b-form-group>

    <b-form-group
      v-if="addForecastType == 'import'"
    >
      <b-form-file
        id="forecast-file"
        v-model="forecastFile"
        class="mt-3"
        placeholder="Choose a forecast file or drop it here..."
        drop-placeholder="Drop forecast file here..."
        :state="state($v.forecastFile)"
      />
      <b-form-invalid-feedback :state="state($v.forecastFile)">
        {{ validationMessage($v.forecastFile) }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      v-if="addForecastType == 'import'"
    >
      <b-button
        @click="showFileLayout = !showFileLayout;"
      >
        {{ fileLayoutText }}
      </b-button>
      <file-layout
        v-if="showFileLayout"
        :file="file"
      />
    </b-form-group>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import FileLayout from './FileLayout.vue'
import SearchAutocomplete from './SearchAutocomplete.vue'
import validationState from '../js/mixins/validationState'
import getErrorMessage from '../js/getErrorMessage'
import api from '../api'
import { required, minLength, decimal } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'
import { runTypes } from '../js/options/runType'
import moment from 'moment';

export default {
  props:{
    value: { type: Boolean },
    forecastGroup: { type: String, required: true },
    forecastTypes: { type: Array, required: true },
    file: { type: Object }
  },
  data () {
    return {
      scenarioName: '',
      forecastType: '',
      selectedForecastTemplate: 'Flat',
      forecastTemplateOptions: [
        { text: 'Flat', value: 'Flat', disabled: false },
        { text: 'AD&Co Default', value: 'AD&Co Default', disabled: false }
      ],
      addForecastType: 'template',
      addForecastTypeOptions: [
        { text: 'Add New Forecast', value: 'template', disabled: false },
        { text: 'Import Forecasts', value: 'import', disabled: false }
      ],
      flatForecastTemplateOptions: [
        { text: 'Use Latest Historical Value', value: 'historical', disabled: false },
        { text: 'Use Custom Value', value: 'custom', disabled: false }
      ],
      flatForecastTemplate: 'historical',
      flatForecastValue: null,
      modelDataDates: [],
      selectedDefaultForecastDate: '',
      forecastFile: null,
      message: null,
      showFileLayout: false,
      fileLayout: []
    }
  },
  async mounted() {
    let dates = await api.getModelDataDates(this.activeAnalysis.id);
    this.modelDataDates = dates.map(r => ({ value: r.dateKey, text: `${r.month < 10 ? '0' + r.month : r.month}/${r.year}` }));
    this.selectedDefaultForecastDate = this.modelDataDates[0].value;
  },
  computed: {
    ...mapState(['activeAnalysis', 'scenarios']),
    dialogSize () {
      if (this.addForecastType == 'import' && this.showFileLayout)
        return 'lg'
      return 'md'
    },
    confirmButtonText() {
      if (this.addForecastType == 'import')
        return 'Import'
      return 'Add'
    },
    fileLayoutText () {
      if (this.showFileLayout)
        return 'Hide File Layout'
      return 'Show File Layout'
    },
    isAutoAnalysis() {
      return this.activeAnalysis.runType == runTypes.loanDynamics && this.activeAnalysis.assetType === 'AutoLoans';
    },
    createNewScenario () {
      return this.isAutoAnalysis || (this.scenarioName && this.scenarioName.length > 0 && this.selectedScenario == null);
    },
    scenarioNameOptions () {
      return this.scenarios.filter(s => s.scenarioId != null).filter(s => this.getForecastTypeOptions(s).length > 0).map(s => s.scenarioName);
    },
    selectedScenario() {
      return this.scenarios.filter(s => s.scenarioName == this.scenarioName).find(s => s.scenarioName == this.scenarioName);
    },
    baseScenarioId () {
      if (this.selectedScenario == null) return null;
      return this.selectedScenario.scenarioId;
    },
    forecastTypeOptions () {
      if (this.selectedScenario == null || this.isAutoAnalysis) return this.forecastTypes.map(t => { return {value: t.name, text: t.header}});
      return this.forecastTypes.filter(f => !this.selectedScenario.forecastTypes.includes(f.name)).map(t => {
        return {value: t.name, text: t.header}
      });
    }
  },
  watch: {
    addForecastType: function (newVal) {
      if (newVal == 'template') {
        this.dialogSize = 'md';
      } else if (newVal == 'import' && this.showFileLayout) {
        this.dialogSize = 'lg';
      }
    },
    scenarioName: function () {
      this.message = null;
    }
  },
  validations() {
    if (this.addForecastType == 'template' && this.selectedForecastTemplate == 'Flat' && this.flatForecastTemplate == 'custom') {
      return {
        scenarioName: { required, minLength: minLength(1) },
        forecastType: { required },
        flatForecastValue: { required, decimal }
      }
    } else if (this.addForecastType == 'template') {
      return {
        scenarioName: { required, minLength: minLength(1) },
        forecastType: { required }
      }
    } else if (this.addForecastType == 'import' && this.isAutoAnalysis) {
      return {
        forecastFile: { required }
      }
    } else if (this.addForecastType == 'import') {
      return {
        scenarioName: { required, minLength: minLength(1) },
        forecastType: { required },
        forecastFile: { required }
      }
    }
  },
  methods: {
    ...mapMutations(['setSavePending', 'setSaving', 'doneSaving', 'errorSaving']),
    getForecastTypeOptions (scenario) {
      if (scenario == null) return this.forecastTypes.map(t => { return {value: t.name, text: t.header}});
      return this.forecastTypes.filter(f => !scenario.forecastTypes.includes(f.name)).map(t => {
        return {value: t.name, text: t.header}
      });
    },
    async addForecast () {

      // Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.setSavePending();
      try {
        let forecast = {};
        if (this.addForecastType == 'template') {
          this.setSaving();
          if (this.selectedForecastTemplate == 'Flat') {
            if (this.flatForecastTemplate == 'custom') {
              forecast = {
                startDate: moment(this.activeAnalysis.settings.firstForecastDate).startOf('month').format(),
                type: this.forecastType,
                values: Array(this.isAutoAnalysis ? 120 : 360).fill(parseFloat(this.flatForecastValue))
              };
            } else {
              forecast = await api.getFlatForecast(this.activeAnalysis.id, this.forecastType);
            }
          } else {
            let options = {
              params: {
                month: this.selectedDefaultForecastDate.slice(4),
                year: this.selectedDefaultForecastDate.slice(0,4)
              }
            }
            forecast = await api.getDefaultForecast(this.activeAnalysis.id, this.forecastType, options);
          }
          if (this.createNewScenario) {
            await api.createScenario(this.activeAnalysis.id, {
            scenarioName: this.scenarioName,
            enabled: true,
            forecasts: forecast.values.length > 0 ? [forecast] : []
          });
          } else {
            await api.addScenarioForecast(this.activeAnalysis.id, this.baseScenarioId, this.forecastType, {
              startDate: forecast.startDate,
              values: forecast.values.length > 0 ? [...forecast.values] : []
            });
          }
          
          this.$emit('scenarios-updated',{newForecastAdded:true});
        } else if (this.addForecastType == 'import') {
          this.setSaving();
          if (this.isAutoAnalysis) {
            await api.postFile(`analyses/${this.activeAnalysis.id}/scenarios/import`, this.forecastFile, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          });
          } else {
            let scenarioId = this.baseScenarioId;
            if (this.createNewScenario) {
              let resp = await api.createScenario(this.activeAnalysis.id, {
                scenarioName: this.scenarioName,
                enabled: true
              });
              scenarioId = resp.scenarioId;
            }
            await api.postFile(`analyses/${this.activeAnalysis.id}/scenarios/${scenarioId}/${this.forecastType}/import`, this.forecastFile, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          }
          this.$emit('scenarios-updated');
        }
        this.doneSaving();
      } catch (err) {
        this.errorSaving();
        this.message = getErrorMessage(err, 'Error importing forecast');
        this.$v.reset();
      }
    },
    clearDialog () {
      this.addForecastType = 'template';
      this.scenarioName = null;
      this.selectedForecastTemplate = 'Flat';
      this.selectedDefaultForecastDate = this.modelDataDates[0].value;
      this.forecastFile = null;
      this.message = null;
      this.showFileLayout = false;
      this.forecastType = this.isAutoAnalysis ? this.forecastTypes[0].name : this.forecastTypeOptions.length > 0 ? this.forecastTypeOptions[0].value : null;
      this.flatForecastTemplate = 'historical';
      this.flatForecastValue = null;

      this.$v.$reset();
    },
    clearErrorMessages () {
      this.message = null;
    }  
  },
  watch: {
    baseScenarioId(newValue) {
      if (newValue) {
        if (this.forecastTypeOptions.find(f => f.value == this.forecastType) === undefined)
          this.forecastType = this.forecastTypeOptions.length > 0 ? this.forecastTypeOptions[0].value : null;
      }
    },
    forecastType (newValue) {
      let supportedDefaults = [
        'Swap2Yr',
        'Swap10Yr',
        'NationalHpi',
        'NationalUnemployment'
      ]
      if (supportedDefaults.find(v => v === newValue) === undefined && !this.isAutoAnalysis) {
        this.selectedForecastTemplate = 'Flat';
        this.forecastTemplateOptions[1].disabled = true;
        this.flatForecastTemplate = 'custom';
        this.flatForecastTemplateOptions[0].disabled = true;
      } else {
        this.forecastTemplateOptions[1].disabled = false;
        this.flatForecastTemplateOptions[0].disabled = false;
      }
    },
    scenarioName() {
      this.clearErrorMessages();
    },
    selectedForecastTemplate() {
      this.clearErrorMessages();
    },
    forecastFile() {
      this.clearErrorMessages();
    }
  },
  components: {
    ModalDialog,
    FileLayout,
    SearchAutocomplete
  },
  mixins: [validationState]
}
</script>