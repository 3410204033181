import { DataType } from '@grapecity/wijmo'
import { decimal, between } from 'vuelidate/lib/validators'

export default {
  title: 'Current Coupon Yields',
  entitySet: 'CurrentCouponYields',
  columns: [
    { binding: 'index', header: 'Index', isRequired: true, isReadOnly: true, dataType: DataType.Number, format: 'g15', desc: 'Valid Index values: FN30, FN15, FN07, FG30, FG15, FG07, FG05, GN30, GN15, LIB6MC, LIB1YC, COFINA, COFI, PRIME', type: 'string' },
    { binding: 'rate', header: 'Rate', isRequired: true, dataType: DataType.Number, format: 'g15',  validations: { decimal, between: between(-100, 100) }, desc: 'Rate for the specified index', type: 'double' }
  ],
  editOptions: {
    keys: ['id'],
    allowAddNew: false,
    allowDelete: false,
    allowSave: true
  }
}
