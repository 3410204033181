<template>
  <modal-dialog 
    title="Add Custom Points" 
    :confirm-button-text="confirmButtonText" 
    :value="value" 
    :action="addPoints"
    :hide-errors="true"
    @input="$emit('input', $event)" 
    @clear="clearDialog"
    :dialog-size="dialogSize"
    :ok-disabled="!!message"
  >
    <b-form-group>
      <b-form-radio-group
        v-model="addPointsType"
        :options="addPointsTypeOptions"
        @input="clearErrorMessages"
      />
    </b-form-group>

    <b-alert
      class="mt-3"
      variant="danger"
      :show="!!message"
    >
      {{ message }}
    </b-alert>

    <b-form-group
      label="Custom Points Name"
      label-for="points-name"
      v-if="addPointsType == 'template'"
    >
      <b-form-input
        id="points-name"
        v-model.trim="pointsName"
        autocomplete="off"
        :state="state($v.pointsName)"
        autofocus
      />
      <b-form-invalid-feedback
        :state="state($v.pointsName)"
      >
        {{ validationMessage($v.pointsName) }}
      </b-form-invalid-feedback>
    </b-form-group>
      
    <b-form-group
      label-for="points-template-select"
      v-if="addPointsType == 'template'"
    >
      <template #label>
        Points Template
        <span>
          <font-awesome-icon
            icon="question-circle"
            id="template-tooltip"
          />
          <b-popover
            target="template-tooltip"
            triggers="hover focus"
          >
            <div
              v-for="p in pointsTemplateDescriptions"
              :key="p.label"
              class="points-template-desc"
            >
              <strong>{{ p.label }}</strong>
              <p>{{ p.description }}</p>
            </div> 
          </b-popover>
        </span>
      </template>
      <b-form-select
        id="points-template-select"
        v-model="selectedPointsTemplate"
        :options="pointsTemplateOptions"
      />
    </b-form-group>

    <b-form-group
      v-if="addPointsType == 'template' && selectedPointsTemplate == 'custom'"
    >
      <template #label>
        Offset
        <span     
          v-b-tooltip.hover.right
          title="Number of months before prepayment penalty begins"
        ><font-awesome-icon icon="question-circle" /></span>
      </template>
      <b-form-input
        id="custom-offset"
        v-model.trim="customOffset"
        autocomplete="off"
        :state="state($v.customOffset)"
        cols="6"
      />
      <b-form-invalid-feedback
        :state="state($v.customOffset)"
      >
        {{ validationMessage($v.customOffset) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-if="addPointsType == 'template' && selectedPointsTemplate == 'custom'"
    >
      <template #label>
        Initial Points Value (%)
        <span     
          v-b-tooltip.hover.right
          title="Starting prepayment penalty amount"
        ><font-awesome-icon icon="question-circle" /></span>
      </template>
      <b-form-input
        id="custom-initial-points"
        v-model.trim="customInitialPoints"
        autocomplete="off"
        :state="state($v.customInitialPoints)"
        cols="6"
      />
      <b-form-invalid-feedback
        :state="state($v.customInitialPoints)"
      >
        {{ validationMessage($v.customInitialPoints) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-if="addPointsType == 'template' && selectedPointsTemplate == 'custom'"
    >
      <template #label>
        Points Duration (months)
        <span     
          v-b-tooltip.hover.right
          title="Number of months a prepayment penalty amount is in place before decreasing"
        ><font-awesome-icon icon="question-circle" /></span>
      </template>
      <b-form-input
        id="custom-points-duration"
        v-model.trim="customPointsDuration"
        autocomplete="off"
        :state="state($v.customPointsDuration)"
        cols="6"
      />
      <b-form-invalid-feedback
        :state="state($v.customPointsDuration)"
      >
        {{ validationMessage($v.customPointsDuration) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-if="addPointsType == 'template' && selectedPointsTemplate == 'custom'"
    >
      <template #label>
        Step-Down Amount (%)
        <span     
          v-b-tooltip.hover.right
          title="Amount the prepayment penalty points decline"
        ><font-awesome-icon icon="question-circle" /></span>
      </template>
      <b-form-input
        id="custom-step-down"
        v-model.trim="customStepDownAmount"
        autocomplete="off"
        :state="state($v.customStepDownAmount)"
        cols="6"
      />
      <b-form-invalid-feedback
        :state="state($v.customStepDownAmount)"
      >
        {{ validationMessage($v.customStepDownAmount) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      v-if="addPointsType == 'import'"
    >
      <b-form-file
        id="points-file"
        v-model="pointsFile"
        class="mt-3"
        placeholder="Choose a points file or drop it here..."
        drop-placeholder="Drop points file here..."
        :state="state($v.pointsFile)"
      />
      <b-form-invalid-feedback :state="state($v.pointsFile)">
        {{ validationMessage($v.pointsFile) }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      v-if="addPointsType == 'import'"
    >
      <b-button
        @click="showFileLayout = !showFileLayout"
      >
        {{ fileLayoutText }}
      </b-button>
      <file-layout
        v-if="showFileLayout"
        :file="file"
      />
    </b-form-group>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import FileLayout from './FileLayout.vue'
import validationState from '../js/mixins/validationState'
import getErrorMessage from '../js/getErrorMessage'
import api from '../api'
import { required, minLength, integer, decimal, between, minValue } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'

export default {
  props:{
    value: { type: Boolean },
    file: { type: Object }
  },
  data () {
    return {
      addPointsType: 'template',
      pointsName: '',
      selectedPointsTemplate: 'adcoDefault',
      customOffset: 0,
      customInitialPoints: 5,
      customPointsDuration: 12,
      customStepDownAmount: 1,
      pointsFile: null,
      showFileLayout: false,
      pointsTemplateOptions: [
        { text: 'AD&Co Default', value: 'adcoDefault' },
        { text: '5-4-3-2-1', value: '54321' },
        { text: 'Custom Step-Down', value: 'custom'}
      ],
      addPointsTypeOptions: [
        { text: 'Add Custom Points', value: 'template' },
        { text: 'Import Custom Points', value: 'import' }
      ],
      pointsTemplateDescriptions: [
        {
          label: "AD&Co Default",
          description: "10-year penalty, starting at 10% and declining 1% annually"
        },
        {
          label: "5-4-3-2-1",
          description: "5-year penalty, starting at 5% and declining 1% annually"
        },
        {
          label: "Custom Step-Down",
          description: "Specify an initial prepayment penalty and how it decreases over time"
        },
      ],
      fileLayout: [],
      message: null
    }
  },
  computed: {
    ...mapState(['activeAnalysis']),
    dialogSize () {
      if (this.addPointsType == 'import' && this.showFileLayout)
        return 'lg'
      return 'md'
    },
    confirmButtonText() {
      if (this.addPointsType == 'import')
        return 'Import'
      return 'Add'
    },
    fileLayoutText () {
      if (this.showFileLayout)
        return 'Hide File Layout'
      return 'Show File Layout'
    }
  },
  watch: {
    pointsName: function () {
      this.message = null;
    }
  },
  validations() {
    if (this.addPointsType == 'template' && this.selectedPointsTemplate == 'custom') {
      return {
        pointsName: { required, minLength: minLength(1) },
        customOffset: { required, integer, between: between(0, 360)},
        customInitialPoints: { required, decimal, minValue: minValue(0) },
        customPointsDuration: { required, integer, minValue: minValue(1) },
        customStepDownAmount: { required, decimal, minValue: minValue(0) }
      }
    } else if (this.addPointsType == 'template') {
      return {
        pointsName: { required, minLength: minLength(1) },
      }
    } else if (this.addPointsType == 'import') {
      return {
        pointsFile: { required }
      }
    }
  },
  methods: {
    ...mapMutations(['setSavePending', 'setSaving', 'doneSaving', 'errorSaving', 'setPortfolioGridDirty']),
    async addPoints () {
      // Validate
      this.$v.$touch();
      if (this.$v.$invalid) return false;

      this.setSavePending();
      try {
        let penaltyPointSet = {
          penaltyPointSetName: this.pointsName,
          points: Array(360).fill(0)
        };

        if (this.addPointsType == 'template') {
          this.setSaving();

          switch (this.selectedPointsTemplate) {
            case '54321':
              for (let i = 5; i > 0; i--) penaltyPointSet.points.fill(i, 12*(5-i), 12*(5-i+1));
              break;
            case 'custom':
              let point = this.customInitialPoints;
              let currentDuration = 0;
              for (let i = 0; i < 360; i++) {
                if (i < this.customOffset) continue;
                penaltyPointSet.points[i] = point;
                currentDuration++;
                if (currentDuration == this.customPointsDuration) {
                  currentDuration = 0;
                  point = Math.max(point - this.customStepDownAmount, 0);
                }
              }
              break;
            default:
              penaltyPointSet.points = (await api.getPenaltyPointSetDefault(this.activeAnalysis.id)).points;
          }

          await api.createPenaltyPointSet(this.activeAnalysis.id, penaltyPointSet);

        } else if (this.addPointsType == 'import') {
          this.setSaving();

          await api.postFile(`analyses/${this.activeAnalysis.id}/penalty-points/import`, this.pointsFile, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        }

        // Reload portfolio grid on reactivation to include new points in Custom Points dropdown
        this.setPortfolioGridDirty(true);

        // Update points grid
        this.$emit('points-updated', { newPointsAdded: true });

        this.doneSaving();
      } catch (err) {
        this.errorSaving();
        this.message = getErrorMessage(err, 'Error importing points');
        this.$v.reset();
      }
    },
    clearDialog () {
      this.addPointsType = 'template';
      this.pointsName = null;
      this.selectedPointsTemplate = 'adcoDefault';
      this.customOffset = 0;
      this.customInitialPoints = 5;
      this.customPointsDuration = 12;
      this.customStepDownAmount = 1;
      this.pointsFile = null;
      this.showFileLayout = false;
      this.message = null;
      this.$v.$reset();
    },
    clearErrorMessages () {
      this.message = null;
    }  
  },
  watch: {
    pointsName() {
      this.clearErrorMessages();
    },
    selectedPointsTemplate() {
      this.clearErrorMessages();
    },
    pointsFile() {
      this.clearErrorMessages();
    }
  },
  components: {
    ModalDialog,
    FileLayout
  },
  mixins: [validationState]
}
</script>
<style scoped>
.points-template-desc:last-child p {
  margin-bottom: 0;
}
</style>