<template>
  <div>
    <b-container
      class="ml-0 calculations"
    >
      <b-row>
        <b-col md>
          <calculations-rvd-analysis-settings
            v-bind.sync="settings"
            :paths="settings.monteCarlo.paths"
            @update-paths="settings.monteCarlo.paths = $event"
            :v="$v.settings"
          />

          <calculations-rvd-assumptions 
            v-bind.sync="settings" 
            :quote="settings.quote"
            :quote-type="settings.quoteType"
            :trade-date="settings.tradeDate"
            :settle-date="settings.settleDate"
            @update-assumptions="updateObject(settings, $event)"
            :title="isMsrAnalysis ? 'MSR Valuation Inputs' : 'Valuation Inputs'" 
          />

          <calculations-rvd-assumptions 
            v-if="settings.useHedgeFile" 
            :quote="settings.hedgeQuote"
            :quote-type="settings.hedgeQuoteType"
            :trade-date="settings.tradeDate"
            :settle-date="settings.hedgeSettleDate"
            :is-hedge-assumptions="true"
            @update-assumptions="updateHedgeSettings($event)"
            title="Hedge Valuation Inputs"
          />

          <calculations-rvd-advanced-settings
            v-bind.sync="settings"
            :v="$v.settings"
          />
        </b-col>
        <b-col md>
          <calculations-rvd-value-and-risk
            v-bind.sync="settings"
            @update-monte-carlo="updateObject(settings.monteCarlo, $event)"
            @update-field-config="updateObject(settings.fieldConfigMetaData, $event)"
            :v="$v.settings"
          />

          <calculations-rvd-scenario-analysis
            v-bind.sync="settings"
            @update-field-config="updateObject(settings.fieldConfigMetaData, $event)"
          />

          <calculations-rvd-cashflows
            v-bind.sync="settings.cashFlows"
            :enable-b5="settings.b5.enableB5"
            :enable-stress-tests="settings.enableStressTests"
            :enable-scenario-grid="settings.enableScenarioGrid"
            :enable-monte-carlo="settings.monteCarlo.enableMonteCarlo"
            :disabled="!settings.cashFlows.enableCashFlows"
          />

          <calculations-rvd-tunings
            v-bind.sync="settings.tunings"
            @update-tunings="updateObject(settings.tunings.customTunings, $event)"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CalculationsRvdAnalysisSettings from './CalculationsRvdAnalysisSettings.vue'
import CalculationsRvdAssumptions from './CalculationsRvdAssumptions.vue'
import CalculationsRvdAdvancedSettings from './CalculationsRvdAdvancedSettings.vue'
import CalculationsRvdValueAndRisk from './CalculationsRvdValueAndRisk.vue'
import CalculationsRvdCashflows from './CalculationsRvdCashflows.vue'
import CalculationsRvdScenarioAnalysis from './CalculationsRvdScenarioAnalysis.vue'
import CalculationsRvdTunings from './CalculationsRvdTunings.vue'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import { validations } from '../js/validation/calcsValidations'
import store from '../store'

export default {
  data() {
    return {
      settings: store.state.activeAnalysis.settings
    };
  },
  async mounted () {
    try {
      this.$emit('loading', true);
      await this.getInputsList();
      this.$nextTick(() => { this.$emit('loading', false); });
    } catch (err) {
      this.$emit('loading', false);
      this.$emit('error', 'Error loading calculations tab');
      // eslint-disable-next-line
      console.error(err);
    }
  },
  validations,
  computed: {
    ...mapState(['activeAnalysis']),
    ...mapGetters(['isMsrAnalysis']),
  },
  methods: {
    ...mapMutations(['setAnalysisModified']),
    ...mapActions(['autoSave', 'getInputsList']),

    updateObject (obj, data) {
      for (let [key, value] of Object.entries(data)) {
        //console.log(key, value);
        obj[key] = value;
      }
    },
    updateHedgeSettings (data) {
      if (data.quote) {
        this.settings.hedgeQuote = data.quote;
      }
      
      if (data.quoteType) {
        this.settings.hedgeQuoteType = data.quoteType;
      }

      if (data.settleDate) {
        this.settings.hedgeSettleDate = data.settleDate;
      }
    }
  },
  watch: {
    settings: {
      handler (settings) {
        if (!this.loading) {
          // console.log(this.$v.settings.$invalid ? "Validation Error!" : "Saving!");
          // console.log(this.$v.settings)
          this.setAnalysisModified();
          this.autoSave({ saveAction: 'saveAnalysisSettings', isValid: !this.$v.settings.$invalid, id: this.activeAnalysis.id, data: settings });
        }
      },
      deep: true
    }
  },
  components: {
    CalculationsRvdAnalysisSettings,
    CalculationsRvdAssumptions,
    CalculationsRvdAdvancedSettings,
    CalculationsRvdValueAndRisk,
    CalculationsRvdCashflows,
    CalculationsRvdScenarioAnalysis,
    CalculationsRvdTunings
  }
};
</script>

<style>
@media (max-width: 992px) {
  .calculations.container {
    max-width: 100%;
  }
}
</style>
