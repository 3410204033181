/**
 * Work around to send correct odata filter values for columns with data maps
 * Support case #: 440490
 */
export default function (data, options) {
  var fieldName = data.column.binding;
  var filterValue = this._asODataValue(options.value, data.column.dataType);
  var hasDataMap = !!data.column.dataMap;
  var datamapVal;

  // Added this to correct datamap issue
  if (hasDataMap) {
    datamapVal = data.column.dataMap.getKeyValue(options.value);
    filterValue = this._asODataValue(datamapVal, data.column.dataType);
  }

  switch(options.operator) {
    case 0:
      return this._asEquals(fieldName, hasDataMap ? datamapVal : options.value, data.column.dataType);
    case 1:
      return fieldName + " ne " + filterValue;
    case 2:
      return fieldName + " gt " + filterValue;
    case 3:
      return fieldName + " ge " + filterValue;
    case 4:
      return fieldName + " lt " + filterValue;
    case 5:
      return fieldName + " le " + filterValue;
    case 6:
      return "startswith(" + fieldName + "," + filterValue + ")";
    case 7:
      return "endswith(" + fieldName + "," + filterValue + ")";
    case 8:
      return this._odv >= 4 ? "contains(" + fieldName + "," + filterValue + ")" : "substringof(" + filterValue.toLowerCase() + ", tolower(" + fieldName + "))";
    case 9:
      return this._odv >= 4 ? "not contains(" + fieldName + "," + filterValue + ")" : "not substringof(" + filterValue.toLowerCase() + ", tolower(" + fieldName + "))";
  }
}