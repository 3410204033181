<template>
  <collapsible-section
    v-model="advSettingsOpen"
    section-title="Advanced Settings"
    chevron
  >
    <b-form-group
      label="AD&amp;Co Data Files Month/Year"
      label-for="model-data-date"
      title="Month and year of AD&amp;Co Data files to use for this analysis"
      :label-cols="6"
    >
      <b-form-select
        id="model-data-date"
        :value="modelDataDate"
        @input="$emit('update:modelDataDate', $event)"
        class="field-sm"
        :options="modelDataDates"
        size="sm"
        @change="v.$touch()"
      />
    </b-form-group>

    <b-form-group
      v-if="activeAnalysis.assetType != 'AutoLoans' && activeAnalysis.assetType != 'MultiFamilyLoans' && hasDefaultDefinitionOptions"
      label="Default Definition"
      label-for="default-definition"
      title="Set termination condition for delinquent loans"
      :label-cols="6"
    >
      <b-select
        id="default-definition"
        :value="defaultDefinition"
        @input="$emit('update:defaultDefinition', $event)"
        :options="defaultDefinitionOptions"
        size="sm"
      />
    </b-form-group>

    <!-- <b-form-group
      v-if="calcs.useHedgeFile"
      label="Hedge Summary Type"
      label-for="hedge-averaging-type"
    >
      <span
        v-b-tooltip.hover="{ customClass: 'tooltip-wide' }"
        v-for="opt in hedgeAveragingOptions"
        :key="opt.value"
        :title="opt.title"
      >
        <b-form-radio
          v-model="calcs.hedgeAveragingType"
          :value="opt.value"
          inline
        >
          {{ opt.text }}
        </b-form-radio>
      </span>
    </b-form-group> -->

    <!-- <b-form-group
      label="Number of Parallel Positions"
      label-for="parallel-positions"
      title="Number of positions to process in parallel"
      :label-cols="6"
    >
      <b-form-input
        id="parallel-positions"
        v-model="v.calcs.numhandles.$model"
        type="text"
        class="field-sm"
        size="sm"
        :state="state(v.calcs.numhandles)"
      />
      <b-form-invalid-feedback :state="state(v.calcs.numhandles)">
        {{ validationMessage(v.calcs.numhandles) }}
      </b-form-invalid-feedback>
    </b-form-group> -->

    <b-form-group
      label="Custom Command"
      label-for="custom-command"
      title="Additional commands to send to the model"
    >
      <b-form-input
        id="custom-command"
        :value="customCommand"
        @input="$emit('update:customCommand', $event)"
        type="text"
        size="sm"
        autocomplete="off"
      />
    </b-form-group>

    <b-form-checkbox
      :checked="useCommandsFile"
      @change="enableCommandsFile"
    >
      Use commands file
    </b-form-checkbox>

    <div
      v-if="useCommandsFile"
      class="pl-4"
    >
      <file-select :file-name="fileNames.customCommands" />
    </div>

    <div class="pt-3">
      <div>
        <b-form-checkbox 
          v-b-tooltip.right.hover
          :checked="outputXmlInputs"
          @change="$emit('update:outputXmlInputs', $event)"
          title="Enable XML input logging for LDM. The file ADCO_ModelInputs.txt will be written to the analysis ppmodel folder. WARNING: These files can be large. It is recommended to limit the number of positions run with this option enabled."
          inline
        >
          Output XML Inputs
        </b-form-checkbox>
      </div>
      <div>
        <b-form-checkbox 
          v-b-tooltip.right.hover
          :checked="outputXmlResults"
          @change="$emit('update:outputXmlResults', $event)"
          title="Enable XML results logging for LDM. The file ADCO_ModelResults.txt will be written to the analysis ppmodel folder. WARNING: These files can be large. It is recommended to limit the number of positions run with this option enabled."
          inline
        >
          Output XML Results
        </b-form-checkbox>
      </div>
    </div>
  </collapsible-section>
</template>

<script>
import FileSelect from './FileSelect.vue'
import CollapsibleSection from './CollapsibleSection.vue'
import { fileNames, fileStatus } from '../js/fileInfo'
import validationState from '../js/mixins/validationState'
import { mapState, mapMutations, mapGetters } from 'vuex'
import api from '../api'
import { hedgeAveragingOptions } from '../js/options/hedgeAveraging'
import { defaultDefinitionOptions } from '../js/options/defaultDefinition'
import { smmMdrDefinitionOptions } from '../js/options/smmMdrDefinition'
import { delinquencyCalculationMethodOptions } from '../js/options/delinquencyCalculationMethod'
import { curveTypeOptions } from '../js/options/curveType'

export default {
  props: {
    modelDataDate: { type: String, required: true },
    defaultDefinition: { type: String, required: true },
    customCommand: { type: String, default: '' },
    useCommandsFile: { type: Boolean, required: true },
    outputXmlInputs: { type: Boolean, required: true },
    outputXmlResults: { type: Boolean, required: true },
    v: { type: Object, required: true }
  },
  data () {
    return {
      advSettingsOpen: false,
      fileNames,
      modelDataDates: [],
      hedgeAveragingOptions,
      defaultDefinitionOptions,
      smmMdrDefinitionOptions,
      delinquencyCalculationMethodOptions,
      curveTypeOptions
    }
  },
  async mounted () {
    let dates = await api.getModelDataDates(this.activeAnalysis.id);
    this.modelDataDates = dates.map(r => ({ value: r.dateKey, text: `${r.month < 10 ? '0' + r.month : r.month}/${r.year}` }));
  },
  computed: {
    ...mapState(['isValidating', 'fileList', 'activeAnalysis', 'licenseInfo']),
    ...mapGetters(['isLoanDynamicsAnalysis', 'hasDefaultDefinitionOptions'])
  },
  methods: {
    ...mapMutations(["toggleFile", "addValidationStatus"]),

    enableCommandsFile(enabled) {
      this.toggleFile({ fileName: fileNames.customCommands, enabled });
      this.$emit('update:useCommandsFile', enabled);
    },
  },
  watch: {
    isValidating (val) {
      if (val === true) {
        this.v.$touch();

        // Open adv settings if invalid
        if (this.v.$invalid || 
          (this.useCommandsFile && this.fileList[fileNames.customCommands].status == fileStatus.missing )) {
         this.advSettingsOpen = true;
        }
        
        this.addValidationStatus({
          tab: 'Calculations',
          item: 'advanced-settings-panel',
          isValid: !this.v.$invalid
        });
      }
    }
  },
  components: {
    FileSelect,
    CollapsibleSection
  },
  mixins: [validationState]
}
</script>

<style scoped>
section > h2.collapsed {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
  cursor: pointer;
}

section > h2.collapsible {
  cursor: pointer;
}
</style>
