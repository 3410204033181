import axios from 'axios'
import router from './router'
import store from './store'
import tokenStore from './js/tokenStore'

let baseURL = `${store.state.authentication.server}/api/`;

const httpClient = axios.create({
  baseURL
});

// Redirect to login page on 401 error
httpClient.interceptors.response.use(config => {

  // Pause any additional requests until refresh completes
  if (refreshRequest) {
    return refreshRequest.then((refreshed) => refreshed ? Promise.resolve(config) : Promise.reject());
  }

  return config;
}, async error => {
  
  if (error.response && error.response.status === 401) {
    // Try to use refresh token
    if (error.response.headers['token-expired'] == "true") {
      // Make request for new token
      const refreshed = await refreshAuthToken();
      if (refreshed) {
        // If refresh was successful retry request
        error.config.headers['Authorization'] = `Bearer ${store.state.authentication.token}`;
        return httpClient.request(error.config);
      }
    }

    store.commit('clearAuth');
    router.push('/login');
    return Promise.resolve();
  }

  return Promise.reject(error);
});

let refreshRequest = null;

function refreshAuthToken () {
  let {jwtToken, refreshToken} = tokenStore.getTokens();

  // Allow only a single refresh request
  if (refreshRequest) {
    return refreshRequest;
  }

  if (!jwtToken || !refreshToken) {
    return false;
  }

  // Don't use httpClient instance so interceptor does not fire
  refreshRequest = axios.post('auth/refresh', {
    jwtToken,
    refreshToken
  }, { baseURL }).then(resp => {
    if (resp.data.jwtToken && resp.data.refreshToken) {
      store.commit('setAuth', resp.data);
      refreshRequest = null;
      return Promise.resolve(true);
    } else {
      refreshRequest = null;
      return Promise.reject(false);
    }
  }).catch(() => {
    refreshRequest = null;
    return false;
  });

  return refreshRequest;
}

function setAuthToken (token) {
  if (token) {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    httpClient.defaults.headers.common['Authorization'] = '';
  }
}

function setServer (server) {
  baseURL = `${server}/api/`
  httpClient.defaults.baseURL = baseURL;
}

export { httpClient as default, setAuthToken, setServer, refreshAuthToken };