<template>
  <div>
    <loading-indicator v-if="loading" />

    <div v-else>
      <b-alert
        class="mt-3"
        variant="danger"
        :show="!!message"
      >
        {{ message }}
      </b-alert>

      <collapsible-options
        v-model="emailSettings.enableEmail"
        title="Enable Email Sending"
        :collapsible="true"
        @input="toggleEmailEnable"
      >
        <div class="form-section">
          <b-form-group
            label="SMTP Server"
            label-for="smtp-server"
            :label-cols="5"
          >
            <b-col cols="auto">
              <b-form-input
                id="smtp-server"
                v-model="$v.emailSettings.smtpServerAddress.$model"
                autocomplete="off"
                size="sm"
                :state="state($v.emailSettings.smtpServerAddress)"
              />
              <b-form-invalid-feedback :state="state($v.emailSettings.smtpServerAddress)">
                SMTP server address is required
              </b-form-invalid-feedback>
            </b-col>
          </b-form-group>

          <b-form-group
            label="SMTP Server Port"
            label-for="smtp-server-port"
            :label-cols="5"
          >
            <b-col
              cols="8"
              lg="3"
            >
              <b-form-input
                id="smtp-server-port"
                v-model="$v.emailSettings.smtpServerPort.$model"
                autocomplete="off"
                size="sm"
                :state="state($v.emailSettings.smtpServerPort)"
              />
              <b-form-invalid-feedback :state="state($v.emailSettings.smtpServerPort)">
                {{ $v.emailSettings.smtpServerPort.$params.between.min }} - {{ $v.emailSettings.smtpServerPort.$params.between.max }}
              </b-form-invalid-feedback>
            </b-col>
          </b-form-group>

          <collapsible-options
            v-model="emailSettings.authenticationRequired"
            title="Requires Authentication"
            :collapsible="true"
          >
            <b-form-group
              label="Username"
              label-for="username"
              :label-cols="5"
            >
              <b-col cols="auto">
                <b-form-input
                  id="username"
                  v-model="$v.emailSettings.username.$model"
                  autocomplete="off"
                  size="sm"
                  :state="state($v.emailSettings.username)"
                />
                <b-form-invalid-feedback :state="state($v.emailSettings.username)">
                  Username is required
                </b-form-invalid-feedback>
              </b-col>
            </b-form-group>

            <b-form-group
              label="Password"
              label-for="password"
              :label-cols="5"
            >
              <b-col cols="auto">
                <b-form-input
                  id="password"
                  v-model="$v.emailSettings.password.$model"
                  type="password"
                  autocomplete="off"
                  size="sm"
                  :state="state($v.emailSettings.password)"
                />
                <b-form-invalid-feedback :state="state($v.emailSettings.password)">
                  Password is required
                </b-form-invalid-feedback>
              </b-col>
            </b-form-group>
          </collapsible-options>

          <collapsible-options
            v-model="emailSettings.useSSL"
            title="Use SSL"
            :collapsible="false"
          />

          <b-form-group
            label="From Email Address"
            label-for="from-address"
            :label-cols="5"
          >
            <b-col cols="auto">
              <b-form-input
                id="from-address"
                v-model="$v.emailSettings.fromAddress.$model"
                autocomplete="off"
                size="sm"
                :state="state($v.emailSettings.fromAddress)"
              />
              <b-form-invalid-feedback :state="state($v.emailSettings.fromAddress)">
                Must be a valid email address
              </b-form-invalid-feedback>
            </b-col>
          </b-form-group>
          <b-col 
            offset="5" 
            cols="7"
          >
            <b-btn 
              @click="sendTestEmail"
            >
              Send Test Email
            </b-btn>
            <b-alert
              class="mt-3"
              :variant="msgVariant"
              :show="!!emailMessage"
            >
              <b-spinner 
                v-if="sendingEmail" 
                label="Sending email..." 
                small 
              />
              {{ emailMessage }}
            </b-alert>
          </b-col>
        </div>
      </collapsible-options>
    </div>
  </div>
</template>

<script>
import api from '../api'
import LoadingIndicator from './LoadingIndicator.vue'
import { mapActions, mapMutations } from 'vuex'
import { required, requiredIf, between, email} from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'
import CollapsibleOptions from './CollapsibleOptions'
import formatters from '../js/formatters'


export default {
  data () {
    return {
      loading: true,
      emailSettings: {},
      error: false,
      message: false,
      sendingEmail: false,
      emailMessage: false
    }
  },
  async mounted () {
    await this.getEmailSettings();
    this.setUnsaved();
  },
  computed: {
    msgVariant () {
      return this.sendingEmail ? 'info' :
        this.error ? 'danger' : 'success';
    }
  },
  validations: {
    emailSettings: {
      smtpServerAddress: { required },
      smtpServerPort: { required, between: between(0,65535)},
      username: { required: requiredIf('authenticationRequired') },
      password: { required: requiredIf('authenticationRequired') },
      fromAddress: { required, email }
    }
  },
  methods: {
    ...mapActions(['autoSave']),
    ...mapMutations(['setUnsaved']),

    async getEmailSettings() {
      this.loading = true; 
      this.error = false;

      try {
        this.emailSettings = await api.getEmailSettings();
        this.message = false;
      } catch {
        this.error = true;
        this.message = 'An error occurred retrieving the email settings';
      }

      this.loading = false;
    },
    toggleEmailEnable(enabled) {
      this.emailSettings.enableEmail = enabled;
    },
    async sendTestEmail() {
      this.error = false;

      try {
        this.sendingEmail = true;
        this.emailMessage = 'Sending email...';
        await api.sendTestEmail(this.emailSettings);
        this.emailMessage = 'Test email sent.';
        this.sendingEmail = false;
      } catch (err) {
        this.sendingEmail = false;
        this.error = true;
        this.emailMessage = err.response.data.message || 'An error occurred sending email.';
      }
      
    }
  },
  watch: {
    emailSettings: {
      deep: true,
      handler (emailSettings, oldSettings) {
        //Check if settings were being loaded
        if (oldSettings.hasOwnProperty('enableEmail') && (!this.$v.emailSettings.$invalid || !this.emailSettings.enableEmail)) {
          this.autoSave({ saveAction: 'saveEmailSettings', isValid: true, id: null, data: this.emailSettings});
        }
      }
    }
  },
  components: {
    CollapsibleOptions,
    LoadingIndicator
  },
  mixins: [validationState, formatters]
}
</script>