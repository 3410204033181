function getReadUrl (path) {
  var readUrl = this._getServiceUrl();
  
  if (path) {
    readUrl = path.indexOf("http") == 0 ? path : readUrl + path;
  } else if (this._tbl) {
    readUrl = readUrl + this._tbl;
    
    // Additional code to allow entityType
    if (this.entityType) {
      readUrl = readUrl + '/' + this.entityType;
    }
  }
  
  return readUrl;
}

function getWriteUrl (item) {
  var self = this;
  var url = this._getServiceUrl() + this._tbl;
  
  if (item) {
    if (!this.keys || !this.keys.length) {
      throw "write operations require keys.";
    }
    
    var urlKeys = [];
    this.keys.forEach(function(k) {
      var o = item[k];
      if (null == o) {
        o = "";
      }
      if (typeof o == "string") {
        o = "'" + o + "'";
      }
      urlKeys.push(self.keys.length == 1 ? o : k + "=" + o);
    });
    
    if (urlKeys.length) {
      url = url + ("(" + urlKeys.join(",") + ")");
    }
  }

  // Additional code to allow entityType
  if (this.entityType) {
    url = url + '/' + this.entityType;
  }

  return url;
}

function convertToDbFormat (obj) {
  var objOk = {};
  var key;
  for (key in obj) {
    var value = obj[key];
    if (value instanceof Date && !isNaN(value.getTime()) && this._showDatesAsGmt) {
      value = new Date(value.getTime() - 6E4 * value.getTimezoneOffset());
    } else {
      if (typeof value == 'number' && this._odv < 4) {
        value = value.toString();
      }
    }
    objOk[key] = value;
  }

  // Remove odata.type property
  // if (this.entityType) {
  //   objOk["odata.type"] = this.entityType;
  // }
  return objOk;
}

export { getReadUrl, getWriteUrl, convertToDbFormat }