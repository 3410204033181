<template>
  <div>
    <div class="content centered-max-1500">
      <div class="btn-container-right">
        <b-button
          v-b-tooltip.hover.bottom
          to="/"
          title="Return to Analysis Manager"
        >
          <font-awesome-icon
            icon="arrow-alt-circle-left"
            class="button-icon"
          />Back
        </b-button>
      </div>
      
      <section v-if="authentication.enabled">
        <h2>Account Information</h2>

        <b-alert
          :show="showChangePasswordErrorMessage || showGetUserErrorMessage"
          variant="danger"
        >
          {{ errorMessage }}
        </b-alert>
        <b-container>
          <b-row>
            <b-col
              cols="12"
              lg="2"
            >
              Username:
            </b-col>
            <b-col
              cols="12"
              lg="2"
            >
              {{ user.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              lg="2"
            >
              Email:
            </b-col>
            <b-col
              cols="12"
              lg="2"
            >
              {{ user.email }}
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              lg="2"
            >
              Password:
            </b-col>
            <b-col
              cols="12"
              lg="2"
            >
              <b-button
                v-b-modal.change-password
                variant="primary"
              >
                Change Password
              </b-button>
            </b-col>
          </b-row>
        </b-container>

        <b-modal
          id="change-password"
          title="Change Password"
          ok-title="Change Password"
          no-close-on-backdrop
          @ok="changePassword"
          @show="clearModal"
        >
          <div v-if="changingPassword">
            <b-spinner class="spinner-md" /> Changing password...
          </div>
          <b-form-group
            label="Current Password"
            label-for="current-password"
          >
            <b-form-input
              id="current-password"
              v-model="$v.currentPassword.$model"
              :state="state($v.currentPassword)"
              type="password"
              autocomplete="off"
              required
              autofocus
            />
            <b-form-invalid-feedback :state="state($v.currentPassword)">
              Must enter current password
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="New Password"
            label-for="new-password"
          >
            <b-form-input
              id="new-password"
              v-model="$v.newPassword.$model"
              :state="state($v.newPassword)"
              type="password"
              autocomplete="off"
              required
            />
            <b-form-invalid-feedback :state="state($v.newPassword)">
              A password of at least {{ $v.newPassword.$params.minLength.min }} characters is required
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Confirm Password"
            label-for="confirm-password"
          >
            <b-form-input
              id="confirm-password"
              v-model="$v.confirmPassword.$model"
              :state="state($v.confirmPassword)"
              type="password"
              autocomplete="off"
              required
            />
            <b-form-invalid-feedback :state="state($v.confirmPassword)">
              Passwords must match
            </b-form-invalid-feedback>
          </b-form-group>
        </b-modal>
      </section>
      <section>
        <h2>User Interface Options</h2>

        <b-link
          @click="switchTheme"
          :class="['section-link', theme === 'dark-theme' ? 'dark-toggle' : 'light-toggle' ]"
        >
          <font-awesome-icon icon="sync-alt" />
          <span>{{ theme == 'light-theme' ? 'Dark' : 'Light' }} Theme</span>
        </b-link>
      </section>
    </div>
  </div>
</template>

<script>
import api from '../api';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import validationState from '../js/mixins/validationState';
import getErrorMessage from '../js/getErrorMessage'

export default {
  data () {
    return {
      user: {},
      loading: false,
      errorMessage: false,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      showGetUserErrorMessage: false,
      showChangePasswordErrorMessage: false,
      changingPassword: false
    }
  },
  validations: {
    currentPassword: { required },
    newPassword: { required, minLength: minLength(8) },
    confirmPassword: { required, sameAsPassword: sameAs('newPassword') }
  },
  computed: {
    ...mapGetters(['userid']),
    ...mapState(['theme', 'authentication'])
  },
  async mounted () {
    await this.getUser();
  },
  methods: {
    ...mapMutations(['setTheme']),
    async getUser() {
      this.loading = true;

      try {
        let response = await api.getCurrentUser();
        this.user = response.data;
        this.showGetUserErrorMessage = false;
      } 
      catch(error) {
        this.errorMessage = getErrorMessage(error, 'Unknown error getting account information.');
        this.showGetUserErrorMessage = true;
      }
      
      this.loading = false;
    },
    async changePassword(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.changingPassword = true;
      
      this.$v.$touch();

      if (!this.$v.$invalid) 
      {
        try {
          await api.changePassword({ CurrentPassword: this.currentPassword, NewPassword: this.newPassword });
          this.showChangePasswordErrorMessage = false;
        } 
        catch(error) {
          this.errorMessage = getErrorMessage(error, 'Unknown error changing password.');
          this.showChangePasswordErrorMessage = true;
        }
      }
      this.$bvModal.hide('change-password');
      this.changingPassword = false;
    },
    clearModal() {
      this.currentPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;
    },
    switchTheme() {
      let theme = this.theme === 'light-theme' ? 'dark-theme' : 'light-theme';
      
      localStorage.setItem('kinetics_theme', theme);
      this.setTheme(theme);
    }
  },
  mixins: [validationState]
}
</script>
<style scoped>
  section > .section-link {
      margin-right: 25px;
      display: inline-block;
      height: 16px;
      line-height: 16px;
  }
  section > .section-link > span {
      padding-left: 5px;
  }
  .centered-max-1500 {
  width: 100%;
  margin: 0px auto;
  max-width: 1500px !important;
}
</style>