<template>
  <div>
    <loading-indicator v-if="loading" />
    <div
      v-else-if="!errorMessage"
      v-disable-all="analysisRunning"
    >
      <b-alert
        :show="!tabValid('Scenarios')"
        variant="danger"
      >
        Please fix the validation errors shown below
      </b-alert>
      <b-navbar
        toggleable="sm"
      >
        <b-navbar-nav>
          <b-nav-form>
            <div class="modern-tab-buttons">
              <b-button
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Create a forecast from a template or import from a text file"
                @click="showAddForecastDialog=true"
              >
                <font-awesome-icon :icon="['far','plus-square']" />
                Add Forecast
              </b-button>
              <b-button
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Fill selected cells using linear interpolation (selection must start and end with a value and be blank in between)"
                @click="fillRange"
                :disabled="chartView"
              >
                <font-awesome-icon icon="columns" />
                Fill Range
              </b-button>
              <b-button
                v-if="isAutoAnalysis"
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Export user-defined scenarios into a text file"
                @click="exportScenarios"
              >
                <font-awesome-icon icon="file-export" />
                Export
              </b-button>
              <b-button
                class="display-shock-panel-button"
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Toggle Apply Shock panel"
                @click="shouldDisplayTopShockPanel=!shouldDisplayTopShockPanel"
              >
                Apply Shock
                <font-awesome-icon :icon="shouldDisplayTopShockPanel ? 'chevron-up' : 'chevron-down'" />
              </b-button>
            </div>
            <b-form-checkbox
              class="ml-3"
              :checked="chartView"
              switch
              @input="chartView = !chartView"
            >
              Chart View
            </b-form-checkbox>
          </b-nav-form>
        </b-navbar-nav>
      </b-navbar>
      <div class="scenario-data-container">
        <apply-shock
          v-if="shouldDisplayTopShockPanel"
          ref="applyShockPanel"
          class="apply-shockpanel-top"
          :forecast-group="forecastGroup"
          :forecast-types="forecastTypes"
          @scenarios-updated="initScenarios"
        />
        <scroll-container
          class="grid-container"
          v-resize:debounce.100="resize"
          :key="key"
        >
          <div v-show="!chartView">
            <scenario-grid
              :ref="gridRef"
              @scenarios-updated="initScenarios"
              @scenario-toggled="updateChartScenarios"
              @cells-selected="updateShockParams"
              :forecast-group="forecastGroup"
              :forecast-types="forecastTypes"
            />
          </div>
          <div
            v-show="chartView"
          >
            <div class="chart-area">
              <!-- <loading-indicator v-if="chartLoading" /> -->
              <plotly
                ref="plotlyChart"
                :data="chartData"
                :layout="chartLayout"
                :display-mode-bar="true"
              />
            </div>
          </div>
        </scroll-container>
        <scroll-container
          v-resize:debounce.100="resize"
          :key="`${key}-1`"
          :min-height="1"
          class="shock-container"
        >
          <apply-shock
            ref="applyShockPanel"
            class="apply-shockpanel-side"
            :forecast-group="forecastGroup"
            :forecast-types="forecastTypes"
            @scenarios-updated="initScenarios"
          />
        </scroll-container>
      </div>
    </div>
    <add-forecast-dialog
      v-model="showAddForecastDialog"
      :forecast-group="forecastGroup"
      :forecast-types="forecastTypes"
      :file="files.find(f => f.fileType == fileType)"
      @scenarios-updated="initScenarios"
    />
  </div>
</template>

<script>
import { Plotly } from './plotly'
import LoadingIndicator from './LoadingIndicator.vue'
import ScrollContainer from './ScrollContainer.vue'
import ApplyShock from './ApplyShock.vue'
import AddForecastDialog from './AddForecastDialog.vue'
import ScenarioGrid from './ScenarioGrid.vue'
import formatters from '../js/formatters'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import fileUpload from '../js/mixins/fileUpload'
import lodash from 'lodash';
import resize from 'vue-resize-directive'
import { runTypes } from '../js/options/runType'

export default {
  props: {
    forecastGroup: { type: [String], required: true },
    forecastTypes: { type: [Array], required: true },
    chartTitle: { type: [String], required: true },
  },
  data () {
    return {
      loading: true,
      errorMessage: null,
      clickedFile: {},
      fileLayout: [],
      useTemplate: false,
      chartView: false,
      chartScenarios: [],
      showAddForecastDialog: false,
      chartTop: 213,
      key: 0,
      shouldDisplayTopShockPanel: false
    }
  },
  async mounted () {
    try
    {
      await this.initScenarios();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.errorMessage = 'Error loading Scenarios tab';
    }

  },
  computed: {
    ...mapState(['activeAnalysis', 'scenarioData', 'scenarioPreviewData', 'scenarios', 'theme']),
    ...mapGetters(['fileSet', 'tabValid', 'analysisRunning', 'isAdmin']),
    gridRef() {
      return `scenarioGrid-${this.forecastGroup}`
    },
    fileType () {
      if (this.isAutoAnalysis) return 'Unemployment Scenario'
      return 'Forecast'
    },
    files () {
      return this.fileSet.filter(f => f.enabled && !f.hidden && f.fileType==this.fileType);
    },
    fileIdentifiers () {
      return this.files.map(f => f.fileName.toLowerCase()).join(', ');
    },
    showLdm () {
      return this.activeAnalysis.runType == runTypes.loanDynamics && this.activeAnalysis.assetType === "SingleFamilyLoans";
    },
    isAutoAnalysis() {
      return this.activeAnalysis.runType == runTypes.loanDynamics && this.activeAnalysis.assetType === 'AutoLoans';
    },
    forecastHeaders() {
      let res = {};
      this.forecastTypes.forEach(t => {
        res[t.name] = t.header;
      })
      return res;
    },
    chartLayout () {
      this.scenarios;
      let chartHeight = window.innerHeight - this.chartTop - 20;
      let layout = {
        title: this.chartTitle,
        height: chartHeight,
        showlegend: true,
        legend: {
					orientation: "h",
          y: -0.4
        }
      }

      if (this.theme == 'dark-theme') {
        layout.paper_bgcolor = "#232529";
        layout.plot_bgcolor = "#232529";
        layout.font = {
          color: "#fffffd"
        };
        layout.xaxis = {
          gridcolor: "#36383b"
        };
        layout.yaxis = {
          gridcolor: "#36383b"
        };
      }

      return layout;
    },
    chartData () {
      if (this.scenarioData[this.forecastGroup] === null) return [];
      let chartData = [];
      let dates = this.scenarioData[this.forecastGroup].map(r => r.date);

      for (const s of this.chartScenarios) {
        if (s.isDefaultScenario) {
          for (const t of this.forecastTypes.filter(f => f.default)) {
            let forecast = this.scenarioData[this.forecastGroup].map(r => r[s.scenarioName][t.name]);
            chartData.push({
              name: `${s.scenarioName} - ${this.forecastHeaders[t.name]}`,
              x: dates,
              y: forecast
            });
          }
        } else {
            for (const t of s.forecastTypes.filter(f => this.forecastTypes.map(g => g.name).includes(f))) {
            let forecast = this.scenarioData[this.forecastGroup].map(r => r[`_${s.scenarioId}`] && r[`_${s.scenarioId}`][t]);
            chartData.push({
              name: `${s.scenarioName} - ${this.forecastHeaders[t]}`,
              x: dates,
              y: forecast
            });
          }
        }
      }

      if (!_.isEmpty(this.scenarioPreviewData)) {
        let forecast = this.scenarioPreviewData.forecast.values;
        chartData.push({
          name: this.isAutoAnalysis ? this.scenarioPreviewData.scenarioName : this.scenarioPreviewData.chartLabel,
          x: dates,
          y: forecast,
          line: {
            dash: 'dash',
            width: 4
          }
        });
      }

      return chartData;
    },
  },
  methods: {
    ...mapMutations(['addExport']),
    ...mapActions(['getScenarios']),

    messageBox (title, message) {
      return this.$bvModal.msgBoxOk(message, {
        title: title,
        headerClass: 'pl-2 pr-2 pt-1 pb-1',
        footerClass: 'p-2 border-top-0'
      });
    },
    async loadGrid (data={}) {
      await this.$refs[this.gridRef].loadGrid(data);
    },
    async initScenarios ({scenarioDeleted,newForecastAdded}={}) {
      await this.getScenarios();
      if (this.$refs[this.gridRef]) await this.loadGrid({scenarioDeleted, newForecastAdded});
      this.updateChartScenarios();
    },
    async updateChartScenarios() {
      this.chartScenarios = this.scenarios.filter(s => s.enabled);
    },
    async fillRange() {
      this.$refs[this.gridRef].fillRange();
      await this.updateChartScenarios(); 
    },
    updateShockParams(data) {
      this.$refs.applyShockPanel.updateShockParams(data);
    },
    calculateChartHeight () {
      return Math.max(window.innerHeight - this.chartTop - 20, 300);
    },
    resize () {
      // Adjust height based on window height
      if (this.$refs.plotlyChart) {
        let height = this.calculateChartHeight();
        let width = window.innerWidth - 320;

        this.$refs.plotlyChart.relayout({
          height,
          width
        });
      }
    },
    exportScenarios () {
      this.addExport({
        analysisId: this.activeAnalysis.id,
        exportMessage: `Exporting scenarios for ${this.activeAnalysis.name}...`,
        errorMessage: `Error exporting scenarios for ${this.activeAnalysis.name}`,
        promise: this.$http.post(`/analyses/${this.activeAnalysis.id}/export/Scenarios`)
      });
    }
  },
  components: {
    Plotly,
    LoadingIndicator,
    ScrollContainer,
    ApplyShock,
    AddForecastDialog,
    ScenarioGrid
  },
  directives: {
    resize
  },
  mixins: [formatters, fileUpload]
}
</script>
<style scoped>
  .scrollable.grid-container {
    flex-basis: 65%;
  }
  .scenario-data-container{
    display: flex;
  }
  .display-shock-panel-button, .apply-shockpanel-top{
    display: none;
  }
  .shock-container {
    min-width: 300px;
  }

  @media only screen and (max-width: 786px) {
    .display-shock-panel-button, .apply-shockpanel-top{
      display: block;
    }
    .apply-shockpanel-top{
       width: 100%;
    }

    .apply-shockpanel-side{
      display: none;
    }
    .scenario-data-container{
      display: block;
    }
  }

</style>