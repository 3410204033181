<template>
  <label :class="wrapperClass">
    <input
      ref="fileInput"
      type="file"
      :multiple="multiple"
      :disabled="disabled"
      @change="onChange"
    >
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    fileTypes: { type: Array, default: () => ['txt', 'tab', 'tsv'] },
    wrapperClass: { type: String, default: '' }
  },
  methods: {
    onChange (evt) {
      let errorExt = this.checkFileTypes(evt.target.files);

      if (errorExt) {
        this.$emit('error', errorExt);
        return;
      }

      if (this.multiple) {
        this.$emit('input', evt.target.files);
      } else {
        this.$emit('input', evt.target.files[0]);
      }
    },
    clear () {
      this.$refs.fileInput.value = null;
    },
    checkFileTypes (files) {
      if (!this.fileTypes || !this.fileTypes.length) {
        return;
      }

      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          let ext = this.getFileExtension(files[i].name);
          if (!this.fileTypes.some(ft => ft == ext)) {
            return ext;
          }
        }
      }
    },
    getFileExtension (fileName) {
      let s = fileName.split('.');

      if (s.length <= 1) {
        return "";
      } else {
        return s.pop();
      }
    }
  }
}
</script>

<style scoped>
label {
  margin: 0;
  cursor: pointer;
}
label > input[type="file"] {
  display: none;
}
</style>
