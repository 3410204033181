import { getValidationMessage } from '../validation/validationMessages'

export default {
  methods: {
    state (field) {
      return field.$dirty && field.$error && (field.required && field.$model || !field.required) ? false : null;
    },
    validationMessage (field) {

      for (let validatorType in field) {
        if (field.hasOwnProperty(validatorType) && !validatorType.startsWith('$') && field[validatorType] === false) {
          return getValidationMessage(validatorType, field.$params[validatorType])
        }
      }

      return 'Validation error';
    },
    validateAndUpdate (name, value, v) {
      this.$emit(name, value);
      v.$touch();
    },
    toNumber(str) {
      let num = parseFloat(str);

      return isNaN(num) ? str : num;
    }
  }
}