<template>
  <span class="ml-auto mr-4 save-ind">
    <b-spinner
      v-if="savePending"
      variant="primary"
      label="Saving..."
      small
    />
    <font-awesome-icon
      v-else-if="!validationError && isSaved"
      class="saved"
      icon="check"
      size="lg"
    />
    <font-awesome-icon
      v-else-if="validationError"
      class="error"
      icon="times"
      size="lg"
    />
    {{ saveStatus }}
  </span>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['savePending', 'isSaved']),
    ...mapGetters(['saveStatus', 'validationError'])
  }
}
</script>

<style scoped>
.spinner-border-sm {
    margin-right: 5px;
}
.saved {
  color: #21732f;
}
.error {
  color: red;
}
</style>