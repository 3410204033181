import { decimal, between, integer } from 'vuelidate/lib/validators'

export const validations = {
  costPerLoan: {
    decimal,
    between: between(0, 10000)
  },
  costPerD1: {
    decimal,
    between: between(0, 10000)
  },
  costPerD2: {
    decimal,
    between: between(0, 10000)
  },
  costPerD3: {
    decimal,
    between: between(0, 10000)
  },
  costPerD4: {
    decimal,
    between: between(0, 10000)
  },
  costPerD5: {
    decimal,
    between: between(0, 10000)
  },
  costPerSeriouslyDelinq: {
    decimal,
    between: between(0, 10000)
  },
  costPerForeclosure: {
    decimal,
    between: between(0, 10000)
  },
  costInflation: {
    decimal,
    between: between(-10, 100)
  },
  advancesLeverage: {
    decimal,
    between: between(0, 100)
  },
  advancesFinanceRate: {
    decimal,
    between: between(0, 100)
  },
  recaptureMonths: {
    integer,
    between: between(0, 24)
  },
  recapturePercent: {
    decimal,
    between: between(0, 100)
  },
  escrowRateEarned: {
    decimal,
    between: between(0, 100)
  },
  escrowRatePaid: {
    decimal,
    between: between(0, 100)
  },
  escrowInflation: {
    decimal,
    between: between(-10, 100)
  },
  scheduleFloatDays: {
    integer,
    between: between(0, 120)
  },
  prepayFloatDays: {
    integer,
    between: between(0, 120)
  },
  paymentFloatRateEarned: {
    decimal,
    between: between(0, 100)
  },
  incomePerLoan: {
    decimal,
    between: between(0, 10000)
  },
  incomePerPayment: {
    decimal,
    between: between(0, 10000)
  },
  baseServicing: {
    decimal,
    between: between(0, 10)
  }
}
