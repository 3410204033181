<template>
  <modal-dialog 
    title="Edit User" 
    confirm-button-text="Update" 
    :action="updateUser" 
    @clear="clearDialog"
    @shown="setUser"
    :value="value"
    @input="$emit('input', $event)"
    :error-message="errorMessage"
  >
    <b-form-group
      label="Email Address"
      label-for="email"
    >
      <b-form-input
        id="email-address"
        v-model="$v.user.email.$model"
        :state="state($v.user.email)"
        required
        autofocus
        autocomplete="off"
      />
      <b-form-invalid-feedback :state="state($v.user.email)">
        Valid email address is required
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      label="Username"
      label-for="name"
    >
      <b-form-input
        id="username"
        v-model="$v.user.displayName.$model"
        :state="state($v.user.displayName)"
        required
        autocomplete="off"
      />
      <b-form-invalid-feedback :state="state($v.user.displayName)">
        {{ validationMessage($v.user.displayName) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        v-model="user.active"
      >
        Active
      </b-form-checkbox>
    </b-form-group>
    
    <b-form-group>
      <b-form-checkbox
        v-model="user.admin"
      >
        Admin
      </b-form-checkbox>
    </b-form-group>
    
    <b-form-group label="Module Permissions">
      <b-form-checkbox-group
        v-model="user.modules"
        :options="moduleOptions"
      />
    </b-form-group>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import { required, email, minLength } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'
import api from '../api'
import getErrorMessage from '../js/getErrorMessage'

export default {
  props:{
    value: { type: Boolean },
    userToEdit: { type: Object, required: true }
  },
  data() {
    return {
      user: {
        id: null,
        displayName: null,
        email: null,
        active: false,
        admin: false,
        modules: []
      },
      errorMessage: 'Unknown error creating user.',
      modules: [],
    };
  },
  async mounted () {
    this.modules = await api.getModules();
  },
  computed: {
    moduleOptions () {
      return this.modules.map((m) => ({ value: m.id, text: m.name }));
    }
  },
  validations: {
    user: {
      displayName: { required, minLength: minLength(4) },
      email: { required, email }
    }
  },
  methods: {
    async updateUser() {
      this.$v.$touch();

      if (this.$v.user.$invalid) {
        return false;
      }

      try {
        await api.updateUser({ id: this.user.id, displayName: this.user.displayName, email: this.user.email, active: this.user.active, isAdmin: this.user.admin, modules: this.user.modules });

        this.$emit('user-updated');

        return true;
      } 
      catch(error) {
        this.errorMessage = getErrorMessage(error, 'Unknown error updating user.');
        throw err;
      }
      
    },
    clearDialog () {
      this.user.displayName = null;
      this.user.email = null;

      if (this.modules.length == 1) {
        this.user.modules = this.modules[0].id;
      } else {
        this.user.modules = [];
      }
      
    },
    setUser () {
      this.user = Object.assign({}, this.user, this.userToEdit);
    }
  },
  components: {
    ModalDialog
  },
  mixins: [validationState]
}
</script>