<template>
  <modal-dialog 
    title="Create Analysis" 
    confirm-button-text="Create" 
    :value="value" 
    :action="createAnalysis" 
    @input="$emit('input', $event)" 
    @clear="clearDialog"
  >
    <b-form-group
      v-if="hasModuleOptions && showModuleSelection"
      label="Select a Module"
      label-for="module-select"
    >
      <b-button
        v-for="module in moduleOptions"
        :key="module.value.id"
        class="module-button"
        variant="light"
        block
        @click="setModule(module.value.id)"
      >
        <img
          :src="allModules.find(m => m.id === module.value.id).logoPath"
          class="module-logo"
        >
        <p>
          {{ allModules.find(m => m.id === module.value.id).desc }}
        </p>
      </b-button>
    </b-form-group>

    <template
      v-if="!hasModuleOptions || !showModuleSelection"
    >
      <b-button
        v-if="hasModuleOptions"
        variant="secondary"
        size="sm"
        @click="clearDialog()"
        class="float-right"
      >
        Change Module
      </b-button>

      <img
        :src="moduleLogoPath"
        class="module-logo"
      >

      <b-form-group
        label="Enter Analysis Name"
        label-for="analysis-name"
      >
        <b-form-input
          id="analysis-name"
          v-model="analysisName"
          autocomplete="off"
          :state="state($v.analysisName)"
          autofocus
        />
        <b-form-invalid-feedback :state="state($v.analysisName)">
          {{ validationMessage($v.analysisName) }}
        </b-form-invalid-feedback>
      </b-form-group>
      
      <collapsible-options
        v-if="selectedModule.assetType == 'Servicing'"
        v-model="enableHedging"
        title="Enable Hedging"
      />

      <b-form-group
        v-if="hasPositionOptions"
        label="Position Format"
      >
        <b-form-radio-group
          id="pos-format-setting"
          v-model="positionFormat"
          :options="positionFormatOptions[selectedModule.assetType]"
          name="pos-format-options"
        />
      </b-form-group>
    </template>
  </modal-dialog>
</template>

<script>
import CollapsibleOptions from './CollapsibleOptions.vue'
import ModalDialog from './ModalDialog.vue'
import validationState from '../js/mixins/validationState'

import api from '../api'
import { mapState, mapGetters } from 'vuex'
import { retentionOptions } from '../js/options/retentionOptions'
import externals from '../js/externals'
import { required } from 'vuelidate/lib/validators'
import {assetTypes } from '../js/options/assetType'
import assetPath from '../js/mixins/assetPath'
import moment from 'moment'

export default {
  props:{
    value: { type: Boolean }
  },
  data () {
    return {
      showModuleSelection: true,
      analysisName: null,
      enableHedging: false,
      selectedModule: {},
      flavor: externals.GUIflavor,
      retentionOptions: retentionOptions,
      positionFormat: 'Loan',
      positionFormatOptions: {
        Servicing: [
          { text: 'Loans', value: 'Loan' },
          { text: 'Pools', value: 'Pool' }
        ],
        AutoLoans: [
          { text: 'Loans', value: 'Loan' }
        ],
        SingleFamilyLoans: [
          { text: 'Loans', value: 'Loan' },
          { text: 'Pools', value: 'Pool' }
        ],
        MultiFamilyLoans: [
          { text: 'Loans', value: 'Loan' }
        ],
        AgencyPools: [
          { text: 'Pools', value: 'Pool' }
        ],
      },
      allModules: [
        {
          name: 'LoanKinetics',
          id: 'LoanKinetics',
          logoPath: this.getImagePath('lk-logo.png'),
          desc: 'Project credit performance and assess the value and risk of residential mortgages.'
        },
        {
          name: 'PoolKinetics',
          id: 'PoolKinetics',
          logoPath: this.getImagePath('pk-logo.png'),
          desc: 'Evaluate agency MBS pool pay-ups by assessing their values relative to TBAs.'
        },
        {
          name: 'MSRKinetics',
          id: 'MSRKinetics',
          logoPath: this.getImagePath('msrk-logo.png'),
          desc: 'Assess the value of mortgage servicing rights and the impact of hedging with MBS and TBAs.'
        },
        {
          name: 'LoanDynamics',
          id: 'LDM',
          logoPath: this.getImagePath('ldm-logo.png'),
          desc: 'Forecast prepayments, defaults, and loss severities for single-family mortgages.'
        },
        {
          name: 'Multifamily LoanDynamics',
          id: 'LDM-Multifamily',
          logoPath: this.getImagePath('mfldm-logo.png'),
          desc: 'Forecast prepayments, defaults, and loss severities for multifamily mortgages.'
        },
        {
          name: 'Auto LoanDynamics',
          id: 'AutoKinetics',
          logoPath: this.getImagePath('aldm-logo.png'),
          desc: 'Forecast prepayments, defaults, and loss severities for auto loans.'
        }
      ]
    }
  },
  beforeMount () {
    this.selectedModule = this.moduleOptions[0].value
  },
  computed: {
    ...mapState(['licenseInfo']),
    ...mapGetters(['isLicensingAgencyPlus']),

    moduleOptions () {
      return this.licenseInfo.modules.filter(m => moment(m.licenseExpiration).isAfter()).map(m => ({ value: { id: m.id, assetType: m.assetType, runType: m.runType }, text: m.name }));
    },

    hasModuleOptions () {
      return this.moduleOptions.length > 1;
    },

    hasPositionOptions () {
      return this.selectedModule.assetType && this.positionFormatOptions[this.selectedModule.assetType] ? this.positionFormatOptions[this.selectedModule.assetType].length > 1 : true;
    },

    moduleLogoPath () {
      return this.allModules.find(m => m.id === this.selectedModule.id).logoPath
    }
  },
  validations: {
    analysisName: {
      required
    }
  },
  methods: {
    setModule(moduleId) {
      this.selectedModule = this.moduleOptions.find(m => m.value.id === moduleId).value
      this.showModuleSelection = false;
    },
    async createAnalysis () {

      // Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      // Create analysis
      let settings = { 
        useHedgeFile: this.enableHedging,
        fieldConfigMetaData : {
          enableInterestRateRisk: false,
          enableModelRisk: false,
          enableExternalHpaAndUnemployment: false
        }
      };

      if (!this.selectedModule.runType || this.selectedModule.runType == "LoanDynamics") {
        settings.enableDefaultScenario = true;
        settings.resultSettings = {
          balances: true,
          dollarDefaultAndLoss: true,
          lossSeverity: true,
          expectedLoss: true,
          delqStatus: true,
          cumulatives: true,
          transitions: true,
          granularDelq: this.isLicensingAgencyPlus && this.selectedModule.assetType == assetTypes.singleFamilyLoans,
          macroeconomicForecasts: this.selectedModule.assetType == assetTypes.autoLoans
        };
      }

      // Set positionFormat if there is only one option
      if (!this.hasPositionOptions) {
        if (this.positionFormatOptions[this.selectedModule.assetType] && this.positionFormatOptions[this.selectedModule.assetType].length == 1) {
          this.positionFormat = this.positionFormatOptions[this.selectedModule.assetType][0].value;
        }
      }

      let data  = Object.assign({},{name: this.analysisName,
        assetType: this.selectedModule.assetType,
        runType: this.selectedModule.runType || "LoanDynamics",
        positionFormat: this.positionFormat},
        settings);

      let analysis = await api.createAnalysis(data);

      if (analysis.assetType == assetTypes.servicing) {
        this.$router.push({ name: 'Servicing', params: { id: analysis.id }});
      } else if (analysis.runType == 'LoanDynamics') {
        this.$router.push({ name: 'Portfolio', params: { id: analysis.id }});
      } else {
        this.$router.push({ name: 'Inputs', params: { id: analysis.id }});
      }
    },
    clearDialog () {
      this.analysisName = null;
      this.enableHedging = false;
      this.selectedModule = this.moduleOptions[0].value;
      this.positionFormat = 'Loan';
      this.showModuleSelection = true;
    }
  },
  components: {
    CollapsibleOptions,
    ModalDialog
  },
  mixins: [validationState, assetPath]
}
</script>
<style scoped>
.module-logo {
  display: block;
  max-width: 100%;
  margin: 1rem 0;
}
.module-button {
  width: 90%;
  margin: 0.5rem auto;
  padding-left: 2rem;
}
.module-button img {
  margin-top: 1rem 0;
}
.module-button p {
  text-align: start;
}
</style>