<template>
  <div id="app">
    <app-header :theme="theme" />

    <router-view />

    <b-toast 
      :visible="isExporting"
      variant="info"
      toaster="b-toaster-bottom-right"
      title="Exports"
      append-toast
      no-auto-hide
      no-close-button
    >
      <div 
        v-for="exp in exportList" 
        :key="exp.analysisId"
      >        
        <div>
          <b-spinner
            v-if="!exp.exportError" 
            label="Exporting..."
            variant="info"
            small 
          />
          {{ exp.message }}
          <icon-button
            v-if="exp.exportError" 
            icon="times-circle"
            title="Remove Export"
            class="btn remove-export"
            size="lg"
            @click="removeExport(exp)"
          />
        </div>
      </div>
    </b-toast>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import IconButton from './components/IconButton.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import isElectron from 'is-electron'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import "@grapecity/wijmo.styles/wijmo.css";

export default {
  data () {
    return {
      themes: ['light-theme', 'dark-theme']
    }
  },
  created () {
    // set app title
    this.setAppTitle("Kinetics");
    
    // Set theme
    let theme = localStorage.getItem('kinetics_theme');

    if (this.themes.some(t => t == theme)) {
      this.setTheme(theme);
    }

    document.body.className = this.theme;
    document.body.style.cssText = `--top-offset: ${isElectron() ? '30px' : '0px'};`;
  },
  computed: {
    ...mapState(['theme', 'exportList']),
    ...mapGetters(['isExporting'])
  },
  methods: {
    ...mapMutations(['setTheme', 'setAppTitle', 'removeExport'])
  },
  watch: {
    theme (val) {
      document.body.className = val;
    }
  },
  components: {
    AppHeader,
    IconButton
  }
}
</script>


<style>
@import url('https://fonts.googleapis.com/css?family=Lato|Montserrat:400,600&display=swap');
@import url('App.css');

.dark-theme .wj-content {
    border-radius: 4px;
    background-color: #3b3f47;
    color: #fffffd;
}
.dark-theme .wj-cell.wj-header {
    background-color: #30333a;
    color: #fffffd;
}
.dark-theme .wj-cell:not(.wj-header):not(.wj-group):not(.wj-alt):not(.wj-state-selected):not(.wj-state-multi-selected) {
    background-color: #3b3f47;
    color: #fffffd;
}
.dark-theme .wj-alt:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
    background-color: #464b54;
    color: #fffffd;
}
</style>

<style scoped>
  .remove-export:hover {
      background-color: transparent;
  }
</style>
