<template>
  <tr>
    <td>
      <b-form-group
        :label-for="toggleScenarioId"
        :title="checkboxTitle"
      >
        <b-form-checkbox
          :checked="scenario.enabled"
          aria-label="Enable/Disable Scenario"
          :id="toggleScenarioId"
          class="scenario-checkbox"
          @change="toggleScenario($event)"
        />
      </b-form-group>
    </td>
    <td>
      <div class="scenario-title">
        <b-form
          inline
          @submit.prevent="save"
        >
          <b-form-group v-if="editName">
            <b-form-input
              v-model.trim="scenarioName"
              :placeholder="inputPlaceholder"
              :state="state($v.scenarioName)"
            />
            <b-form-invalid-feedback :state="state($v.scenarioName)">
              {{ validationMessage($v.scenarioName) }}
            </b-form-invalid-feedback>
          </b-form-group>
          <p 
            v-else
            v-b-tooltip.hover.bottom.v-light 
            class="mr-3 truncate-text" 
            @click="beginEdit" 
          >
            <span class="analysis-name-prefix" /> {{ scenario.scenarioName }}
          </p>
        
          <div v-if="!isDefaultScenario">
            <icon-button
              v-if="!editName"
              v-b-tooltip.hover.right.v-light
              icon="pen"
              title="Edit Scenario Name"
              icon-class="scenarios-icon"
              size="sm"
              @click="beginEdit"
            />
            <icon-button
              v-if="editName"
              v-b-tooltip.hover.bottom.v-light
              icon="check"
              title="Save Scenario Name"
              @click="save"
            />
            <icon-button
              v-if="editName"
              v-b-tooltip.hover.bottom.v-light
              icon="times"
              title="Cancel"
              @click="revert"
            />
          </div>
        </b-form>
      </div> 
    </td>
    <td>
      <icon-button
        v-if="!isDefaultScenario"
        v-b-tooltip.hover
        icon="sliders-h"
        title="Scenario Tuning"
        class="btn"
        icon-class="scenarios-icon"
        size="sm"
        @click="openTuningModal"
      />
      <icon-button
        v-if="!scenario.isDefaultScenario"
        v-b-tooltip.hover
        icon="trash-alt"
        title="Delete Scenario"
        class="btn remove-scenarios"
        icon-class="scenarios-icon"
        size="sm"
        @click="confirmDelete"
      />
    </td>
    <modal-dialog
      ref="confirmDeleteScenarioDialog"
      title="Delete Scenario"
      :prompt="`Are you sure you want to delete the '${ scenario.scenarioName }' scenario?`"
      :action="deleteScenario"
      error-message="An error occurred deleting the scenario."
      confirm-button-text="Delete"
    />
  </tr>
</template>

<script>

import IconButton from './IconButton.vue'
import ModalDialog from './ModalDialog.vue'
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '../api'
import { calcsToSettings } from '../api/calcsMapper'
import validationState from '../js/mixins/validationState'
import { required } from 'vuelidate/lib/validators'

export default {
   props: {
      scenario: Object,
      tuningClick: Function
  },
  data () {
    return {
      editName: false,
      scenarioName: '',
      showTuningScenarioDialog: false
    }
  },
   validations: {
    scenarioName: {
      required
    }
  },
  computed: {
  
    ...mapState(['activeAnalysis']),
    ...mapGetters(['calcs']),
    toggleScenarioId() {
      return `toggle-scenario-${this.scenario.scenarioId}`;
    },
     checkboxTitle() {
      return this.scenario.enabled ? "Uncheck to disable this scenario" : "Check to enable this scenario"
    },
    isDefaultScenario() {
      return this.scenario.id == null && this.scenario.scenarioName == 'AD&Co Default';
    },
    inputPlaceholder () {
      return `Enter a new name for the '${this.scenario.scenarioName}' scenario...`;
    }
  },
 
   methods: {
    ...mapActions(['saveAnalysisSettings', 'getScenarios']),

        beginEdit () {
        this.scenarioName = this.scenario.scenarioName;
        this.editName = true;
        },
        async save(){
          this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
          await api.updateScenario(this.activeAnalysis.id, this.scenario.scenarioId, {
          scenarioName: this.scenarioName,
          enabled: this.scenario.enabled
        });
        this.editName = false;
          await this.getScenarios();

        },
        revert(){
            this.scenarioName = this.scenario.scenarioName;
            this.editName = false;
        },
        confirmDelete(){
           this.$refs.confirmDeleteScenarioDialog.showDialog();
        },
        async deleteScenario(){
          await api.deleteScenario(this.activeAnalysis.id, this.scenario.scenarioId);
          await this.getScenarios();

        },
        openTuningModal(){
          this.$emit('tuningClick', this.scenario)
        },
        async toggleScenario (val) {
          if (this.isDefaultScenario) {
            let settings = calcsToSettings(this.calcs);
            settings.enableDefaultScenario = val ? true : false;
            
            await this.saveAnalysisSettings({
              id: this.activeAnalysis.id,
              data: settings
            });
          } else {
            if (this.scenario.scenarioId) {
              await api.updateScenario(this.activeAnalysis.id, this.scenario.scenarioId, {
                scenarioName: this.scenario.scenarioName,
                enabled: val
              });
            }
          }
          await this.getScenarios();
        }
    },
    components: {
    IconButton,
    ModalDialog,
  },
  mixins: [validationState]
}
</script>

<style scoped>
.scenario-title form{
    justify-content: flex-start;
}
</style>
