import { findIconDefinition, icon } from '@fortawesome/fontawesome-svg-core'

function attachGridHeaderEventHandler (grid, actions) {
  grid.hostElement.addEventListener("click", (e) => {
    let action, column, element;
    
    // Search for action attribute until we get to the grid cell
    for (let el of e.composedPath()) {
      if (!el.getAttribute) {
        continue;
      }
      
      action = el.getAttribute("data-action");
      
      if (action) {
        element = el;
        column = el.getAttribute("data-column");
        break;
      }

      // Stop search at grid cell
      if (el.classList.contains('wj-cell')) {
        return;
      }
    }

    if (action) {
      actions[action]({ column, element });
    }
  });
}

function createGridIconButton (prefix, iconName, action, column, title) {
  let btn = document.createElement('button');
  btn.type = "button";
  btn.className = "btn icon-button theme-item";
  btn.title = title;
  btn.setAttribute('data-action', action);
  btn.setAttribute('data-column', column);

  const iconDef = findIconDefinition({ prefix, iconName })
  const iconSvg = icon(iconDef);
  btn.appendChild(iconSvg.node[0]);
  return btn;
}

function createPointsLabel (text) {
  let lbl = document.createElement('span');
  lbl.className = 'points-name';
  lbl.innerText = text;
  lbl.title = text;

  return lbl;
}

// Be sure to bind "this" to the Points vue component
function gridHeaderFormatter (s, e) {
  if (e.panel == s.columnHeaders) {
    // Ignore preview column
    let penaltyPointSet = this.getColumnMetaData(e.col);
    if (!penaltyPointSet || penaltyPointSet.isDefault) return;

    // Add points label and grid controls
    if (e.row == 0) {
      let lbl = createPointsLabel(e.cell.innerText);
      e.cell.innerText = '';
      e.cell.appendChild(lbl);
      e.cell.appendChild(createGridIconButton('fas', 'pen', 'edit', e.col, 'Rename Custom Points'));
      e.cell.appendChild(createGridIconButton('fas', 'trash', 'delete', e.col, 'Delete Custom Points'));
    }
  }
};

export { attachGridHeaderEventHandler, gridHeaderFormatter }