<template>
  <scroll-container
    v-resize:debounce.100="resize"
    class="results-chart"
  >
    <div class="chart-area">
      <loading-indicator v-if="chartLoading" />
      <plotly
        v-else
        ref="plotlyChart"
        :data="chartData"
        :layout="chartLayout"
        :display-mode-bar="true"
      />
    </div>

    <section class="chart-options">
      <div class="chart-filters">
        <h2>Chart Options</h2>
        <div v-if="isCashflowResult"> 
          <h3>Filters</h3>
          <cash-flow-filters
            :is-cashflow-result="isCashflowResult"
            :available-cash-flows="availableCashFlows"
            :mc-path="mcPath"
            :set-cash-flow-file-selected="setCashFlowFileSelected"
            :calcs="calcs"
            :loan-id="loanIdChart"
            :path="pathChart"
            :cashflow-type="cashflowType"
          />
        </div>
        <div v-if="filters.length && !filtersLoading">
          <h3>Filters</h3>
          
          <div class="options-section">
            <div
              v-for="(filter, idx) in filters"
              :key="filter.name"
            >
              <label :for="createId(`multiselect-${filter.name}`)">{{ filter.name }}</label>
              <multiselect
                :id="createId(`multiselect-${filter.name}`)" 
                v-model="filters[idx].selected" 
                :options="filters[idx].values"
                :multiple="true"
                :max="10" 
                :close-on-select="false"
                :loading="filter.loading"
                open-direction="bottom"
                @open="toggleFilter"
                @close="toggleFilter"
                @input="loadData"
                @search-change="findFilterValues"
              />
            </div>
          </div>
        </div>
      </div>

      <scroll-container
        ref="chartOptionsContainer"
        v-if="!filtersLoading"
        :min-height="chartOptionsMinHeight"
      >
        <h3>Y-Axis</h3>
        
        <div class="options-section yaxis-section clearfix">
          <draggable
            v-model="yaxisFields"
            group="field"
            :sort="true"
          >
            <div
              v-for="item in yaxisFields"
              :key="item.fieldName"
              class="report-field"
            >
              <b-form-checkbox
                :checked="item.selected"
                @input="selectField(item.fieldName, yaxisFields, $event)"
              >
                {{ item.fieldName }}
              </b-form-checkbox>
              <font-awesome-icon
                icon="grip-vertical"
                class="ml-auto"
              />
            </div>
          </draggable>
        </div>
            
        <h3>Y2</h3>

        <div class="options-section yaxis-section clearfix">
          <draggable
            v-model="yaxis2Fields"
            group="field"
            :sort="true"
          >
            <div
              v-for="item in yaxis2Fields"
              :key="item.fieldName"
              class="report-field"
            >
              <b-form-checkbox
                :checked="item.selected"
                @input="selectField(item.fieldName, yaxis2Fields, $event)"
              >
                {{ item.fieldName }}
              </b-form-checkbox>
              <font-awesome-icon
                icon="grip-vertical"
                class="ml-auto"
              />
            </div>
          </draggable> 
        </div>
      </scroll-container>
    </section>
  </scroll-container>
</template>

<script>
import { Plotly } from './plotly'
import Draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
import createId from '../js/mixins/createId'
import resize from 'vue-resize-directive'
import { mapMutations, mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import LoadingIndicator from './LoadingIndicator.vue'
import ScrollContainer from './ScrollContainer.vue'
import CashFlowFilters from './CashFlowFilters.vue'

export default {
  props: {
    chartItem: { type: Object, required: true },
    fields: { type: Array },
    resultsTop: { type: Number, required: true },
    isCashflowResult:{type: Boolean},
    availableCashFlows:{type:Array},
    mcPath:{type: Number},
    setCashFlowFileSelected:{type: Function},
    setCashFlowError:{type: Function},
    calcs:{type: Object},
    loanIdChart:{type: String},
    pathChart:{type: String},
    cashflowType:{type: String}
  },
  data () {
    return {
      data: [],
      yaxisFields: [],
      yaxis2Fields: [],
      filters: [],
      chartLoading: true,
      filtersLoading: true
    }
  },
  async mounted () {
    await this.initOptions();
  },
  computed: {
    ...mapState(['activeAnalysis', 'selectedFields', 'theme']),
    ...mapGetters(['selectedFilters']),

    chartLayout () {
      let chartHeight = window.innerHeight - this.resultsTop - 20;
      
      let layout = {
        title: this.chartItem.chartOptions && this.chartItem.chartOptions.title || null,
        height: chartHeight,
        showlegend: true,
				legend: {
					orientation: "h",
          y: -0.2
        },
        // xaxis: {
        //   rangeslider: {
        //     thickness: 0.1
        //   }
        // },
        // yaxis: {
        //   fixedrange: false
        // }
      }

      if (this.yaxis2Fields.some(f => f.selected)) {
        layout.yaxis2 = {
          visible: true,
          overlaying: 'y',
          side: 'right'
        }
      }

      if (this.theme == 'dark-theme') {
        layout.paper_bgcolor = "#232529";
        layout.plot_bgcolor = "#232529";
        layout.font = {
          color: "#fffffd"
        };
        layout.xaxis = {
          gridcolor: "#36383b"
        };
        layout.yaxis = {
          gridcolor: "#36383b"
        };
      }
      
      return layout;
    },
    chartData () {
      const data = this.data.value ? this.data.value : this.data;
      const filteredData = data.filter(d => {
        // Filter out unselected fields
        return this.yaxisFields.some(f => f.fieldName == d.fieldName && f.selected) ||
          this.yaxis2Fields.some(f => f.fieldName == d.fieldName && f.selected)
      }).map(d => {
        // Map the correct yaxis
        d.yaxis = this.yaxis2Fields.some(f => f.fieldName == d.fieldName) ? 'y2' : 'y';

        return d;
      });
      
      return filteredData;
    },
    chartOptionsMinHeight () {
      return this.filters.length ? 150 : 250;
    }
  },
  methods: {
    ...mapMutations(['storeSelectedFilters', 'setFieldSelection']),

    selectField (fieldName, fields, selected) {
      let idx = fields.findIndex(f => f.fieldName == fieldName);
      let fld = fields[idx];
      fld.selected = selected;
      this.$set(fields, idx, fld);

      // Store selection
      let axis = this.yaxis2Fields.some(f => f.fieldName == fieldName) ? 'y2' : 'y';
      this.setFieldSelection({analysisId: this.activeAnalysis.id, fieldName, axis, selected});
    },
    getAxisFields (selectedFields, axis) {
      let fields = [];
      this.chartItem.chartOptions.fields.forEach(d => {
        let defAxis = d.defaultAxis || 'y';
        let sel = selectedFields.find(f => f.fieldName == d.fieldName);

        if (sel && sel.axis == axis) {
          // add to axis if selected
          fields.push({ fieldName: sel.fieldName, selected: true });
        } else if (axis == defAxis) {
          // add to axis if default
          fields.push({ fieldName: d.fieldName, selected: false });
        }
      });

      return fields;
    },
    async initOptions () {
      // Init field arrays
      if (this.chartItem.chartOptions && this.chartItem.chartOptions.fields) {

        if (!this.selectedFields[this.activeAnalysis.id]) {
          // Load pre-selected fields
          this.chartItem.chartOptions.fields.filter(f => f.selected).forEach(f => {
            this.setFieldSelection({analysisId: this.activeAnalysis.id, fieldName: f.fieldName, axis: f.defaultAxis || 'y', selected: true});
          });
        }
        let selectedFields = this.selectedFields[this.activeAnalysis.id] || [];
        this.yaxisFields = this.getAxisFields(selectedFields, 'y');
        this.yaxis2Fields = this.getAxisFields(selectedFields, 'y2');
      }
      
      // Init filters
      this.filters = [];
      if (this.chartItem.chartOptions && this.chartItem.chartOptions.filters) {
        for (let f of this.chartItem.chartOptions.filters) {
          let values = await f.getOptions(this.activeAnalysis.id, '');
          let selectedFilters = this.selectedFilters[this.activeAnalysis.id];
          let selectedFilter = selectedFilters &&
            selectedFilters.find(selectedFilter => selectedFilter.name === f.name);
          let selected = selectedFilter ? selectedFilter.selected : f.preselect ? values.slice(0, f.preselect) : [];
          selected = selected.filter(s => values.includes(s));

          if (selected.length === 0 && values.length > 0) selected.push(values[0])

          if (!this.filters.find(i => i.name == f.name)) {
            this.filters.push({
              name: f.name,
              prop: f.prop,
              selected, 
              values,
              loading: false
            });
          }
        }
      }

      this.filtersLoading = false;

      await this.loadData();
    },
    async loadData () {
      this.chartLoading = true;
      
      // If no loans are selected return empty data set
      if (this.filters.length && this.filters.some(f => !f.selected.length)) {
        this.data = [];
      } else {
        // Store filters selections
        let selectedFilters = this.filters.filter(f => f.selected);
        this.storeSelectedFilters({analysisId: this.activeAnalysis.id, filters: selectedFilters});

        // Load the chart data
        let filter = this.filters.filter(f => f.selected.length).map(f => `(${f.selected.map(s => `${f.prop} eq '${s}'`).join(' or ')})`);
        let data = await this.chartItem.getData(this.activeAnalysis, { params: { '$filter': filter.join(' and ') } });
        
        for (let prop of data) {
          let field = this.fields.find(f => f.key == prop.key);
          if (field != null && field.formatter != null)
            prop.y = prop.y.map(t => field.formatter.apply(this, [t, field.format]));
        }

        this.data = data;

        this.setCashFlowError(this.chartItem.errorInCashFlow, this.chartItem.errorInCashFlowMessage)
     }
      
      this.chartLoading = false;
    },
    calculateChartHeight () {
      return Math.max(window.innerHeight - this.resultsTop - 20, 300);
    },
    resize () {
      // Adjust height based on window height
      if (this.$refs.plotlyChart) {
        let height = this.calculateChartHeight();
        let widthOffset = height > 300 ? 0 : 15;
        let width = window.innerWidth >= 992 ? window.innerWidth - 410 - widthOffset : window.innerWidth - 60 - widthOffset;

        this.$refs.plotlyChart.relayout({
          height,
          width
        });
      }
    },
    toggleFilter () {
      this.$nextTick(() => {
        this.$refs.chartOptionsContainer.resize();
      });
    },
    setFilterLoading(filterName) {
      let filter = this.filters.find(f => f.name === filterName);

      if (filter) {
        filter.loading = true;
      }
    },
    debounceLoanIdSearch: debounce(async function (chartFilters, filters, id, query) {
      let loanIdFilter = chartFilters.find(f => f.name === "Loan ID");
      let filter = filters.find(f => f.name === "Loan ID");
      filter.values = await loanIdFilter.getOptions(id, query);
      filter.loading = false;
    }, 500),
    async findFilterValues (query, filterName) {
      switch (filterName) {
        case "multiselect-loan-id":
          this.setFilterLoading("Loan ID");
          this.debounceLoanIdSearch(
            this.chartItem.chartOptions.filters,
            this.filters,
            this.activeAnalysis.id,
            query
          );
          break;
        default:
          break;
      }
    }
  },
  watch: {
    async chartItem () {
      if (this.filtersLoading) {
        // Loading already in progress
        return;
      }

      this.filtersLoading = true;
      await this.initOptions();
      this.filtersLoading = false;

      this.resize();
      this.$refs.chartOptionsContainer.resize();
    },
  },
  components: {
    Plotly,
    Draggable,
    Multiselect,
    LoadingIndicator,
    ScrollContainer,
    CashFlowFilters 
  },
  directives: {
    resize
  },
  mixins: [createId]
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

.chart-area {
  position: relative;
}

.chart-options {
  margin-bottom: 15px;
}

.options-section {
  padding-left: 5px;
  margin-bottom: 15px;
}

.remove-icon {
  color: red;
  cursor: pointer;
}

h2 {
  font-size: 1.1em;
}

h3 {
  font-size: 0.9em;
  font-weight: bold;
}

a {
  font-size: 0.9em;
}

.report-field {
  display: flex;
  align-items: center;
  cursor: grab;
  margin-bottom: 5px;
  padding: 0 5px;
}

.report-field svg {
  color: rgb(214, 214, 214);
}

.report-field:hover {
  background-color: #eff1f7;
}

@media (min-width: 992px) {
  .results-chart {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
  }

  .chart-area {
    flex: 1;
  }

  .chart-options {
    flex: 0 0 350px;
    margin-left: 15px;
    /* max-height: calc(100vh - var(--results-top) - 20px); */
  }
}

</style>

<style>
  .report-field .custom-checkbox {
    display: inline-block;
    width: 90%;
  }
</style>