<template>
  <div>
    <loading-indicator v-if="editorLoading" />
    <flex-grid-editor
      v-else 
      :id="`editor-${analysisId}`"
      :collection="odataPath"
      :entity-type="entityType"
      :columns="gridColumns"
      :data-maps="gridDataMaps"
      :grid-options="gridOptions"
      :title="gridOptions.title"
      :item-creator="itemCreator"
      @close="close"
    />
  </div>
</template>

<script>
import FlexGridEditor from './FlexGridEditor.vue'
import LoadingIndicator from './LoadingIndicator.vue'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  props: {
    analysisId: { type: [Number, String], required: true },
    route: { type: String, required: true }
  },
  data () {
    return {
      editorLoading: true,
      gridOptions: null,
      open: false,
      visible: false
    }
  },
  async mounted () {
    await this.loadAnalysis(this.analysisId);

    if (!this.gridRoutes[this.route]) {
      await this.getInputsList();
    }
    
    this.gridOptions = this.gridRoutes[this.route];
    this.editorLoading = false;
  },
  computed: {
    ...mapState(['activeAnalysis']),
    ...mapGetters(['gridRoutes']),

    gridColumns () {
      if (this.gridOptions.columns instanceof Function) {
        return this.gridOptions.columns(this.activeAnalysis);
      } else {
        return this.gridOptions.columns;
      }
    },

    gridDataMaps () {
      if (this.gridOptions.dataMaps instanceof Function) {
        return this.gridOptions.dataMaps(this.activeAnalysis);
      } else {
        return this.gridOptions.dataMaps;
      }
    },

    odataPath () {
      let entitySet = this.gridOptions.entitySet instanceof Function ? 
        this.gridOptions.entitySet(this.activeAnalysis) : 
        this.gridOptions.entitySet;

      return `Analyses(${this.analysisId})/${entitySet}`;
    },

    entityType () {
      return this.gridOptions.entityType instanceof Function ? 
        this.gridOptions.entityType(this.activeAnalysis) : 
        this.gridOptions.entityType;
    },

    itemCreator () {
      return this.gridOptions.itemCreator instanceof Function ? 
        this.gridOptions.itemCreator(this.activeAnalysis) : 
        null;
    }
  },
  methods: {
    ...mapActions(['loadAnalysis', 'getInputsList']),

    close () {
      if (this.route == 'UnemploymentForecast') this.$router.push(`/edit/${this.analysisId}/scenarios`);
      else this.$router.push(`/edit/${this.analysisId}`);
    }
  },
  components: {
    FlexGridEditor,
    LoadingIndicator
  }
}
</script>

<style scoped>

</style>
