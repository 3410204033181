<template>
  <modal-dialog 
    title="Import Scenarios" 
    confirm-button-text="Import" 
    :value="value" 
    :action="addForecast"
    :hide-errors="true"
    @input="$emit('input', $event)" 
    @clear="clearDialog"
    :dialog-size="dialogSize"
    :ok-disabled="!!message"
  >
    <b-alert
      class="mt-3"
      variant="danger"
      :show="!!message"
    >
      {{ message }}
    </b-alert>

    <b-form-group>
      <b-form-file
        id="forecast-file"
        v-model="forecastFile"
        class="mt-3"
        placeholder="Choose a forecast file or drop it here..."
        drop-placeholder="Drop forecast file here..."
        :state="state($v.forecastFile)"
      />
      <b-form-invalid-feedback :state="state($v.forecastFile)">
        {{ validationMessage($v.forecastFile) }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group>
      <b-button
        v-b-tooltip.hover.bottom
        :title="fileLayoutText"
        @click="toggleFileLayout"
      >
        {{ fileLayoutText }}
      </b-button>
      <file-layout
        v-if="showFileLayout"
        :file="file"
      />
    </b-form-group>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import FileLayout from './FileLayout.vue'
import validationState from '../js/mixins/validationState'
import getErrorMessage from '../js/getErrorMessage'
import api from '../api'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'

export default {
  props:{
    value: { type: Boolean },
    file: { type: Object }
  },
  data () {
    return {
      forecastType: '',
      forecastFile: null,
      message: null,
      showFileLayout: false,
      fileLayout: [],
    }
  },
  computed: {
    ...mapState(['activeAnalysis', 'scenarios']),
    dialogSize () {
      if (this.showFileLayout) return 'lg';
      return 'md';
    },
    fileLayoutText () {
      if (this.showFileLayout) return 'Hide File Layout';
      return 'Show File Layout';
    }
  },
  validations() {
    return {
      forecastFile: { required }
    }
  },
  methods: {
    ...mapMutations(['setSavePending', 'setSaving', 'doneSaving', 'errorSaving']),
    async addForecast () {

      // Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.setSavePending();
      try {
        this.setSaving();
        await api.postFile(`analyses/${this.activeAnalysis.id}/scenarios/import`, this.forecastFile, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.$emit('scenarios-updated');
        this.doneSaving();
      } catch (err) {
        this.errorSaving();
        this.message = getErrorMessage(err, 'Error importing forecast');
        this.$v.reset();
      }
    },
    async toggleFileLayout () {
      this.showFileLayout = !this.showFileLayout;
    },
    clearDialog () {
      this.forecastFile = null;
      this.message = null;
      this.showFileLayout = false;
      this.$v.$reset();
    },
    clearErrorMessages () {
      this.message = null;
    }
  },
  watch: {
    forecastFile() {
      this.clearErrorMessages();
    }
  },
  components: {
    ModalDialog,
    FileLayout
  },
  mixins: [validationState]
}
</script>