<template>
  <div class="dialog-toolbar">
    <b-button-toolbar aria-label="Editor Toolbar">
      <slot />
    </b-button-toolbar>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.dialog-toolbar {
  padding: 5px 0;
  background-color: #276681;
}

.dialog-toolbar .btn-group {
  margin-left: 1rem;
  margin-right: 1rem;
}
</style>