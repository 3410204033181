<template>
  <div>
    <b-alert
      class="ml-3 mr-3"
      :show="!tabValid('Servicing')"
      variant="danger"
    >
      Please fix the validation errors shown below
    </b-alert>
    <b-alert
      class="ml-3 mr-3"
      :show="!!errorMessage"
      variant="danger"
    >
      {{ errorMessage }}
    </b-alert>

    <loading-indicator v-if="loading" />
    <div
      v-else-if="!errorMessage"
      v-disable-all="analysisRunning"
    >
      <b-navbar
        toggleable="md"
        class="ml-3 mr-3"
      >
        <b-navbar-nav>
          <b-nav-text class="mr-5">
            <strong>Servicing Assumption Sets</strong>
          </b-nav-text>
        </b-navbar-nav>
        <b-navbar-toggle target="msr-collapse" />

        <b-collapse
          id="msr-collapse"
          is-nav
        >
          <b-navbar-nav v-if="assumptions.length">
            <b-nav-text v-show="!gridView">
              Select an assumption set to view:
            </b-nav-text>
            <b-nav-item-dropdown
              v-show="!gridView"
              id="assumption-dropdown"
              :text="selectedAssumption.name"
            >
              <b-dropdown-item
                v-for="assumption in assumptions"
                :key="assumption.name"
                @click="selectedAssumption = assumption"
              >
                {{ assumption.name }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="gridView = true">
                Show all in grid view
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav
            id="msr-actions-icons"
            class="ml-auto"
          >
            <b-nav-item
              v-show="!gridView"
              :disabled="analysisRunning"
              @click="showModal('new-assumption-modal')"
            >
              <b-button
                variant="outline-secondary"
              >
                <font-awesome-icon :icon="['far','plus-square']" />
                New
              </b-button>
            </b-nav-item>
            <b-nav-item
              v-show="!gridView && assumptions.length"
              :disabled="analysisRunning"
              @click="showRenameModal('rename-assumption-modal')"
            >
              <b-button
                variant="outline-secondary"
              >
                <font-awesome-icon icon="file-signature" />
                Rename
              </b-button>
            </b-nav-item>
            <b-nav-item
              v-show="!gridView && assumptions.length"
              :disabled="analysisRunning"
              @click="showModal('clone-assumption-modal')"
            >
              <b-button
                variant="outline-secondary"
              >
                <font-awesome-icon :icon="['far','clone']" />
                Duplicate
              </b-button>
            </b-nav-item>
            <b-nav-item
              v-show="!gridView && assumptions.length"
              :disabled="analysisRunning"
              @click="showModal('delete-assumption-modal')"
            >
              <b-button
                variant="outline-secondary"
              >
                <font-awesome-icon :icon="['far','trash-alt']" />
                Delete
              </b-button>
            </b-nav-item>
            <b-nav-item
              v-show="assumptions.length"
              @click="toggleView"
            >
              <b-button
                variant="outline-secondary"
              >
                <font-awesome-icon :icon="gridView ? 'columns' : 'table' " />
                {{ gridView ? 'Form' : 'Grid' }} View
              </b-button>
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav
            id="msr-actions-dropdown"
            class="ml-auto"
          >
            <b-nav-item-dropdown
              text="Actions"
              :disabled="analysisRunning"
              right
            >
              <b-dropdown-item
                v-show="!gridView"
                @click="showModal('new-assumption-modal')"
              >
                <font-awesome-icon :icon="['far','plus-square']" />New
              </b-dropdown-item>
              <b-dropdown-item
                v-show="!gridView && assumptions.length"
                @click="showRenameModal('rename-assumption-modal')"
              >
                <font-awesome-icon icon="file-signature" />Rename
              </b-dropdown-item>
              <b-dropdown-item
                v-show="!gridView && assumptions.length"
                @click="showModal('clone-assumption-modal')"
              >
                <font-awesome-icon :icon="['far','clone']" />Duplicate
              </b-dropdown-item>
              <b-dropdown-item
                v-show="!gridView && assumptions.length"
                @click="showModal('delete-assumption-modal')"
              >
                <font-awesome-icon :icon="['far','trash-alt']" />Delete
              </b-dropdown-item>
              <b-dropdown-item
                v-show="assumptions.length"
                @click="gridView = !gridView"
              >
                <font-awesome-icon :icon="gridView ? 'columns' : 'table' " />{{ gridView ? 'Form' : 'Grid' }} View
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-alert
        class="ml-3 mr-3"
        :show="!assumptions.length && !isDefaults"
        variant="warning"
      >
        There are currently no servicing assumption sets to show here. You can 
        set default assumptions in <router-link to="/settings/servicing">
          settings
        </router-link> or <b-link @click="showModal('new-assumption-modal')">
          create
        </b-link> one for this analysis.
      </b-alert>
      <b-alert
        class="ml-3 mr-3"
        :show="!assumptions.length && isDefaults"
        variant="warning"
      >
        There are currently no servicing defaults to show here, but you can 
        <b-link @click="showModal('new-assumption-modal')">
          create
        </b-link> one.
      </b-alert>

      <scroll-container>
        <msr-assumptions-form-editor
          v-if="!gridView && assumptions.length"
          ref="assumptionsForm"
          v-bind.sync="selectedAssumption"
          :v="activeValidator"
        />
        <msr-assumptions-grid-editor
          v-else-if="assumptions.length"
          :assumptions="assumptions"
          @row-edit-ended="gridSave"
          @delete-rows="gridDelete"
        />
      </scroll-container>
    </div>
    
    <b-modal
      id="new-assumption-modal"
      title="New Servicing Assumptions"
      ok-title="Create"
      no-close-on-backdrop
      @ok="createSet"
    >
      <b-form-group
        label="Enter Name"
        label-for="new-set-name"
      >
        <b-form-input
          id="new-set-name"
          v-model.trim="$v.setName.$model"
          :state="state($v.setName)"
          autocomplete="off"
        />
        <b-form-invalid-feedback :state="state($v.setName)">
          {{ validationMessage($v.setName) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>

    <b-modal
      id="rename-assumption-modal"
      title="Rename Servicing Assumptions"
      ok-title="Rename"
      no-close-on-backdrop
      @ok="renameSet"
    >
      <b-form-group
        label="Enter New Name"
        label-for="new-name"
      >
        <b-form-input
          id="new-name"
          ref="renameInput"
          v-model.trim="$v.setName.$model"
          :state="state($v.setName)"
        />
        <b-form-invalid-feedback :state="state($v.setName)">
          {{ validationMessage($v.setName) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>

    <b-modal
      id="clone-assumption-modal"
      title="Duplicate Servicing Assumptions"
      ok-title="Clone"
      no-close-on-backdrop
      @ok="cloneSet"
    >
      <b-form-group
        label="Enter Name"
        label-for="clone-name"
      >
        <b-form-input
          id="clone-name"
          v-model.trim="$v.setName.$model"
          :state="state($v.setName)"
          autocomplete="off"
        />
        <b-form-invalid-feedback :state="state($v.setName)">
          {{ validationMessage($v.setName) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>

    <b-modal
      id="delete-assumption-modal"
      title="Delete Servicing Assumptions"
      ok-title="Delete"
      no-close-on-backdrop
      @ok="deleteSet"
    >
      Are you sure you want to delete the <strong>{{ selectedAssumption ? selectedAssumption.name : '' }}</strong> servicing assumptions set?
    </b-modal>
  </div>
</template>

<script>
import MsrAssumptionsFormEditor from './MsrAssumptionsFormEditor.vue'
import MsrAssumptionsGridEditor from './MsrAssumptionsGridEditor.vue'
import LoadingIndicator from './LoadingIndicator.vue'
import ScrollContainer from './ScrollContainer.vue'
import modalAutoFocus from '../js/mixins/modalAutoFocus'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import { validations } from '../js/validation/msrAssumptionValidations'
import { required } from 'vuelidate/lib/validators'
import { exists } from '../js/validation/validators'
import validationState from '../js/mixins/validationState'
import api from '../api'
import { msrDefaultValues } from '../js/calcs'

const defaultLoader = async (analysisId) => {
  let data = await api.loadMsrAssumptions(analysisId);
  return data;
}

export default {
  props: {
    id: { type: [String, Number], required: false },
    isDefaults: { type: Boolean, default: false },
    loadAssumptions: { type: Function, default: defaultLoader }
  },
  data () {
    return {
      assumptions: [],
      selectedAssumption: null,
      setName: null,
      gridView: false,
      loading: true,
      errorMessage: null,
      creatingOrDeleting: false
    }
  },
  async mounted () {
    try {
      this.assumptions = await this.loadAssumptions(this.id);

      if (this.assumptions && this.assumptions.length) {
        this.selectedAssumption = this.assumptions[0];
      }

      this.$nextTick(() => { this.loading = false; })
    } catch (err)  {
      this.loading = false;
      this.errorMessage = 'Error loading Servicing Assumptions';
      // eslint-disable-next-line
      console.error(err);
    }
  },
  computed: {
    ...mapState(['isValidating', 'activeAnalysis']),
    ...mapGetters(['tabValid', 'analysisRunning']),

    activeValidator () {
      let idx = this.assumptions.findIndex(a => a.name == this.selectedAssumption.name)
      return this.$v.assumptions.$each[idx];
    }
  },
  validations: {
    assumptions: {
      $each: {
        ...validations
      }
    },
    setName: {
      required,
      exists: exists('name', 'assumptions')
    }
  },
  methods: {
    ...mapMutations(['addValidationStatus', 'setAnalysisModified']),
    ...mapActions(['autoSave', 'updateAnalysis']),
    
    createSet (evt) {
      this.$v.setName.$touch();

      if (this.$v.setName.$invalid) {
        evt.preventDefault();
        return;
      }

      // Add new assumption set to array
      this.creatingOrDeleting = true;
      let assumption = Object.assign({}, msrDefaultValues);
      assumption.name = this.setName;
      this.assumptions.push(assumption);
      this.selectedAssumption = assumption;

      // Emit event to create assumption set
      this.$emit('create', { isValid: !this.$v.assumptions.$invalid, selectedAssumption: this.selectedAssumption });
      this.$nextTick(() => this.creatingOrDeleting = false);
    },
    cloneSet (evt) {
      this.$v.setName.$touch();

      if (this.$v.setName.$invalid) {
        evt.preventDefault();
        return;
      }

      // Create a copy of the selected set
      this.creatingOrDeleting = true;
      let assumption = Object.assign({}, this.selectedAssumption);
      assumption.name = this.setName;
      this.assumptions.push(assumption);
      this.selectedAssumption = assumption;

      // Emit event to create assumption set
      this.$emit('create', { isValid: !this.$v.assumptions.$invalid, selectedAssumption: this.selectedAssumption });
      this.$nextTick(() => this.creatingOrDeleting = false);
    },
    deleteSet () {
      // Emit event to delete the assumption set
      this.creatingOrDeleting = true;
      this.$emit('delete', { isValid: !this.$v.assumptions.$invalid, selectedAssumption: this.selectedAssumption });

      // Remove from assumption sets array
      let idx = this.assumptions.findIndex(a => a.name == this.selectedAssumption.name);
      this.assumptions.splice(idx, 1);
      this.selectedAssumption = this.assumptions[0];
      this.$nextTick(() => this.creatingOrDeleting = false);
    },
    showModal (modalName) {
      this.setName = '';
      this.$v.setName.$reset();
      this.$bvModal.show(modalName);
    },
    showRenameModal () {
      this.setName = this.selectedAssumption.name;
      this.$bvModal.show('rename-assumption-modal');
    },
    renameSet (evt) {
      this.$v.setName.$touch();

      if (this.$v.setName.$invalid) {
        evt.preventDefault();
        return;
      }

      this.selectedAssumption.name = this.setName;
    },
    toggleView () {
      this.gridView = !this.gridView;

      // Check if selected assumption still exists
      if (!this.assumptions.some(a => a.name == this.selectedAssumption.name)) {
        this.selectedAssumption = this.assumptions[0];
      }
    },
    gridSave () {
      this.$emit('create-update-sets', {
          isValid: !this.$v.assumptions.$invalid, 
          servicingSettings: this.assumptions , 
          onSave: (newData) => {
            this.assumptions = newData.servicingSettings;
          }
      });
    },
    gridDelete (rowArgs) {
      // Delete all selected rows
      for (let i = rowArgs.startIndex; i <= rowArgs.endIndex; i++) {
        this.selectedAssumption = this.assumptions[i];
        this.deleteSet();
      }
    }
  },
  watch: {
    isValidating(val) {
      if (val === true) {
        let firstInvalid = null
        this.$v.$touch();

        // Validate each msr assumption set
        for (let a of Object.values(this.$v.assumptions.$each.$iter)) {
          this.addValidationStatus({
            tab: 'Servicing',
            item: a.$model.name,
            isValid: !a.$invalid
          })

          if (firstInvalid == null && a.$invalid) {
            firstInvalid = a.$model;
          }
        }

        // select first invalid set
        if (firstInvalid) {
          this.selectedAssumption = firstInvalid;
        }
      }
    },
    assumptions: {
      deep: true,
      handler (assumptions) {
        if (!this.loading) {
          // Don't save if editing msr defaults
          if (!this.isDefaults) {
            this.setAnalysisModified();
            this.autoSave({ saveAction: 'saveMsrAssumptions', isValid: !this.$v.assumptions.$invalid, id: this.activeAnalysis.id, data: assumptions});
          } else if (!this.gridView && !this.creatingOrDeleting) {
            this.$emit('update', { isValid: !this.$v.assumptions.$invalid, selectedAssumption: this.selectedAssumption });
          }
        }
      }
    }
  },
  components: {
    MsrAssumptionsFormEditor,
    MsrAssumptionsGridEditor,
    LoadingIndicator,
    ScrollContainer
  },
  mixins: [modalAutoFocus,validationState]
}
</script>

<style scoped>

#msr-actions-icons {
  display: none;
}

#msr-actions-icons > .nav-item > a:not(.disabled):hover {
  background-color: transparent;
}

@media (min-width: 1200px) {
  #msr-actions-dropdown {
    display: none;
  }
  #msr-actions-icons {
    display: flex;
  }
}
</style>