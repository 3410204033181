import ResultItem, { resultTypes } from './ResultItem'
import api from '../../api'

async function getTableData (analysis, options) {
  let data = await api.odataQuery(analysis.id, this.collectionName, options.params);
  console.log(data)
  if (!data.value) {
    return [];
  }

  // Update to WAL in years
  data.value.forEach(r => {
    r.wal = r.wal / 12;
  });

  return data;
}


async function getTableDataFromFiles (analysis, options) {
  let data = await api.getResults(analysis.id, this.collectionName, 1000);

  if (!data) {
    return [];
  }

  return data;
}

async function loadLdmReportColumns(analysisId, resultItem) {
  let cols = await api.getReportFields(resultItem.id, analysisId);
  resultItem.columns = () => 
    cols.filter(c => c.selected && c.enabled).map(c => ({ 
      header: c.label, 
      binding: c.name, 
      format: c.format, 
      class: c.class ? c.class : c.alignment == 'right' ? 'text-right' : '', 
      visible: c.visible === false ? false : true
    })).filter(c => c.visible);
    resultItem.columnsLoaded = true;
}

export default function createLdmLifetimeResultItem(reportId, analysisId, displayType, cols, importResultsEnabled=true) {
  return new ResultItem ({
    id: reportId,
    name: "Lifetime Results",
    loadColumns: async function (options) {
      await loadLdmReportColumns(analysisId, this)
    },
    resultType: resultTypes.custom,
    default: false,
    displayType: displayType || 'table',
    reportLevel: 0,
    collectionName: 'LdmLifetimeResults',
    getData: importResultsEnabled ? getTableData : getTableDataFromFiles
  });
};