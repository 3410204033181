// Directive to diable all input fields for a Vue component

let toggleDisabled = (el, disabled) => {
  const tags = ["input", "button", "textarea", "select"];
  tags.forEach(tagName => {
    const nodes = el.getElementsByTagName(tagName);
    for (let i = 0; i < nodes.length; i++) {

      let wasDisabled = false;
      
      if (disabled && nodes[i].disabled) {
        // If the node is already disabled we set the data-was-disabled attribute
        nodes[i].setAttribute('data-was-disabled', '')
      } else if (!disabled && nodes[i].hasAttribute('data-was-disabled')) {
        // If the data-was-disabled is set we should leave the node disabled
        nodes[i].removeAttribute('data-was-disabled');
        wasDisabled = true;
      }

      // Enabled/disabled node as long as it wasn't originally disabled
      if (!wasDisabled) {
        nodes[i].disabled = disabled;
      }
      
    }
  });
}

export default {
  bind: function(el, binding) {
    if (binding.value) 
      toggleDisabled(el, binding.value);
  },
  componentUpdated: function(el, binding) {
    if (binding.value == binding.oldValue) 
      return;

    toggleDisabled(el, binding.value);
  }
};