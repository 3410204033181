<template>
  <div>
    <section>
      <h2>License Information</h2>

      <b-container 
        class="settings-section"
        fluid
      >
        <b-row>
          <b-col lg>
            <p>Modules</p>
            <div
              v-for="m in licenseInfo.modules" 
              :key="m.name"
            >
              <p class="license-name">
                <strong>{{ m.name }}</strong>
              </p>
              <p>Expiration: {{ m.licenseExpiration | formatShortDate }}</p>
            </div>
          </b-col>
          <b-col lg>
            <p>Models</p>
            <div
              v-for="m in licenseInfo.models" 
              :key="m.name"
            >
              <p class="license-name">
                <strong>{{ m.name }}</strong>
              </p>
              <p>Expiration: {{ m.licenseExpiration | formatShortDate }}</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section>
      <h2>Update License Key</h2>
      <div class="settings-section">
        <license-key-updater @lic-key-updated="getVersions()" />
      </div>
    </section>

    <section>
      <h2>Update AD&amp;Co Data Files</h2>

      <div class="settings-section">
        <data-files-updater />
      </div>
    </section>

    <section
      v-if="hasDefaultDefinitionOptions"
    >
      <h2>General Settings</h2>

      <b-container 
        class="settings-section"
        fluid
      >
        <b-row>
          <b-col lg>
            <b-form-group
              label="Default Definition for New Analyses"
              label-for="default-definition"
              title="Set termination condition for delinquent loans"
            >
              <b-select
                id="default-definition"
                style="max-width: 300px;"
                v-model="userSettings.defaultDefinition"
                :options="defaultDefinitionOptionsWithNull"
                size="sm"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col lg>
            <b-form-group
              label="Default Hedge Summary Type for New Analyses"
              label-for="hedge-averaging-type"
              title="Determines how loans are averaged with hedges"
            >
              <span
                v-b-tooltip.hover="{ customClass: 'tooltip-wide' }"
                v-for="opt in hedgeAveragingOptions"
                :key="opt.value"
                :title="opt.title"
              >
                <b-form-radio
                  v-model="userSettings.defaultHedgeAveragingType"
                  :value="opt.value"
                  inline
                >
                  {{ opt.text }}
                </b-form-radio>
              </span>
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-container>
    </section>

    <section v-if="isAdmin">
      <h2>Email Settings</h2>

      <div class="settings-section">
        <settings-email />
      </div>
    </section>

    <section>
      <h2>About</h2>

      <b-container 
        class="settings-section"
        fluid
      >
        <b-row>
          <b-col lg>
            <div>
              <p><strong>Kinetics Version:</strong> {{ applicationVersion }}</p>
              <div
                v-for="(module) in modules"
                :key="module.id"
              >
                <p>
                  <strong>{{ module.name }} LDM Version:</strong> {{ module.ldmVersion }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import SettingsEmail from './SettingsEmail.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import LicenseKeyUpdater from './LicenseKeyUpdater.vue'
import DataFilesUpdater from './DataFilesUpdater.vue'
import formatters from '../js/formatters'
import { hedgeAveragingOptions } from '../js/options/hedgeAveraging'
import { defaultDefinitionOptions } from '../js/options/defaultDefinition'
import api from '../api'

export default {
  data () {
    return {
      loading: true,
      emailSettings: {},
      error: false,
      message: false,
      sendingEmail: false,
      emailMessage: false,
      hedgeAveragingOptions,
      defaultDefinitionOptions,
      defaultDefinitionOptionsWithNull: [],
      userSettings: {
        defaultHedgeAveragingType: 0,
        defaultDefinition: 3
      },
      applicationVersion: '',
      modules: []
    }
  },
  async mounted () {
    this.userSettings = await api.getUserSettings();
    this.defaultDefinitionOptionsWithNull = [...defaultDefinitionOptions];
    this.defaultDefinitionOptionsWithNull.unshift({ value: null, text: "No setting (Kinetics selects)" });
    this.getVersions();
    this.$nextTick(() =>
    {
      this.loading = false;
    });
  },
  computed: {
    ...mapState(['licenseInfo']),
    ...mapGetters(['isAdmin', 'hasDefaultDefinitionOptions'])
  },
  methods: {
    ...mapActions(['autoSave']),
    async getVersions() {
      let resp = await api.getApplicationVersion();
      this.applicationVersion = resp.applicationVersion;
      this.modules = resp.modules;
    }
  },
  watch: {
    userSettings: {
      deep: true,
      handler () {
        if (!this.loading) {
          this.autoSave({ saveAction: 'saveUserSettings', isValid: true, id: null, data: this.userSettings});
        }
      }
    }
  },
  components: {
    SettingsEmail,
    LicenseKeyUpdater,
    DataFilesUpdater
  },
  mixins: [formatters]
}
</script>

<style scoped>
.settings-section {
  max-width: 1200px;
  margin-left: 0;
  padding-left: 15px;
}
.license-name {
  margin-bottom: 0;
}
</style>