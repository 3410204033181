// Try to find error message in an error response
export default function getErrorMessage (error, defaultMessage) {
  console.log(error)
  if(error.response.data.message) {
    return error.response.data.message;
  } else if(error.response.status == 400 && typeof error.response.data == 'string') {
    return error.response.data;
  } else {
    return defaultMessage;
  }
}