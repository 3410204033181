import { fileStatus } from '../fileInfo'

export default {
  methods: {
    getLabel (status) {
      switch (status) {
        case fileStatus.missing:
          return "Missing";
        case fileStatus.selected:
          return "Selected";
        case fileStatus.posted:
          return "Imported";
        case fileStatus.optional:
          return "Optional";
        case fileStatus.uploading:
          return "Uploading";
        default: 
          return "Unknown Status";
      }
    },
    getVariant (status) {
      switch (status) {
        case fileStatus.missing:
          return "danger";
        case fileStatus.selected:
          return "success";
        case fileStatus.posted:
          return "success";
        case fileStatus.optional:
          return "info";
        case fileStatus.uploading:
          return "warning";
        default: 
          return "danger";
      }
    }
  }
}