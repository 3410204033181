<template>
  <div>
    <modal-dialog
      ref="dlg"
      title="Scenario Viewer"
      :value="value"
      dialog-class="modal-wide"
      confirm-button-text="Close"
      :ok-only="true"
      @input="$emit('input', $event)"
      @clear="clear"
      @shown="loadData"
    >
      <div
        v-if="loading"
        class="loading"
      >
        <loading-indicator />
      </div>
      <div v-else>
        <span v-if="filesImported > 0">Imported {{ filesImported }} file{{ filesImported > 1 ? 's' : '' }} successfully. </span>
        <div class="btn-container-right">
          <b-btn 
            class="mt-2" 
            title="Load default scenarios"
            @click="showResetScenarioConfirmation=true"
          >
            Reset to Default
          </b-btn>
          <b-btn
            class="mt-2"
            :to="{ name: 'FileEditor', params: { analysisId: activeAnalysis.id, path: fileName, returnPath: `/edit/${activeAnalysis.id}/calculations` } }"
          >
            Edit Scenarios
          </b-btn>
        </div>
        
        <b-table-lite
          bordered
          small
          striped
          hover
          sticky-header="calc(100vh - 300px)"
          :fields="fields"
          :items="data"
        />
      </div>
    </modal-dialog>

    <modal-dialog
      ref="confirmResetScenarios"
      title="Reset to Default Scenarios"
      v-model="showResetScenarioConfirmation"
      :prompt="`Are you sure you want reset all scenario data to default?`"
      :action="loadDefault"
      error-message="An error occurred loading default scenarios."
      confirm-button-text="Reset"
    />
  </div>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import LoadingIndicator from './LoadingIndicator.vue'
import api from '../api'
import { mapState } from 'vuex';
import { assetTypes } from '../js/options/assetType';

export default {
  props: {
    value: { type: Boolean },
    fileName: { type: String, required: true },
    showReprice: { type: Boolean, default: false },
    filesImported: { type: Number, default: 0 }
  },
  data() {
    return {
      data: [],
      loading: true,
      fields: [
        { key: 'SCENARIO_#', label: 'Scenario' }, 
        { key: 'SCALE', label: 'Scale' }, 
        { key: 'MDR', label: 'MDR' }, 
        { key: 'SEVERITY', label: 'Severity' }, 
        { key: 'PREPAY', label: 'Prepay' }, 
        { key: 'STHPIx', label: 'ST HPA' }, 
        { key: 'LTHPI', label: 'LT HPA' }, 
        { key: 'IRSHIFT', label: 'IR SHIFT' }, 
        { key: 'HPIDataFile', label: 'HPI Data File' }, 
        { key: 'UNEMPDataFile', label: 'Unemployment Data File' }, 
        { key: 'IRDataFile', label: 'IR Data File' },
        { key: 'REPRICEDataFile', label: 'Reprice Data File' }
      ],
      showResetScenarioConfirmation: false
    };
  },
  mounted () {
    // If Servicing then change MDR label to C2D
    if (this.activeAnalysis.assetType == assetTypes.servicing) {
      let field = this.fields.find(f => f.key == 'MDR');

      if (field) {
        field.label = 'C2D';
      }
    }
  },
  computed: {
    ...mapState(['activeAnalysis']),

    isStressTest () {
      return this.fileName.startsWith("ST_");
    }
  },
  methods: {
    showDialog () {
      this.$refs.dlg.showDialog();
    },
    clear () {
      this.loading = true;
      this.data = [];
    },
    async loadData () {
      this.loading = true;
      this.data = await api.getFile(this.activeAnalysis.id, this.fileName);
      this.loading = false;
    },
    async loadDefault () {
      await api.loadDefaultScenarios(this.activeAnalysis.id, this.isStressTest);
      await this.loadData();
    }
  },
  components: {
    ModalDialog,
    LoadingIndicator
  }
}
</script>

<style scoped>
.loading {
  min-height: 300px;
}
</style>