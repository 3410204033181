<template>
  <div 
    ref="scrollContainer"
    class="scrollable scroll-container"
    v-resize:debounce.100="resize"
    :style="containerStyle"
  >
    <slot />
  </div>
</template>

<script>
import resize from 'vue-resize-directive'

export default {
  props: {
    minHeight: { type: Number, default: 0 }
  },
  data () {
    return {
      top: 100
    }
  },
  computed: {
    containerStyle () {
      return {
        '--container-top': `${this.top}px`,
        'min-height': `${this.minHeight}px`
      };
    }
  },
  methods: {
    resize () {
      if (this.$refs.scrollContainer) {
        this.top = this.$refs.scrollContainer.getBoundingClientRect().top || 100;
      }
    }
  },
  directives: {
    resize
  }
}
</script>

<style>
.scroll-container {
  height: calc(100vh - var(--container-top) - 20px);
}
</style>