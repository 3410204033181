<template>
  <collapsible-section
    section-title="Apply Shock"
    class="shock-panel ml-2"
    hide-toggle
  >
    <b-form
      @submit.stop.prevent
      @keyup="updatePreview"
      @submit="applyShock"
    >
      <b-form-row>
        <b-col>
          <b-form-group
            label="Scenario"
            label-for="scenario"
            title="Scenario to shock"
            v-b-tooltip.hover.topleft.ds1200
            :label-cols="labelSize"
          >
            <b-select
              id="scenario"
              class="field-md"
              :disabled="previewActive"
              :options="scenarioOptions"
              v-model="baseScenarioId"
              size="sm"
            />
            <b-form-invalid-feedback :state="state($v.baseScenarioId)">
              {{ validationMessage($v.baseScenarioId) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row
        v-if="forecastOptions.length > 0 && !isAutoAnalysis"
      >
        <b-col>
          <b-form-group
            label="Forecast"
            label-for="forecast"
            title="Forecast to shock"
            v-b-tooltip.hover.topleft.ds1200
            :label-cols="labelSize"
          >
            <b-select
              id="forecast"
              class="field-md"
              :disabled="previewActive"
              :options="forecastOptions"
              v-model="baseForecastType"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            :label="`Shock (${shockUnits})`"
            label-for="shock-value"
            :title="shockToolTip"
            v-b-tooltip.hover.topleft.ds1200
            :label-cols="labelSize"
          >
            <b-form-input
              id="shock-value"
              v-model="shockValue"
              :state="state($v.shockValue)"
              type="text"
              class="field-sm"
              size="sm"
              style="display: inline"
              autocomplete="off"
            />
            <b-form-invalid-feedback :state="state($v.shockValue)">
              {{ validationMessage($v.shockValue) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <date-picker
              id="start-date"
              v-model="startDate"
              :value="startDate"
              :state="state($v.startDate)"
              :label="'Start'"
              :label-cols="labelSize"
              :month-year-only="true"
              title="Date the shock will begin"
              v-b-tooltip.hover.topleft.ds1200
            >
              <b-form-invalid-feedback :state="state($v.startDate)">
                {{ validationMessage($v.startDate) }}
              </b-form-invalid-feedback>
            </date-picker>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <date-picker
              id="end-date"
              v-model="endDate"
              :value="endDate"
              :state="state($v.endDate)"
              :label="'End'"
              :label-cols="labelSize"
              :month-year-only="true"
              title="Date the shock will end"
              v-b-tooltip.hover.topleft.ds1200
            >
              <b-form-invalid-feedback :state="state($v.endDate)">
                {{ validationMessage($v.endDate) }}
              </b-form-invalid-feedback>
            </date-picker>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            v-if="startDate != ''"
            label="Fade In (Months)"
            label-for="fade-in"
            title="Number of months for shock to fade in"
            v-b-tooltip.hover.topleft.ds1200
            :label-cols="labelSize"
          >
            <b-form-input
              id="fade-in"
              v-model="fadeIn"
              :state="state($v.fadeIn)"
              type="text"
              class="field-sm"
              size="sm"
              autocomplete="off"
            />
            <b-form-invalid-feedback :state="state($v.fadeIn)">
              {{ validationMessage($v.fadeIn) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            v-if="endDate != ''"
            label="Fade Out (Months)"
            label-for="fade-out"
            title="Number of months for shock to fade out"
            v-b-tooltip.hover.topleft.ds1200
            :label-cols="labelSize"
          >
            <b-form-input
              id="fade-out"
              v-model="fadeOut"
              :state="state($v.fadeOut)"
              type="text"
              class="field-sm"
              size="sm"
              autocomplete="off"
            />
            <b-form-invalid-feedback :state="state($v.fadeOut)">
              {{ validationMessage($v.fadeOut) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            title="Check to create a new scenario"
            v-b-tooltip.hover.topleft.ds1200
          >
            <b-form-checkbox
              :disabled="baseScenarioId == null || previewActive"
              v-model="createNewScenario"
            >
              Create a new scenario
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="createNewScenario">
        <b-col>
          <b-form-group
            label="Scenario Name"
            label-for="new-scenario"
            title="Name of newly created scenario (required if you are shocking the AD&Co default scenario)"
            v-b-tooltip.hover.topleft.ds1200
            :label-cols="labelSize"
          >
            <b-form-input
              id="new-scenario"
              v-model="newScenarioName"
              :state="state($v.newScenarioName)"
              :disabled="previewActive"
              type="text"
              class="field-md"
              size="sm"
              autocomplete="off"
            />
            <b-form-invalid-feedback :state="state($v.newScenarioName)">
              {{ validationMessage($v.newScenarioName) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-button
            class="mt-4 btn-block"
            variant="primary"
            v-if="!previewActive"
            @click="previewScenario"
          >
            Preview Shock
          </b-button>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-button
            v-if="previewActive"
            :disabled="$v.$anyError"
            type="submit"
            class="mt-4 btn-block"
            variant="primary"
          >
            Apply Shock
          </b-button>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-button
            v-if="previewActive"
            @click="clearPreview"
            class="mt-4 btn-block"
            variant="secondary"
          >
            Cancel
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </collapsible-section>
</template>

<script>
import DatePicker from './DatePicker.vue'
import CollapsibleSection from './CollapsibleSection.vue'
import validationState from '../js/mixins/validationState'
import { required, between, integer, decimal } from 'vuelidate/lib/validators'
import { mapState, mapMutations } from 'vuex'
import api from "../api";
import moment from 'moment';
import { runTypes } from '../js/options/runType'

const uniqueScenarioName = (name, vm) => (!vm.scenarios.some(s => s.scenarioName == name && s.forecastTypes.includes(vm.baseForecastType)));
const sameOrBeforeEndDate = (date, vm) => (vm.startDate && vm.endDate ? moment(date).isSameOrBefore(moment(vm.endDate)): true);
const validFadeInMonths = (fadeInMonths, vm) => (vm.startDate && vm.endDate && moment(vm.startDate).isSameOrBefore(moment(vm.endDate)) ? fadeInMonths <= moment(vm.endDate).diff(moment(vm.startDate), 'months')+1: true);
const validFadeOutMonths = (fadeOutMonths, vm) => (vm.startDate && vm.endDate && moment(vm.startDate).isSameOrBefore(moment(vm.endDate)) ? fadeOutMonths <= moment(vm.endDate).diff(moment(vm.startDate), 'months')+1: true);
const validEndDate = (endDate, vm) => (vm.startDate && vm.fadeIn && vm.fadeOut && moment(vm.startDate).isSameOrBefore(moment(endDate)) ? parseInt(vm.fadeIn) + parseInt(vm.fadeOut) <= moment(endDate).diff(moment(vm.startDate), 'months')+1: true);
const validScenario = (id, vm) => (vm.validationStatus.find(s => s.tab == "Scenarios")?.invalidScenarios.find(s => s == id) === undefined);

export default {
  props: {
    forecastGroup: { type: String, required: true },
    forecastTypes: { type: Array, required: true }
  },
  data () {
    return {
      baseScenarioId: null,
      baseForecastType: null,
      shockValue: null,
      startDate: '',
      endDate: '',
      fadeIn: 0,
      fadeOut: 0,
      createNewScenario: true,
      newScenarioName: null,
      previewActive: false,
      newForecast: [],
      forecastMonths: 360,
      labelSize: 5
    }
  },
  computed: {
    ...mapState(['activeAnalysis', 'scenarioData', 'scenarios', 'validationStatus']),
    scenarioOptions () {
      return this.scenarios.filter(s => !s.groups || s.groups.includes(this.forecastGroup)).map(s => {
        return {value: s.scenarioId, text: s.scenarioName}}
      );
    },
    forecastOptions () {
      if (this.baseScenarioId === null)
        return this.forecastTypes.filter(t => t.default).map(f =>  { return {text: f.header, value: f.name} });
      
      let baseScenario = this.scenarios.find(s => s.scenarioId == this.baseScenarioId);
      if (baseScenario === undefined) return [];
      return this.forecastTypes.filter(t => baseScenario.forecastTypes.includes(t.name)).map(f => { return {text: f.header, value: f.name} });
    },
    isAutoAnalysis() {
      return this.activeAnalysis.runType == runTypes.loanDynamics && this.activeAnalysis.assetType === 'AutoLoans';
    },
    shockUnits () {
      return this.forecastGroup == 'Rate' ? 'bps' : '%'
    },
    shockToolTip () {
      return `Shock value in ${this.shockUnits} (additive)`
    }
  },
  watch: {
    scenarios: function(newValue) {
      if (!newValue.map(s => s.scenarioId).includes(this.baseScenarioId)) this.baseScenarioId = null;
    },
    baseScenarioId: function(newValue) {
      if (newValue == null) this.createNewScenario = true;
    },
    startDate: function(newValue) {
      this.updatePreview();
    },
    endDate: function(newValue) {
      this.updatePreview();
    },
    forecastOptions: function(newValue) {
      if (newValue.find(f => f.value == this.baseForecastType) === undefined)
        this.baseForecastType = this.forecastOptions[0].value;
    }
  },
  mounted() {
    this.forecastMonths = this.isAutoAnalysis ? 120 : 360;
    this.startDate = this.activeAnalysis.settings.firstForecastDate;
    this.endDate = moment(this.startDate).add(this.forecastMonths-1, 'M').startOf('day').format();
    this.baseForecastType = this.forecastTypes[0].name;
  },
  destroyed() {
    this.clearScenarioPreviewData();
  },
  methods: {
    ...mapMutations(['setScenarioPreviewData', 'clearScenarioPreviewData', 'setSavePending', 'setSaving', 'doneSaving']),
    previewScenario() {
      // Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.previewActive = true;

      let baseScenarioName = this.scenarios.find(s => s.scenarioId == this.baseScenarioId).scenarioName;
      let baseScenarioId = this.scenarios.find(s => s.scenarioId == this.baseScenarioId).scenarioId;
      let baseScenarioData = this.scenarioData[this.forecastGroup].map(s => {
        return {
          'date': s.date,
          'value': s[`${baseScenarioId === null ? 'AD&Co Default' : `_${baseScenarioId}`}`][this.baseForecastType]
        }
      })
      this.newForecast = this.generateNewForecast(baseScenarioData);
      this.setScenarioPreviewData({
        createNewScenario: this.createNewScenario,
        scenarioName: (this.createNewScenario ? this.newScenarioName : baseScenarioName) + ' (Preview)',
        chartLabel: (this.createNewScenario ? this.newScenarioName : baseScenarioName) + ` - ${this.baseForecastType}` + ' (Preview)',
        baseScenarioId: this.baseScenarioId,
        baseForecastType: this.forecastTypes.filter(t => t.name == this.baseForecastType)[0].header,
        forecast: this.newForecast
      });
    },
    async applyShock() {
      this.setSavePending();
      if (this.createNewScenario) {
        let scenario = this.scenarios.find(s => s.scenarioName == this.newScenarioName);
        if (!scenario) {
          scenario = {
            scenarioName: this.newScenarioName,
            enabled: true,
            forecasts: [this.newForecast]
          }
          this.setSaving();
          await api.createScenario(this.activeAnalysis.id, scenario);
        } else {
          this.setSaving();
          await api.addScenarioForecast(this.activeAnalysis.id, scenario.scenarioId, this.baseForecastType, this.newForecast);
        }
      } else {
        this.setSaving();
        await api.updateScenarioForecast(this.activeAnalysis.id, this.baseScenarioId, this.baseForecastType, this.newForecast);
      }

      this.doneSaving();
      this.newScenarioName = null;
      this.previewActive = false;
      this.$v.$reset();
      this.clearScenarioPreviewData();
      this.$emit('scenarios-updated');
    },

    generateNewForecast(baseForecast) {
      let startDate = this.startDate ? moment(this.startDate) : null;
      let endDate = this.endDate ? moment(this.endDate) : null;
      let startDateOffset = this.startDate ? moment(this.startDate).add(this.fadeIn, 'M') : null;
      let endDateOffset = this.endDate ? moment(this.endDate).subtract(this.fadeOut, 'M') : null;
      let finalShockValue = this.shockUnits == 'bps' ? this.shockValue / 100 : this.shockValue;

      let newForecast = {
        startDate: moment(baseForecast[0].date).startOf('day').format(),
        type: this.baseForecastType,
        values: baseForecast.map(s => {

          let date = moment(s.date);
          let newValue = s.value;

          if (
            (startDate == null && endDate == null) ||
            (startDate == null && date.isSameOrBefore(endDateOffset, 'month')) ||
            (endDate == null && date.isSameOrAfter(startDateOffset, 'month'))
          ) {
            newValue = s.value+parseFloat(finalShockValue);
          } else if (date.isBefore(startDate, 'month')) {
            newValue = s.value;
          } else if (date.isAfter(endDate, 'month')) {
            newValue = s.value;
          } else if (date.isSameOrAfter(startDate, 'month') && date.isBefore(startDateOffset, 'month')) {
            let tuning = this.fadeIn ? parseFloat(finalShockValue)/(this.fadeIn)*(date.diff(startDate, 'months')+1) : parseFloat(finalShockValue);
            newValue = s.value+tuning;
          } else if (date.isSameOrAfter(startDateOffset, 'month') && date.isSameOrBefore(endDateOffset, 'month')) {
            newValue = s.value+parseFloat(finalShockValue);
          } else if (date.isAfter(endDateOffset, 'month') && date.isSameOrBefore(endDate, 'month')) {
            let tuning = this.fadeOut ? parseFloat(finalShockValue)/(this.fadeOut)*(endDate.diff(date, 'months')) : parseFloat(finalShockValue);
            newValue = s.value+tuning;
          }

          return newValue;
        })
      };
      return newForecast;
    },
    updatePreview() {
      if (this.previewActive) {
        this.previewScenario();
      }
    },
    clearPreview() {
      this.clearScenarioPreviewData();
      this.previewActive = false;
      this.$v.$reset();
      this.$emit('scenarios-updated');
    },
    updateShockParams(data) {
      if (this.previewActive) return;
      this.startDate = moment(data.startDate).format('YYYY-MM');
      this.endDate = moment(data.endDate).format('YYYY-MM');
      this.baseScenarioId = data.scenarioId;
      this.baseForecastType = data.forecastType;
    },
  },
  validations() {
    let validations = {
      newScenarioName: {
        uniqueScenarioName
      },
      startDate: {
        sameOrBeforeEndDate
      },
      shockValue: {
        required,
        between: between(-10000, 10000),
        decimal
      },
      fadeIn: {
        between: between(0, 10000),
        integer,
        validFadeInMonths
      },
      fadeOut: {
        between: between(0, 10000),
        integer,
        validFadeOutMonths
      },
      endDate: {
        validEndDate
      },
      baseScenarioId: {
        validScenario
      }
    }

    if(this.createNewScenario) validations.newScenarioName.required = required;

    return validations
  },
  components: {
    DatePicker,
    CollapsibleSection
  },
  mixins: [validationState]
}
</script>

<style scoped>
.invalid-feedback {
  max-width: 100px;
}
.form-row {
  margin-bottom: .5rem;
}
</style>
