<template>
  <div
    v-if="analysisStatus"
    v-resize:debounce.100="resize"
    class="results"
    :style="resultsStyle"
  >
    <b-alert
      variant="warning"
      :show="analysisRunning || analysisQueued"
      class="d-flex"
    >
      <div>
        <h3><b-spinner label="Analysis Status" /> {{ statusAlertTitle }}</h3>
        <span v-if="!analysisStatus.stopping">
          {{ analysisQueued ? 'Waiting for other analyses to complete.' : analysisStatus.statusText || '' }}
        </span>
        <span v-else>Attempting to stop the analysis. 
          <!-- If the analysis does not stop you can <b-link class="light-link" @click="$emit('cancel-analysis')">abort the connection</b-link> -->
        </span>
      </div>
      
      <b-form-checkbox
        v-if="showOutputCheckBox"
        v-model="showOutput"
        class="ml-auto"
      >
        Show Output
      </b-form-checkbox>
    </b-alert>

    <b-alert
      variant="danger"
      :show="analysisError"
      class="d-flex"
    >
      <div>
        <h3>Error running analysis</h3>
        <span>{{ analysisStatus.statusText }}</span>
      </div>

      <b-form-checkbox
        v-if="showOutputCheckBox"
        v-model="showOutput"
        class="ml-auto"
      >
        Show Output
      </b-form-checkbox>
    </b-alert>

    <b-alert
      variant="danger"
      :show="analysisSuccess && analysisDirty"
    >
      <span>Analysis inputs or settings have been changed since this analysis was last run. These results may be out of date.</span>
    </b-alert>

    <b-alert
      variant="danger"
      :show="errorInCashFlow"
    >
      <span>{{ errorInCashFlowMessage }}</span>
    </b-alert>
    <b-alert
      variant="warning"
      :show="analysisNotRun || analysisCanceled"
    >
      <span>No results found. Please run the analysis to view results.</span>
    </b-alert>

    <b-alert
      variant="warning"
      :show="analysisSuccess && hasErrorRecords"
    >
      <span>Errors occurred processing some records. View error results for details.</span>
    </b-alert>

    <div
      v-show="showOutput && (analysisRunning || analysisError)"
      class="analysis-output scrollable"
    >
      <h3>Analysis Output</h3>
      <div>{{ analysisStatus.output }}</div>
    </div>
    
    <div v-if="analysisSuccess || (analysisError && hasErrorRecords && !showOutput)">
      <div class="results-section">
        <loading-indicator v-if="loading" />
        <b-navbar
          v-if="selectedFile && selectedFile.groupName"
          toggleable="lg"
        >
          <b-navbar-nav class="navbar-nav-left">
            <b-nav-item-dropdown :text="selectedFile && selectedFile.groupName">
              <b-dropdown-item
                v-for="item in resultGroupList"
                :key="item.name"
                @click="setSelectedResultFile(item)"
              >
                {{ item.groupName }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav class="navbar-nav-left">
            <b-nav-form v-if="isCashflowResult">
              <b-form-radio-group
                v-model="resultDisplayType"
                :options="resultDisplayOptions"
                @change="handleViewChange(selectedCashflowFile, $event)"
              />
              <div
                class="table-cash-flow-filters"
                v-if="resultDisplayType==='table'"
              >
                <cash-flow-filters
                  :is-cashflow-result="isCashflowResult"
                  :available-cash-flows="selectedCashFlows"
                  :mc-path="(calcs.paths && (typeof calcs.paths === 'string' || calcs.paths instanceof String)) ? parseInt(calcs.paths) : calcs.paths"
                  :set-cash-flow-file-selected="setCashFlowFileSelected"
                  :calcs="calcs"
                  :loan-id="selectedLoanId"
                  :path="path"
                  :cashflow-type="selectedCashFlow"
                />
              </div>
            </b-nav-form>
          </b-navbar-nav>

          <b-navbar-nav class="navbar-nav-left">
            <b-nav-form v-if="selectedReportHasRelatedReports">
              <b-form-radio-group
                v-model="selectedRelatedReport"
                :options="relatedReportOptions"
                text-field="shortName"
                value-field="name"
                @change="setRelatedResultFile"
              />
            </b-nav-form>
          </b-navbar-nav>

          <b-navbar-nav
            v-if="selectedFile && selectedFile.collectionName && selectedFile.displayType !== 'chart' && !isLdmResult(selectedFile)"
            class="navbar-nav-left"
          >
            <b-nav-form>
              <b-button
                variant="outline-secondary"
                size="sm"
                class="btn-choose-fields"
                @click="exportFile"
              >
                <font-awesome-icon icon="cloud-download-alt" />
                Export Results
              </b-button>
            </b-nav-form>
          </b-navbar-nav>

          <b-navbar-nav
            v-if="selectedFile && isCashflowResult"
            class="navbar-nav-left"
          >
            <b-nav-form>
              <b-button
                variant="outline-secondary"
                size="sm"
                class="btn-choose-fields"
                @click="exportCashFlows"
              >
                <font-awesome-icon icon="cloud-download-alt" />
                Export Cash Flows
              </b-button>
            </b-nav-form>
          </b-navbar-nav>

          <b-navbar-nav
            v-if="showcashFlowTypeFilter"
            class="navbar-nav-left"
          >
            <b-nav-form>
              <b-form-select
                id="cashFlowTypeFilter"
                title="Select Position Type"
                v-model="cashFlowType"
                size="sm"
              >
                <option :value="null">
                  All Positions
                </option>
                <option value="MSR">
                  MSR
                </option>
                <option value="Hedge">
                  Hedges
                </option>
              </b-form-select>
            </b-nav-form>
          </b-navbar-nav>

          <b-navbar-nav class="navbar-nav-left">
            <b-nav-form v-if="isLdmResult(selectedFile) && selectedFile.resultType != 1">
              <b-form-radio-group
                v-if="hasMultipleReportTypes"
                v-model="resultDisplayType"
                :options="resultDisplayOptions"
                @change="setSelectedLdmResultFile(selectedFile, $event)"
              />
              <b-nav-form>
                <b-button
                  variant="outline-secondary"
                  size="sm"
                  class="btn-choose-fields"
                  @click="exportFile"
                >
                  <font-awesome-icon icon="cloud-download-alt" />
                  Export Results
                </b-button>
              </b-nav-form>
            </b-nav-form>
          </b-navbar-nav>

          <b-navbar-nav
            class="navbar-nav-left"
          >
            <b-nav-form>
              <b-button
                v-if="selectedFile && displayChooseFields"
                variant="outline-secondary"
                size="sm"
                class="btn-choose-fields"
                @click="chooseField"
              >
                <font-awesome-icon icon="list" />
                Choose Fields
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
          <b-navbar-nav
            v-if="selectedFile && reportData && selectedFile.displayType !== 'chart'"
            class="ml-auto"
          >
            <b-nav-text class="pr-3">
              Records {{ firstDisplayRow.toLocaleString() }} - {{ lastDisplayRow.toLocaleString() }} of {{ totalDisplayRows.toLocaleString() }} {{ limitedResults ? '(limit 1000)' : '' }}
            </b-nav-text>
          </b-navbar-nav>

          <b-navbar-nav v-if="selectedFile && reportData && selectedFile.displayType !== 'chart'">
            <b-pagination
              v-model="pagination.currentPage"
              class="mr-3"
              size="sm"
              :total-rows="totalRows"
              :per-page="pagination.perPage"
              @change="handlePageChange"
              hide-ellipsis
            />
          </b-navbar-nav>
        </b-navbar>
        <choose-fields-dialog
          v-if="selectedFile && displayChooseFields"
          v-model="openModel"
          :report-id="selectedFile.id"
          @refreshResults="refreshResults"
        /> 
        <div
          v-if="selectedFile"
          ref="resultsArea"
          class="theme-item"
        >
          <loading-indicator v-if="loading" />
          <div v-else-if="selectedFile.displayType == 'chart'">
            <results-chart
              :chart-item="selectedFile"
              :fields="resultFields"
              :results-top="resultsTop"
              :is-cashflow-result="isCashflowResult"
              :calcs="calcs"
              :available-cash-flows="selectedCashFlows"
              :mc-path="(calcs.paths && (typeof calcs.paths === 'string' || calcs.paths instanceof String)) ? parseInt(calcs.paths) : calcs.paths"
              :set-cash-flow-file-selected="setCashFlowFileSelected"
              :set-cash-flow-error="setCashFlowError"
              :loan-id-chart="selectedLoanId"
              :path-chart="path"
              :cashflow-type="selectedCashFlow"
            />
          </div>
          <div
            class="scrollable theme-item"
            v-else
          >   
            <div v-if="resultFields.length>0">
              <b-table
                tabindex="0"
                id="results-table"
                table-class="table-compact table-modern theme-item"
                :items="reportData" 
                :fields="resultFields" 
                :per-page="isLdmResult(selectedFile) ? null : pagination.perPage"
                :current-page="isLdmResult(selectedFile) ? null : pagination.currentPage"
                :show-empty="true"
                empty-text="There are no records to display."
                :filter="cashFlowType"
                :filter-function="filterPositions"
                :tbody-tr-class="rowClass"
                :busy="loading"
              >
                <div
                  slot="table-busy"
                  class="text-center"
                >
                  <b-spinner
                    class="align-middle"
                    small
                  />
                  <strong> Loading...</strong>
                </div>
              </b-table>
            </div>
            <div
              class="text-center"
              v-else
            >
              There are no fields to display. Please choose fields to display.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultsChart from './ResultsChart.vue'
import { getResultList } from '../js/results/resultList'
import ResultItem, { resultTypes } from '../js/results/ResultItem'
import { mapGetters, mapState, mapMutations } from 'vuex';
import formatters from '../js/formatters'
import { statusCodes } from '../js/options/statusCodes'
import createCashFlowChartItem from '../js/results/cashFlowChart'
import { runTypes } from '../js/options/runType'
import LoadingIndicator from './LoadingIndicator.vue'
import resize from 'vue-resize-directive'
import ChooseFieldsDialog from './ChooseFieldsDialog.vue'
import { getCashflowColumns } from '../js/results/cashFlowColumns';
import CashFlowFilters from './CashFlowFilters.vue'
import createErrorResultItem from '../js/results/errorResults'

export default {
  props: {
    id: { type: [String, Number], required: true }
  },
  data () {
    return {
      resultList: { items: [] },
      selectedFile: null,
      selectedReportItem: null,
      selectedCashflowFile: null,
      pagination: {
        perPage: 100,
        currentPage: 1
      },
      reportData: [],
      cashFlowType: null,
      loading: false,
      resultsTop: 200,
      resultDisplayType: 'chart',
      resultDisplayOptions: [],
      showOutput: false,
      totalLdmResultRows: 0,
      openModel: false,
      selectedCashFlow:null,
      selectedLoanId:null,
      path:null,
      relatedReportOptions: [],
      selectedRelatedReport: null,
      selectedReportHasRelatedReports: false, 
      errorInCashFlow:false, 
      errorInCashFlowMessage: null,
      hasMultipleReportTypes: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      // Load results when switching to results tab
      if (vm.analysisSuccess || vm.analysisError) {
        await vm.getResults();
      }
    });
  },
  beforeRouteLeave (to, from, next) {
    // Large b-table slows the UI
    // We clear the report data when leave the results tab to fix this issue
    this.reportData = [];
    next();
  },
  async created() {
    if (this.appConfig.importResults) {
      this.resultDisplayOptions = [
        { text: 'Chart View', value: 'chart' },
        { text: 'Table View', value: 'table' },
      ];
      this.resultDisplayType = 'chart';
    } else {
      this.resultDisplayType = 'table';
      await this.getResults();
    }
  },
  computed: {
    ...mapState(['activeAnalysis', 'appConfig']),
    ...mapGetters(['analysisDirty', 'analysisStatus', 'analysisRunning', 'analysisQueued', 'analysisError', 'analysisSuccess', 'analysisCanceled', 'analysisNotRun', 'calcs']),

    statusAlertTitle () {
      return this.analysisQueued ? 'Analysis Queued...' :
        this.analysisStatus.stopping ? 'Stopping analysis...' : 
        this.analysisStatus.statusTitle || '';
    },

    hasErrorRecords () {
      //let errResults = this.resultList.items.find(f => f.resultType == resultTypes.error);
      //return !this.analysisRunning && errResults && errResults.data && errResults.data.length > 0;
      return this.activeAnalysis.status.hasErrorRecords;
    },
    firstDisplayRow () {
      return this.pagination.perPage * (this.pagination.currentPage - 1) + 1;
    },
    lastDisplayRow () {
      return Math.min(this.pagination.perPage * this.pagination.currentPage, this.totalDisplayRows);
    },
    // Row count excluding total rows
    totalDisplayRows () {
      if (this.selectedFile && this.reportData && this.isLdmResult(this.selectedFile)) return this.totalLdmResultRows;
      if (this.selectedFile && this.reportData) {
        if (this.cashFlowType != null) {
          return this.reportData.filter(r => this.filterPositions(r, this.cashFlowType) && r.footer !== true).length;
        } else {
          return this.reportData.filter(r => r.footer !== true).length;
        }
      } else {
        return 0;
      }
    },
    // Total row count including total rows
    totalRows () {
      if (this.selectedFile && this.reportData && this.isLdmResult(this.selectedFile)) return this.totalLdmResultRows;
      if (this.selectedFile && this.reportData) {
        if (this.cashFlowType != null) {
          return this.reportData.filter(r => this.filterPositions(r, this.cashFlowType)).length;
        } else {
          return this.reportData.length;
        }
      } else {
        return 0;
      }
    },
    showcashFlowTypeFilter () {
      return this.calcs.useHedgeFile && this.selectedFile && this.selectedFile.resultType == resultTypes.portfolio;
    },
    isCashflowResult() {
      return this.selectedReportItem && this.selectedReportItem.resultType == resultTypes.cashflow;
    },
    resultFields () {
      let calcs = this.calcs;
      let header = this.reportData && this.reportData[0];
      let resultType = this.selectedFile.resultType;

      if (!this.selectedFile.columns) {
        return [];
      }

      return this.selectedFile.columns({ calcs, resultType, header, assetType: this.activeAnalysis.assetType }).map(c => ({ 
        key: c.binding, 
        label: c.header, 
        formatter: c.format ? (v) => this.format(v, c.format) : c.formatter || null,
        ...c
      }));
    },
    resultsStyle () {
      return {
        '--results-top': this.resultsTop + 'px'
      }
    },
    limitedResults () {
      return (!this.appConfig.importResults || this.activeAnalysis.runType == runTypes.riskValDynamics) && this.totalDisplayRows >= 1000;
    },
    displayChooseFields() {
      return (this.activeAnalysis.runType == runTypes.riskValDynamics && this.selectedFile.resultType != resultTypes.cashflow) ||
        (this.activeAnalysis.runType == runTypes.loanDynamics && (this.resultDisplayType == 'table' || this.selectedFile.collectionName == "LdmLifetimeResults"));
    },
    showOutputCheckBox() {
      return this.activeAnalysis.runType != runTypes.loanDynamics;
    },
    selectedCashFlows(){
      let selectedCashFlows =[];
      let cashFlows = {
        showLoanLevel:'Loan Level',
        showMonte:'Monte Carlo',
        showMonteAvg:'Averaged MC',
        showScenarios:'Scenario'
      }
      for (var key in this.calcs.cashflows) {
        if( this.calcs.cashflows[key]===true){
          selectedCashFlows.push(cashFlows[key])
        }
      }
      return selectedCashFlows
    },
    resultGroupList() {
      return this.resultList.items.filter(r => r.reportLevel == 0);
    }
  },
  watch: {
    'activeAnalysis.resultsUpdated' (val) {
      if (val && this.analysisStatus.executionStatus != statusCodes.running) {
        this.reportData = [];
        this.selectedCashFlow= null
        this.getResults();
      } else {
        this.selectedReportItem = null;
        this.selectedCashflowFile = null;
      }
    },
  },
  methods: {
    ...mapMutations(['addExport']),

    refreshResults(){
      this.getResults();
    },
    isLdmResult(selectedFile) {
      return this.calcs.GUIflavor == 'LDM' && selectedFile // && selectedFile.resultType != resultTypes.error;
    },
    async getResults () {
      this.loading = true;
      this.resultList = await getResultList(this.activeAnalysis.id, this.calcs, this.analysisError, this.appConfig.importResults);
      
      // Get results file list
      await this.resultList.loadData(this.activeAnalysis, { calcs: this.calcs });
    
      if (this.resultList.items.length) {
        const item = this.findDefaultResultItem(this.resultList.items);
        await this.setSelectedResultFile(item);

        this.$nextTick(() => { this.resize(); });
      }

      this.loading = false;
    },
    findDefaultResultItem (resultItems) {
      let item = null;
      
      // Find previously selected item
      if (this.selectedFile != null) {
        item = resultItems.find(f => f.name == this.selectedFile.name && f.resultType !== resultTypes.error);
      }

      // Show errors, if there are errors records
      if (item == null && this.hasErrorRecords) {
        item = resultItems.find(f => f.resultType === resultTypes.error)
      }
      
      // Find default item
      if (item == null) {
        item = resultItems.find(f => f.default);
      }

      // Find any item
      if (item == null && resultItems.length) {
        item = resultItems[0];
      }

      return item;
    },
    setSelectedCashflowFile(file, displayType, selectedCashFlows,selectedLoanId, path) {
      let item = displayType == 'chart' ? 
        createCashFlowChartItem(this.selectedReportItem.id, this.selectedReportItem.name, file.name, this.calcs, file.folder, selectedCashFlows,selectedLoanId, path, this.activeAnalysis.assetType) : 
        new ResultItem ({ id: this.selectedReportItem.id, name: this.selectedReportItem.name, fileName: file.name ||'dummyfileName' , resultType: resultTypes.cashflow, columns: getCashflowColumns, folder: file.folder, selectedCashFlows,selectedLoanId, path });
      
      this.setSelectedFile(item);
    },
    async setSelectedLdmResultFile(file, displayType) {
      let item;
      if (file.resultType == resultTypes.error) {
        item = createErrorResultItem(this.appConfig.importResults)
      } else {
        let items = this.resultList.items.filter(r => r.collectionName == file.collectionName);

        this.hasMultipleReportTypes = items.length > 1;
        item = this.hasMultipleReportTypes ? items.find(r => r.displayType == displayType) : items[0];
      }

      if (!item.columnsLoaded) {
        this.loading = true;
        await item.loadColumns({ calcs: this.calcs });
      }
      await this.setSelectedFile(item);
    },
    async setRelatedResultFile(name) {
      await this.setSelectedResultFile(this.resultList.items.filter(r => r.name == name)[0]);
    },
    async setSelectedResultFile(item) {

      // Load column meta data if needed
      if (!item.columnsLoaded) {
        this.loading = true;
        await item.loadColumns({ calcs: this.calcs });
      }

      if (this.isLdmResult(item)) {
        this.pagination.currentPage = 1;
        await this.setSelectedLdmResultFile(item, this.resultDisplayType);
      }
      else if (item.resultType == resultTypes.cashflow) {
        this.selectedReportItem = item;
        this.selectedCashflowFile = item.subItems.length && item.subItems[0];
        this.reportData = [];
        this.pagination.perPage = 372;

        // Select the first subitem only if it exists
        this.setSelectedCashflowFile(item.subItems.length ? item.subItems[0] : item, this.resultDisplayType);
      } else {
        this.selectedReportItem = null;
        this.selectedCashflowFile = null;
        this.cashFlowType = null;
        this.pagination.perPage = 100;
        await this.setSelectedFile(item);
      }
    },
    async setSelectedFile (file) {
      this.selectedFile = file;

      this.selectedReportHasRelatedReports = file.reportLevel == 0 ?
        this.resultList.items.filter(r => r.parentReport == file.name && r.reportLevel > 0).length > 0 :
        this.resultList.items.filter(r => r.name == file.parentReport && r.reportLevel == 0).length > 0
      
      this.relatedReportOptions = this.selectedReportHasRelatedReports ? this.resultList.items.filter(r => r.parentReport == file.parentReport) : [];
      this.selectedRelatedReport = this.selectedReportHasRelatedReports ? file.name : null;

      // ResultChart component will call get data
      if (file && file.displayType != 'chart') {
        let params = {};
        if (this.isLdmResult(file)) {
          params = {
            $top: this.pagination.perPage,
            $skip: (this.pagination.currentPage - 1)*this.pagination.perPage,
            $count: true
          };
        }
        this.loading = true;
        const reportData = await file.getData(this.activeAnalysis, { calcs: this.calcs, assetType: this.activeAnalysis.assetType, params , 
        selectedLoanId: this.selectedLoanId, selectedCashFlows:this.selectedCashFlow, path:this.path   });
        if(file.resultType===resultTypes.cashflow){     
          this.errorInCashFlow= file.errorInCashFlow
          this.errorInCashFlowMessage= file.errorInCashFlowMessage
        }
        if (this.isLdmResult(file) && this.appConfig.importResults) {
          this.totalLdmResultRows = reportData['@odata.count'];
          this.reportData = reportData.value;
        } else if (this.isLdmResult(file) && !this.appConfig.importResults) {
          this.totalLdmResultRows = reportData.length;
          let skip = (this.pagination.currentPage - 1)*this.pagination.perPage;
          let top = this.pagination.perPage;
          this.reportData = reportData.slice(skip).slice(0,top);
        } else {
          this.reportData = reportData;
        }
      }

      this.loading = false; 
    },
    async handlePageChange (value) {
      if (this.isLdmResult(this.selectedFile)) {
        this.pagination.currentPage = value;
        await this.setSelectedFile(this.selectedFile);
      }
    },
    exportFile () {
      let columns = null;
      if (this.isLdmResult(this.selectedFile)) columns = this.resultFields;
      
      this.addExport({
        analysisId: this.activeAnalysis.id,
        exportMessage: `Exporting ${this.selectedFile.name} for ${this.activeAnalysis.name}...`,
        errorMessage: `Error exporting ${this.selectedFile.name} for ${this.activeAnalysis.name}`,
        promise: this.$http.post(`/analyses/${this.activeAnalysis.id}/export/${this.selectedFile.collectionName}`, { reportId: this.selectedFile.id, columns })
      });
    },
    exportCashFlows () {
      this.addExport({
        analysisId: this.activeAnalysis.id,
        exportMessage: `Exporting cash flows for ${this.activeAnalysis.name}...`,
        errorMessage: `Error exporting cash flows for ${this.activeAnalysis.name}`,
        promise: this.$http.post(`/analyses/${this.activeAnalysis.id}/export/CashFlows`)
      });
    },
    chooseField(){
      this.openModel = true
    },
    filterPositions (row, filter) {
      if (filter == row.cashFlowType) {
        return true;
      }
      return false;
    },
    rowClass (item) {
      if (!item || !item.footer) return;
      if (item.footer === true) return 'total-row';
    },
    resize () {
      if (this.$refs.resultsArea) {
        this.resultsTop = this.$refs.resultsArea.getBoundingClientRect().top || 200;
      }
    },
    setCashFlowFileSelected(selectedCashFlows,selectedLoanId, path){
      this.selectedCashFlow = selectedCashFlows;
      this.selectedLoanId = selectedLoanId;
      this.path = path;
      this.setSelectedCashflowFile({ name:'dummyfileName',folder:'results' }, this.resultDisplayType,selectedCashFlows,selectedLoanId, path);
    },
    setCashFlowError(isErrorInCashFlow, errorInCashFlowMessage){  
      this.errorInCashFlow=isErrorInCashFlow
      this.errorInCashFlowMessage= errorInCashFlowMessage
    },
    handleViewChange(selectedCashflowFile, $event){
      this.setSelectedCashflowFile(selectedCashflowFile, $event);
    }
  },
  components: {
    ResultsChart,
    LoadingIndicator,
    ChooseFieldsDialog,
    CashFlowFilters
  },
  directives: {
    resize
  },
  mixins: [formatters]
}
</script>

<style scoped>
.results h3 {
  font-size: 1.1em;
  font-weight: bold;
}

.results h3 > .spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}

.results h4 {
    font-size: 0.9em;
}

.results h3 svg {
    margin-left: 10px;
}

.scrollable {
  height: calc(100vh - var(--results-top) - 5px);
}

.navbar-nav-left {
  margin-right: 30px;
}
.nav-item,
.navbar-text {
  font-size: 0.9em;
}

.analysis-output {
  background-color: #fffffd;
  padding: 5px;
  height: calc(100vh - var(--results-top) - 35px);
}

.analysis-output > div {
  overflow-wrap: break-word;
  white-space: pre;
}
</style>

<style>
.results .nav-link > span {
  font-size: 1.1em;
  font-weight: bold;
}

.results .table {
  margin-bottom: 0;
}

.results .table.b-table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 999;
}

.light-theme .results .table.b-table > thead > tr > th { 
  border-bottom: 1px solid #eff1f7;
}

.dark-theme .results .table.b-table > thead > tr > th { 
  border-bottom: 1px solid #262930;
}

.table-compact > tbody > tr.total-row > td {
  font-weight: bold;
}

.multiselect--active{
  z-index:1000;
}

</style>
