<template>
  <div>
    <modal-dialog 
      title="Add Scenario" 
      confirm-button-text="Add" 
      :value="value" 
      :action="addScenario"
      :hide-errors="true" 
      @input="$emit('input', $event)" 
      @clear="clearDialog"
      :dialog-size="dialogSize"
      :ok-disabled="!!message"
    >
      <b-form-group
        label="Scenario Name"
        label-for="scenario-name"
      >
        <b-form-input
          id="scenario-name"
          v-model.trim="scenarioName"
          autocomplete="off"
          :state="state($v.scenarioName)"
          autofocus
        />
        <b-form-invalid-feedback
          :state="state($v.scenarioName)"
        >
          {{ validationMessage($v.scenarioName) }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-radio-group
          v-model="addScenarioType"
          :options="addScenarioTypeOptions"
          @input="clearErrorMessages"
        />
      </b-form-group>

      <b-alert
        class="mt-3"
        variant="danger"
        :show="!!message"
      >
        {{ message }}
      </b-alert>
      
      <b-form-group
        label="Scenario Template"
        label-for="forecast-template-select"
        v-if="addScenarioType == 'template'"
      >
        <b-form-select
          id="forecast-template-select"
          v-model="selectedForecastTemplate"
          :options="forecastTemplateOptions"
        />
      </b-form-group>

      <b-form-group
        label="Rates"
        label-for="rates-select"
        v-if="addScenarioType == 'import'"
      >
        <b-form-select
          id="rates-select"
          v-model="selectedRate"
          :options="forecastRateOptions"
        />
      </b-form-group>

      <b-form-group
        label="HPI"
        label-for="HPI-select"
        v-if="addScenarioType == 'import'"
      >
        <b-form-select
          id="HPI-select"
          v-model="selectedHPI"
          :options="forecastHPIOptions"
        />
      </b-form-group>

      <b-form-group
        label="Unemployement"
        label-for="Unemployement-select"
        v-if="addScenarioType == 'import'"
      >
        <b-form-select
          id="Unemployement-select"
          v-model="selectedUnemployement"
          :options="forecastUnemployementOptions"
        />
      </b-form-group>

      <b-form-group
        label="Tunings"
        label-for="Tunings-select"
        v-if="addScenarioType == 'import'"
      >
        <b-form-select
          id="Tunings-select"
          v-model="selectedTunings"
          :options="tuningOptions"
        />
      </b-form-group>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import validationState from '../js/mixins/validationState'
import getErrorMessage from '../js/getErrorMessage'
import api from '../api'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  props:{
    value: { type: Boolean },
    file: { type: Object },
    scenarios: {type: Array}
  },
  data () {
    return {
      scenarioName: null,
      selectedModule: {},
      selectedForecastTemplate: 'CCAR2023Base',
      selectedRate:'',
      selectedHPI:'',
      selectedUnemployement:'',
      selectedTunings:'',
      selected:'',
      forecastTemplateOptions: [
        { text: 'CCAR 2023 Base', value: 'CCAR2023Base' },
        { text: 'CCAR 2023 Severe', value: 'CCAR2023Severe' },
        { text: 'CCAR 2022 Base', value: 'CCAR2022Base' },
        { text: 'CCAR 2022 Severe', value: 'CCAR2022Severe' }
      ],
      forecastRateOptions:[],
      forecastHPIOptions:[],
      forecastUnemployementOptions:[],
      tuningOptions:[],
      addScenarioType: 'template',
      addScenarioTypeOptions: [
        { text: 'Create From Template', value: 'template' },
        { text: 'Copy Forecasts/Tunings', value: 'import' }
      ],
      modelDataDates: [],
      forecastFile: null,
      message: null,
      showFileLayout: false,
      fileLayout: [],
      dialogSize: 'md'
    }
  },
  computed: {
    ...mapState(['activeAnalysis']),

    confirmButtonText() {
      if (this.addScenarioType == 'import')
        return 'Import'
      return 'Add'
    },

    fileLayoutText () {
      if (this.showFileLayout)
        return 'Hide File Layout'
      return 'Show File Layout'
    }
  },
  watch: {
    scenarios: function(){
      let groupMapping={
        'Rate': [],
        'Hpi': [],
        'Unemployment': []
      },
        defaultScenario = '';
      this.tuningOptions = [];
      this.scenarios.forEach((s)=>{
        if(s.isDefaultScenario===true) defaultScenario=s.scenarioName;
        s.groups?.forEach((group)=>{
          groupMapping[group]?  groupMapping[group].push({value:s.scenarioId , text:s.scenarioName}) : groupMapping[group]=[{value:s.scenarioId , text:s.scenarioName}];
        })
        if (s.tuningParameters && s.tuningParameters.length > 0) this.tuningOptions.push({value:s.scenarioId , text:s.scenarioName})
      })
      if(defaultScenario){
        for(let scenario in groupMapping){
          groupMapping[scenario].unshift({value:null , text:`None (Use ${defaultScenario})`})
        }
        this.tuningOptions.unshift({value: null, text: `None (Use ${defaultScenario})`})
      }
      this.forecastRateOptions=groupMapping["Rate"]
      this.forecastHPIOptions=groupMapping["Hpi"]
      this.forecastUnemployementOptions=groupMapping["Unemployment"]
      this.selectedRate = this.forecastRateOptions[0].value
      this.selectedHPI = this.forecastHPIOptions[0].value
      this.selectedUnemployement = this.forecastUnemployementOptions[0].value
      this.selectedTunings = this.tuningOptions[0].value
    },
    scenarioName: function () {
      this.message = null;
    }
  },
  validations() {
    if (this.addScenarioType == 'template') {
      return {
        scenarioName: { required,
         minLength: minLength(1) }
      }
    } 
    else if (this.addScenarioType == 'import') {
      return {
        scenarioName: { required }
      }
    }
  },
  methods: {
    ...mapMutations(['setSavePending', 'setSaving', 'doneSaving', 'errorSaving']),
    ...mapActions([ 'getScenarios']),
    async addScenario () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if(this.addScenarioType == 'import'){
        let scenarioData = {"scenarioName": this.scenarioName,
                            "rates": this.selectedRate,
                            "hpi": this.selectedHPI,
                            "unemployment": this.selectedUnemployement,
                            "tunings": this.selectedTunings}
        await api.copyScenario( this.activeAnalysis.id, scenarioData)
        await this.getScenarios()
      } else{
        await api.copyScenarioFromTemplate( this.activeAnalysis.id, this.selectedForecastTemplate,this.scenarioName )
        await this.getScenarios()
      }
    },
    async toggleFileLayout () {
      this.showFileLayout = !this.showFileLayout;
      if (this.showFileLayout) this.dialogSize = 'lg';
      else this.dialogSize = 'md';
    },
    clearDialog () {
      this.addScenarioType = 'template';
      this.scenarioName = null;
      this.selectedForecastTemplate = 'CCAR2023Base';
      this.forecastFile = null;
      this.message = null;
      this.dialogSize = 'md';
      this.showFileLayout = false;
      this.$v.$reset();
    },
    clearErrorMessages () {
      this.message = null;
    }
  },
  components: {
    ModalDialog,
  },
  mixins: [validationState]
}
</script>