<template>
  <div>
    <b-alert
      class="ml-3 mr-3"
      :show="!tabValid('Calculations')"
      variant="danger"
    >
      Please fix the validation errors shown below
    </b-alert>
    <b-alert
      class="ml-3 mr-3"
      :show="!!errorMessage"
      variant="danger"
    >
      {{ errorMessage }}
    </b-alert>

    <loading-indicator v-if="loadingInd" />

    <scroll-container
      v-if="!errorMessage"
      v-show="!loadingInd"
      v-disable-all="analysisRunning"
    >
      <component
        :is="isLoanDynamicsAnalysis ? 'CalculationsLdm' : 'CalculationsRvd'" 
        @loading="setLoadingInd($event)"
        @error="errorMessage = $event"
      />
    </scroll-container>
  </div>
</template>

<script>
import CalculationsLdm from './CalculationsLdm.vue'
import CalculationsRvd from './CalculationsRvd.vue'
import LoadingIndicator from './LoadingIndicator.vue'
import ScrollContainer from './ScrollContainer.vue'
import {  mapGetters } from 'vuex'

export default {
  data() {
    return {
      loadingInd: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapGetters(['tabValid', 'analysisRunning', 'isLoanDynamicsAnalysis'])
  },
  methods: {
    setLoadingInd (val) {
      this.loadingInd = !!val;
    }
  },
  components: {
    CalculationsRvd,
    CalculationsLdm,
    LoadingIndicator,
    ScrollContainer
  }
};
</script>

<style>
@media (max-width: 992px) {
  .calculations.container {
    max-width: 100%;
  }
}
</style>
