<template>
  <div>
    <loading-indicator v-if="loading" />
    <div
      v-else-if="!errorMessage"
      v-disable-all="analysisRunning"
    >
      <b-alert
        :show="!tabValid('Points')"
        variant="danger"
      >
        Please fix the validation errors shown below
      </b-alert>
      <b-navbar
        toggleable="sm"
      >
        <b-navbar-nav>
          <b-nav-form>
            <div class="modern-tab-buttons">
              <b-button
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Create a points vector from a template"
                @click="showAddPointsDialog=true"
              >
                <font-awesome-icon :icon="['far','plus-square']" />
                Add Custom Points
              </b-button>
              <b-button
                variant="outline-secondary"
                v-b-tooltip.hover.bottom
                title="Fill selected cells using linear interpolation (selection must start and end with a value and be blank in between)"
                @click="fillRange"
              >
                <font-awesome-icon icon="columns" />
                Fill Range
              </b-button>
            </div>
          </b-nav-form>
        </b-navbar-nav>
      </b-navbar>
      <div class="points-data-container">
        <scroll-container
          class="grid-container"
        >
          <div>
            <points-grid
              :ref="gridRef"
              @points-updated="initPenaltyPointSets"
            />
          </div>
        </scroll-container>
      </div>
    </div>
    <add-points-dialog
      v-model="showAddPointsDialog"
      :file="file"
      @points-updated="initPenaltyPointSets"
    />
  </div>
</template>

<script>
import LoadingIndicator from './LoadingIndicator.vue'
import ScrollContainer from './ScrollContainer.vue'
import AddPointsDialog from './AddPointsDialog.vue'
import PointsGrid from './PointsGrid.vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data () {
    return {
      loading: true,
      errorMessage: null,
      showAddPointsDialog: false,
    }
  },
  async mounted () {
    try
    {
      await this.initPenaltyPointSets();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.errorMessage = 'Error loading Points tab';
    }

  },
  computed: {
    ...mapState(['activeAnalysis', 'scenarioData', 'scenarioPreviewData', 'scenarios', 'theme']),
    ...mapGetters(['fileSet', 'tabValid', 'analysisRunning', 'isAdmin']),
    gridRef() {
      return `points-grid`
    },
    file () {
      return this.fileSet.filter(f => f.enabled && !f.hidden).find(f => f.fileType == 'Points');
    }
  },
  methods: {
    ...mapMutations(['addExport']),
    ...mapActions(['getPenaltyPoints']),
    async loadGrid (data={}) {
      await this.$refs[this.gridRef].loadGrid(data);
    },
    async initPenaltyPointSets ({ pointsDeleted, newPointsAdded } = { }) {
      await this.getPenaltyPoints();
      if (this.$refs[this.gridRef]) await this.loadGrid({pointsDeleted, newPointsAdded});
    },
    async fillRange() {
      this.$refs[this.gridRef].fillRange();
    },
  },
  components: {
    LoadingIndicator,
    ScrollContainer,
    AddPointsDialog,
    PointsGrid
  },
}
</script>
<style scoped>
  .scrollable.grid-container {
    flex-basis: 65%;
  }
  .points-data-container{
    display: flex;
  }
</style>