<template>
  <section :class="cssClass">
    <header>
      <label
        :id="createId(sectionTitle+' label')"
        :class="{ clickable: chevronEnabled || switchEnabled }"
        @click="toggle"
      >{{ sectionTitle }}</label>
      
      <b-form-checkbox
        v-if="switchEnabled"
        :checked="value"
        :id="createId(sectionTitle+' checkbox')"
        :aria-label="createId(sectionTitle+' checkbox')"
        class="float-right"
        switch
        inline
        @input="handleInput"
      />
      
      <div
        v-else-if="chevronEnabled"
        v-b-tooltip.hover.right
        class="float-right"
        :title="value ? 'Collapse' : 'Expand'"
        @click="toggle"
      >
        <font-awesome-icon 
          :icon="value ? 'chevron-up' : 'chevron-down'"
          class="chevron"
          size="lg"
          :value="value"
        />
      </div>
    </header>

    <b-collapse
      class="section-body"
      :visible="value"
    >
      <slot />
    </b-collapse>
  </section>
</template>

<script>
import createId from '../js/mixins/createId'

export default {
  props: {
    value: { type: Boolean, default: true },
    sectionTitle: { type: String, default: '' },
    hideToggle: { type: Boolean, default: false },
    chevron : { type: Boolean, default: false },
    cssClass: { type: String, default: '' }
  },
  computed: {
    chevronEnabled () {
      return this.chevron && !this.hideToggle;
    },
    switchEnabled () {
      return !this.chevron && !this.hideToggle;
    }
  },
  methods: {
    toggle () {
      if (this.chevronEnabled || this.switchEnabled) {
        this.$emit('input', !this.value);
      }
    },
    handleInput (event) {
      // Find all CollapsibleOptions components
      this.findCollapsibleOptions(this.$children, event);

      // emit input event
      this.$emit('input', event);
    },
    findCollapsibleOptions (children, enabled) {
      children.forEach(c => {
        if (c.$options.name == "CollapsibleOptions") {
          // Fire handler to set any files to enabled / disabled
          c.setFileSelects(enabled && c.value);
        }
        this.findCollapsibleOptions(c.$children, enabled);
      });
    }
  },
  mixins: [createId]
}
</script>

<style scoped>
section {
  padding: 12px;
}

section > header {
  padding: 5px;
}

section > header > label {
  font-size: 1em;
  margin: 0;
}

.clickable {
  cursor: pointer;
}

.section-body {
  border-top: 1px solid #3C3E4A;
  padding: 10px 4px 4px 4px;
}

.chevron {
  cursor: pointer;
}
</style>