<template>
  <collapsible-section
    section-title="Tuning Settings"
    hide-toggle
  >
    <b-form-group
      label="Tuning Set"
      label-for="tunings-set"
      title="Select tuning set"
      :label-cols="3"
    >
      <b-select
        id="tunings-set"
        :value="tuningType"
        @input="$emit('update:tuningType', $event)"
        :options="tuningOptions"
        size="sm"
      />
    </b-form-group>

    <b-btn
      v-if="tuningType == 'Custom'"
      v-b-modal.tunings
      class="mt-2 mb-3"
    >
      Edit Tuning Values
    </b-btn>

    <b-form-checkbox
      v-if="appConfig.copyAdcoDataFiles"
      :checked="useCustomTuningFiles"
      @input="$emit('update:useCustomTuningFiles', $event)"
      @change="enableTuningFiles"
    >
      Use Custom Tuning Files
    </b-form-checkbox>

    <div v-if="useCustomTuningFiles && appConfig.copyAdcoDataFiles">
      <label>Tuning Conditions File</label>
      <file-select 
        :file-name="fileNames.tuningConditions"
        :hide-create-edit="true" 
      />

      <div class="pt-2">
        <label>Tuning Values File</label>
        <file-select 
          :file-name="fileNames.tuningValues"
          :hide-create-edit="true" 
        />
      </div>
    </div>

    <b-form-checkbox
      :checked="useCustomHpaTuningFile"
      @input="$emit('update:useCustomHpaTuningFile', $event)"
      class="pt-2 pb-1"
      @change="enableHpaTuningFile"
    >
      HPA Tuning File
    </b-form-checkbox>

    <div v-if="useCustomHpaTuningFile">
      <file-select :file-name="fileNames.hpaTune" />
    </div>

    <tunings-modal
      id="tunings"
      :tunings="customTunings"
      no-close-on-backdrop
      @save-tunings="$emit('update-tunings', $event)"
    />
  </collapsible-section>
</template>

<script>
import CollapsibleSection from './CollapsibleSection.vue'
import { fileNames } from '../js/fileInfo'
import FileSelect from './FileSelect.vue'
import TuningsModal from './TuningsModal.vue'
import { fileStatus } from "../js/fileInfo"
import { mapState, mapMutations, mapActions } from 'vuex'
import api from '../api'

export default {
  props: {
    tuningType: { type: String, required: true },
    useCustomTuningFiles: { type: Boolean, required: true },
    useCustomHpaTuningFile: { type: Boolean, required: true },
    customTunings: { type: Object, required: true }
  },
  data () {
    return {
      fileNames,
      tuningOptions: [
        { value: "Default", text: "Default Tunings" },
        { value: "Custom", text: "Custom Tunings (including risk-neutral tunings)" }
      ]
    }
  },
  computed: {
    ...mapState(['activeAnalysis', 'fileList', 'appConfig'])
  },
  methods: {
    ...mapMutations(['toggleFile', 'updateFile']),

    async enableTuningFiles(enabled) {
      this.toggleFile({ fileName: fileNames.tuningConditions, enabled });
      this.toggleFile({ fileName: fileNames.tuningValues, enabled });

    },

    async enableHpaTuningFile(enabled){
      this.toggleFile({ fileName: fileNames.hpaTune, enabled });
      
      if(enabled) {
        if (await api.getFile(this.activeAnalysis.id, "hpatune_disabled.txt", null))
          this.renameTuningFile(fileNames.hpaTune, "hpatune_disabled.txt", fileNames.hpaTune, fileStatus.posted);
      } else {
        if (this.fileList[fileNames.hpaTune].status == fileStatus.posted)
          this.renameTuningFile(fileNames.hpaTune, fileNames.hpaTune, "hpatune_disabled.txt", fileStatus.missing);
      }
    },
    async renameTuningFile(fileName, oldName, newName, status) {
      await api.renameFile(this.activeAnalysis.id, oldName, null, newName);
        this.updateFile({
          fileName: fileName,
          fileInfo: {
            fileName: newName,
            status
          }
        });
    }
  },
  components: {
    FileSelect,
    TuningsModal,
    CollapsibleSection
  }
}
</script>