import axios from 'axios'
import { fileStatus } from '../fileInfo'
import api from '../../api'
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState(['fileList']),
    ...mapGetters(['fileSet'])
  },
  methods: {
    ...mapMutations(['updateFile']),

    uploadFileList (id, files) {
      for (let i = 0; i < files.length; i++) {
        let fileName = files[i].name;
        let file = this.fileSet.find(f => fileName.toLowerCase().includes(f.fileName.toLowerCase()));
        if (file) {
          // Delete any existing data
          api.deleteInput(id, file.route).then(() => {
            this.uploadFile(id, file, files[i]);
          });
        }
      }
    },

    async uploadFile (id, fileInfo, file, onCancel) {
      let tokenSrc = axios.CancelToken.source();
      
      // Update file info
      this.setUploadingStatus(fileInfo.fileName, file, tokenSrc);

      try {
        let url = fileInfo.route ? `analyses/${id}/inputs/${fileInfo.route}` : api.getFilePath(id, fileInfo.fileName, fileInfo.folder);
        let response = await api.postFile(url, file, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          cancelToken: tokenSrc.token,
          onUploadProgress: (progressEvent) => {
            // update upload progress
            this.updateFile({ 
              fileName: fileInfo.fileName, 
              fileInfo: {
                uploadProgress: (progressEvent.loaded / progressEvent.total) * 100
              }
            });
          }
        });

        // Update file info
        this.setFileStatus(
          fileInfo.fileName,
          fileStatus.posted,
          response.data.recordsImported,
          response.data.modified
        );
        return response.data;
      } catch (err) {
        if (axios.isCancel(err)) {
          // Remove file info and reset status if the upload was canceled
          this.removeFile(id, fileInfo, null, false);

          if (onCancel && typeof onCancel === 'function') {
            onCancel(fileInfo);
          }

          return {};
        } else {
          throw err.response.data;
        }
      }
    },

    setUploadingStatus (fileName, file, tokenSrc) {
      this.updateFile({ 
        fileName, 
        fileInfo: {
          originalName: file.name,
          status: fileStatus.uploading,
          modifiedDate: file.lastModifiedDate,
          uploadProgress: 0,
          uploadCancelToken: tokenSrc || null
        }
      });
    },

    setFileStatus (fileName, status, recordCount, modifiedDate) {
      this.updateFile({ 
        fileName, 
        fileInfo: {
          status,
          recordCount,
          modifiedDate,
          uploadProgress: 0,
          uploadCancelToken: null
        }
      });
    },

    cancelUpload (tokenSrc) {
      tokenSrc.cancel('User canceled upload.');
    },

    async removeFile (id, fileInfo, fileSelect, del = true) {
      
      if (del) {
        await api.deleteFile(id, fileInfo.fileName, fileInfo.folder);
      }

      this.updateFile({ 
        fileName: fileInfo.fileName, 
        fileInfo: {
          originalName: null,
          status: fileInfo.optional ? fileStatus.optional : fileStatus.missing,
          size: null,
          modifiedDate: null,
          file: null,
          uploadProgress: 0,
          uploadCancelToken: null
        }
      });
      
      if (fileSelect) { 
       fileSelect.clear();
      }
    },
  }
}