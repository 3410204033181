<template>
  <div style="overflow: auto;">
    <b-form class="set-password-form">
      <h2>Set Password</h2>
      <b-alert
        :show="!keyInQueryString"
        variant="danger"
      >
        An application admin must have created a user for you in order to proceed.  Please use the registration key provided by the administrator.
      </b-alert>
      <b-form-group
        class="pt-3"
        label="Username"
        label-for="username"
      >
        <b-form-input
          id="username"
          v-model="$v.credentials.username.$model"
          type="text"
          :state="state($v.credentials.username)"
          autocomplete="off"
          :disabled="usernameInQueryString"
        />
        <b-form-invalid-feedback :state="state($v.credentials.username)">
          Username is required
        </b-form-invalid-feedback>
      </b-form-group>
      
      <b-form-group
        label="Registration Key"
        label-for="key"
      >
        <b-form-input
          id="key"
          v-model="$v.key.$model"
          type="text"
          :state="state($v.key)"
          :disabled="keyInQueryString"
        />
        <b-form-invalid-feedback :state="state($v.key)">
          Registration key is required
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Password"
        label-for="password"
      >
        <b-form-input
          id="password"
          v-model="$v.credentials.password.$model"
          :state="state($v.credentials.password)"
          type="password"
          autocomplete="off"
        />
        <b-form-invalid-feedback :state="state($v.credentials.password)">
          A password of at least {{ $v.credentials.password.$params.minLength.min }} characters is required
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Confirm Password"
        label-for="confirm-password"
      >
        <b-form-input
          id="confirm-password"
          v-model="$v.confirmPassword.$model"
          :state="state($v.confirmPassword)"
          type="password"
          autocomplete="off"
        />
        <b-form-invalid-feedback :state="state($v.confirmPassword)">
          Passwords must match
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="clearfix">
        <b-link
          v-b-toggle.login-options
          class="options-link"
        >
          <font-awesome-icon :icon="showOptions ? 'chevron-up' : 'chevron-down'" />
          {{ showOptions ? 'Hide' : 'More' }} Options
        </b-link>
      </div>

      <b-collapse
        id="login-options"
        v-model="showOptions"
      >
        <b-form-group
          label="Analysis Server"
          label-for="server"
        >
          <b-form-input
            id="server"
            v-model="server"
            type="text"
            :state="state($v.server)"
            autocomplete="off"
          />
          <b-form-invalid-feedback :state="state($v.server)">
            Analysis Server is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-collapse>
      
      <b-button-group>
        <b-button
          class="m-2"
          variant="primary"
          :disabled="$v.$invalid"
          @click="setPassword"
        >
          Set Password
        </b-button>
        <b-button
          to="/login"
          class="m-2"
        >
          Back to Login
        </b-button>
      </b-button-group>
      
      <div v-if="settingPassword">
        <b-spinner /> Setting password...
      </div>
      <b-alert
        :show="showError"
        variant="danger"
      >
        An error occurred: {{ errorMessage }}
      </b-alert>
    </b-form>
  </div>
</template>

<script>
import externals from '../js/externals';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import validationState from '../js/mixins/validationState';
import { mapMutations } from 'vuex';
import { setServer } from '../httpClient';
import api, { apiVersion } from '../api';
import getErrorMessage from '../js/getErrorMessage'

export default {
  data () {
    return {
      usernameInQueryString: false,
      keyInQueryString: false,
      serverInQueryString: false,
      key: null,
      server: window.location.origin,
      confirmPassword: null,
      credentials: {
        username: null,
        password: null
      },
      showError: false,
      errorMessage: "unknown error",
      showOptions: false,
      settingPassword: false
    }
  },
  validations: {
    credentials: {
      username: { required },
      password: { required, minLength: minLength(8) }
    },
    key: { required },
    confirmPassword: { required, sameAsPassword: sameAs(function() {return this.credentials.password;}) },
    server: { required }
  },
  mounted () {
    if(this.$route.query.username) {
      this.usernameInQueryString = true;
      this.credentials.username = this.$route.query.username;
    }
    if(this.$route.query.key) {
      this.keyInQueryString = true;
      this.key = this.$route.query.key;
    }
    if(this.$route.query.server) {
      this.serverInQueryString = true;
      this.server = this.$route.query.server;
    }
    else {
      let server = localStorage.getItem('kinetics_server');

      if (server) {
        this.server = server;
      }
    }
  },
  methods: {
    ...mapMutations(['setAuth', 'setAuthServer']),

    async setPassword() {

      this.showError = false;
      this.settingPassword = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let server = this.cleanServerString(this.server);
        
        localStorage.setItem('kinetics_server', server);
        this.setAuthServer(server);

        let newToken = false;

        try {
          let setPasswordResponse = await api.setPassword({Username: this.credentials.username, Password: this.credentials.password, OneUseKey: this.key});
          newToken = setPasswordResponse.data;
        }
        catch(error) {
          this.errorMessage = getErrorMessage(error, 'Unknown error setting password.');
          this.showError = true;
          this.settingPassword = false;
        }

        // if setting new password succeeded, authenticate the user and send to home page
        if(newToken) {
            let authenticationResponse = await api.authenticate(this.credentials);

            if (authenticationResponse.status == 200) {
              localStorage.setItem('kinetics_token', authenticationResponse.data);
              this.setAuth(authenticationResponse.data);
            }
            else {
              this.errorMessage = authenticationResponse;
              this.showError = true;
            }

            this.$router.push('/');
        }
      } 
      else if (this.$v.server.$error) {
        this.showOptions = true;
      }
      this.settingPassword = false;
    },
    cleanServerString(server) {
      let s = server.trim();
      if (!s.startsWith('https://') && !s.startsWith('http://')) {
        s = 'https://' + s;
      }

      if (s.endsWith('/')) {
        s = s.slice(0, -1);
      }
      return s;
    }
  },
  mixins: [validationState]
}
</script>

<style scoped>
.set-password-form {
  margin: auto;
  margin-top: 40px;
  width: 400px;
}

.options-link {
  float: right;
  font-size: 0.9rem;
  color: rgb(42, 110, 140);
}

.invalid-feedback {
    color: #d92638;
  }
</style>
