<template>
  <b-container class="ml-0 msr-inputs">
    <b-row>
      <b-col md>
        <section>
          <h2>
            Cost to Service
            <b-form-checkbox
              v-b-tooltip.hover
              :checked="costToServiceOverride"
              class="float-right"
              :title="switchTitle(costToServiceOverride)"
              switch
              @input="update('costToServiceOverride', $event)"
            >
              {{ switchLabel(costToServiceOverride) }}
            </b-form-checkbox>
          </h2>
          
          <b-row>
            <b-col md>
              <h3>Current Loan</h3>
              <div class="form-section">
                <b-form-group
                  label="Cost Per Loan ($/yr)"
                  label-for="msr-cost-per-loan"
                  :label-cols="6"
                >
                  <b-form-input
                    id="msr-cost-per-loan"
                    autocomplete="off"
                    size="sm"
                    :value="costPerLoan"
                    :state="state(v.costPerLoan)"
                    @input="update('costPerLoan', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.costPerLoan)">
                    {{ validationMessage(v.costPerLoan) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <h3>Delinquent Loan</h3>
              <div class="form-section">
                <span
                  v-for="delfield in delfields"
                  :key="delfield.valuename"
                >
                  <b-form-group
                    :label="delfield.label"
                    :label-for="'msr-' + delfield.valuename"
                    :label-cols="6"
                  >
                    <b-form-input
                      :id="'msr-' + delfield.valuename"
                      autocomplete="off"
                      size="sm"
                      :value="getvalue(delfield.valuename)"
                      :state="state(v[delfield.valuename])"
                      @input="update(delfield.valuename, $event)"
                    />
                    <b-form-invalid-feedback :state="state(v[delfield.valuename])">
                      {{ validationMessage(v[delfield.valuename]) }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </span>
                
                <b-form-group
                  label="180+ Days Cost ($/yr)"
                  label-for="msr-cost-per-seriously-delinq"
                  :label-cols="6"
                >
                  <b-form-input
                    id="msr-cost-per-seriously-delinq"
                    autocomplete="off"
                    size="sm"
                    :value="costPerSeriouslyDelinq"
                    :state="state(v.costPerSeriouslyDelinq)"
                    @input="update('costPerSeriouslyDelinq', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.costPerSeriouslyDelinq)">
                    {{ validationMessage(v.costPerSeriouslyDelinq) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              
              <h3>Foreclosure</h3>
              <div class="form-section">
                <b-form-group
                  label="Cost Per Loan ($)"
                  label-for="msr-cost-per-foreclosure"
                  :label-cols="6"
                >
                  <b-form-input
                    id="msr-cost-per-foreclosure"
                    autocomplete="off"
                    size="sm"
                    :value="costPerForeclosure"
                    :state="state(v.costPerForeclosure)"
                    @input="update('costPerForeclosure', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.costPerForeclosure)">
                    {{ validationMessage(v.costPerForeclosure) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <b-form-group
                label="Cost Inflation (%/yr)"
                label-for="msr-cost-inflation"
                :label-cols="6"
              >
                <b-form-input
                  id="msr-cost-inflation"
                  autocomplete="off"
                  size="sm"
                  :value="costInflation"
                  :state="state(v.costInflation)"
                  @input="update('costInflation', $event)"
                />
                <b-form-invalid-feedback :state="state(v.costInflation)">
                  {{ validationMessage(v.costInflation) }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </section>
        
        <section>
          <h2>
            Advance Financing
            <b-form-checkbox
              v-b-tooltip.hover 
              :checked="financingAdvOverride"
              class="float-right"
              :title="switchTitle(financingAdvOverride)"
              switch
              @input="update('financingAdvOverride', $event)"
            >
              {{ switchLabel(financingAdvOverride) }}
            </b-form-checkbox>
          </h2>

          <b-row>
            <b-col md>
              <b-form-group
                label="Leverage (%)"
                label-for="msr-leverage-adv"
                :label-cols-xl="5"
                :label-cols="3"
              >
                <b-form-input
                  id="msr-leverage-adv"
                  autocomplete="off"
                  size="sm"
                  :value="advancesLeverage"
                  :state="state(v.advancesLeverage)"
                  @input="update('advancesLeverage', $event)"
                />
                <b-form-invalid-feedback :state="state(v.advancesLeverage)">
                  {{ validationMessage(v.advancesLeverage) }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="Advances D"
                label-for="advances-d"
                :label-cols-xl="5"
                :label-cols="3"
              >
                <b-form-checkbox
                  v-b-tooltip.hover.left
                  :checked="advancesD"
                  title="Advance missed payments for loans that are 4 or less months delinquent"
                  switch
                  @input="update('advancesD', $event)"
                />
                <!-- <b-form-input id="advances-d" autocomplete="off" size="sm" :value="advancesD" @input="update('advancesD', $event)" :state="state(v.advancesD)"></b-form-input>
                <b-form-invalid-feedback :state="state(v.advancesD)">
                  {{ validationMessage(v.advancesD) }}
                </b-form-invalid-feedback> -->
              </b-form-group>

              <b-form-group
                label="Advances S"
                label-for="advances-s"
                :label-cols-xl="5"
                :label-cols="3"
              >
                <b-form-checkbox
                  v-b-tooltip.hover.left
                  :checked="advancesS"
                  title="Advance missed payments for loans through foreclosure"
                  switch
                  @input="update('advancesS', $event)"
                />
                <!-- <b-form-input id="advances-s" autocomplete="off" size="sm" :value="advancesS" @input="update('advancesS', $event)" :state="state(v.advancesS)"></b-form-input>
                <b-form-invalid-feedback :state="state(v.advancesS)">
                  {{ validationMessage(v.advancesS) }}
                </b-form-invalid-feedback> -->
              </b-form-group>

              <b-form-group
                label="Rate / Spread (%)"
                label-for="msr-fin-rate-adv"
                :label-cols-xl="5"
                :label-cols="3"
              >
                <b-form-input
                  id="msr-fin-rate-adv"
                  class="mr-2"
                  autocomplete="off"
                  size="sm"
                  :value="advancesFinanceRate"
                  :state="state(v.advancesFinanceRate)"
                  @input="update('advancesFinanceRate', $event)"
                />
                <b-form-select
                  id="msr-fin-rate-float"
                  size="sm"
                  :value="advancesFinanceRateFloat"
                  :options="rateOptions"
                  @input="update('advancesFinanceRateFloat', $event)"
                />
                <b-form-invalid-feedback :state="state(v.advancesFinanceRate)">
                  {{ validationMessage(v.advancesFinanceRate) }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </section>
      </b-col>

      <b-col md>
        <section>
          <h2>
            Earnings
            <b-form-checkbox
              v-b-tooltip.hover 
              :checked="earningsOverride"
              class="float-right"
              :title="switchTitle(earningsOverride)"
              switch
              @input="update('earningsOverride', $event)"
            >
              {{ switchLabel(earningsOverride) }}
            </b-form-checkbox>
          </h2>

          <b-row>
            <b-col md>
              <h3>Escrow Earnings</h3>

              <div class="form-section">
                <b-form-group
                  label="Rate / Spread Earned (%)"
                  label-for="msr-esc-rate-earned"
                  :label-cols-xl="5"
                  :label-cols="3"
                >
                  <b-form-input
                    id="msr-esc-rate-earned"
                    class="mr-2"
                    autocomplete="off"
                    size="sm"
                    :value="escrowRateEarned"
                    :state="state(v.escrowRateEarned)"
                    @input="update('escrowRateEarned', $event)"
                  />
                  <b-form-select
                    id="msr-esc-earned-float"
                    size="sm"
                    :value="escrowRateEarnedFloat"
                    :options="rateOptions"
                    @input="update('escrowRateEarnedFloat', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.escrowRateEarned)">
                    {{ validationMessage(v.escrowRateEarned) }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Rate / Spread Paid (%)"
                  label-for="msr-esc-rate-paid"
                  :label-cols-xl="5"
                  :label-cols="3"
                >
                  <b-form-input
                    id="msr-esc-rate-paid"
                    class="mr-2"
                    autocomplete="off"
                    size="sm"
                    :value="escrowRatePaid"
                    :state="state(v.escrowRatePaid)"
                    @input="update('escrowRatePaid', $event)"
                  />
                  <b-form-select
                    id="msr-esc-paid-float"
                    size="sm"
                    :value="escrowRatePaidFloat"
                    :options="rateOptions"
                    @input="update('escrowRatePaidFloat', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.escrowRatePaid)">
                    {{ validationMessage(v.escrowRatePaid) }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Escrow Inflation (%/yr)"
                  label-for="msr-escrow-inflation"
                  :label-cols-xl="5"
                  :label-cols="3"
                >
                  <b-form-input
                    id="msr-escrow-inflation"
                    autocomplete="off"
                    size="sm"
                    :value="escrowInflation"
                    :state="state(v.escrowInflation)"
                    @input="update('escrowInflation', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.escrowInflation)">
                    {{ validationMessage(v.escrowInflation) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <h3>Payment Float</h3>

              <div class="form-section">
                <b-form-group
                  label="Schedule Days"
                  label-for="msr-sch-float"
                  :label-cols-xl="5"
                  :label-cols="3"
                >
                  <b-form-input
                    id="msr-sch-float"
                    autocomplete="off"
                    size="sm"
                    :value="scheduleFloatDays"
                    :state="state(v.scheduleFloatDays)"
                    @input="update('scheduleFloatDays', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.scheduleFloatDays)">
                    {{ validationMessage(v.scheduleFloatDays) }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Prepay Days"
                  label-for="msr-pp-float"
                  :label-cols-xl="5"
                  :label-cols="3"
                >
                  <b-form-input
                    id="msr-pp-float"
                    autocomplete="off"
                    size="sm"
                    :value="prepayFloatDays"
                    :state="state(v.prepayFloatDays)"
                    @input="update('prepayFloatDays', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.prepayFloatDays)">
                    {{ validationMessage(v.prepayFloatDays) }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Rate / Spread Earned (%)"
                  label-for="msr-float-rate-earned"
                  :label-cols-xl="5"
                  :label-cols="3"
                >
                  <b-form-input
                    id="msr-float-rate-earned"
                    class="mr-2"
                    autocomplete="off"
                    size="sm"
                    :value="paymentFloatRateEarned"
                    :state="state(v.paymentFloatRateEarned)"
                    @input="update('paymentFloatRateEarned', $event)"
                  />
                  <b-form-select
                    id="msr-float-earned-float"
                    size="sm"
                    :value="paymentFloatRateFloat"
                    :options="rateOptions"
                    @input="update('paymentFloatRateFloat', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.paymentFloatRateEarned)">
                    {{ validationMessage(v.paymentFloatRateEarned) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <h3>Ancillary Income</h3>

              <div class="form-section">
                <b-form-group
                  label="Income Per Loan ($/yr)"
                  label-for="msr-income-per-loan"
                  :label-cols-xl="5"
                  :label-cols="3"
                >
                  <b-form-input
                    id="msr-income-per-loan"
                    autocomplete="off"
                    size="sm"
                    :value="incomePerLoan"
                    :state="state(v.incomePerLoan)"
                    @input="update('incomePerLoan', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.incomePerLoan)">
                    {{ validationMessage(v.incomePerLoan) }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Income Per Payment (%)"
                  label-for="msr-income-per-payment"
                  :label-cols-xl="5"
                  :label-cols="3"
                >
                  <b-form-input
                    id="msr-income-per-payment"
                    autocomplete="off"
                    size="sm"
                    :value="incomePerPayment"
                    :state="state(v.incomePerPayment)"
                    @input="update('incomePerPayment', $event)"
                  />
                  <b-form-invalid-feedback :state="state(v.incomePerPayment)">
                    {{ validationMessage(v.incomePerPayment) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </section>

        <section>
          <h2>
            Excess Servicing
            <b-form-checkbox
              v-b-tooltip.hover 
              :checked="excessServicingOverride"
              class="float-right"
              :title="switchTitle(excessServicingOverride)"
              switch
              @input="update('excessServicingOverride', $event)"
            >
              {{ switchLabel(excessServicingOverride) }}
            </b-form-checkbox>
          </h2>

          <b-row>
            <b-col md>
              <b-form-group
                label="Base Servicing Fee Cut-off (%)"
                label-for="base-servicing"
                :label-cols-xl="5"
                :label-cols="3"
              >
                <b-form-input
                  id="base-servicing"
                  autocomplete="off"
                  size="sm"
                  :value="baseServicing"
                  :state="state(v.baseServicing)"
                  @input="update('baseServicing', $event)"
                />
                <b-form-invalid-feedback :state="state(v.baseServicing)">
                  {{ validationMessage(v.baseServicing) }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </section>
        
        <section>
          <h2>
            Premium Recapture
            <b-form-checkbox
              v-b-tooltip.hover 
              :checked="premiumRecaptureOverride"
              class="float-right"
              :title="switchTitle(premiumRecaptureOverride)"
              switch
              @input="update('premiumRecaptureOverride', $event)"
            >
              {{ switchLabel(premiumRecaptureOverride) }}
            </b-form-checkbox>
          </h2>

          <b-row>
            <b-col md>
              <b-form-group
                label="Recapture Months"
                label-for="msr-recapture-months"
                :label-cols="6"
              >
                <b-form-input
                  id="msr-recapture-months"
                  autocomplete="off"
                  size="sm"
                  :value="recaptureMonths"
                  :state="state(v.recaptureMonths)"
                  @input="update('recaptureMonths', $event)"
                />
                <b-form-invalid-feedback :state="state(v.recaptureMonths)">
                  {{ validationMessage(v.recaptureMonths) }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="Recapture Percent"
                label-for="msr-recapture-percent"
                :label-cols="6"
              >
                <b-form-input
                  id="msr-recapture-percent"
                  autocomplete="off"
                  size="sm"
                  :value="recapturePercent"
                  :state="state(v.recapturePercent)"
                  @input="update('recapturePercent', $event)"
                />
                <b-form-invalid-feedback :state="state(v.recapturePercent)">
                  {{ validationMessage(v.recapturePercent) }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </section>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import validationState from '../js/mixins/validationState'

const propValidator = (prop) => typeof prop === 'string' || typeof prop === 'number' || prop === null

export default {
  mixins: [validationState],
  props: {
    costInflation: { validator: propValidator, required: true },
    escrowInflation: { validator: propValidator, required: true },
    costPerLoan: { validator: propValidator, required: true },
    costPerD1: { validator: propValidator, required: true },
    costPerD2: { validator: propValidator, required: true },
    costPerD3: { validator: propValidator, required: true },
    costPerD4: { validator: propValidator, required: true },
    costPerD5: { validator: propValidator, required: true },
    costPerSeriouslyDelinq: { validator: propValidator, required: true },
    costPerForeclosure: { validator: propValidator, required: true },
    prepayFloatDays: { validator: propValidator, required: true },
    advancesLeverage: { validator: propValidator, required: true },
    incomePerLoan: { validator: propValidator, required: true },
    incomePerPayment: { validator: propValidator, required: true },
    scheduleFloatDays: { validator: propValidator, required: true },
    escrowRateEarned: { validator: propValidator, required: true },
    escrowRateEarnedFloat: { type: Boolean, required: true },
    escrowRatePaid: { validator: propValidator, required: true },
    escrowRatePaidFloat: { type: Boolean, required: true },
    paymentFloatRateEarned: { validator: propValidator, required: true },
    paymentFloatRateFloat: { type: Boolean, required: true },
    recaptureMonths: { validator: propValidator, required: true },
    recapturePercent: { validator: propValidator, required: true },
    advancesFinanceRate: { validator: propValidator, required: true },
    advancesFinanceRateFloat: { type: Boolean, required: true },
    baseServicing:  { validator: propValidator, required: true },
    costToServiceOverride: { type: Boolean, required: true },
    financingAdvOverride: { type: Boolean, required: true },
    earningsOverride: { type: Boolean, required: true },
    excessServicingOverride: { type: Boolean, required: true },
    premiumRecaptureOverride: { type: Boolean, required: true },
    advancesS: { type: Boolean, required: true },
    advancesD: { type: Boolean, required: true },
    v: { type: Object, required: true }
  },
  data () {
    return {
      rateOptions: [
        { value: false, text: 'fixed' },
        { value: true, text: 'float' }
      ],
      delfields: [
        {label: "30 Days Cost ($/yr)", valuename: 'costPerD1'},
        {label: "60 Days Cost ($/yr)", valuename: 'costPerD2'},
        {label: "90 Days Cost ($/yr)", valuename: 'costPerD3'},
        {label: "120 Days Cost ($/yr)", valuename: 'costPerD4'},
        {label: "150 Days Cost ($/yr)", valuename: 'costPerD5'}
      ]    
    }
  },
  methods: {
    getvalue(label) {
      return this[label];
    },
    update (name, value) {
      this.v[name] && this.v[name].$touch();
      this.$emit(`update:${name}`, value);
    },
    switchLabel (val) {
      return val ? 'Override' : 'Defaults'
    },
    switchTitle (val) {
      return val ? 'Values in this section override loan file attributes.' : 'Values in this section are used as defaults. Loan file attributes will take precedence.';
    }
  }
}
</script>

<style>
.msr-inputs .form-group,
.msr-inputs .form-table-row {
  margin-bottom: 0.75rem;
}

.msr-inputs input,
.msr-inputs select {
  display: inline-block;
  max-width: 100px;
}

.form-section {
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 10px;
}

.col-align-bottom {
  margin-top: auto;
}

.msr-inputs h3 {
  font-size: 0.9em;
  font-weight: bold;
}

.msr-inputs section > h2 > .custom-switch > label {
  padding-top: 5px;
  width: 63px;
  font-size: 0.8em;
}

.msr-inputs section > h2 > .custom-switch .custom-control-label::after {
  background-color: #276681;
}

@media (max-width: 992px) {
  .msr-inputs.container {
    max-width: 100%;
  }
}

</style>
