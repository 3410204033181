import { findIconDefinition, icon } from '@fortawesome/fontawesome-svg-core'

function attachGridHeaderEventHandler (grid, actions) {
  grid.hostElement.addEventListener("click", (e) => {
    let action, column, element;
    
    // Search for action attribute until we get to the grid cell
    for (let el of e.composedPath()) {
      if (!el.getAttribute) {
        continue;
      }
      
      action = el.getAttribute("data-action");
      
      if (action) {
        element = el;
        column = el.getAttribute("data-column");
        break;
      }

      // Stop search at grid cell
      if (el.classList.contains('wj-cell')) {
        return;
      }
    }

    if (action) {
      actions[action]({ column, element });
    }
  });
}

function createCheckbox (id, checked, action, column) {
  let ck = document.createElement('input');
  ck.type = 'checkbox';
  ck.id = id;
  ck.checked = checked;
  ck.title = 'Enable / Disable Scenario';
  ck.setAttribute('data-action', action);
  ck.setAttribute('data-column', column);

  return ck;
}

function createGridIconButton (prefix, iconName, action, column, title) {
  let btn = document.createElement('button');
  btn.type = "button";
  btn.className = "btn icon-button theme-item";
  btn.title = title;
  btn.setAttribute('data-action', action);
  btn.setAttribute('data-column', column);

  const iconDef = findIconDefinition({ prefix, iconName })
  const iconSvg = icon(iconDef);
  btn.appendChild(iconSvg.node[0]);
  return btn;
}

function createScenarioLabel (text) {
  let lbl = document.createElement('span');
  lbl.className = 'scenario-name';
  lbl.innerText = text;
  lbl.title = text;

  return lbl;
}

// Be sure to bind "this" to the ScenarioGrid vue component
function gridHeaderFormatter (s, e) {
  if (e.panel == s.columnHeaders) {
    // Ignore preview column
    let scenario = this.getColumnMetaData(e.col);
    if (!scenario || scenario.isPreview || scenario.isDefault) {

      // Allow toggling default scenario
      if (this.isAutoAnalysis && scenario.isDefaultScenario && e.row == 0) {
        let ck = createCheckbox('toggle-scenario-default', this.calcs.enableDefaultScenario, 'toggle', e.col);
        ck.style.marginLeft = '10px'
        e.cell.appendChild(ck);
      }

      return;
    }

    // Add scenario label
    if (e.row == 0) {
      let lbl = createScenarioLabel(e.cell.innerText);
      e.cell.innerText = '';
      e.cell.appendChild(lbl);
    }

    // Add grid controls
    if (this.isAutoAnalysis && e.row == 0) {
      e.cell.appendChild(createGridIconButton('fas', 'pen', 'edit', e.col, 'Rename Scenario'));
      e.cell.appendChild(createGridIconButton('fas', 'trash', 'delete', e.col, 'Delete Scenario'));

      let id = `toggle-scenario-${scenario.scenarioId}`;
      e.cell.appendChild(createCheckbox(id, scenario.enabled, 'toggle', e.col));
    } else if (!this.isAutoAnalysis && e.row == 1) {
      e.cell.appendChild(createGridIconButton('fas', 'file-export', 'export', e.col, 'Export Forecast'));
      e.cell.appendChild(createGridIconButton('fas', 'trash', 'delete', e.col, 'Delete Forecast'));
    }
  }
  
};

export { attachGridHeaderEventHandler, gridHeaderFormatter }