import ResultItem, { resultTypes } from './ResultItem'
import api from '../../api'

function getErrorColumns() {
  return [
    { binding: 'recordIndex', label: 'Line Number' },
    { binding: 'loanId', label: 'Loan ID' },
    { binding: 'errorMessage', label: 'Error Message' }
  ];
}

async function getTableData (analysis, options) {
  let data = await api.odataQuery(analysis.id, this.collectionName, options.params);

  if (!data.value) {
    return [];
  }

  // Adjust to 1 based index
  data.value.forEach(i => i.recordIndex++);

  return data;
}

async function getFileData (analysis, options) {
  let data = await api.getResults(analysis.id, this.collectionName, 1000);

  if (!data) {
    return [];
  }

  return data;
}

export default function createErrorResultItem(importResultsEnabled=true) {
  return new ResultItem ({
    name: "Error Results",
    columns: getErrorColumns,
    resultType: resultTypes.error,
    collectionName: 'LdmErrorResults',
    getData: importResultsEnabled ? getTableData : getFileData
  });
}