<template>
  <div
    class="cash-flow-filters"
    v-if="isCashflowResult"
  > 
    <div
      class="filter-option"
      v-if="notDisplayLoanType"
    >
      <label for="cash-flow-select"> Cash Flow Type</label>
      <multiselect
        id="cash-flow-select"
        :value="selectedCashFlows"
        :options="cashFlowOptions"
        :close-on-select="true"
        open-direction="bottom"
        :show-labels="false"
        @select="onCashFlowChange"
      />
    </div>

    <div
      class="filter-option"
      v-if="displayLoanSelection"
    >
      <label for="cash-flow-loan">Loan Id</label>
      <multiselect
        id="cash-flow-loanId"
        v-model="selectedLoanId" 
        :options="loanIds"
        :options-limit="1000"
        :close-on-select="true"
        :show-labels="false"
        :allow-empty="false"
        open-direction="bottom"
        @search-change="getLoanIds"
        @close="buildTheSelectedFile"
      />
      <!-- @select="buildTheSelectedFile" -->
    </div>
    <div
      class="filter-option"
      v-if="displayMcPathSelection"
    >
      <label for="cash-flow-mcPath">Monte Carlo Path</label>
      <multiselect
        id="cash-flow-mcPath"
        v-model="selectedMcPath" 
        :options="mcPaths"
        :options-limit="1000"
        :close-on-select="true"
        :show-labels="false"
        :allow-empty="false"
        open-direction="bottom"
        @close="buildTheSelectedFile"
      />
    </div>

    <div
      class="filter-option"
      v-if="displayScenarioSelection"
    >
      <label for="cash-flow-scenario">Scenario</label>
      <multiselect
        id="cash-flow-scenario"
        v-model="selectedScenario" 
        :options="senarios"
        :close-on-select="true"
        :show-labels="false"
        :allow-empty="false"
        open-direction="bottom"
        @close="buildTheSelectedFile"
      />
    </div>
  </div>       
</template>

<script>
import Multiselect from 'vue-multiselect'
import createId from '../js/mixins/createId'
import resize from 'vue-resize-directive'
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import api from "../api";


export default {
  props: {
    isCashflowResult:{type: Boolean},
    availableCashFlows:{type:Array},
    mcPath:{type: Number},
    setCashFlowFileSelected:{type: Function},
    calcs:{type: Object},
    loanId:{type: String},
    path:{type: String},
    cashflowType:{type: String}
  },
  data () {
    return {
      loanIds:[],
      mcPaths:[],
      senarios:[],
      selectedMcPath: null,
      selectedLoanId: null,
      selectedScenario: null,
      selectedCashFlows:[],
      selectedCashFlow: null,
      cashFlowOptions:[],
      showCCAR: false
    }
  },
  async mounted () {
    await this.initOptions();
  },
  computed: {
    ...mapState(['activeAnalysis', 'selectedFields']),
    ...mapGetters(['selectedFilters', 'oasVersion']),

    selectedCashFlowsArray (){
      return ( this.availableCashFlows)
    },
    displayLoanSelection (){       
      return this.selectedCashFlowsArray.includes('Loan Level')
    },
    displayMcPathSelection(){    
      return (this.selectedCashFlowsArray.includes('Monte Carlo') || (this.selectedCashFlowsArray.includes('Averaged MC'))) && this.selectedCashFlows == 'Monte Carlo'
    },
    displayScenarioSelection(){       
      return this.selectedCashFlowsArray.includes('Scenario') && this.selectedCashFlows == 'Scenario'
    },
    notDisplayLoanType (){       
      return this.selectedCashFlows != 'Loan Level'
    },

  },
  methods: {
    async initOptions () {
      this.showCCAR = this.calcs?.showCCAR;
      if (this.isCashflowResult) {
        this.selectedCashFlow = this.cashflowType;
        if(this.availableCashFlows.includes('Scenario')) {
          let scenarioResult = await api.getFile(this.activeAnalysis.id, 'scenarios');
          this.senarios = scenarioResult.map(ele => ele['SCENARIO_#'])
          this.senarios.unshift('Average')
          if(this.showCCAR){
            this.senarios = [...this.senarios,'BASE','SEV','STAVG']
          }
          this.selectedScenario = this.path && this.selectedCashFlow == 'Scenario' ? this.path: this.senarios[0]
        }

        if (this.availableCashFlows.includes('Loan Level')) {
          let data = await api.odataQuery(this.activeAnalysis.id, "positions", {
                $top: 1000,
                $select: 'loanId',
                $filter: this.getLoanIdFilter(''),
                $count: true
              });
              if(this.oasVersion == 'main' || (!isNaN(this.oasVersion) && (parseInt(this.oasVersion.split('.')[0]) >= 9 && parseInt(this.oasVersion.split('.')[1]) > 0))){
                this.loanIds = ['Average',...data.value.map(x => x.loanId)];
              }
              else
              this.loanIds = data.value.map(x => x.loanId);
            this.selectedLoanId = this.loanId ? this.loanId: this.loanIds[0];
        }

        this.cashFlowOptions = this.availableCashFlows.slice()        
        if(this.cashFlowOptions.includes('Loan Level')){
          this.cashFlowOptions = this.cashFlowOptions.filter(e => e !== 'Loan Level')
        }

        if (this.cashFlowOptions.includes('Averaged MC')) {
          let index = this.cashFlowOptions.indexOf('Averaged MC')
          this.cashFlowOptions.splice(index,1)
          if (!this.cashFlowOptions.includes('Monte Carlo')) {
            this.cashFlowOptions.push('Monte Carlo')
          }
        }
        this.selectedCashFlows= this.cashflowType ? this.cashflowType : 
        (this.cashFlowOptions[0] ? this.cashFlowOptions[0]: this.availableCashFlows[0]);
        this.buildMcPaths();
      }
    },
    debounceLoanIdSearch: debounce(async function (query) {
      if(query){
        this.loanIds=[]
              let data = await api.odataQuery(this.activeAnalysis.id, "positions", {
              $top: 1000,
              $select: 'loanId',
              $filter: this.getLoanIdFilter(query),
              $count: true
            });
        this.loanIds = data.value.map(x => x.loanId);
      }
    }, 500),

    async getLoanIds (query) {
          this.debounceLoanIdSearch(
            query
          );
    },

    getLoanIdFilter (query) {
      let filter = `contains(tolower(loanId), tolower('${query}'))`;
        
      if (this.calcs.useHedgeFile !== true) {
        filter += " and (positionType ne 'HedgeLoanPosition') and (positionType ne 'HedgePoolPosition')";
      }

      // Don't show TBAs
      filter += " and (positionType ne 'TbaPoolPosition')";
      
      return filter;
    },

     buildTheSelectedFile(){
       if (this.selectedCashFlows) {
         let selectedPath = this.selectedCashFlows == 'Monte Carlo' ? this.selectedMcPath?.toString() : this.selectedScenario?.toString();
         this.setCashFlowFileSelected(this.selectedCashFlows?.toString(), this.selectedLoanId == 'Average' ? null: this.selectedLoanId, selectedPath);
       }
    },
    buildMcPaths(){
      if(this.selectedCashFlows.includes('Monte Carlo') && this.availableCashFlows.includes('Monte Carlo')){
         this.mcPaths=['Average',...Array(this.mcPath).keys()].map(i=> i.toString())
        if(this.showCCAR){
          this.mcPaths = [...this.mcPaths,'BASE','SEV']
        }
      } else{
         this.mcPaths=[]
      }
      if(this.selectedCashFlows.includes('Monte Carlo') && this.availableCashFlows.includes('Averaged MC') && !this.mcPaths.includes('Average')){
        this.mcPaths=['Average']
      }
      this.selectedMcPath= this.path && this.selectedCashFlow == 'Monte Carlo' ? this.path : this.mcPaths[0];
    },
    onCashFlowChange(value){
      this.selectedCashFlows=[value]
      this.selectedCashFlow = this.selectedCashFlows
    }
  },
  watch: {
    selectedCashFlows(){
      this.buildMcPaths();
      this.buildTheSelectedFile();
    },
  },
  components: {
    Multiselect,
  },
  directives: {
    resize
  },
  mixins: [createId]
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style >
.table-cash-flow-filters .cash-flow-filters{
  display: flex;
}
.table-cash-flow-filters .multiselect .multiselect__tags {  
  padding: 4px 22px 0 8px;
  min-height: 25px;
}

.table-cash-flow-filters .multiselect__select{

  width: 20px;
  height: 20px;
  right: 5px;
  top: 9px;
}

.filter-option{
  margin-bottom: 10px;
}

.table-cash-flow-filters .filter-option{
  padding: 0 20px 0 0;
  margin-bottom: 0px;
}

.table-cash-flow-filters .filter-option label{
  justify-content: flex-start;
}

.filter-option label{
  white-space: nowrap;
  display: block;
  font-size: 14px;
}

.cash-flow-filters .multiselect__content-wrapper{
  border: 0.1px solid #8f938f;
}

@media only screen and (max-width: 768px) {
  .table-cash-flow-filters .cash-flow-filters{
    margin-top: 10px;
  }
}

</style>
