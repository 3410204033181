import { fileStatus } from '../js/fileInfo'
import { apiVersion } from '../api'
import moment from 'moment'
import { statusCodes } from '../js/options/statusCodes'
import { createCalcs } from '../js/calcs'
import { runTypes } from '../js/options/runType'
import { parseToken, tokenValid } from '../js/tokenParser'
import { assetTypes } from '../js/options/assetType'

const singleFamilyModels = ['AgencyLDM', 'AgencyLDMPlus', 'NonAgencyLDM'];

export default {
  isLoggedIn: state => state.authentication.enabled === false || tokenValid(state.authentication.token),
  username: state => state.authentication.enabled === true ? parseToken(state.authentication.token)['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] : "User",
  roles: state => state.authentication.enabled === true ? parseToken(state.authentication.token)['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : "User",
  userid: state => state.authentication.enabled === true ? parseToken(state.authentication.token)['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] : 0,
  isAdmin: (state, getters) => getters.roles.indexOf('Admin') > -1,
  displayname: state => state.authentication.enabled === true ? parseToken(state.authentication.token)['http://www.ad-co.com/claims/displayname'] : "",
  fileSet: state => state.fileNames.map(name => state.fileList[name]),
  uploadInProgress: (state, getters) => getters.fileSet.some(f => f.status == fileStatus.uploading),
  analysisValid: state => {
    const inputsValid = !state.fileNames
    .map(name => state.fileList[name])
    .filter(f => f.enabled)
    .some(f => f.status == fileStatus.missing)

    return state.validationStatus.every(item => item.isValid === true) && 
      (inputsValid || state.activeAnalysis.runType == runTypes.loanDynamics);
  },
  tabValid: state => (tabName) => {
    const items = state.validationStatus.filter(item => item.tab == tabName);
    return items ? items.every(item => item.isValid === true) : true;
  },
  saveStatus: (state, getters) => state.savePending ? 'Saving...' : 
    getters.validationError ? 'Validation Error' :
    state.isSaved ? `Saved at ${state.lastSaved}` : '',
  validationError: state => state.validationStatus.some(s => !s.isValid),
  analysisDirty: state => apiVersion == 1 ? false : moment(state.activeAnalysis.modifiedDate).isAfter(state.activeAnalysis.lastRunDate),
  analysisStatus: state => state.activeAnalysis && state.activeAnalysis.status,
  analysisRunning : state => state.activeAnalysis && state.activeAnalysis.status.executionStatus == statusCodes.running,
  analysisError : state => state.activeAnalysis && state.activeAnalysis.status.executionStatus == statusCodes.error,
  analysisSuccess : state => state.activeAnalysis && state.activeAnalysis.status.executionStatus == statusCodes.success,
  analysisNotRun : state => state.activeAnalysis && state.activeAnalysis.status.executionStatus == statusCodes.notRun,
  analysisCanceled : state => state.activeAnalysis && state.activeAnalysis.status.executionStatus == statusCodes.canceled,
  analysisQueued : state => state.activeAnalysis && state.activeAnalysis.status.executionStatus == statusCodes.queued,
  calcs: state => createCalcs(state.activeAnalysis),
  gridRoutes: state => state.fileNames.reduce((acc, cur) => {
    let route = state.fileList[cur].route;
    let gridOptions = state.fileList[cur].gridOptions;

    if (route && gridOptions) {
      acc[route] = gridOptions;
    }
    return acc;
  }, {}),
  isExporting: state => state.exportList.length > 0,
  selectedFilters: state => state.selectedFilters,
  ldmVersion: state => state.activeAnalysis ? state.licenseInfo.modules.find(m => m.assetType == state.activeAnalysis.assetType).ldmVersion : '',
  ldmVersionMajor: (state, getters) => parseInt(getters.ldmVersion.split('.')[0]),
  ldmVersionMinor: (state, getters) => parseInt(getters.ldmVersion.split('.')[1]),
  ldmVersionRev: (state, getters) => parseInt(getters.ldmVersion.split('.')[2]),
  oasVersion: state => state.activeAnalysis ? state.licenseInfo.modules.find(m => m.assetType == state.activeAnalysis.assetType).oasVersion : '',
  scenarioHeaderItems: state => {
    if (_.isEmpty(state.scenarioPreviewData)) return state.scenarios;
    let res = state.scenarios.slice(0);
    let idx = state.scenarios.findIndex(s => s.scenarioId == state.scenarioPreviewData.baseScenarioId);
    res.splice(idx+1, 0, {
      preview: true,
      scenarioName: state.scenarioPreviewData.scenarioName
    });
    return res;
  },
  isOnlyLicensingAgency: state => {
    let userSingleFamilyModels = state.licenseInfo.models.filter(m => singleFamilyModels.includes(m.id));
    return userSingleFamilyModels.length >= 1 && userSingleFamilyModels.every(m => m.id === 'AgencyLDM' || m.id === 'AgencyLDMPlus')
  },
  isOnlyLicensingNonAgency: state => {
    let userSingleFamilyModels = state.licenseInfo.models.filter(m => singleFamilyModels.includes(m.id));
    return userSingleFamilyModels.length >= 1 && userSingleFamilyModels.every(m => m.id === 'NonAgencyLDM')
  },
  hasDefaultDefinitionOptions: state => {
    let userSingleFamilyModels = state.licenseInfo.models.filter(m => singleFamilyModels.includes(m.id));
    return userSingleFamilyModels.length >= 1
      && !userSingleFamilyModels.every(m => m.id == "NonAgencyLDM")
      && !userSingleFamilyModels.every(m => m.id == "AgencyLDM")
  },
  isLicensingAgencyPlus: state => state.licenseInfo.models.some(m => m.id == 'AgencyLDMPlus'),
  isLoanDynamicsAnalysis: state => state.activeAnalysis.runType == runTypes.loanDynamics,
  isRiskValDynamicsAnalysis: state => state.activeAnalysis.runType == runTypes.riskValDynamics,
  isMsrAnalysis: state => state.activeAnalysis.runType == runTypes.riskValDynamics && state.activeAnalysis.assetType == assetTypes.servicing,
  isLdmSingleFamily: state => state.activeAnalysis.runType == runTypes.loanDynamics && state.activeAnalysis.assetType == assetTypes.singleFamilyLoans,
}