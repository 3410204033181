export default {
  title: 'Forecast',
  entitySet: 'Forecast',
  columns: [
    { binding: 'date', header: 'Date', format: 'MM-yyyy', isReadOnly: false, desc: 'Forecast date', example: '2021-05', type: 'string', isRequired: true },
    { binding: 'forecast', header: 'Forecast', desc: 'Forecast value', example: '3.7', type: 'double', isRequired: true }
  ],
  editOptions: {
    keys: ['id'],
    allowAddNew: true,
    allowDelete: true,
    allowSave: true
  },
  defaultSort: [
    { property: 'date' }
  ]
}