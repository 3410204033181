import { DataType } from '@grapecity/wijmo'
import { decimal, between } from 'vuelidate/lib/validators'

export default {
  title: 'Yield Curves',
  entitySet: 'YieldCurves',
  columns: [
    { binding: 'term', header: 'Term', isRequired: true, isReadOnly: true, desc: 'Valid Term values: 1M, 3M, 6M, 1Y, 2Y, 3Y, 4Y, 5Y, 7Y, 10Y, 15Y, 20Y, 25Y, 30Y', type: 'string' },
    { binding: 'treasury', header: 'Treasury', isRequired: true, dataType: DataType.Number, format: 'g15', validations: { decimal, between: between(-100, 100) }, desc: 'Treasury rate for the specified term', type: 'double' },
    { binding: 'libor', header: 'LIBOR', isRequired: true, dataType: DataType.Number, format: 'g15', validations: { decimal, between: between(-100, 100) }, desc: 'LIBOR rate for the specified term', type: 'double' },
    { binding: 'sofr', header: 'SOFR', isRequired: true, dataType: DataType.Number, format: 'g15', validations: { decimal, between: between(-100, 100) }, desc: 'SOFR rate for the specified term', type: 'double' }
  ],
  editOptions: {
    keys: ['id'],
    allowAddNew: false,
    allowDelete: false,
    allowSave: true
  }
}
