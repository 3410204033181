<template>
  <button
    :class="btnClassList"
    type="button"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <font-awesome-icon 
      :class="iconClassList"
      :icon="icon"
      :size="size"
    />
    <span>
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    icon: { type: [String, Array], required: true },
    size: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    iconClass: { type: String, default: ''},
    themeClass: { type: String, default: 'theme-item' },
    bordered: { type: Boolean, default: false }
  },
  computed: {
    btnClassList () {
      return `icon-button ${this.themeClass} ${this.bordered ? 'bordered' : ''}`;
    },
    iconClassList () {
      return `${this.iconClass} ${this.$slots.default ? 'mr-2' : '' }`;
    }
  }
}
</script>
