<template>
  <div>
    <div class="container-fluid">
      <loading-indicator v-if="gridLoading" />
      <div 
        :id="`grid-points`" 
        class="points-grid"
      />
      <modal-dialog
        ref="invalidSelectionDialog"
        title="Invalid Fill Range Selection"
        error-message="An error occurred."
        confirm-button-text="Dismiss"
        :ok-only="true"
      >
        <ul>
          <li
            v-for="message in fillRangeErrorMessages"
            :key="message"
          >
            {{ message }}
          </li>
        </ul>
      </modal-dialog>
    </div>
    <rename-points-dialog
      v-model="showRenamePointsDialog"
      :points="pointsToRename"
      @points-updated="$emit('points-updated')"
    />
    <modal-dialog
      ref="confirmDeletePointsDialog"
      title="Delete Points"
      :prompt="`Are you sure you want to delete ${pointsToDelete.penaltyPointSetName}?`"
      :action="deletePoints"
      error-message="An error occurred deleting the points."
      confirm-button-text="Delete"
    />
  </div>
</template>

<script>
import LoadingIndicator from './LoadingIndicator'
import PointsGrid from "../js/gridExtensions/PointsGrid"
import { attachGridHeaderEventHandler, gridHeaderFormatter } from "../js/gridExtensions/PointsGridHeader"
import ModalDialog from './ModalDialog.vue'
import RenamePointsDialog from './RenamePointsDialog.vue'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import api from "../api";
import { DataType } from '@grapecity/wijmo'
import { decimal, required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      gridLoading: true,
      columns: [],
      columnMetaData: [],
      defaultColumns: [
        { header: "Age", binding: "age", format: "d", isReadOnly: true }
      ],
      fillRangeErrorMessages: [],
      newPointsAdded: false, 
      pointsDeleted: false,
      pointsToDelete: {},
      showRenamePointsDialog: false,
      pointsToRename: {},
    };
  },
  async mounted() {
    this.defaultColumns.push({
      header: "AD&Co Default",
      columns: [{
        binding: "_AD&Co Default.Points",
        header: "Points",
        isReadOnly: true,
        dataType: DataType.Number
      }]
    });

    await this.loadGrid();
  },
  computed: {
    ...mapState(["activeAnalysis", "penaltyPointSets", "isValidating"]),
    ...mapGetters(["calcs"]),
  },
  methods: {
    ...mapMutations(['setPointsData', 'updateValidationStatus', 'setAnalysisModified', 'addExport', 'setPortfolioGridDirty']),
    ...mapActions(['autoSaveItem', 'saveAnalysisSettings']),

    async loadGrid (data = {}) {
      let { pointsDeleted, newPointsAdded } = data
      this.pointsDeleted = pointsDeleted;
      this.newPointsAdded = newPointsAdded;

      this.gridLoading = true;
      this.columnMetaData = [];
      if (this.$options.grid) this.$options.grid._grid.dispose();
      this.columns = this.defaultColumns.slice(0);
      this.defaultColumns.forEach(c => {
        if (c.columns) {
          this.columnMetaData.push(...c.columns.map(x => ({ isDefault: true, isDefaultPoints: x.binding.startsWith('AD&Co Default') })));
        } else {
          this.columnMetaData.push({ isDefault: true });
        }
      });

      this.penaltyPointSets.forEach((s) => {
        if (s.isDefaultPoints) return;
      
        let col = {
          header: s.penaltyPointSetName,
          columns: [{
            header: "Points",
            binding: `_${s.penaltyPointSetId}.Points`,
            isReadOnly: false,
            isRequired: false,
            dataType: DataType.Number,
            validations: { required, decimal }
          }]
        }
        this.columns.push(col);
        this.columnMetaData.push(Object.assign({}, s));
      });

      let gridOptions = {
        analysisId: this.activeAnalysis.id,
        loaded: () => { this.gridLoading = false },
        getItems: this.getItems,
        patchItem: this.patchItem,
        stickyHeaders: true,
        formatItem: gridHeaderFormatter.bind(this)
      }

      gridOptions.columnGroups = this.columns;

      this.$options.grid = new PointsGrid(`#grid-points`, gridOptions);

      attachGridHeaderEventHandler(this.$options.grid._grid, {
        delete: (data) => {
          this.pointsToDelete = this.getColumnMetaData(data.column);
          this.confirmDeletePoints();
        },
        edit: (data) => {
          this.pointsToRename = this.getColumnMetaData(data.column);
          this.showRenamePointsDialog = true;
        }
      });
    },
    getColumnMetaData (column) {
      let col = this.columnMetaData[column];

      if (col) {
        return this.columnMetaData[column];
      } else {
        return null;
      }
    },
    confirmDeletePoints () {
      this.$refs.confirmDeletePointsDialog.showDialog();
    },
    async deletePoints () {
      await api.deletePenaltyPointSet(this.activeAnalysis.id, this.pointsToDelete.penaltyPointSetId);
      // Reload portfolio grid on reactivation to include new points in Custom Points dropdown
      this.setPortfolioGridDirty(true);
      this.$emit('points-updated', { pointsDeleted: true });
    },
    async getItems() {
      let res = [];
      let allData = [];

      for (let p of this.penaltyPointSets) {
        let penaltyPointSet = p.penaltyPointSetId == "AD&Co Default" ? await api.getPenaltyPointSetDefault(this.activeAnalysis.id) : await api.getPenaltyPointSet(this.activeAnalysis.id, p.penaltyPointSetId);
        allData[p.penaltyPointSetName] = penaltyPointSet.points;
      };

      for (let i = 0; i < allData['AD&Co Default'].length; i++) {
        let row = { age: i };
        this.penaltyPointSets.forEach(p => row[`_${p.penaltyPointSetId}`] = { 'Points' : allData[p.penaltyPointSetName][i]})
        res.push(row)
      }

      this._pointsGridData = res;
      this.pointsDeleted = false;
      this.newPointsAdded = false;
      this.setPointsData({
        data: this._pointsGridData
      });
      this.validatePoints();

      return res;
    },
    validatePoints(penaltyPointSetId) {
      let invalidPoints = [];
      let isValid = true;
      let isPointsValid = true;
      for (const p of this.penaltyPointSets) {
        if (p.isDefaultPoints) continue;
        if (penaltyPointSetId && (p.penaltyPointSetId !== penaltyPointSetId)) continue;
        isPointsValid =  !this._pointsGridData.some(r => !(`_${[p.penaltyPointSetId]}` in r) || !("Points" in r[`_${p.penaltyPointSetId}`]) || !(r[`_${p.penaltyPointSetId}`]["Points"] || r[`_${p.penaltyPointSetId}`]["Points"] === 0));
        if (!isPointsValid) {
          isValid = false;
          invalidPoints.push(p.penaltyPointSetId);
        }
      }

      this.updateValidationStatus({
        tab: 'Points',
        item: 'points-grid',
        invalidPoints,
        isValid
      });

      return isValid;
    },
    async patchItem(item) {
      let grid = this.$options.grid._grid;
      let range = grid.selectedRanges[0];
      let selectedColStart = range.col > 1 ? range.col-1 : null
      let selectedColEnd = range.col2 > 1 ? range.col2-1 : null
      
      if (selectedColStart > selectedColEnd) {
        [selectedColStart, selectedColEnd] = [selectedColEnd, selectedColStart]
      }
      
      let selectedPoints = []
      if (selectedColStart) {
        selectedPoints.push(parseInt(grid.getColumn(selectedColStart+1).binding.split('.')[0].slice(1)))
        if (selectedColEnd && selectedColEnd !== selectedColStart) {
          for(let i=selectedColStart+1; i<=selectedColEnd; i++){
            selectedPoints.push(parseInt(grid.getColumn(i+1).binding.split('.')[0].slice(1)))
          }
        }
      }
      
      const savePoints = () => {
        let q = [];      
        for (const p of this.penaltyPointSets) {
          if (p.isDefaultPoints) continue;
          if (selectedPoints.length > 0 && !selectedPoints.includes(p.penaltyPointSetId)) continue;
          if (!this.validatePoints(p.penaltyPointSetId)) continue;

          let penaltyPointSet = {
            penaltyPointSetName: p.penaltyPointSetName,
            points: []
          };

          this._pointsGridData.forEach(r => {
            if (r[`_${p.penaltyPointSetId}`]["Points"] || r[`_${p.penaltyPointSetId}`]["Points"] === 0)
              penaltyPointSet.points.push(r[`_${p.penaltyPointSetId}`]["Points"]);
          });

          q.push(api.updatePenaltyPoints(this.activeAnalysis.id, p.penaltyPointSetId, penaltyPointSet));
        }
        
        this.validatePoints();
        return Promise.all(q);
      };

      let isValidColumn = false;
      for (const p of this.penaltyPointSets) {
        if(selectedPoints.length > 0 && !selectedPoints.includes(p.penaltyPointSetId)) continue;
        if(this.validatePoints(p.penaltyPointSetId))
          isValidColumn = true
      }
      
      if (isValidColumn) {
        this.autoSaveItem({ itemName: 'PointsGrid', isValid: true, onSave: savePoints })
      }

      this.validatePoints();
    },
    async fillRange() {
      this.fillRangeErrorMessages = [];
      let validSelection = true;
      let grid = this.$options.grid._grid;
      let range = grid.selectedRanges[0];
      let firstRow = Math.min(range.row, range.row2);
      let lastRow = Math.max(range.row, range.row2);
      let firstValue = grid.getCellData(firstRow, range.col);
      let lastValue = grid.getCellData(lastRow, range.col);

      if (range.columnSpan > 1) {
        this.fillRangeErrorMessages.push("Multiple columns selected.");
        validSelection = false;
      }
      
      if (range.col <= 1 || range.col2 <= 1) {
        this.fillRangeErrorMessages.push("Invalid column(s) selected.");
        validSelection = false;
      }
      
      if (firstRow == lastRow) {
        this.fillRangeErrorMessages.push("Only one row selected.");
        validSelection = false;
      }
      
      if (firstValue == undefined) {
        this.fillRangeErrorMessages.push("Unknown first value.");
        validSelection = false;
      }

      if (lastValue == undefined) {
        this.fillRangeErrorMessages.push("Unknown last value.");
        validSelection = false;
      }
      
      for (let i = firstRow+1; i < firstRow + range.rowSpan - 1; i++) {
        if (grid.getCellData(i, range.col) != undefined) {
          this.fillRangeErrorMessages.push("Value found within range.");
          validSelection = false;
          break;
        }
      }

      if (!validSelection) {
        this.$refs.invalidSelectionDialog.showDialog();
        return;
      }

      let increment = (lastValue - firstValue) / (lastRow - firstRow);
      let n = firstValue + increment;
      for (let i = firstRow+1; i < firstRow + range.rowSpan - 1; i++) {
        grid.setCellData(i, range.col, n);
        n+=increment;
      }
      await this.patchItem({});
      this.setPointsData({
        data: this._pointsGridData
      });
    }
  },
  watch: {
    isValidating (val) {
      if (val === true) {
        this.validatePoints();
      }
    }
  },
  components: {
    LoadingIndicator,
    ModalDialog,
    RenamePointsDialog
  }
};
</script>

<style scoped>
.wj-content {
  border-radius: 0 0 4px 4px;
  border-right: none;
  width: auto;
}
</style>

<style>
.points-grid.wj-flexgrid .wj-state-sticky .wj-header {
    opacity: 1.0;
}

.points-grid.wj-flexgrid .wj-header-alt {
  display: flex;
  align-items: center;
  justify-content: safe flex-end;
  -webkit-box-align: center;
}

.points-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>