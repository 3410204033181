import { refreshAuthToken } from '../../httpClient'
import store from '../../store'

export const authHandler = (baseURL, collectionView) => (s, e) => {
  if (e.request.status == 401) {
    refreshAuthToken().then((refreshed) => {
      if (refreshed) {
        // Set auth header for grid
        collectionView.requestHeaders['Authorization'] = `Bearer ${store.state.authentication.token}`;

        if (e.request.responseURL.includes('$count')) {
          // retry loading data
          collectionView.load();
        } else {
          // Update items that failed
          collectionView.itemsEdited.forEach(item => {
            this.$http.put(`${baseURL}/${this.collectionUrl}(Id=${item.Id})`, item);
          });
          collectionView.clearChanges();
        }
      }
    });
  }
}