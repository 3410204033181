<template>
  <modal-dialog 
    title="Duplicate Analysis" 
    confirm-button-text="Duplicate" 
    :value="value" 
    :action="cloneAnalysis" 
    @input="$emit('input', $event)" 
    @clear="clearDialog"
  >
    <b-form-group
      label="Enter New Analysis Name"
      label-for="cloned-name"
    >
      <b-form-input
        id="cloned-name"
        v-model="analysisName"
        autocomplete="off"
        :disabled="modalBusy"
        :state="state($v.analysisName)"
        autofocus
      />
      <b-form-invalid-feedback :state="state($v.analysisName)"> 
        {{ validationMessage($v.analysisName) }}
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- <b-form-group label="Analysis Retention">
      <b-form-radio-group
        id="persist-setting-clone"
        v-model="analysisPersist"
        :options="retentionOptions"
        name="persist-options-clone"
        :disabled="modalBusy"
      ></b-form-radio-group>
    </b-form-group> -->
  </modal-dialog>
</template>

<script>
import api from '../api'
import { retentionOptions } from '../js/options/retentionOptions'
import ModalDialog from './ModalDialog.vue'
import { required } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'

export default {
  components: {
    ModalDialog
  },
  mixins: [validationState],
  props:{
    value: { type: Boolean },
    selectedAnalysis: { type: Object }
  },
  data () {
    return {
      analysisName: null,
      //analysisPersist: false,
      modalBusy: false,
      retentionOptions
    }
  },
  validations: {
    analysisName: {
      required
    }
  },
  methods: {
    async cloneAnalysis() {
      // Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      
      this.modalBusy = true;

      let analysis = await api.cloneAnalysis(this.selectedAnalysis.id, {
        name: this.analysisName,
        //persist: this.analysisPersist
      });

      this.modalBusy = false;
      this.$router.push({ name: 'Inputs', params: { id: analysis.id } });
    },
    clearDialog () {
      this.modalBusy = false;
      this.analysisName = null;
      //this.analysisPersist = false;
    }
  }
}
</script>