<template>
  <div>
    <div class="content scrollable theme-item">
      <b-alert
        :show="showAlert"
        :variant="msgVariant"
        @dismissed="showAlert=false"
        dismissible
        fade
      >
        {{ message }}
      </b-alert>
      <div class="btn-container-right">
        <b-button
          variant="primary"
          @click="showCreateUserDialog=true"
        >
          New User
        </b-button>
        <b-button
          title="Back to Analysis Manager"
          @click="closeUserManager"
        >
          <font-awesome-icon
            icon="arrow-alt-circle-left"
            class="button-icon"
          />Back
        </b-button>
      </div>
      <div class="scrollable theme-item">
        <b-table 
          table-class="table-modern theme-item"
          :items="filteredUsers" 
          :fields="fields" 
          :busy="loading" 
          tbody-tr-class="clickable" 
          :show-empty="true" 
          empty-text="No users found"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner
                class="align-middle"
                small
              />
              <strong> Loading...</strong>
            </div>
          </template>

          <template #head(active)>
            <div>
              Active
              <b-dropdown
                no-caret
                right
                toggle-class="no-padding"
                variant="link"
              >
                <template #button-content>
                  <font-awesome-icon
                    title="Filter Users"
                    icon="filter"
                    :class="getFilterIconClass('active')"
                  />
                </template>
                <b-dropdown-item @click="setFilter('active','')">
                  All
                </b-dropdown-item>
                <b-dropdown-item @click="setFilter('active','true')">
                  Active
                </b-dropdown-item>
                <b-dropdown-item @click="setFilter('active','false')">
                  Not Active
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>

          <template #cell(active)="data">
            <b-checkbox
              aria-label="Set User as Actice"
              v-model="data.item.active"
              :disabled="data.item.id == userid"
              @change="openConfirmChangeUserActiveStatusModal(data.item, $event)"
            />
          </template>

          <template #cell(admin)="data">
            <b-checkbox
              aria-label="Set User as Admin"
              v-model="data.item.admin"
              :disabled="data.item.id == userid"
              @change="openConfirmChangeUserAdminStatusModel(data.item, $event)"
            />
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              text="..."
              :variant="variant"
              toggle-class="user-menu-btn"
              no-caret
              right
            >
              <b-dropdown-item-button 
                @click="editUser(data.item)" 
                :disabled="data.item.id == userid"
              >
                Edit User
              </b-dropdown-item-button>
              <b-dropdown-item-button @click="openChangeUserPasswordModal(data.item)">
                Change Password
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
      </div>

      <b-modal
        id="change-user-password"
        :title="'Change User Password (' + changePasswordUser.displayName + ')'"
        ok-title="Change Password"
        no-close-on-backdrop
        @ok="changeUserPassword"
        @show="clearChangePasswordModal"
      >
        <div v-if="changingPassword">
          <b-spinner class="spinner-md" /> Changing password...
        </div>

        <b-form-group
          label="New Password"
          label-for="new-password"
        >
          <b-form-input
            id="new-password"
            v-model="$v.changePassword.newPassword.$model"
            :state="state($v.changePassword.newPassword)"
            type="password"
            autocomplete="off"
            required
          />
          <b-form-invalid-feedback :state="state($v.changePassword.newPassword)">
            A password of at least {{ $v.changePassword.newPassword.$params.minLength.min }} characters is required
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Confirm Password"
          label-for="confirm-password"
        >
          <b-form-input
            id="confirm-password"
            v-model="$v.changePassword.confirmPassword.$model"
            :state="state($v.changePassword.confirmPassword)"
            type="password"
            autocomplete="off"
            required
          />
          <b-form-invalid-feedback :state="state($v.changePassword.confirmPassword)">
            Passwords must match
          </b-form-invalid-feedback>
        </b-form-group>
      </b-modal>

      <b-modal
        id="confirm-change-user-active-status" 
        title="Change User Active Status" 
        :ok-title="changeUserActiveStatusUser.active == false ? 'Make Inactive' : 'Make Active'" 
        no-close-on-backdrop
        @ok="updateUserActive"
        @cancel="cancelUpdateUserModal"
      >
        Are you sure you want to make user "{{ changeUserActiveStatusUser.displayName }}" {{ changeUserActiveStatusUser.active == false ? 'Inactive' : 'Active' }} ?
        <div v-if="changingUserActiveStatus">
          <b-spinner class="spinner-md" /> Changing user active status...
        </div>
      </b-modal>

      <b-modal
        id="confirm-change-user-admin-status" 
        title="Change User Admin Status" 
        :ok-title="changeUserAdminStatusUser.admin == false ? 'Make Not Admin' : 'Make Admin'" 
        no-close-on-backdrop
        @ok="updateUserAdmin"
        @cancel="cancelUpdateUserModal"
      >
        Are you sure you want to make user "{{ changeUserAdminStatusUser.displayName }}" {{ changeUserAdminStatusUser.admin == false ? 'Not Admin' : 'Admin' }} ?
        <div v-if="changingUserAdminStatus">
          <b-spinner class="spinner-md" /> Changing user admin status...
        </div>
      </b-modal>

      <user-manager-create-dialog 
        v-model="showCreateUserDialog" 
        @user-created="userCreated"
      />
      
      <user-manager-edit-dialog 
        v-model="showEditUserDialog" 
        :user-to-edit="userToEdit"
        @user-updated="userUpdated"
      />
    </div>
  </div>
</template>

<script>
import UserManagerCreateDialog from './UserManagerCreateDialog.vue'
import UserManagerEditDialog from './UserManagerEditDialog.vue'
import api from '../api'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'
import { mapGetters, mapState } from 'vuex';
import getErrorMessage from '../js/getErrorMessage'

export default {
  data () {
    return {
      users: [],
      userToEdit: {},
      loading: false,
      showCreateUserDialog: false,
      showEditUserDialog: false,
      showAlert: false,
      isError: false,
      message: false,
      changePassword: {
        newPassword: null,
        confirmPassword: null
      },
      changingPassword: false,
      changingUserActiveStatus: false,
      changingUserAdminStatus: false,
      changePasswordUser: {},
      changeUserActiveStatusUser: {},
      changeUserAdminStatusUser: {},
      fields: [
        { key: 'displayName', label: 'Username', sortable: true, class: 'col-width-50' },
        { key: 'email', label: 'Email', sortable: true, class: 'col-width-50' },
        { key: 'active', label: 'Active', sortable: true, class: 'text-center' },
        { key: 'admin', label: 'Admin', sortable: true, class: 'text-center' },
        { key: 'actions', label: '', class: 'table-actions text-center col-width-10' }
      ],
      filters: {
        active: 'true'
      }
    }
  },
  validations: {
    changePassword: {
      newPassword: { required, minLength: minLength(8) },
      confirmPassword: { required, sameAsPassword: sameAs(function() { return this.changePassword.newPassword }) }
    }
  },
  mounted () {
    this.getUsers();
  },
  computed: {
    ...mapState(['theme']),
    ...mapGetters(['userid']),
    variant () {
      return this.theme == 'light-theme' ? 'dark' : 'light';
    },
    msgVariant () {
      return this.isError ? 'danger' : 'info';
    },
    filteredUsers () {
      if (this.users.length > 0) {
        const filtered = this.users.filter(user => {
          return Object.keys(this.filters).every(key =>
              String(user[key]).includes(this.filters[key]))
        })
        return filtered.length > 0 ? filtered : [
              Object.keys(this.users[0]).reduce(function(obj, value) {
                obj[value] = '';
                return obj;
              }, {})
            ];
      } else {
        return [];
      }
    }
  },
  methods: {
    async getUsers() {
      this.loading = true;
      this.isError = false;
      try {
        let rawUsers = await api.getUsers();
        this.users = rawUsers.map( user => {
                                   user.admin = user.roles.indexOf("Admin") > -1;
                                   return user
                                  })
      } catch {
        this.displayAlert('An error occurred retrieving the user list', 30, true);
      }
      
      this.loading = false;
    },
    editUser (user) {
      this.userToEdit = user;
      this.showEditUserDialog = true
    },
    async changeUserPassword(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$v.$touch();
      this.isError = false;

      if (!this.$v.changePassword.$invalid) {
        try {
          this.changingPassword = true;
          await api.changeUserPassword({Id: this.changePasswordUser.id, password: this.changePassword.newPassword});
          this.displayAlert('Password changed.', 10);
        } 
        catch(error) {
          let message = getErrorMessage(error, 'Unknown error changing password.');
          this.displayAlert(message, 30, true);
        }
      }
      this.$bvModal.hide('change-user-password');
      this.changingPassword = false;
    },
    async closeUserManager() {
      this.$router.push('/');
    },
    clearCreateUserModal() {
      this.newUser.userName = null;
      this.newUser.emailAddress = null;
      this.newUser.password = null;
      this.newUser.confirmPassword = null;
      this.newUser.sendRegistrationEmail = false;
    },
    clearChangePasswordModal() {
      this.changePassword.newPassword = null;
      this.changePassword.confirmPassword = null;
    },
    openConfirmChangeUserActiveStatusModal(user,value) {
      this.changeUserActiveStatusUser = user;
      this.changeUserActiveStatusUser.active = value;
      this.$bvModal.show('confirm-change-user-active-status');
    },
    async updateUserActive() {
      this.changingUserActiveStatus = true;
      this.updateUser(this.changeUserActiveStatusUser);
      this.changingUserActiveStatus = false;
    },
    openConfirmChangeUserAdminStatusModel(user,value) {
      this.changeUserAdminStatusUser = user;
      this.changeUserAdminStatusUser.admin = value;
      this.$bvModal.show('confirm-change-user-admin-status');
    },
    async updateUserAdmin() {
      this.changeUserAdminStatusUser.isAdmin = this.changeUserAdminStatusUser.admin;
      this.updateUser(this.changeUserAdminStatusUser);
    },
    async updateUser(user) {
      await api.updateUser(user);
      this.getUsers();
    },
    cancelUpdateUserModal() {
      this.getUsers();
    },
    openChangeUserPasswordModal(user) {
      this.changePasswordUser = user;
      this.$bvModal.show('change-user-password');
    },
    setFilter(field, value) {
      this.filters[field] = value;
    },
    getFilterIconClass(field) {
      return this.filters[field] === '' ? "filter-disabled" : "filter-enabled";
    },
    async userCreated() {
      this.displayAlert('New user created.', 10);
      await this.getUsers();
    },
    async userUpdated() {
      this.displayAlert('User updated.', 10);
      await this.getUsers();
    },
    displayAlert (message, duration, isError = false) {
      this.isError = isError;
      this.message = message;
      this.showAlert = duration;
    }
  },
  components: {
    UserManagerCreateDialog,
    UserManagerEditDialog
  },
  mixins: [validationState]
}
</script>
<style>
.centered-max-1500 {
  width: 100%;
  margin: 0px auto;
  max-width: 1500px !important;
}


.table > tbody > tr > td.table-actions {
  padding: 9px 15px;
}

.table.b-table > thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 999;
}

.table-actions .user-menu-btn {
  padding: 0 10px 8px 10px;
  line-height: 1;
  font-size: 1.2em;
}

.no-padding {
  padding: 0px !important;
}

.light-theme .filter-enabled {
  color: #00994b;
}

.dark-theme .filter-enabled {
  color: #00994b
}

.light-theme .filter-disabled {
  color: #333;
}

.dark-theme .filter-disabled {
  color: #fffffd
}

</style>