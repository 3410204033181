var externals = {
    SVNversion: "31484M",
		apiVersion: 2, // 1 - original, 2 - new form with .Net service
		// This is now set automatically based on the selected module
		GUIflavor: "All", // All, CMO, Payup, LK, MI, LDM, MSR, AUTO
	welcomename: function () {
		if (this.GUIflavor == 'MSR') {
			return 'MSRKinetics';
		} else {
			return 'Kinetics';
		}
	},
  LOANS_template: function (calcs) {
		if (this.GUIflavor == 'CMO')
			return 'LOANS_cusip';
		else if ((this.GUIflavor == 'AUTO') || (this.GUIflavor == 'LDM'))
		// should have a selection for LDM
			return 'LOANS_AUTO';
		else if (this.val('showMSR', calcs))
			return 'LOANS_MSR';
		else
			return 'LOANS_template';
	},
	// supportX() - supported by flavor, independent of calcs
	// enableX(calcs) - supported by flavor and other calcs
	// valX(calcs) - selected in calcs
	// initX() - default value by flavor
	enableMIonly: function (calcs) {
		// perhaps LDM should have this enabled - can add later
		if ((this.GUIflavor == 'Payup') || (this.GUIflavor == 'LDM') ||
				(this.GUIflavor == 'AUTO') ||
				(this.val('showMSR', calcs)))
			return false;
		return this.val('LOANs', calcs) || this.val('POOLs', calcs);
	},
	enableshowMSR: function (calcs) {
		// perhaps LDM should have this enabled - can add later
		if ((this.GUIflavor == 'Payup') || (this.GUIflavor == 'LDM') ||
			(this.GUIflavor == 'AUTO'))
			return false;

		return this.val('LOANs', calcs) || this.val('POOLs', calcs);
	},
	enableshowMSRdecomp: function () {
		if (this.GUIflavor == 'MSR')
			return true;
		else
			return false;
	},
	enableshowtunings: function () {
		// Can add LDM later
		if ((this.GUIflavor == 'All') || (this.GUIflavor == 'Payup') || (this.GUIflavor == 'MSR'))
			return true;
		return false;
	},
	enableTBAflag: function (calcs) {
		return this.val('showpayup', calcs);
	},
	enableshowpayup: function (calcs) {
		if (this.GUIflavor == 'LDM')
			return false;
		if (this.GUIflavor == 'MSR')
			return false;
		if (this.val('showCCAR', calcs))
			return false;
		return this.val('POOLs', calcs);
	},
	enableshowrepricing: function () {
		if (this.GUIflavor == 'LDM')
			return false;
		if (this.GUIflavor == 'MSR')
			return false;
		return true;
	},
	enableshowLDM: function () {
		if (this.GUIflavor == 'LDM')
			return true;
		return false; // this may change
	},
	enable: function (arg, calcs) {
		let myarg = arg;
		let args = arg.split('.');
		if (args.length > 1)
			myarg = args.join('_');
		if (this['enable' + myarg] != undefined) {
			// specific
			//console.log('enable' + myarg);
			//console.log(this['enable' + myarg]);
			return this['enable' + myarg](calcs);
		}
		else if (args.length > 1) {
			// check for hierarchical
			return this.val('show' + args[0], calcs);
		}
		else
			// default to enabled
			return true;
	},
	val: function (arg, calcs) {
		if (!this.enable(arg, calcs))
			return false;
		let args = arg.split('.');
		let retval;
		if (args.length > 1) {
			let myarg = args.join('_');
			if (this['val' + myarg] != undefined)
				return this['val' + myarg](calcs);
			if (calcs[args[0]] == undefined)
				return false;
			retval = calcs[args[0]][args[1]];
		}
		else {
			if (this['val' + arg] != undefined)
				return this['val' + arg](calcs);
			retval = calcs[arg];
		}
		if (retval == 1)
			retval = true; // for MIonly
		if (retval == 2)
			retval = true; // for showMSR
		return retval;
	},
	enableshowprice: function (calcs) {
		if (this.val('showCCAR', calcs))
			return false;
		if (this.GUIflavor == 'LDM')
			return false;
		if (this.GUIflavor != 'MI')
			return true;
		return this.val('POOLs', calcs);
	},
	enabledshowFcAnalysis: function () {
		// Currently enabled for all flavors
		return true;
	},
	enableshowdurations: function (calcs) {
		if (this.val('showCCAR', calcs))
			return false;
		if ((this.GUIflavor != 'MI') && (this.GUIflavor != 'CMO') &&
			(this.GUIflavor != 'LDM'))
			return true;
		return false;
	},
	enableshowoas: function (calcs) {
		if (this.val('showCCAR', calcs))
			return false;
		if ((this.GUIflavor != 'MI') && (this.GUIflavor != 'LDM'))
			return true;
		return false;
	},
	enableshowyld: function (calcs) {
		if (this.val('showCCAR', calcs))
			return false;
		if ((this.GUIflavor != 'MI') && (this.GUIflavor != 'LDM'))
			return true;
		return false;
	},
	enableshowzspread: function (calcs) {
		if (this.val('showCCAR', calcs))
			return false;
		if ((this.GUIflavor != 'MI') && (this.GUIflavor != 'LDM'))
			return true;
		return false;
	},
	enableshowscenprice: function (calcs) {
		if (!this.val('showCCAR', calcs) && !this.val('showcredit', calcs) &&
			!this.val('showB5', calcs))
			return false;
		return true;
	},
	enableshowcredit: function () {
		if (this.GUIflavor == 'LDM')
			return false;
		// if (this.GUIflavor == 'MSR')
		// 	return false;
		return true;
	},
	enableshowMCcredit: function () {
		if (this.GUIflavor == 'LDM' || this.GUIflavor == 'MSR')
			return false;
		return true;
	},
	enableshowcashflows: function () {
		if (this.GUIflavor == 'LDM')
			return false;
		return true;
	},
	enableshowCCAR: function (calcs) {
		if (this.GUIflavor == 'LDM')
			return false;
		// CCAR added to MSR
		// if (this.GUIflavor == 'MSR')
		// 	return false;
		if (this.val('showB5', calcs))
			return false;
		// if (this.val('showcredit', calcs))
		// 	return false;
		return true;
	},
	enableshowB5: function (calcs) {
		if (this.GUIflavor == 'LDM')
			return false;
		if (this.GUIflavor == 'MSR')
			return false;
		if ((this.GUIflavor == 'Payup') || (this.GUIflavor == 'LDM'))
			return false;
		return this.val('LOANs', calcs);
	},
	enableirprocess: function () {
		return ((this.GUIflavor == 'All') || (this.GUIflavor == 'Payup') || (this.GUIflavor == 'MSR') || (this.GUIflavor == 'LK'));
	},
	enabledDefaultDef: function () {
		if (this.GUIflavor == 'MSR')
			return false;
		else
			return true;
	},
	valLOANs: function (calcs) {
		//console.log('LOANs');
		//console.log(calcs);
		return (calcs.supportCMO != 3);
	},
	valPOOLs: function (calcs) {
		return ((calcs.supportCMO == 1) ||
			(calcs.supportCMO == 2));
	},
	valCMOs: function (calcs) {
		return (calcs.supportCMO >= 2);
	},
	supportCMOs: function () {
		if ((this.GUIflavor == 'CMO') || (this.GUIflavor == 'All'))
			return true;
		return false;
	},
	supportLOANs: function () {
		if ((this.GUIflavor == 'LK') || (this.GUIflavor == 'All') ||
				(this.GUIflavor == 'MI') || (this.GUIflavor == 'LDM') ||
				(this.GUIflavor == 'MSR') || (this.GUIflavor == 'AUTO'))
				return true;
		return false;
	},
	supportPOOLs: function () {
		if ((this.GUIflavor == 'Payup') || (this.GUIflavor == 'All') ||
			(this.GUIflavor == 'LDM') || (this.GUIflavor == 'MSR'))
			return true;
		return false;
	},
	enableSettleDate: function () {
		if ((this.GUIflavor != 'MI') && (this.GUIflavor != 'LDM'))
			return true;
		return false;
	},
	initshowtunings: function () {
		if (this.GUIflavor == 'Payup' || this.GUIflavor == 'MSR')
			return true;
		return false;
	},
	initPaths: function () {
		if (process.env.NODE_ENV == 'development')
			return 5;

		return 200;
	},
	initMIonly: function () {
		if (this.GUIflavor == 'MI')
			return 1;
		else
			return 0;
	},
	initshowMSR: function () {
		if (this.GUIflavor == 'MSR')
			return 5;
		else
			return 0;
	},
	initquotetype: function () {
		if (this.GUIflavor == 'CMO')
			return -1;
		else if (this.GUIflavor == 'MI')
			return 1;
		else
			return 2;
	},
	initshowB5: function () {
		return (this.GUIflavor == 'MI');
	},
	initshowcredit: function () {
		if ((this.GUIflavor == 'CMO') || (this.GUIflavor == 'LDM'))
			return false;
		return true;
	},
	initshowoas: function () {
		if (this.GUIflavor == 'MI')
			return false;
		if (this.GUIflavor == 'MSR')
			return false;
		return true;
	},
	initshowdurations: function () {
		if ((this.GUIflavor == 'CMO') || (this.GUIflavor == 'MI'))
			return false;
		if (this.GUIflavor == 'MSR')
			return false;
		return true;
	},
	initshowyld: function () {
		if ((this.GUIflavor == 'CMO') || (this.GUIflavor == 'MI'))
			return false;
		return true;
	},
	initshowzspread: function () {
		return true;
	},
	enableprice: function () {
		if (this.GUIflavor == 'MI')
			return false;
		return true;
	},
	initsupportCMO: function () {
		if (this.supportPOOLs())
			return 1;
		else if (this.supportCMOs())
			return 3;
		return 0; // this may change
	},
	initdefaultdef: function () {
		if (this.GUIflavor == 'CMO')
			return 0;
		if (this.GUIflavor == 'Payup')
			return 1;
		return 3;
	},
	initintexmode: function () {
		return 0;
	},
	initirprocess: function () {
		return 1;
	},
	initTBAflag: function () {
		if (this.GUIflavor == 'Payup')
			return false;
	},
	initshowpayup: function () {
		if (this.GUIflavor == 'Payup')
			return true;
		return false; // this may change
	},
	initshowLDM: function () {
		if (this.GUIflavor == 'LDM')
			return true;
		return false; // this may change
	},
	initshowprice: function () {
		if (this.GUIflavor == 'MI')
			return false;
		return true; // this may change
	},
	initshowscenprice: function () {
		return true;
	},
	vselection: function (selections, value) {
		for (let i = 0; i < selections.length; i++) {
			if (selections[i].value == value)
				return selections[i];
		}

		return "unknown";
	},
	handlesort(a, b) {
		if (a.name == b.name)
			return 0;
		let inta = parseInt(a.name);
		let intb = parseInt(b.name);

		if (!isNaN(inta) && !isNaN(intb)
			&& (inta != intb)) {
			// console.log('intb - inta:' + inta - intb);
			return intb - inta;
		}
		else {
			let retval = (a.name > b.name) ? 1 : -1;
			return retval;
		}
	},
	handlesort_gen(key, dir, a, b) {
		let aval = a[key];
		let bval = b[key];
		let retval;
		if (dir == -1) {
			bval = aval;
			aval = b[key];
		}
		if (aval == bval)
			retval = 0;
		else {
			let inta = parseInt(aval);
			let intb = parseInt(bval);

			if (!isNaN(inta) && !isNaN(intb)
				&& (inta != intb)) {
				//console.log('inta - intb:' + inta - intb);
				retval = inta - intb;
			}
			else {
				if ((aval == undefined) && (bval != undefined))
					retval = -1;
				else if ((bval == undefined) && (aval != undefined))
					retval = 1;
				else if ((bval == undefined) && (aval == undefined))
					retval = 0;
				else
					retval = (aval > bval) ? 1 : -1;
			}
		}
		if ((retval == 0) && (key != 'name'))
			return externals.handlesort_gen('name', dir, a, b);
		return retval;
	},
	striptofilename(fullpath) {
		let comps = fullpath.split('/');
		if (comps.length <= 1)
			return fullpath;
		return comps[comps.length - 1];
	},
	needsheaderrow(fullpath) {
		let filename = this.striptofilename(fullpath);
		if (filename == 'tuningvalues_custom')
			return true;
		if (filename == 'tuningvalues_custom.txt')
			return true;
		if (filename == 'ldmvectors.txt')
			return true;
		if (filename == 'ldmvectors')
			return true;
	},
	addheaderrow(text) {
		var d3 = require('d3-dsv');
		let results = d3.tsvParseRows(text);
		let longest = results.reduce(function (a, b) { return a.length > b.length ? a : b; }).length;
		results.splice(0, 0, []);
		for (let i = results[0].length; i < longest; i++) {
			results[0].push('Value' + i);
		}
		text = d3.tsvFormatRows(results);
		return text;
	},
}

export default externals
