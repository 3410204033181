import { statusCodes } from '../options/statusCodes'

export default {
  methods: {
    getStatusText (status) {
      switch (status) {
        case statusCodes.notRun:
          return "Not Run";
        case statusCodes.running:
          return "Running";
        case statusCodes.success:
          return "Success";
        case statusCodes.error:
          return "Error";
        case statusCodes.canceled:
          return "Canceled";
        case statusCodes.queued:
          return "Queued";
        default: 
          return "Unknown Status";
      }
    },
    getStatusVariant (status) {
      switch (status) {
        case statusCodes.notRun:
          return "secondary";
        case statusCodes.running:
          return "primary";
        case statusCodes.success:
          return "success";
        case statusCodes.error:
          return "danger";
        case statusCodes.canceled:
          return "warning";
        case statusCodes.queued:
          return "info"
        default: 
          return "danger";
      }
    }
  }
}