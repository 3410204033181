import { DataType } from '@grapecity/wijmo'
import { decimal, between } from 'vuelidate/lib/validators'

export default {
  title: 'Swap Volatilities',
  entitySet: 'SwaptionVolatilities',
  columns: [
    { binding: 'term', header: 'Term', isRequired: true, isReadOnly: true, desc: 'Valid Term values: 3M, 6M, 1Y, 2Y, 3Y, 5Y, 7Y, 10Y', type: 'string' },
    { binding: 'vol_1Y', header: 'Vol 1Y', isRequired: true, dataType: DataType.Number, format: 'g15', validations: { decimal, between: between(-100, 300) }, desc: '1 year volatility', type: 'double' },
    { binding: 'vol_2Y', header: 'Vol 2Y', isRequired: true, dataType: DataType.Number, format: 'g15', validations: { decimal, between: between(-100, 300) }, desc: '2 year volatility', type: 'double' },
    { binding: 'vol_10Y', header: 'Vol 10Y', isRequired: true, dataType: DataType.Number, format: 'g15', validations: { decimal, between: between(-100, 300) }, desc: '10 year volatility', type: 'double' }
  ],
  editOptions: {
    keys: ['id'],
    allowAddNew: false,
    allowDelete: false,
    allowSave: true
  }
}