import { assetTypes } from "../options/assetType"

function getCashflowColumns({ calcs, assetType }) {
  let cols = [
    { header: "Date", binding: "date" },
    { header: "Balance", binding: "balance", format: "n2", defaultAxis: 'y2', selected: true },
    { header: "Total Cashflow", binding: "totalCF", format: "n2", defaultAxis: 'y2' },
    { header: "Schedule", binding: "scheduled", format: "n2", defaultAxis: 'y2' },
    { header: "Net Interest", binding: "netInterest" , format: "n2", defaultAxis: 'y2' },
    // Removed for MSR, should be conditionally re-added for other modules in the future
    //{ header: "Principal Loss", binding: "PrinLoss" , format: "n2" },
    //{ header: "Interest Loss", binding: "IntLoss", format: "n2" },
    //{ header: "Cum Prin Loss", binding: "CumPrinLoss", format: "n2" },
    //{ header: "Cum Int Loss", binding: "CumIntLoss", format: "n2" },
    { header: "Cum Default", binding: "cumDefault", format: "n2" },
    { header: "Default", binding: "default", format: "n2" },
  ]

  if (assetType == assetTypes.servicing) {
    cols.push(...[
      { header: "IO", binding: "msrIo", format: "n2" },
      { header: "Income", binding: "msrIncome", format: "n2" },
      { header: "Cost", binding: "msrCost", format: "n2" },
      { header: "Loan Cost", binding: "msrCostLoan", format: "n2" },
      { header: "Delinquent Loan Cost", binding: "msrCostDLoan", format: "n2" },
      { header: "Cost Advances", binding: "msrCostAdvances", format: "n2" },
      { header: "Foreclosure Cost", binding: "msrCostFloatLoan", format: "n2" },
      { header: "Escrow", binding: "msrEscrow", format: "n2" },
      { header: "Payment Float", binding: "msrPmtFloat", format: "n2" },
      { header: "Float Schedule", binding: "msrPmtFloatSch", format: "n2" },
      { header: "Float Prepay", binding: "msrPmtFloatPrepay", format: "n2" },
    ]);
  }
  
  cols.push(...[
    { header: "OneMo", binding: "oneMo", format: "n2" },
    { header: "OneYr", binding: "oneYr", format: "n2" },
    { header: "TwoYr", binding: "twoYr", format: "n2" },
    { header: "TenYr", binding: "tenYr", format: "n2" },
    { header: "HPA", binding: "hpa", format: "n2" },
    { header: "HPI", binding: "hpi", format: "n2" },
    { header: "MRR", binding: "mrr", format: "n2", selected: true },
    { header: "MDR", binding: "mdr", format: "n2" },
    { header: "SEV", binding: "sev", format: "n2" }
  ]);

  if (calcs.cashflows.columnSet >= 12) {
    cols.push(...[
      { header: "CPct", binding: "cPct", format: "n2" },
      { header: "DPct", binding: "dPct", format: "n2" },
      { header: "SPct", binding: "sPct", format: "n2" },
      { header: "CD", binding: "cd", format: "n2" },
      { header: "CC", binding: "cc", format: "n2" },
      { header: "CS", binding: "cs", format: "n2" },
      { header: "CT", binding: "ct", format: "n2" },
      { header: "DD", binding: "dd", format: "n2" },
      { header: "DC", binding: "dc", format: "n2" },
      { header: "DS", binding: "ds", format: "n2" },
      { header: "DT", binding: "dt", format: "n2" },
      { header: "SC", binding: "sc", format: "n2" },
      { header: "SS", binding: "ss", format: "n2" },
      { header: "SD", binding: "sd", format: "n2" },
      { header: "ST", binding: "st", format: "n2" }
    ])
  }

  if (calcs.cashflows.columnSet >= 14) {
    cols.push(...[
      { header: "Prime", binding: "prime", format: "n2" },
      { header: "Mtg30", binding: "mtg30", format: "n2" },
      { header: "LocHPI", binding: "locHpi", format: "n2" }
    ])
  }

  if (calcs.cashflows.columnSet >= 15) {
    cols.push(...[
      { header: "Refi", binding: "refi", format: "n4" },
      { header: "Turnover", binding: "turnover", format: "n2" },
      { header: "Delinquent0Days", binding: "del0Days", format: "n2" },
      { header: "Delinquent30Days", binding: "del30Days", format: "n2" },
      { header: "Delinquent60Days", binding: "del60Days", format: "n2" },
      { header: "Delinquent90Days", binding: "del90Days", format: "n2" },
      { header: "Delinquent120Days", binding: "del120Days", format: "n2" },
      { header: "Delinquent150Days", binding: "del150Days", format: "n2" },
      { header: "SeriouslyDelinquent1", binding: "seriouslyDelinquent1", format: "n2" },
      { header: "SeriouslyDelinquent2", binding: "seriouslyDelinquent2", format: "n2" },
      { header: "REO", binding: "reo", format: "n2" },
      { header: "Delinquent90Plus", binding: "del90Plus", format: "n2" },
      { header: "BalanceForecastC", binding: "balanceForecastC", format: "n2" },
      { header: "BalanceForecastD", binding: "balanceForecastD", format: "n2" },
      { header: "BalanceForecastS", binding: "balanceForecastS", format: "n2" },
      { header: "D0toD0", binding: "d0toD0", format: "n2" },
      { header: "D0toD1", binding: "d0toD1", format: "n2" },
      { header: "D0toT", binding: "d0toT", format: "n2" },
      { header: "D1toD0", binding: "d1toD0", format: "n2" },
      { header: "D1toD1", binding: "d1toD1", format: "n2" },
      { header: "D1toD2", binding: "d1toD2", format: "n2" },
      { header: "D1toT", binding: "d1toT", format: "n2" },
      { header: "D2toD0", binding: "d2toD0", format: "n2" },
      { header: "D2toD1", binding: "d2toD1", format: "n2" },
      { header: "D2toD2", binding: "d2toD2", format: "n2" },
      { header: "D2toD3", binding: "d2toD3", format: "n2" },
      { header: "D2toT", binding: "d2toT", format: "n2" },
      { header: "D3toD0", binding: "d3toD0", format: "n2" },
      { header: "D3toD1", binding: "d3toD1", format: "n2" },
      { header: "D3toD2", binding: "d3toD2", format: "n2" },
      { header: "D3toD3", binding: "d3toD3", format: "n2" },
      { header: "D3toD4", binding: "d3toD4", format: "n2" },
      { header: "D3toT", binding: "d3toT", format: "n2" },
      { header: "D4toD0", binding: "d4toD0", format: "n2" },
      { header: "D4toD1", binding: "d4toD1", format: "n2" },
      { header: "D4toD2", binding: "d4toD2", format: "n2" },
      { header: "D4toD3", binding: "d4toD3", format: "n2" },
      { header: "D4toD4", binding: "d4toD4", format: "n2" },
      { header: "D4toD5", binding: "d4toD5", format: "n2" },
      { header: "D4toT", binding: "d4toT", format: "n2" },
      { header: "D5toD0", binding: "d5toD0", format: "n2" },
      { header: "D5toD1", binding: "d5toD1", format: "n2" },
      { header: "D5toD2", binding: "d5toD2", format: "n2" },
      { header: "D5toD3", binding: "d5toD3", format: "n2" },
      { header: "D5toD4", binding: "d5toD4", format: "n2" },
      { header: "D5toD5", binding: "d5toD5", format: "n2" },
      { header: "D5toS1", binding: "d5toS1", format: "n2" },
      { header: "D5toT", binding: "d5toT", format: "n2" },
      { header: "S1toD0", binding: "s1toD0", format: "n2" },
      { header: "S1toD1", binding: "s1toD1", format: "n2" },
      { header: "S1toD2", binding: "s1toD2", format: "n2" },
      { header: "S1toD3", binding: "s1toD3", format: "n2" },
      { header: "S1toD4", binding: "s1toD4", format: "n2" },
      { header: "S1toD5", binding: "s1toD5", format: "n2" },
      { header: "S1toS1", binding: "s1toS1", format: "n2" },
      { header: "S1toS2", binding: "s1toS2", format: "n2" },
      { header: "S1toR", binding: "s1toR", format: "n2" },
      { header: "S1toT", binding: "s1toT", format: "n2" },
      { header: "S2toD0", binding: "s2toD0", format: "n2" },
      { header: "S2toD1", binding: "s2toD1", format: "n2" },
      { header: "S2toS2", binding: "s2toS2", format: "n2" },
      { header: "S2toR", binding: "s2toR", format: "n2" },
      { header: "S2toT", binding: "s2toT", format: "n2" },
      { header: "RtoR", binding: "rtoR", format: "n2" },
      { header: "RtoT", binding: "rtoT", format: "n2" },
      { header: "SMonthsDQ", binding: "sMonthsDQ", format: "n2" },
      { header: "S1MonthsDQ", binding: "s1MonthsDQ", format: "n2" },
      { header: "S2MonthsDQ", binding: "s2MonthsDQ", format: "n2" },
      { header: "RMonthsDQ", binding: "rMonthsDQ", format: "n2" },
    ])
  }

  return cols;
}

export { getCashflowColumns }