<template>
  <div>
    <b-modal
      :title="title"
      :visible="show"
      ok-variant="danger"
      @ok="ok"
      header-class="pl-2 pr-2 pt-2 pb-2" 
      footer-class="p-2 border-top-0"
      no-close-on-backdrop
      ok-only
    >
      <template #modal-header>
        <div>
          <font-awesome-icon
            class="error-icon"
            icon="exclamation-circle"
          />
          <span>{{ title }}</span>
        </div>
      </template>
      {{ errorMessage }}
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: { type: String, required: true },
    title: { type: String, required: true },
    show: { type: Boolean, required: true },
    onOk: { type: Function, default: null }
  },
  methods: {
    ok () {
      if (this.onOk) {
        this.onOk();
      }
    }
  }
}
</script>

<style scoped>
.error-icon {
  color: red;
  margin-right: 7px;
}
</style>