const messages = {
  required: () => 'This field is required.',
  decimal: () => 'This field must contain a valid number.',
  integer: () => 'This field must contain a valid integer.',
  between: ({min, max}) => `This field must be between ${min} and ${max}.`,
  unique: () => 'This field cannot contain duplicates.',
  exists: () => 'An item with this name already exists.',
  date: () => 'This field must be a valid date (MM/DD/YYYY).',
  folder: () => 'Invalid characters in file / folder name.',
  isSameOrAfter: ({message}) => message,
  isSameOrBefore: ({message}) => message,
  minLength: ({min}) => `This field requires a minimum length of ${min} characters.`,
  maxLength: ({max}) => `This field requires a maximum length of ${max} characters.`,
  uniqueScenarioName: () => 'A scenario with this name already exists.',
  sameOrBeforeEndDate: () => 'Start date must be the same or before end date.',
  validFadeInMonths: () => 'Fade in months cannot be greater than the difference between start and end dates.',
  validFadeOutMonths: () => 'Fade out months cannot be greater than the difference between start and end dates.',
  validEndDate: () => 'End date must extend beyond fade in and fade out period.',
  validScenario: () => 'Please fix the validation errors for this scenario.',
  requiredIfArm: () => 'Missing ARM Index.'
}

export function getValidationMessage (validatorType, params) {
  if (messages[validatorType]) {
    return messages[validatorType](params);
  }

  // eslint-disable-next-line
  console.warn(`Missing validation message for ${validatorType}`);

  return 'This field contains invalid data'
}