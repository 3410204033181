<template>
  <div>
    <b-modal
      id="dir-browser-dialog" 
      title="Analysis Directory Browser" 
      size="lg"
      ok-title="Close"
      ok-only
      no-close-on-backdrop
      no-close-on-esc
    >
      <directory-browser 
        :analysis-id="analysisId" 
        :folder="folder" 
      />
    </b-modal>
  </div>
</template>

<script>
import DirectoryBrowser from './DirectoryBrowser.vue'

export default {
  components: {
    DirectoryBrowser
  },
  props: {
    analysisId: { type: [Number, String], required: true },
    folder: { type: String, default: '' }
  },
  methods: {
    show () {
      this.$bvModal.show('dir-browser-dialog');
    }
  }
}
</script>