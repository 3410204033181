<template>
  <div>
    <b-form class="reset-password-form">
      <h2>Password Reset</h2>
      <b-form-group
        class="pt-3"
        label="Username"
        label-for="username"
      >
        <b-form-input
          id="username"
          v-model="$v.username.$model"
          type="text"
          :state="state($v.username)"
          autocomplete="off"
        />
        <b-form-invalid-feedback :state="state($v.username)">
          Username is required
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="clearfix">
        <b-link
          v-b-toggle.login-options
          class="options-link"
        >
          <font-awesome-icon :icon="showOptions ? 'chevron-up' : 'chevron-down'" />
          {{ showOptions ? 'Hide' : 'More' }} Options
        </b-link>
      </div>

      <b-collapse
        id="login-options"
        v-model="showOptions"
      >
        <b-form-group
          label="Analysis Server"
          label-for="server"
        >
          <b-form-input
            id="server"
            v-model="server"
            type="text"
            :state="state($v.server)"
            autocomplete="off"
          />
          <b-form-invalid-feedback :state="state($v.server)">
            Analysis Server is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-collapse>
      
      <b-button-group>
        <b-button
          class="m-2"
          variant="primary"
          :disabled="$v.$invalid"
          @click="resetPassword"
        >
          Reset Password
        </b-button>
        <b-button
          to="/login"
          class="m-2"
        >
          Back to Login
        </b-button>
      </b-button-group>
      
      <div v-if="resettingPassword">
        <b-spinner /> Resetting password...
      </div>
      <b-alert
        class="pt-3"
        :show="showMessage"
        variant="danger"
      >
        {{ message }}
      </b-alert>
    </b-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'
import { mapMutations } from 'vuex'
import api from '../api'
import getErrorMessage from '../js/getErrorMessage'

export default {
  data () {
    return {
      username: "",
      server: window.location.origin,
      showMessage: false,
      message: "unknown error",
      showOptions: false,
      resettingPassword: false
    }
  },
  validations: {
    username: { required },
    server: { required }
  },
  mounted () {
    let server = localStorage.getItem('kinetics_server');

    if (server) {
      this.server = server;
    }
  },
  methods: {
    ...mapMutations(['setAuthServer']),

    async resetPassword() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.showMessage = false;
        this.message = "unknown error";
        this.resettingPassword = true;

        let server = this.cleanServerString(this.server);
        
        localStorage.setItem('kinetics_server', server);
        this.setAuthServer(server);

        try {
          let resetPasswordResponse = await api.resetPassword({Username: this.username});

          if(resetPasswordResponse.status == 200) {
            this.message = "Check your email for a link to reset your password";
          }
          
          this.showMessage = true;
        }
        catch(error) {
          this.message = getErrorMessage(error, 'Unknown error resetting password.');

          this.showMessage = true;
          this.resettingPassword = false;
        }
        this.resettingPassword = false;
      }
    },
    cleanServerString(server) {
      let s = server.trim();
      if (!s.startsWith('https://') && !s.startsWith('http://')) {
        s = 'https://' + s;
      }

      if (s.endsWith('/')) {
        s = s.slice(0, -1);
      }
      return s;
    }
  },
  mixins: [validationState]
}
</script>

<style scoped>
  .reset-password-form {
    margin: auto;
    margin-top: 40px;
    width: 400px;
  }

  .options-link {
    color: rgb(42, 110, 140);
  }
</style>