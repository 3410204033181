<template>
  <table :class="tableClasses">
    <thead>
      <slot name="columns">
        <tr>
          <th
            v-for="col in columns"
            :key="col[nameField]"
          >
            {{ col[labelField] }}
          </th>
        </tr>
      </slot>
    </thead>
    <tbody v-if="loading">
      <tr>
        <td
          :colspan="colCount"
          class="text-center"
        >
          <b-spinner
            label="Loading..."
            small
          /><span class="loading">Loading...</span>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <template v-for="(row, index) in data">
        <slot :row="row">
          <tr :key="index">
            <td
              v-for="col in columns"
              :key="col[nameField]"
            >
              {{ col.format ? format(row[col[nameField]], col.format) : row[col[nameField]] }}
            </td>
          </tr>
        </slot>
      </template>
    </tbody>
  </table>
</template>

<script>
import formatters from '../js/formatters'

export default {
  mixins: [formatters],
  props: {
    columns: { type: Array, required: false },
    data: { type: Array, required: true },
    nameField: { type: String, default: 'name' },
    labelField: { type: String, default: 'label' },
    loading: { type: Boolean, default: false },
    colCount: { type: Number, default: 1 },
    fixed: { type: Boolean, default: false }
  },
  computed: {
    tableClasses () {
      return {
        table: true,
        'table-fixed': this.fixed
      };
    }
  }
}
</script>

<style>

.loading {
  margin-left: 5px;
}

.table-fixed {
  table-layout: fixed;
}
</style>
