import api from '../api'
import debounce from 'lodash.debounce'
import { fileStatus } from '../js/fileInfo'

const autoSaveFuncs = {};
const flushAutoSaveFunc = async (func) => {
  if (func && func.flush) {
    await func.flush();
  }
};

export default {
  async loadAnalysis({ commit }, analysisId) {
    var analysis = await api.getAnalysis(analysisId);
    commit('setActiveAnalysis', analysis);
    commit('setAnalysisStatus', {
      executionStatus: analysis.status.executionStatus,
      errorMessage: analysis.status.errorMessage,
      output: analysis.status.output
    });
  },

  async endValidate ({ commit }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('resetValidationStatus');
        resolve();
      }, 200)
    })
  },
  async autoSave ({ commit, dispatch }, { saveAction, isValid, id, data, onSave}) {

    commit('setUnsaved');

    if (isValid) {
      commit('setSavePending');
      
      // This function will only be called after a 2 second "cool down period"
      // meaning it will wait until there are no changes for 2 seconds before auto saving
      // we create a new auto save function for each save action so that all actions will be completed
      if (!autoSaveFuncs[saveAction]) {
        autoSaveFuncs[saveAction] = debounce(async function (dispatch, saveAction, id, data, onSave) {
          try {
            let result = await dispatch(saveAction, {id, data});

            // Call onSave callback function
            if (onSave && typeof onSave === 'function') {
              onSave(result);
            }
          }
          catch (err) {
            console.error(err);
            commit('errorSaving', err);
          }
        }, 2000);
      }
      autoSaveFuncs[saveAction](dispatch, saveAction, id, data, onSave);
    } else {
      // Cancel pending save if invalid
      await dispatch('cancelAutoSave', saveAction);
    }
  },
  // Simplified auto-save method that will just call the onSave callback to save
  async autoSaveItem ({ commit, dispatch }, { itemName, isValid, onSave, waitTime}) {

    commit('setUnsaved');

    if (isValid) {
      commit('setSavePending');

      // This function will only be called after a 2 second "cool down period"
      // meaning it will wait until there are no changes for 2 seconds before auto saving
      // we create a new auto save function for each save action so that all actions will be completed
      if (!autoSaveFuncs[itemName]) {
        autoSaveFuncs[itemName] = debounce(async function (onSave) {
          try {
            // Call onSave callback function
            commit('setSaving');
            let p = onSave();
            
            if (p && p instanceof Promise) {
              p.then(() => {
                commit('doneSaving');
              }, (err) => { 
                console.error(err);
                commit('errorSaving', err);
              });
            } else {
              commit('doneSaving');
            }
            
          }
          catch (err) {
            console.error(err);
            commit('errorSaving', err);
          }
        }, waitTime || 2000);
      }
      autoSaveFuncs[itemName](onSave);
    } else {
      // Cancel pending save if invalid
      await dispatch('cancelAutoSave', itemName);
    }
  },
  async flushAutoSave (context, saveAction) {
    if (saveAction) {
      // Flush saveAction function
      var func = autoSaveFuncs[saveAction];
      await flushAutoSaveFunc(func);
    } else {
      // Flush all actions
      await Promise.all(Object.values(autoSaveFuncs).map(async func => {
        await flushAutoSaveFunc(func);
      }));
    }
  },
  async cancelAutoSave ({ commit }, saveAction) {
    var func = autoSaveFuncs[saveAction];
    if (func && func.cancel) {
      await func.cancel();
      commit('cancelSave');
    }
  },
  async saveAnalysisSettings ({ commit }, { id, data }) {
    commit('setSaving');

    await api.saveAnalysisSettings(id, data);

    commit('setAnalysisSettings', data);
    commit('doneSaving');
  },
  async saveMsrAssumptions ({ commit }, { id, data }) {
    commit('setSaving');

    await api.saveMsrAssumptions(id, data);

    commit('doneSaving');
  },
  async createOrUpdateServicingSettings ({ commit }, { data }) {
    commit('setSaving');

    let settings = await api.createOrUpdateServicingSettings(data);

    commit('doneSaving');
    return settings;
  },
  async updateServicingSettings ({ commit }, { data }) {
    commit('setSaving');

    await api.updateServicingSettings(data);

    commit('doneSaving');
  },
  async createServicingSettings ({ commit }, { data }) {
    commit('setSaving');

    await api.createServicingSettings(data);

    commit('doneSaving');
  },
  async deleteServicingSettings ({ commit }, { data }) {
    commit('setSaving');

    await api.deleteServicingSettings(data);

    commit('doneSaving');
  },
  async saveEmailSettings ({ commit }, { data }) {
    commit('setSaving');

    await api.updateEmailSettings(data);

    commit('doneSaving');
  },
  async saveUserSettings ({ commit }, { data }) {
    commit('setSaving');

    await api.updateUserSettings(data);

    commit('doneSaving');
  },
  async cancelUploads ({ getters }) {
    getters.fileSet
      .filter(f => f.status == fileStatus.uploading)
      .forEach(file => {
        if (file.uploadCancelToken) {
          file.uploadCancelToken.cancel('User closed analysis and canceled uploads.');
        }
      });
  },
  async getInputsList ({ state, commit }) {
    let inputs = await api.getInputsList(state.activeAnalysis.id);
    commit('createInputsList', inputs);
  },
  async getAnalysisStatus ({ state, commit }) {
    let analysisStatus = await api.getAnalysisStatus(state.activeAnalysis.id);
    commit('setAnalysisStatus', analysisStatus);
  },
  async getLicense({ commit }) {
    let lic = await api.checkLicense();
    commit('setLicense', lic);
  },
  async updateAnalysis ({ state, commit }) {
    commit('setSaving');

    await api.updateAnalysis(state.activeAnalysis);

    commit('doneSaving');
  },
  async updateScenario ({ state, commit }, { id, data }) {
    commit('setSaving');

    await api.updateScenario(state.activeAnalysis.id, data.scenarioId, data.scenario);

    commit('doneSaving');
  },
  async getScenarios ({ state, commit }) {
    let scenarios = await api.getInputScenarios(state.activeAnalysis.id);
    scenarios.unshift({
      isDefaultScenario: true,
      analysisId: state.activeAnalysis.id,
      enabled: state.activeAnalysis.settings.enableDefaultScenario,
      forecasts: null,
      scenarioId: null,
      scenarioName: "AD&Co Default"
    });

    commit('setScenarios', scenarios);
  },
  async getPenaltyPoints ({ state, commit }) {
    let penaltyPointSets = await api.getPenaltyPointSets(state.activeAnalysis.id);
    penaltyPointSets.unshift({
      isDefaultPoints: true,
      penaltyPointSetId: "AD&Co Default",
      penaltyPointSetName: "AD&Co Default"
    });

    commit('setPenaltyPointSets', penaltyPointSets);
  },
  async getAuthSettings({ commit }) {
    let enableAuthentication = await api.getAuthSettings();
    commit('setAuth', { enabled: enableAuthentication });
  },
  async getAppConfig ({ commit }) {
    let data = await api.getConfiguration();
    commit('setAppConfig', data);
  }
}