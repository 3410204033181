<template>
  <div>
    <b-form class="username-lookup-form">
      <h2>Username Lookup</h2>

      <b-form-group
        class="pt-3"
        label="Email Address"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="$v.emailAddress.$model"
          type="text"
          :state="state($v.emailAddress)"
          autocomplete="off"
        />
        <b-form-invalid-feedback :state="state($v.emailAddress)">
          Must be a valid email address
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="clearfix">
        <b-link
          v-b-toggle.login-options
          class="options-link"
        >
          <font-awesome-icon :icon="showOptions ? 'chevron-up' : 'chevron-down'" />
          {{ showOptions ? 'Hide' : 'More' }} Options
        </b-link>
      </div>

      <b-collapse
        id="login-options"
        v-model="showOptions"
      >
        <b-form-group
          label="Analysis Server"
          label-for="server"
        >
          <b-form-input
            id="server"
            v-model="server"
            type="text"
            :state="state($v.server)"
            autocomplete="off"
          />
          <b-form-invalid-feedback :state="state($v.server)">
            Analysis Server is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-collapse>
      
      <b-button-group>
        <b-button
          class="m-2"
          variant="primary"
          :disabled="$v.$invalid"
          @click="lookupUsername"
        >
          Find Username
        </b-button>
        <b-button
          to="/login"
          class="m-2"
        >
          Back to Login
        </b-button>
      </b-button-group>

      <div v-if="lookingUpUsername">
        <b-spinner /> Looking up username...
      </div>
      <b-alert
        class="pt-3"
        :show="showMessage"
        variant="danger"
      >
        {{ message }}
      </b-alert>
    </b-form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import validationState from '../js/mixins/validationState'
import { mapMutations } from 'vuex'
import api from '../api'
import getErrorMessage from '../js/getErrorMessage'

export default {
  data () {
    return {
      emailAddress: "",
      server: window.location.origin,
      showMessage: false,
      message: "unknown error",
      showOptions: false,
      lookingUpUsername: false
    }
  },
  validations: {
    emailAddress: { required, email },
    server: { required }
  },
  mounted () {
    let server = localStorage.getItem('kinetics_server');

    if (server) {
      this.server = server;
    }
  },
  methods: {
    ...mapMutations(['setAuthServer']),

    async lookupUsername() {

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.showMessage = false;
        this.message = "unknown error";
        this.lookingUpUsername = true;

        let server = this.cleanServerString(this.server);
        
        localStorage.setItem('kinetics_server', server);
        this.setAuthServer(server);

        try {
          let usernameLookupResponse = await api.usernameLookup({Email: this.emailAddress});

          if(usernameLookupResponse.status == 200) {
            this.message = "An email has been sent with your username information.";
          }
          
          this.showMessage = true;
        }
        catch(error) {
          this.message = getErrorMessage(error, 'Unknown error looking up user.');

          this.showMessage = true;
          this.lookingUpUsername = false;
        }
      }
      this.lookingUpUsername = false;
    },
    cleanServerString(server) {
      let s = server.trim();
      if (!s.startsWith('https://') && !s.startsWith('http://')) {
        s = 'https://' + s;
      }

      if (s.endsWith('/')) {
        s = s.slice(0, -1);
      }
      return s;
    }
  },
  mixins: [validationState]
}
</script>

<style scoped>
  .username-lookup-form {
    margin: auto;
    margin-top: 40px;
    width: 400px;
  }

  .options-link {
    color: rgb(42, 110, 140);
  }

  .invalid-feedback {
    color: #d92638;
  }
</style>