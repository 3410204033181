import GridBase from './GridBase'
import { FlexGridFilter, FilterType, ConditionFilterEditor } from '@grapecity/wijmo.grid.filter'
import { ODataCollectionView } from '@grapecity/wijmo.odata'
import AsyncCollectionViewNavigator from './AsyncCollectionViewNavigator'
import { DataType, DateTime, SortDescription } from "@grapecity/wijmo"
import { authHandler } from './gridAuthHandler'
import asODataCondition from './asODataCondition'
import createOperatorCombo from './createOperatorCombo'
import commitChanges from './commitChanges'
import { getReadUrl, getWriteUrl, convertToDbFormat } from './odataReadWriteUrl'
import store from '../../store'

/**
 * Work around to send correct odata filter values for columns with data maps
 * Support case #: 440490
 */
ODataCollectionView.prototype._asODataCondition = asODataCondition;

/**
 * Work around to send authorize header when commitChanges is called
 */
ODataCollectionView.prototype.commitChanges = commitChanges;

/**
 * Work around to fix issue with filting for 0 ("" == 0 is true!)
 * Changed: "" == value To: "" === value
 * Support case #: 440498
 */
ODataCollectionView.prototype._asEquals = function(field, value, type) {
  return type == DataType.Date ? "(" + field + " ge " + this._asODataValue(value, type) + " and " + field + " lt " + this._asODataValue(DateTime.addDays(value, 1), type) + ")" : "" === value ? "(" + field + " eq null or " + field + " eq '')" : "(" + field + " eq " + this._asODataValue(value, type) + ")";
};

// Work around to allow derived types using entityType option
ODataCollectionView.prototype._getReadUrl = getReadUrl;
ODataCollectionView.prototype._getWriteUrl = getWriteUrl;
ODataCollectionView.prototype._convertToDbFormat = convertToDbFormat;

// Work around to show correct options in the filter conditions drop down for columns with datamaps
ConditionFilterEditor.prototype._createOperatorCombo = createOperatorCombo;

export default class extends GridBase {
  constructor (gridElement, options) {
    super();

    this._url = `${options.baseURL}/${options.collection}`;
    this.loading = false;
    
    let sort = options.defaultSort ? options.defaultSort.map(s => new SortDescription(s.property, s.descending !== true )) : [];
    
    this._collectionView = new ODataCollectionView(options.baseURL, options.collection, {
      keys: options.keys,
      oDataVersion: 4,
      deferCommits: true,
      requestHeaders: { Authorization: `Bearer ${store.state.authentication.token}` },
      entityType: options.entityType,
      sortDescriptions: sort,
      newItemCreator: options.itemCreator,
      pageSize: options.pageSize,
      getError: options.getError,
      pageOnServer: true,
      sortOnServer: true
    });

    // create pager
    let paging = options.paging;
    delete options.paging;
    this._pager = new AsyncCollectionViewNavigator(options.pager, {
      byPage: true,
      headerFormat: '',
      cv: this._collectionView
    }, paging);

    let grid = super._initGrid(gridElement, this._collectionView, options);

    // Init filter
    this._filter = new FlexGridFilter(grid);
    this._filter.defaultFilterType = FilterType.Condition;
    
    // Handle 401 errors
    this._collectionView.error.addHandler(authHandler(options.baseURL, this._collectionView));

    // Loading flag
    this._collectionView.loading.addHandler(() => {
      this.loading = true;
      this._setPagerText("Loading...");
      this._callHandler(options.loading);
    });

    this._collectionView.loaded.addHandler(() => {
      this._updatePagerText();
    });

    this._collectionView.collectionChanged.addHandler(() => {
      this._updatePagerText();
    });

    super._bindDataMaps(grid, options);

    // Setup menu
    super._initMenu(() => this.insertRow(), () => super.deleteSelectedRows(), options);

    // Init validators
    super._initValidators(grid, options);
  }

  _setPagerText (content) {
    this._pager.headerFormat = content;
    this._pager.refresh();
  }

  _updatePagerText() {
    let recStart = (this._collectionView.pageIndex + 1) * this._collectionView.pageSize - this._collectionView.pageSize + 1;
    let recEnd = recStart - 1 + this._collectionView.itemCount;
    let totalItems = this._collectionView.totalItemCount + this._collectionView.itemsAdded.length - this._collectionView.itemsRemoved.length;
    let pagerText = `${recStart.toLocaleString()} - ${recEnd.toLocaleString()} of ${totalItems.toLocaleString()}`;
    if (!!this._collectionView.filterDefinition) {
      pagerText += " (filtered)";
    }
    this._setPagerText(pagerText);
  }

  isValid () {
    // Check added items for invalid rows
    for (let i = 0; i < this._collectionView.itemsAdded.length; i++) {
      let item = this._collectionView.itemsAdded[i];
      for (let c of this._grid.columns) {
        if (this._gridValidator.validateItem(item, c.binding) != null) {
          return false;
        }
      }
    }

    // Check edited items for invalid rows
    for (let i = 0; i < this._collectionView.itemsEdited.length; i++) {
      let item = this._collectionView.itemsEdited[i];
      for (let c of this._grid.columns) {
        if (this._gridValidator.validateItem(item, c.binding) != null) {
          return false;
        }
      }
    }

    return true;
  }

  refresh () {
    this._collectionView.itemsAdded.clear();
    this._collectionView.load();
  }

  save (commit=true) {
    // Commit any add operations before save
    if (this._collectionView.isAddingNew) {
      this._collectionView.commitNew();
    }

    // Save changes to server
    let p;

    if(commit && (this._collectionView.itemsAdded.length > 0 ||
      this._collectionView.itemsEdited.length > 0 ||
      this._collectionView.itemsRemoved.length > 0)) {
      
        p = new Promise((resolve, reject) => {
          this._collectionView.commitChanges(req => {
            if (req.status == 500) {
              reject();
            } else {
              resolve();
            }
          });
        });
    }

    return super.save(p);
  }

}