import { initCalcs } from '../js/calcs'
import moment from 'moment'

function formatDateField (date) {
  return moment(date, 'MM/DD/YYYY').toISOString(true);
}

function settingsToCalcs(settings, runType) {
  let calcs = {
    tradedate: runType == 'LoanDynamics' ? settings.firstForecastDate : settings.tradeDate,
    showLDM: runType == 'LoanDynamics',
    customCommand: settings.customCommand,
    useCustomTuningFiles: settings.useCustomTuningFiles,
    defaultdef: settings.defaultDefinition,
    xmlinputs: settings.outputXmlInputs,
    xmlresults: settings.outputXmlResults,
    modelDataDate: settings.modelDataDate,
    runType,
    enableDefaultScenario: settings.enableDefaultScenario,
    maxForecastMonths: settings.maxForecastMonths,
    smmMdrDefinition: settings.smmMdrDefinition,
    delinquencyCalculationMethod: settings.delinquencyCalculationMethod,
    curveType: settings.curveType,
    resultSettings: runType == 'LoanDynamics' ? settings.resultSettings : null
  }

  if (runType == 'RiskValDynamics') {
    Object.assign(calcs, {
      paths: settings.monteCarlo.paths,
      assumptions: {
        quoteType: settings.quoteType,
        quote: settings.quote,
        settleDate: settings.settleDate,
        excessServicingQuote: settings.excessServicingQuote
      },
      useHedgeFile: settings.useHedgeFile,
      hedgeAssumptions: {
        quoteType: settings.hedgeQuoteType,
        quote: settings.hedgeQuote,
        settleDate: settings.hedgeSettleDate
      },
      hedgeAveragingType: settings.hedgeAveragingType,
      enableValueAndRiskMetrics: settings.monteCarlo.enableMonteCarlo,
      showpayup: settings.payup,
      payupHorizon: settings.payupHorizon,
      showcredit: settings.enableScenarioGrid,
      creditRepricing: settings.scenarioGridRepricing,
      showscenprice: settings.scenarioGridPrice,
      supportCMO: settings.cmo,
      MIonly: settings.MiOnly,
      irprocess: settings.irProcess,
      irprocessShift: settings.irProcessShift,
      volatilityType: settings.volatilityType,
      defaultdef: settings.defaultDefinition,
      showoas: settings.monteCarlo.oas,
      showFcAnalysis: settings.forwardCurves,
      showinterestRateRisk: settings.fieldConfigMetaData.enableInterestRateRisk,
      interestRateRisk: {
        showeffdur: settings.monteCarlo.effectiveDuration,
        showspreaddur:  settings.monteCarlo.mortgageSpread,
        showVega: settings.monteCarlo.vega,
        showycdur: settings.monteCarlo.yieldCurves,
        showkeydur: settings.monteCarlo.keyRateDurations,
        showparshock: settings.monteCarlo.parShock
      },
      showmodelRisk: settings.fieldConfigMetaData.enableModelRisk,
      modelRisk: {
        showppdur: settings.monteCarlo.prepaySensitivity,
        showrefidur: settings.monteCarlo.refiSensitivity,
        showturndur: settings.monteCarlo.turnoverSensitivity,
        showslidedur: settings.monteCarlo.sCurveSensitivity,
        showltv: settings.monteCarlo.ltvSensitivity,
        showfico: settings.monteCarlo.ficoSensitivity
      },
      showhpi: settings.monteCarlo.hpiDuration,
      showMSR: settings.msrValueDecomposition ? 6 : 5,
      showcashflows: settings.cashFlows.enableCashFlows,
      cashflows: {
        columnSet: settings.cashFlows.cashFlowColumnSet,
        showLoanLevel: settings.cashFlows.loanLevelCashFlows,
        showMonte: settings.cashFlows.mcCashFlows,
        showMonteAvg: settings.cashFlows.averagedMcCashFlows,
        showScenarios: settings.cashFlows.scenarioCashFlows
      },
      showB5: settings.b5.enableB5,
      b5calcs: {
        premscale: settings.b5.premiumScale,
        CC_r: settings.b5.riskFreeRate,
        CC_confidence: settings.b5.confidenceLevels,
        CC_R: settings.b5.returnOnEquityRates,
      },
      showCCAR: settings.enableStressTests,
      stressTestPrice: settings.stressTestPrice,
      stressTestRepricing: settings.stressTestRepricing,
      showExternalHpaAndUnemp: settings.fieldConfigMetaData.enableExternalHpaAndUnemployment,
      scenarioIrAdjust: settings.scenarioIrAdjust,
      scenarioLocalityAdjust: settings.scenarioLocalityAdjust,
      scenarioAddSeasonality: settings.scenarioAddSeasonality,
      useHpaZipMap: settings.useHpaZipMap,
      useUnempZipMap: settings.useUnempZipMap,
      showRepricing: settings.repricing,
      //numhandles: settings.numParallelPositions,
      msr: {},
      oasBenchmark: settings.oasBenchmark,
      tuningType: settings.tunings.tuningType,
      useCustomHpaTuningFile: settings.tunings.useCustomHpaTuningFile,
      useCustomTuningFiles: settings.tunings.useCustomTuningFiles,
      tunings: {
        tunecd: settings.tunings.customTunings.tuneCD,
        tunedc: settings.tunings.customTunings.tuneDC,
        tuneds: settings.tunings.customTunings.tuneDS,
        tunedt: settings.tunings.customTunings.tuneDT,
        tunesc: settings.tunings.customTunings.tuneSC,
        tunest: settings.tunings.customTunings.tuneST,
        tuneMDR_scale: settings.tunings.customTunings.mdrScale,
        tunepl_td: settings.tunings.customTunings.tuneProbLossTD,
        tunepl_ts: settings.tunings.customTunings.tuneProbLossTS,
        tunepl_tc: settings.tunings.customTunings.tuneProbLossTC,
        tunesev_td: settings.tunings.customTunings.tuneSeverityTD,
        tunesev_ts: settings.tunings.customTunings.tuneSeverityTS,
        tunesev_tc: settings.tunings.customTunings.tuneSeverityTC,
        tuneLTV_slide: settings.tunings.customTunings.ltvSlide,
        tuneFICO_slide: settings.tunings.customTunings.ficoSlide,
        tuneSeverity_scale: settings.tunings.customTunings.severityScale,
        tunesato_residual: settings.tunings.customTunings.satoResidual,
        PPtunerefi_factor: settings.tunings.customTunings.refiScale,
        PPtuneturnover_factor: settings.tunings.customTunings.turnoverScale,
        PPtuneslide_factor: settings.tunings.customTunings.slideFactor,
        PPetunescale_tuning: settings.tunings.customTunings.smmScale,
        SmmTuneAge: settings.tunings.customTunings.smmAging,
        SmmTuneSteep: settings.tunings.customTunings.smmSteepness,
        SmmTuneLag: settings.tunings.customTunings.smmLag,
        SmmTuneBurnout: settings.tunings.customTunings.smmBurnout,
        TuneSeasonality: settings.tunings.customTunings.seasonality,
        SmmTunePremiumSteepness: settings.tunings.customTunings.smmTunePremiumSteepness
      },
      runType
    })
  }
  
  return calcs;
}

function calcsToSettings(calcs) {
  if (calcs.runType == "LoanDynamics") {
    return {
      firstForecastDate: formatDateField(calcs.tradedate),
      aggregateResults: true,
      loanLevelResults: true,
      customCommand: calcs.customCommand,
      defaultDefinition: calcs.defaultdef,
      useCommandsFile: calcs.enableCommandsFile,
      useCustomTuningFiles: calcs.useCustomTuningFiles,
      outputXmlInputs: calcs.xmlinputs,
      outputXmlResults: calcs.xmlresults,
      modelDataDate: calcs.modelDataDate,
      enableDefaultScenario: calcs.enableDefaultScenario,
      maxForecastMonths: calcs.maxForecastMonths,
      smmMdrDefinition: calcs.smmMdrDefinition,
      delinquencyCalculationMethod: calcs.delinquencyCalculationMethod,
      curveType: calcs.curveType,
      runType: calcs.runType,
      resultSettings: calcs.resultSettings
    };
  }

  return {
    runType: calcs.runType,
    tradeDate: formatDateField(calcs.tradedate),
    quoteType: calcs.assumptions.quoteType,
    quote: calcs.assumptions.quote,
    excessServicingQuote: calcs.assumptions.excessServicingQuote,
    settleDate: formatDateField(calcs.assumptions.settleDate),
    useHedgeFile: calcs.useHedgeFile,
    hedgeQuoteType: calcs.hedgeAssumptions.quoteType,
    hedgeQuote: calcs.hedgeAssumptions.quote,
    hedgeSettleDate: formatDateField(calcs.hedgeAssumptions.settleDate),
    hedgeAveragingType: calcs.hedgeAveragingType,
    payup: calcs.showpayup,
    payupHorizon: calcs.payupHorizon,
    enableScenarioGrid : calcs.showcredit,
    scenarioGridRepricing: calcs.creditRepricing,
    scenarioGridPrice: true, //calcs.showscenprice,
    cmo: calcs.supportCMO,
    MiOnly: calcs.MIonly,
    irProcess: calcs.irprocess,
    irProcessShift: calcs.irprocessShift,
    volatilityType: calcs.volatilityType,
    defaultDefinition: calcs.defaultdef,
    forwardCurves: calcs.showFcAnalysis,
    monteCarlo: {
      enableMonteCarlo: calcs.enableValueAndRiskMetrics,
      paths: calcs.paths,
      oas: calcs.showoas,
      effectiveDuration: calcs.interestRateRisk.showeffdur,
      mortgageSpread: calcs.interestRateRisk.showspreaddur,
      vega: calcs.interestRateRisk.showVega,
      keyRateDurations: calcs.interestRateRisk.showkeydur,
      yieldCurves: calcs.interestRateRisk.showycdur,
      parShock: calcs.interestRateRisk.showparshock,
      prepaySensitivity: calcs.modelRisk.showppdur,
      refiSensitivity: calcs.modelRisk.showrefidur,
      turnoverSensitivity: calcs.modelRisk.showturndur,
      sCurveSensitivity: calcs.modelRisk.showslidedur,
      ficoSensitivity: calcs.modelRisk.showfico,
      ltvSensitivity: calcs.modelRisk.showltv,
      hpiDuration: calcs.showhpi,
    },
    fieldConfigMetaData: {
      enableModelRisk: calcs.showmodelRisk,
      enableInterestRateRisk: calcs.showinterestRateRisk,
      enableExternalHpaAndUnemployment: calcs.showExternalHpaAndUnemp
    },
    msrValueDecomposition: calcs.showMSR == 6,
    cashFlows: {
      enableCashFlows: calcs.showcashflows,
      averagedMcCashFlows: calcs.cashflows.showMonteAvg,
      mcCashFlows: calcs.cashflows.showMonte,
      loanLevelCashFlows: calcs.cashflows.showLoanLevel,
      cashFlowColumnSet: calcs.cashflows.columnSet,
      scenarioCashFlows: calcs.cashflows.showScenarios
    },
    b5: {
      enableB5: calcs.showB5,
      premiumScale: calcs.b5calcs.premscale,
      riskFreeRate: calcs.b5calcs.CC_r,
      confidenceLevels: calcs.b5calcs.CC_confidence,
      returnOnEquityRates: calcs.b5calcs.CC_R
    },
    enableStressTests: calcs.showCCAR,
    stressTestPrice:  true, //calcs.stressTestPrice,
    stressTestRepricing: calcs.stressTestRepricing,
    scenarioIrAdjust: calcs.scenarioIrAdjust,
    scenarioLocalityAdjust: calcs.scenarioLocalityAdjust,
    scenarioAddSeasonality: calcs.scenarioAddSeasonality,
    useHpaZipMap: calcs.useHpaZipMap,
    useUnempZipMap: calcs.useUnempZipMap,
    repricing: calcs.showRepricing,
    //numParallelPositions: calcs.numhandles,
    useCommandsFile: calcs.enableCommandsFile,
    customCommand: calcs.customCommand,
    outputXmlInputs: calcs.xmlinputs,
    outputXmlResults: calcs.xmlresults,
    modelDataDate: calcs.modelDataDate,
    oasBenchmark: calcs.oasBenchmark,
    tunings: {
      tuningType: calcs.tuningType,
      useCustomHpaTuningFile: calcs.useCustomHpaTuningFile,
      useCustomTuningFiles: calcs.useCustomTuningFiles,
      customTunings: {
        tuneCD: calcs.tunings.tunecd,
        tuneDC: calcs.tunings.tunedc,
        tuneDS: calcs.tunings.tuneds,
        tuneDT: calcs.tunings.tunedt,
        tuneSC: calcs.tunings.tunesc,
        tuneST: calcs.tunings.tunest,
        mdrScale: calcs.tunings.tuneMDR_scale,
        ltvSlide: calcs.tunings.tuneLTV_slide,
        ficoSlide: calcs.tunings.tuneFICO_slide,
        severityScale: calcs.tunings.tuneSeverity_scale,
        satoResidual: calcs.tunings.tunesato_residual,
        refiScale: calcs.tunings.PPtunerefi_factor,
        turnoverScale: calcs.tunings.PPtuneturnover_factor,
        slideFactor: calcs.tunings.PPtuneslide_factor,
        smmScale: calcs.tunings.PPetunescale_tuning,
        smmAging: calcs.tunings.SmmTuneAge,
        smmSteepness: calcs.tunings.SmmTuneSteep,
        smmLag: calcs.tunings.SmmTuneLag,
        smmBurnout: calcs.tunings.SmmTuneBurnout,
        seasonality: calcs.tunings.TuneSeasonality,
        SmmTunePremiumSteepness: calcs.tunings.SmmTunePremiumSteepness
      }
    }
  }
}

// Temporary function to get default settings
function initSettings() {
  var calcs = initCalcs();

  return calcsToSettings(calcs);
}

export { settingsToCalcs, calcsToSettings, initSettings }
