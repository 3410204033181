const vehicleTypeOptions = [
  { value: 0, text: "Car" },
  { value: 1, text: "Truck" },
  { value: 2, text: "SUV" },
  { value: 3, text: "Unknown" },
  { value: 4, text: "Van" }
];

const vehicleMakeOptions = [
  { value: 0, text: "Honda" },
  { value: 1, text: "Toyota" },
  { value: 2, text: "Other" }
];

export { vehicleTypeOptions, vehicleMakeOptions }
