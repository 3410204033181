const delinquencyOptions = [
  { value: 'C', text: "C (Current)" },
  { value: 'F', text: "F (Foreclosure)" },
  { value: 'R', text: "R (REO)" },
  { value: 'T', text: "T (Terminated)" },
  { value: '1', text: "1 (30 Days Delq)" },
  { value: '2', text: "2 (60 Days Delq)" },
  { value: '3', text: "3 (90 Days Delq)" },
  { value: '4', text: "4 (120 Days Delq)" },
  { value: '5', text: "5 (150 Days Delq)" },
  { value: '6', text: "6 (180+ Days Delq)" },
];

const indexOptions = [
  { value: 'SOFRRATE', text: "SOFRRATE" },
  { value: 'SOFR30A', text: "SOFR30A" },
  { value: 'SOFR90A', text: "SOFR90A" },
  { value: 'SOFR180A', text: "SOFR180A" },
  { value: 'MTA_12', text: "MTA_12" },
  { value: 'PAR_TSY_1MO', text: "PAR_TSY_1MO" },
  { value: 'PAR_TSY_3MO', text: "PAR_TSY_3MO" },
  { value: 'PAR_TSY_6MO', text: "PAR_TSY_6MO" },
  { value: 'PAR_TSY_1YR', text: "PAR_TSY_1YR" },
  { value: 'PAR_TSY_2YR', text: "PAR_TSY_2YR" },
  { value: 'PAR_TSY_3YR', text: "PAR_TSY_3YR" },
  { value: 'PAR_TSY_5YR', text: "PAR_TSY_5YR" },
  { value: 'PAR_TSY_7YR', text: "PAR_TSY_7YR" },
  { value: 'PAR_TSY_10YR', text: "PAR_TSY_10YR" },
  { value: 'PAR_TSY_30YR', text: "PAR_TSY_30YR" },
  { value: 'PAR_LIBOR_1M', text: "PAR_LIBOR_1M" },
  { value: 'PAR_LIBOR_3M', text: "PAR_LIBOR_3M" },
  { value: 'PAR_LIBOR_6M', text: "PAR_LIBOR_6M" },
  { value: 'PAR_LIBOR_1YR', text: "PAR_LIBOR_1YR" },
  { value: 'PAR_PRIME', text: "PAR_PRIME" },
  { value: 'COF', text: "COF" },
  { value: '_FED_DISCOUNT', text: "_FED_DISCOUNT" },
  { value: 'FED_FUND', text: "FED_FUND" }
];

const indexOptionsLdm = [
  { value: 'SOFR_1', text: "SOFR_1" },
  { value: 'SOFR_3', text: "SOFR_3" },
  { value: 'SOFR_6', text: "SOFR_6" },
  { value: 'MTA_12', text: "MTA_12" },
  { value: 'PAR_TSY_1MO', text: "PAR_TSY_1MO" },
  { value: 'PAR_TSY_3MO', text: "PAR_TSY_3MO" },
  { value: 'PAR_TSY_6MO', text: "PAR_TSY_6MO" },
  { value: 'PAR_TSY_1YR', text: "PAR_TSY_1YR" },
  { value: 'PAR_TSY_2YR', text: "PAR_TSY_2YR" },
  { value: 'PAR_TSY_3YR', text: "PAR_TSY_3YR" },
  { value: 'PAR_TSY_5YR', text: "PAR_TSY_5YR" },
  { value: 'PAR_TSY_7YR', text: "PAR_TSY_7YR" },
  { value: 'PAR_TSY_10YR', text: "PAR_TSY_10YR" },
  { value: 'PAR_TSY_30YR', text: "PAR_TSY_30YR" },
  { value: 'PAR_LIBOR_1M', text: "PAR_LIBOR_1M" },
  { value: 'PAR_LIBOR_3M', text: "PAR_LIBOR_3M" },
  { value: 'PAR_LIBOR_6M', text: "PAR_LIBOR_6M" },
  { value: 'PAR_LIBOR_1YR', text: "PAR_LIBOR_1YR" },
  { value: 'PAR_PRIME', text: "PAR_PRIME" },
  { value: 'COF', text: "COF" },
  { value: '_FED_DISCOUNT', text: "_FED_DISCOUNT" },
  { value: 'FED_FUND', text: "FED_FUND" }
];

const documentationOptions = [
  { value: 'F', text: "F (Full)" },
  { value: 'L', text: "L (Limited)" },
  { value: '0', text: "0 (None)" }
];

const occupancyOptions = [
  { value: 'O', text: "O (Owner)" },
  { value: 'S', text: "S (Second Home)" },
  { value: 'I', text: "I (Investor)" }
];

const loanPurposeOptions = [
  { value: 'P', text: "P (Purchase)" },
  { value: 'E', text: "E (Cash out refinance)" },
  { value: 'R', text: "R (Rate driven refinance)" },
  { value: 'C', text: "C (Refi Construction)" }
];

const propertyTypeOptions = [
  { value: 'SFR', text: "SFR (Single Family)" },
  { value: 'MFR', text: "MFR (Multi-Unit Single Family)" },
  { value: 'Condo', text: "Condo" },
  { value: 'Coop', text: "Coop" },
  { value: 'PUD', text: "PUD" },
  { value: 'MH', text: "MH (Manufactured Housing)" },
];

const tuneStringOptions = [
  { value: 'PRIME', text: "PRIME" },
  { value: 'SUBPRIME', text: "SUBPRIME" },
  { value: 'FRD', text: "FRD (Freddie Mac)" },
  { value: 'GNMA', text: "GNMA (Ginnie Mae)" },
  { value: 'FNMA', text: "FNMA (Fannie Mae)" },
  { value: 'ALT_A', text: "ALT_A" },

  // R/T Tunings
  // { value: 'FNMA_TRANSACTOR', text: "FNMA Transactor" },
  // { value: 'FNMA_REVOLVER', text: "FNMA Revolver" },
  // { value: 'GNMA_TRANSACTOR', text: "GNMA Transactor" },
  // { value: 'GNMA_REVOLVER', text: "GNMA Revolver" },
];

const trendedCreditOptions = [
  { value: 'R', text: 'R (Revolver)'},
  { value: 'T', text: 'T (Transactor)'},
];

const originationChannelOptions = [
  { value: 'R', text: "R (Retail)" },
  { value: 'B', text: "B (Broker)" },
  { value: 'C', text: "C (Correspondent)" },
];

const issuerOptions = [
  { value: 'FANNIE_MAE', text: 'Fannie Mae' },
  { value: 'FREDDIE_MAC', text: 'Freddie Mac' },
  { value: 'GINNIE_MAE', text: 'Ginnie Mae' },
  { value: 'NONAGENCY_SUBPRIME', text: 'Subprime (NA)' },
  { value: 'NONAGENCY_PRIME', text: 'Prime (NA)' },
  { value: 'NONAGENCY_OTHER', text: 'Other (NA)' }
]

const issuerOptionsAgency = [
  { value: 'FANNIE_MAE', text: 'Fannie Mae' },
  { value: 'FREDDIE_MAC', text: 'Freddie Mac' },
  { value: 'GINNIE_MAE', text: 'Ginnie Mae' }
]

const issuerOptionsNonAgency = [
  { value: 'NONAGENCY_SUBPRIME', text: 'Subprime (NA)' },
  { value: 'NONAGENCY_PRIME', text: 'Prime (NA)' },
  { value: 'NONAGENCY_OTHER', text: 'Other (NA)' }
]

const stateOptions = [
  {value: 'AK', text: 'AK'},
  {value: 'AL', text: 'AL'},
  {value: 'AR', text: 'AR'},
  {value: 'AZ', text: 'AZ'},
  {value: 'CA', text: 'CA'},
  {value: 'CO', text: 'CO'},
  {value: 'CT', text: 'CT'},
  {value: 'DC', text: 'DC'},
  {value: 'DE', text: 'DE'},
  {value: 'FL', text: 'FL'},
  {value: 'GA', text: 'GA'},
  {value: 'HI', text: 'HI'},
  {value: 'IA', text: 'IA'},
  {value: 'ID', text: 'ID'},
  {value: 'IL', text: 'IL'},
  {value: 'IN', text: 'IN'},
  {value: 'KS', text: 'KS'},
  {value: 'KY', text: 'KY'},
  {value: 'LA', text: 'LA'},
  {value: 'MA', text: 'MA'},
  {value: 'MD', text: 'MD'},
  {value: 'ME', text: 'ME'},
  {value: 'MI', text: 'MI'},
  {value: 'MN', text: 'MN'},
  {value: 'MO', text: 'MO'},
  {value: 'MS', text: 'MS'},
  {value: 'MT', text: 'MT'},
  {value: 'NC', text: 'NC'},
  {value: 'ND', text: 'ND'},
  {value: 'NE', text: 'NE'},
  {value: 'NH', text: 'NH'},
  {value: 'NJ', text: 'NJ'},
  {value: 'NM', text: 'NM'},
  {value: 'NV', text: 'NV'},
  {value: 'NY', text: 'NY'},
  {value: 'OH', text: 'OH'},
  {value: 'OK', text: 'OK'},
  {value: 'OR', text: 'OR'},
  {value: 'PA', text: 'PA'},
  {value: 'RI', text: 'RI'},
  {value: 'SC', text: 'SC'},
  {value: 'SD', text: 'SD'},
  {value: 'TN', text: 'TN'},
  {value: 'TX', text: 'TX'},
  {value: 'UT', text: 'UT'},
  {value: 'VA', text: 'VA'},
  {value: 'VT', text: 'VT'},
  {value: 'WA', text: 'WA'},
  {value: 'WI', text: 'WI'},
  {value: 'WV', text: 'WV'},
  {value: 'WY', text: 'WY'},
  {value: 'PR', text: 'PR'},
  {value: 'GU', text: 'GU'},
  {value: 'VI', text: 'VI'},
  {value: 'US', text: 'US'}
]

const prepayPenaltyStructureOptions = [
  {value: 'POINTS_ONLY', text: 'Points Only'},
  {value: 'LOCKOUT_ONLY', text: 'Lockout Only'},
  {value: 'YIELD_MAINTENANCE_ONLY', text: 'Yield Maintenance Only'},
  {value: 'LOCKOUT_TO_POINTS', text: 'Lockout to Points'},
  {value: 'YIELD_MAINTENANCE_TO_POINTS', text: 'Yield Maintenance to Points'},
  {value: 'LOCKOUT_TO_YIELD_MAINTENANCE', text: 'Lockout to Yield Maintenance'},
  {value: 'UNKNOWN_TO_POINTS', text: 'Unknown to Points'}
]

export {
  delinquencyOptions,
  indexOptions,
  indexOptionsLdm,
  documentationOptions,
  occupancyOptions,
  loanPurposeOptions,
  propertyTypeOptions,
  tuneStringOptions,
  originationChannelOptions,
  issuerOptions,
  issuerOptionsAgency,
  issuerOptionsNonAgency,
  stateOptions,
  trendedCreditOptions,
  prepayPenaltyStructureOptions
}
