<template>
  <collapsible-section
    section-title="Analysis Settings"
    hide-toggle
  >
    <b-form-group
      v-if="enableCmo && !isMsrAnalysis"
      label="Position Type"
      label-for="cmo-options"
      title="Allow processing by Cusip name rather than pool characteristics"
    >
      <b-select
        id="cmo-options"
        :value="cmo"
        @change="$emit('update:cmo', $event)"
        size="sm"
      >
        <option
          v-for="(option,index) in cmoOptions"
          :key="option.value"
          :value="option.value"
          :hidden="cmoOptions[index].disabled"
        >
          {{ option.text }}
        </option>
      </b-select>
    </b-form-group>

    <!-- These options are not currently used by any modules

    <b-form-group
      v-if="enable('TBAflag')"
      label="Determine TBA future settle"
      label-for="tba-flag-options"
    >
      <b-select
        id="tba-flag-options"
        v-model="settings.TbaFlag"
        :options="TBAflagoptions"
        size="sm"
      />
    </b-form-group>

      <b-form-group
      v-if="enable('showMSR') && !enable('showMSRdecomp')"
      label="Select Mortgage Servicing Rights calculations"
      label-for="msr-calcs"
      title="Select whether to value as MSR and to do decomposition of valuation"
    >
      <b-select
        id="msr-calcs"
        v-model="settings.showMSR"
        :options="showMsrOptions"
        size="sm"
      />
    </b-form-group>

    <b-form-group
      v-if="enable('MIonly')"
      label="Cashflow point of view"
      label-for="cashflow-pov"
    >
      <b-select
        id="cashflow-pov"
        v-model="settings.MiOnly"
        :options="miOnlyOptions"
        size="sm"
      />
    </b-form-group> -->

    <b-form-row>
      <b-col>
        <b-form-group
          label="Monte Carlo Paths"
          label-for="mc-paths"
          title="Number of paths for MC calculations"
          :label-cols="5"
        >
          <b-form-input
            id="mc-paths"
            :value="v.monteCarlo.paths.$model"
            @input="validateAndUpdate('update-paths', toNumber($event), v.monteCarlo.paths)"
            type="text"
            class="field-sm"
            size="sm"
            :state="state(v.monteCarlo.paths)"
            autocomplete="off"
          />
          <b-form-invalid-feedback :state="state(v.monteCarlo.paths)">
            {{ validationMessage(v.monteCarlo.paths) }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- <b-form-group
          v-if="val('CMOs')"
          label="Number of LDM Threads"
          label-for="ldm-threads"
          title="Number of pieces of collateral to process in parallel for each CMO"
          :label-cols="5"
        >
          <b-form-input
            id="ldm-threads"
            v-model="settings.numThreads"
            type="text"
            size="sm"
          />
        </b-form-group> -->

        <date-picker
          id="trade-date"
          :value="tradeDate"
          @input="validateAndUpdate('update:tradeDate', $event, v.tradeDate)"
          label="Trade Date"
          :label-cols="5"
          :state="state(v.tradeDate)"
        >
          <b-form-invalid-feedback :state="state(v.tradeDate)">
            {{ validationMessage(v.tradeDate) }}
          </b-form-invalid-feedback>
        </date-picker>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          label="Interest Rate Process"
          label-for="ir-process"
          title="Choose interest rate process"
          :label-cols="5"
        >
          <b-select
            id="ir-process"
            class="field-md"
            :value="irProcess"
            @input="$emit('update:irProcess', $event)"
            :options="irOptions"
            size="sm"
          />
        </b-form-group>
        <b-form-group
          v-if="irProcess == 'BlackKarasinski' || irProcess == 'SquaredGaussian'"
          label="Interest Rate Process Shift (%)"
          label-for="irprocess-shift"
          title="Shift parameter for non-linear rate models (Black Karasinski or Squared Gaussian)"
          :label-cols="5"
        >
          <b-form-input
            id="irprocess-shift"
            class="field-sm"
            :value="irProcessShift"
            @input="validateAndUpdate('update:irProcessShift', toNumber($event), v.irProcessShift)"
            type="text"
            size="sm"
            :state="state(v.irProcessShift)"
          />
          <b-form-invalid-feedback :state="state(v.irProcessShift)">
            {{ validationMessage(v.irProcessShift) }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          label="OAS Benchmark"
          label-for="oas-benchmark"
          :label-cols="5"
        >
          <b-select
            id="oas-benchmark"
            class="field-md"
            :value="oasBenchmark"
            @input="$emit('update:oasBenchmark', $event)"
            :options="oasBenchmarkOptions"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group
          label="Volatility Type"
          label-for="volatility-type"
          :label-cols="5"
        >
          <b-select
            id="volatilityType"
            class="field-md"
            :value="volatilityType"
            @input="$emit('update:volatilityType', $event)"
            :options="volatilityTypeOptions"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <!-- These options are not currently use by any modules
    <b-form-group
      v-if="val('CMOs')"
      label="Intex Mode"
      label-for="intex-mode"
      title="Set termination condition for delinquent loans"
    >
      <b-select
        id="intex-mode"
        v-model="settings.intexMode"
        :options="intexModeOptions"
        size="sm"
      />
    </b-form-group>

    <b-form-group
      v-if="(settings.intexMode == 3) || (settings.intexMode == 4)"
      label="Custom groups list"
      label-for="custom-groups"
      title="Filename for loan grouping specifier"
    >
      <b-form-input
        id="custom-groups"
        v-model="settings.intexGroupFile"
        type="text"
        size="sm"
      />
    </b-form-group>

    <b-form-group
      v-if="(settings.intexmode == 5) || (settings.intexmode == 6)"
      label="Number of k-mean groups"
      label-for="custom-groups"
      title="Number of groups for k-mean calculation"
    >
      <b-form-input
        id="custom-groups"
        v-model="settings.intexNumGroups"
        type="text"
        size="sm"
      />
    </b-form-group> -->
  </collapsible-section>
</template>

<script>
import validationState from '../js/mixins/validationState'
import DatePicker from './DatePicker.vue'
import CollapsibleSection from './CollapsibleSection.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { volatilityTypeOptions } from '../js/options/volatilityTypeOptions'

export default {
  props: {
    cmo: { type: Number, required: true },
    paths: { type: [Number,String], required: true },
    tradeDate: { type: [String, Date], required: true },
    irProcess: { type: String, required: true },
    irProcessShift: { type: [Number,String], required: true },
    oasBenchmark: { type: String, required: true },
    volatilityType: { type: String, required: true },
    v: { type: Object, required: true }
  },
  data () {
    return {
      cmoOptions: [
        { value: 0, text: "Loans" },
        { value: 1, text: "Loans/Pools" },
        { value: 2, text: "Loans/Pools/CMOs" },
        { value: 3, text: "CMOs only" }
      ],
      irOptions: [
        { value: 'BlackKarasinski', text: "Black Karasinski" },
        { value: 'HullWhite', text: "Hull-White" },
        { value: 'SquaredGaussian', text: "Squared Gaussian" },
        { value: 'TwoFactorGaussian', text: "Two Factor Gaussian" }
      ],
      oasBenchmarkOptions: [
        { value: 'Libor', text: "LIBOR" },
        { value: 'Sofr', text: "SOFR" },
        { value: 'Treasury', text: "Treasury" }
      ],
      volatilityTypeOptions,
      // TBAflagoptions: [
      //   { value: 0, text: "Apply characteristics at trade date" },
      //   { value: 1, text: "Settle date for payup, trade date other calcs" },
      //   { value: 2, text: "Trade date for payup, settle date others" },
      //   { value: 3, text: "Apply characteristics at settle date" }
      // ],
      showMsrOptions: [
        { value: 0, text: "Run as loan, not MSR valuation" },
        { value: 5, text: "Show valuation as MSR" },
        { value: 6, text: "Show MSR valuation and its components" }
      ],
      miOnlyOptions: [
        { value: 0, text: "Analyze by loan holder" },
        { value: 1, text: "Analyze by MI cashflow" }
      ],
      intexModeOptions: [
        { value: 0, text: "Explode" },
        { value: 1, text: "Explode (not mega) NOT SUPPORTED" },
        { value: 2, text: "Weighted Average" },
        { value: 3, text: "Custom Groups, run LDM as pools" },
        { value: 4, text: "Custom Groups, run LDM loan-level" },
        { value: 5, text: "K-mean Groups, run LDM as pools" },
        { value: 6, text: "K-mean Groups, run LDM loan-level" }
      ],
    }
  },
  computed: {
    ...mapState(['isValidating', 'activeAnalysis']),
    ...mapGetters(['isMsrAnalysis']),

    enableCmo () {
      let templist = this.cmoOptions.filter(
        element => element.disabled === false
      );
      return templist.length > 1;
    }
  },
  methods: {
    ...mapMutations(['addValidationStatus'])
  },
  watch: {
    isValidating (val) {
      if (val === true) {
        this.v.$touch();

        this.addValidationStatus({
          tab: 'Calculations',
          item: 'analysis-settings-panel',
          isValid: !this.v.$invalid
        });
      }
    }
  },
  components: {
    DatePicker,
    CollapsibleSection
  },
  mixins: [validationState]
}
</script>