<template>
  <div>
    <div>
      <h3 class="ml-2">
        Analysis {{ displayPath }}
      </h3>
      <icon-button
        icon="sync-alt"
        theme-class="light"
        title="Refresh"
        @click="getDirectoryListing"
      />
      
      <div class="float-md-right">
        <icon-button
          icon="level-up-alt"
          theme-class="light"
          :disabled="path.length === 0"
          @click="upLevel"
        >
          Up a level
        </icon-button>
        <upload-button 
          ref="uploadButton"
          wrapper-class="icon-button light"
          :file-types="[]"
          @input="uploadFiles"
          multiple
        >
          <font-awesome-icon
            icon="cloud-upload-alt"
            class="upload-files-icon mr-2"
            title="Upload Files"
          />
          <span>Upload</span>
        </upload-button>
        <icon-button
          icon="file"
          theme-class="light"
          @click="showModal('file')"
        >
          New File
        </icon-button>
        <icon-button
          icon="folder"
          theme-class="light"
          @click="showModal('folder')"
        >
          New Folder
        </icon-button>
      </div>

      <div class="dir-browser scrollable">
        <b-table
          table-class="table-modern"
          :items="directories"
          :fields="fields"
          sort-by="name"
          :sort-compare="sortCompare"
          :busy="loading"
          @row-clicked="openFolder"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner
                class="align-middle"
                small
              />
              <strong> Loading...</strong>
            </div>
          </template>

          <template #cell(name)="data">
            <span v-if="data.item.isDirectory">
              <font-awesome-icon
                icon="folder"
                class="dir-icon"
              />{{ data.item.name }}
            </span>
            <span v-else>{{ data.item.name }}</span>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="dark"
              text="..."
              size="sm"
              no-caret
              right
            >
              <b-dropdown-item
                v-if="!data.item.isDirectory"
                @click="edit(data.item.name)"
              >
                Edit
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!data.item.isDirectory"
                @click="confirmRename(data.item.name)"
              >
                Rename
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.confirm-delete
                @click="confirmDelete(data.item)"
              >
                Delete
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!data.item.isDirectory"
                @click="download(data.item.name)"
              >
                Download
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </div>

    <!-- <file-editor-dialog id="directory-editor" ref="fileEditor" :editor-data="editorData" :save-path="savePath"></file-editor-dialog> -->
    
    <b-modal
      id="add-item"
      :ok-title="`Create ${itemType}`"
      no-close-on-backdrop
      @ok="addItem"
    >
      <b-form-group
        :label="`Enter ${itemType} Name`"
        label-for="item-name"
      >
        <b-form-input
          id="item-name"
          v-model="$v.itemName.$model"
          :state="state($v.itemName)"
        />
        <b-form-invalid-feedback :state="state($v.itemName)">
          {{ validationMessage($v.itemName) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>

    <b-modal
      id="rename-item"
      ok-title="Rename"
      no-close-on-backdrop
      @ok="rename"
    >
      <b-form-group
        label="Enter New Name"
        label-for="new-item-name"
      >
        <b-form-input
          id="new-item-name"
          v-model="$v.newItemName.$model"
          :state="state($v.newItemName)"
        />
        <b-form-invalid-feedback :state="state($v.newItemName)">
          {{ validationMessage($v.newItemName) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>

    <b-modal
      id="confirm-delete"
      ref="deleteModal"
      ok-title="Delete"
      no-close-on-backdrop
      @ok="deleteFile"
    >
      Are you sure you want to delete this {{ deleteItem.isDirectory ? 'folder' : 'file' }}?
    </b-modal>
    
    <b-modal
      id="upload-status"
      title="File Upload"
      :busy="uploading"
      no-close-on-backdrop
      ok-only
    >
      <span v-if="uploading">
        <b-spinner small />
        Uploading file {{ currentUpload }}...
      </span>
      <span v-else>{{ uploadMsg }}</span>
    </b-modal>
  </div>
</template>

<script>
import IconButton from './IconButton.vue'
import UploadButton from './UploadButton.vue'
import formatters from '../js/formatters'
import { mapState } from 'vuex';
import api from '../api'
import { required } from 'vuelidate/lib/validators'
import { exists, folder } from '../js/validation/validators'
import validationState from '../js/mixins/validationState'

export default {
  props: {
    analysisId: { type: [String,Number], required: true },
    folder: { type: String, default: '' }
  },
  data () {
    return {
      directories: [],
      fields: [
        { key: 'name', label: 'File Name', sortable: true },
        { key: 'modifiedDate', label: 'Last Modified Date', formatter: 'formatDateString', sortable: true },
        { key: 'size', label: 'Size', formatter: 'formatSizeString', sortable: true },
        { key: 'actions', label: '' }
      ],
      loading: false,
      editorData: [],
      savePath: null,
      path: this.folder.length > 0 ? this.folder.split('/') : [],
      itemName: null,
      itemType: null,
      newItemName: null,
      deleteItem: {},
      currentUpload: null,
      uploading: false,
      uploadMsg: null
    }
  },
  validations: {
    itemName: {
      required,
      folder,
      exists: exists('name', 'directories')
    },
    newItemName: {
      required,
      folder,
      exists: exists('name', 'directories')
    }
  },
  mounted () {
    this.getDirectoryListing();
  },
  computed: {
    folderPath () {
      return this.path.length > 0 ? `${this.path.join('/')}` : '';
    },
    displayPath () {
      return this.analysisId + '/' + this.path.join('/');
    }
  },
  methods: {
    async getDirectoryListing() {
      this.loading = true;
      this.directories = await api.listFiles(this.analysisId, this.folderPath);
      this.loading = false;
    },
    edit (fileName) {
      let path = this.folderPath ? `${this.folderPath}/${fileName}` : fileName;
      let returnPath = { name: this.$route.name, params: { id: this.analysisId, browse: true, folder: this.folderPath } };
      this.$router.push({ name: 'FileEditor', params: { analysisId: this.analysisId, path, returnPath } })
    },
    async rename (evt) {
      if (this.$v.newItemName.$invalid) {
        evt.preventDefault();
        return;
      }

      await api.renameFile(this.analysisId, this.itemName, this.folderPath, this.newItemName);
      await this.getDirectoryListing();
    },
    confirmRename (name) {
      this.itemName = name;
      this.$v.newItemName.$model = name;
      this.$bvModal.show('rename-item');
    },
    download (fileName) {
      api.downloadFile(this.analysisId, fileName, this.folderPath);
    },
    confirmDelete (item) {
      this.deleteItem = item;
      this.$refs.deleteModal.show();
    },
    getFolderPath (folderName) {
      return this.folderPath ? `${this.folderPath}/${folderName}` : folderName
    },
    async deleteFile () {
      if (this.deleteItem.isDirectory) {
        await api.deleteFile(this.analysisId, null, this.getFolderPath(this.deleteItem.name));
      } else {
        await api.deleteFile(this.analysisId, this.deleteItem.name, this.folderPath);
      }
      
      await this.getDirectoryListing();
    },
    openFolder (item) {
      if (item.isDirectory) {
        this.path.push(item.name);
      }
    },
    upLevel () {
      if (this.path.length > 0) {
        this.path.pop();
      }
    },
    showModal (itemType) {
      this.itemName = '';
      this.itemType = itemType;
      this.$v.itemName.$reset();
      this.$bvModal.show('add-item');
    },
    async addItem (evt) {
      this.$v.itemName.$touch();

      if (this.$v.itemName.$invalid) {
        evt.preventDefault();
        return;
      }

      if (this.itemType == 'folder') {
        await api.createFolder(this.analysisId, this.getFolderPath(this.itemName))
      } else {
        let url = api.getFilePath(this.analysisId, this.itemName, this.folderPath);
        await api.postFile(url, null);
      }
      
      await this.getDirectoryListing();
    },
    async uploadFiles (files) {
      this.uploading = true;
      this.$bvModal.show('upload-status');

      try {
        for (var i = 0; i < files.length; i++) {
          this.currentUpload = files[i].name;
          let url = api.getFilePath(this.analysisId, files[i].name, this.folderPath);
          await api.postFile(url, files[i]);
        }
        this.uploadMsg = `Uploaded ${ files.length } files sucessfully.`;
      }
      catch (err) {
        this.uploadMsg = 'Error uploading files.';
      }
      
      this.uploading = false;
      this.$refs.uploadButton.clear();
      await this.getDirectoryListing();
    },
    sortCompare(a, b, key) {
      if (a.isDirectory && !b.isDirectory) {
        return -1;
      } else if (!a.isDirectory && b.isDirectory) {
        return 1;
      }
      return false;
    }
  },
  watch: {
    path() {
      this.getDirectoryListing();
    }
  },
  components: {
    IconButton,
    UploadButton
  },
  mixins: [formatters, validationState]
}
</script>

<style scoped>
.dir-browser {
  width: 100%;
  height: 450px;
  overflow: auto;
  margin-top: 20px;
}

.dir-browser-control {
  margin: 0 20px 15px 0;
}

h3 {
  display: inline;
  padding-left: 10px;
}

section {
  padding: 15px 10px;
}

.dir-icon {
  margin-right: 7px;
}
</style>

<style>
.dir-browser > .table > tbody > tr > td {
  padding: 3px 12px;
}

.dir-browser > .table {
  border-spacing: 0;
}

.dir-browser > .table > thead > tr > th,
.dir-browser > .table > tbody > tr > td {
  border: none;
  border-bottom: 1px solid #e3e6ed;
}

.dir-browser > .table > tbody > tr.b-table-row-selected > td {
  background-color: #e8eeff;
}

.dir-browser > .table > tbody > tr > td > .btn-group > .btn-sm {
  line-height: 1;
  padding: 2px 3px;
}

.refresh-btn {
  margin-left: 5px;
}
</style>