import { Menu } from '@grapecity/wijmo.input'
import { CellType, CellRange } from '@grapecity/wijmo.grid'

const gridContext = {
  rowHeader: 1,
  columnHeader: 2,
  cell: 3
}

export { gridContext }

export default class GridMenu {
  constructor (menuSelector, grid, actions, options) {
    let opt = options || {}

    this._wijmoMenu = new Menu(menuSelector, {
      displayMemberPath: 'header',
      dropDownCssClass: 'ctx-menu',
      itemsSource: actions,
      itemClicked: () => {
        this._wijmoMenu.selectedValue.cmd();

        if (opt.updateCommand && typeof opt.updateCommand === 'function') {
          opt.updateCommand();
        }
      }
    });

    this._grid = grid;

    // Set up context menu
    this._wijmoMenu.owner = grid.hostElement;
    grid.addEventListener(grid.hostElement, 'contextmenu', (e) => {
      let ht = grid.hitTest(e);

      // Hide the menu if shown
      this._wijmoMenu.hide();
      e.preventDefault();

      // Do not display menu for header row
      if (ht.cellType == CellType.RowHeader && 
        ht.row == 0 && opt.hasHeaderRow) {
        return false;
      }

      // Filter menu items based on click location
      this._wijmoMenu.collectionView.filter = function(item) {
        switch(ht.cellType) {
          //case wjGrid.CellType.Cell:
          case CellType.RowHeader:
            return (item.context & 1) != 0;
          case CellType.ColumnHeader:
            return (item.context & 2) != 0;
        }
        return false;
      }

      this.selectRange(ht);
      
      this._wijmoMenu.show(e);
    }, true);

    document.addEventListener('click', () => { this._wijmoMenu && this._wijmoMenu.hide() });
  }

  selectRange (ht) {
    let rowStart = ht.row;
    let rowEnd = ht.row;
    let colStart = 0;
    let colEnd = this._grid.columns.length;
    let selection = this._grid.selection;

    if (ht.cellType == CellType.RowHeader && 
        selection.bottomRow - selection.topRow > 0) {
      return;
    }
    
    if (ht.cellType == CellType.ColumnHeader) {
      if (selection.rightCol - selection.leftCol > 0) {
        return;
      }

      rowStart = 0;
      rowEnd = this._grid.rows.length;
      colStart = ht.col;
      colEnd = ht.col;
    }

    if (ht.cellType == CellType.RowHeader || ht.cellType == CellType.ColumnHeader) {
      let rng = new CellRange(rowStart, colStart, rowEnd, colEnd);
      this._grid.select(rng, false);
    }
  }

  dispose () {
    this._wijmoMenu.dispose();
    this._wijmoMenu = null;

    // Remove context menu element
    var menuElement = document.querySelector('[wj-part="context-menu"]');

    if (menuElement) {
      menuElement.remove();
    }
  }
}