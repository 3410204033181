<template>
  <modal-dialog 
    title="Rename Scenario" 
    confirm-button-text="Confirm" 
    :value="value"
    :action="renameScenario" 
    @input="$emit('input', $event)" 
    @clear="clearDialog"
  >
    <b-alert
      class="mt-3"
      variant="danger"
      :show="!!message"
    >
      {{ message }}
    </b-alert>
    <b-form-group>
      <b-form-input
        id="scenario-name"
        v-model.trim="newScenarioName"
        class="mt-3"
        :placeholder="inputPlaceholder"
        :state="state($v.newScenarioName)"
      />
      <b-form-invalid-feedback :state="state($v.newScenarioName)">
        {{ validationMessage($v.newScenarioName) }}
      </b-form-invalid-feedback>
    </b-form-group>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog.vue'
import validationState from '../js/mixins/validationState'
import { mapState, mapMutations } from 'vuex';
import api from '../api'
import { required } from 'vuelidate/lib/validators'
import getErrorMessage from '../js/getErrorMessage'

export default {
  props: {
    value: { type: Boolean },
    scenario: { type: Object, required: true }
  },
  data () {
    return {
      newScenarioName: null,
      error: false,
      message: null
    }
  },
  computed: {
    ...mapState(["activeAnalysis"]),

    inputPlaceholder () {
      return `Enter a new name for the '${this.scenario.scenarioName}' scenario...`;
    }
  },
  validations: {
    newScenarioName: {
      required
    }
  },
  methods: {
    ...mapMutations(['setSavePending', 'setSaving', 'doneSaving', 'errorSaving']),
    async renameScenario () {
      //Validate
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.setSavePending();
      this.message = null;
      this.error = false;

      try {
        this.setSaving();
        await api.updateScenario(this.activeAnalysis.id, this.scenario.scenarioId, {
          scenarioName: this.newScenarioName,
          enabled: this.scenario.enabled
        });
        this.newScenarioName = null;
        this.$nextTick(() => { this.$v.$reset() });
        this.$emit('scenarios-updated');
      } catch (err) {
        this.errorSaving();
        this.error = true;
        this.message = getErrorMessage(err, 'An error occurred renaming the scenario.')
        this.$nextTick(() => { this.$v.$reset() });
        return false;
      }
      this.doneSaving();
    },
    clearDialog () {
      this.message = null;
      this.newScenarioName = null;
      this.$v.$reset()
    }
  },
  components: {
    ModalDialog
  },
  mixins: [validationState]
}
</script>