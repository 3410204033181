<template>
  <b-modal 
    v-model="show" 
    :id="id"
    :title="title" 
    :size="dialogSize"
    :busy="modalBusy"
    :ok-only="okOnly"
    :cancel-title="cancelButtonText"
    :dialog-class="dialogClass"
    no-close-on-backdrop 
    :hide-header-close="hideHeaderClose"
    :ok-disabled="okDisabled"
    @show="clearModal"
    @ok="confirmed"
    @hidden="$emit('input', false)"
    @shown="$emit('shown')"
  >
    <b-alert
      :show="!hideErrors && modalError"
      variant="danger"
    >
      {{ errorMessage }}
    </b-alert>
    <slot>
      <span>{{ prompt }}</span>
    </slot>
    <template #modal-ok>
      <b-spinner
        v-if="modalBusy"
        small
      /> {{ confirmButtonText }}
    </template>
    <template #modal-footer="{ ok, cancel }">
      <slot
        name="footer"
        :ok="ok"
        :cancel="cancel"
      />
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: { type: Boolean },
    action: { type: Function, default: null },
    prompt: { type: String, default: '' },
    title: { type: String, default: '' },
    errorMessage: { type: String, default: 'An error occurred processing your request.' },
    confirmButtonText: { type: String, default: 'Confirm' },
    cancelButtonText: { type: String, default: 'Cancel' },
    okOnly: { type: Boolean, default: false },
    dialogSize: { type: String, default: '' },
    hideErrors: { type: Boolean, default: false },
    hideHeaderClose: { type: Boolean, default: false },
    dialogClass: { type: String, default: '' },
    okDisabled: { type: Boolean, default: false },
    id: { type: String, default: '' }
  },
  data () {
    return {
      modalBusy: false,
      modalError: null,
      show: false
    }
  },
  watch: {
    value() {
      this.show = this.value;
    },
    confirmButtonText() {
      this.modalError = null;
    },
    hasError() {
      this.modalError = this.hasError;
    }
  },
  methods: {
    clearModal () {
      this.modalBusy = false;
      this.modalError = null;
      this.$emit('clear');
    },

    showDialog () {
      this.show = true;
    },

    hideDialog () {
      this.show = false;
    },

    async confirmed (evt) {
      // Prevent closing before the action has completed
      evt.preventDefault();
      this.modalBusy = true;

      try 
      {
        // Run the action while the dialog is open
        let retVal;
        if (this.action) {
          retVal = await this.action();
        }
        
        this.modalBusy = false;
        this.$emit('confirmed', retVal);

        // Close the dialog if action did not return false
        if (retVal !== false) {
          this.show = false;
        }
        
      } catch (err) {
        this.modalBusy = false;
        this.modalError = true;
      }

    },
  }
}
</script>