import ResultItem, { resultTypes, getResultData, updateTotals } from './ResultItem'
import createLdmTotResultItem from './ldmTotResults'
import createLdmResultItem from './ldmResults'
import createLdmLifetimeResultItem from './ldmLifetimeResults'
import createErrorResultItem from './errorResults'
import store from '../../store'
import api from '../../api'
import { assetTypes } from '../options/assetType'

async function getResultList(analysisId, calcs, analysisError = false, importResultsEnabled=true) {
  let resultList = {
    items: [],
    async loadData(analysis) {
      let files = this.items.filter(f => f.load);
      
      for (let file of files) {
        try {
          file.data = await file.getData(analysis);
        }
        catch (err) {
          // ignore errors, console will still show request error
        }
      }
    }
  }

  if (calcs.showLDM) {
    if (analysisError) {
      // Add Error results
      resultList.items.push(createErrorResultItem());
    } else if (calcs.val("showB5")) {
      resultList.items.push(new ResultItem({ 
        name: "Scenario Averaged Results", 
        fileName: "ldmvectors.txt",
        resultType: resultTypes.scenario,
        default: true
      }));
    } else {
      await LoadLdmResultList(resultList, analysisId, importResultsEnabled); 
    }
  } else {
    await LoadLkResultList(resultList, analysisId, analysisError);
  }

  return resultList;
}

async function loadLdmReportFields(reportId, analysisId) {
  let cols = await api.getReportFields(reportId, analysisId);
  return cols;
}

async function LoadLdmResultList(resultList, analysisId, importResultsEnabled) {
  if (!store.state.activeModule) {
    console.error('Could not get module Id for active module.');
    return resultList;
  }

  const moduleId = store.state.activeModule.id;
  let reports = await api.getReports(moduleId, analysisId);
  
  let results = [];

  for (let r of reports.filter(r => r.enabled)) {
    if (r.resultName == 'LdmAggregateResults') {
      let cols = await loadLdmReportFields(r.id, analysisId);
      results.push(createLdmTotResultItem(r.id, analysisId, 'chart', cols.filter(c => c.enabled), importResultsEnabled));
      results.push(createLdmTotResultItem(r.id, analysisId, 'table', cols, importResultsEnabled));
    } else if (r.resultName == 'LdmResults') {
      let cols = await loadLdmReportFields(r.id, analysisId);
      results.push(createLdmResultItem(r.id, analysisId, 'chart', cols.filter(c => c.enabled), importResultsEnabled));
      results.push(createLdmResultItem(r.id, analysisId, 'table', cols, importResultsEnabled));
    } else if (r.resultName == 'LdmLifetimeResults') {
      let cols = await loadLdmReportFields(r.id, analysisId);
      results.push(createLdmLifetimeResultItem(r.id, analysisId, 'table', cols, importResultsEnabled));
    }
  }

  results.push(createErrorResultItem());
  resultList.items = results;
}

function getResultType(name) {
  if (name.indexOf("Value and Risk Results") >= 0) {
    return resultTypes.portfolio;
  } else if (name.indexOf("Value and Risk Summary") >= 0) {
    return resultTypes.summary;
  } else if (name.indexOf("Cash Flow") >= 0) {
    return resultTypes.cashflow;
  } else if (name.indexOf("20 Scenario Summary") >= 0 || name.indexOf("Stress Test Summary") >= 0) {
    return resultTypes.scenario;
  } else if (name.indexOf("20 Scenario Results") >= 0 || name.indexOf("Stress Test Results") >= 0) {
    return resultTypes.loanScenario;
  } else if (name.indexOf("Error") >= 0) {
    return resultTypes.error;
  } else if (name.indexOf("Decomp") >= 0) {
    return resultTypes.msrDecomp;
  } else {
    return resultTypes.custom;
  }
}

function getReportLevel(name) {
  let subReports = [
    "Value and Risk Results",
    "Value and Risk Results ($)",
    "MSR Decomposition Results",
    "20 Scenario Results",
    "20 Scenario Repricing Results",
    "Stress Test Results",
    "Stress Test Repricing Results"
  ];

  for(let n of subReports) if (n == name) return 1;

  return 0;
}

function getParentReport(name) {
  if (name.indexOf("Value and Risk") >= 0) {
    return "Value and Risk Summary"
  } else if (name.indexOf("MSR Decomposition") >= 0) {
    return "MSR Decomposition Summary";
  } else if (name.indexOf("20 Scenario Repricing") >= 0) {
    return "20 Scenario Repricing Summary";
  } else if (name.indexOf("20 Scenario") >= 0) {
    return "20 Scenario Summary";
  } else if (name.indexOf("Stress Test Repricing") >= 0) {
    return "Stress Test Repricing Summary";
  } else if (name.indexOf("Stress Test") >= 0) {
    return "Stress Test Summary";
  }
  return null;
}

function getGroupName(name) {
  if (name.indexOf("Value and Risk") >= 0) {
    return "Value and Risk"
  } else if (name.indexOf("MSR Decomposition") >= 0) {
    return "MSR Decomposition";
  } else if (name.indexOf("20 Scenario Repricing") >= 0) {
    return "20 Scenario Repricing";
  } else if (name.indexOf("20 Scenario") >= 0) {
    return "20 Scenario";
  } else if (name.indexOf("Stress Test Repricing") >= 0) {
    return "Stress Test Repricing";
  } else if (name.indexOf("Stress Test") >= 0) {
    return "Stress Test";
  }
  return name;
}

function getShortName(name, isPools) {
  if (name.indexOf("Summary") >= 0) {
    return "Summary"
  } else if (name.indexOf("($)") >= 0) {
    return "$";
  } 
  
  return isPools ? "Pool-Level" : "Loan-Level";
}

async function loadReportColumns (analysisId, resultItem, options, isPools) {
  let cols = await api.getReportFields(resultItem.id, analysisId);
  
  // Hide label and bold cftype
  if (resultItem.resultType == resultTypes.summary || resultItem.resultType == resultTypes.portfolio) {
    var col = cols.find(c => c.name == 'cashFlowType');
    if (col) {
      if (resultItem.resultType == resultTypes.summary) {
        col.label = '';
      }
      col.class = 'font-weight-bold';
      col.visible = options.calcs.useHedgeFile
    }
  }

  // Hide CfType for decomp reports
  if (resultItem.resultType == resultTypes.msrDecomp) {
    var col = cols.find(c => c.name == 'cashFlowType');
    if (col) {
      col.visible = false;
    }
  }

  // Change LoanId to PoolId when running pools
  if (isPools) {
    var col = cols.find(c => c.name == 'loanId');
    if (col) {
      col.label = 'PoolId';
    }
  }
  
  resultItem.columns = () => 
    cols.filter(c => c.selected && c.enabled).map(c => ({ 
      header: c.label, 
      binding: c.name, 
      format: c.format, 
      class: c.class ? c.class : c.alignment == 'right' ? 'text-right' : '', 
      visible: c.visible === false ? false : true
    })).filter(c => c.visible);

  // Load cash flow files
  if (resultItem.resultType == resultTypes.cashflow) {
    resultItem.subItems = [];
  }

  resultItem.columnsLoaded = true;
}

async function LoadLkResultList(resultList, analysisId, analysisError) {
  if (!store.state.activeModule) {
    console.error('Could not get module Id for active module.');
    return resultList;
  }
  
  const moduleId = store.state.activeModule.id;
  const isPools = store.state.activeAnalysis.positionFormat == "Pool";

  let reports = await api.getReports(moduleId, analysisId);

  if (!analysisError) {
    resultList.items = reports.filter(r => r.enabled).map(r => new ResultItem({
      id: r.id,
      name: r.name,
      resultType: getResultType(r.name),
      collectionName: r.resultName,
      aggregateData: r.aggregateData,
      includeTotals: r.includeTotals,
      reportLevel: getReportLevel(r.name),
      parentReport: getParentReport(r.name),
      groupName: getGroupName(r.name),
      shortName: getShortName(r.name, isPools),
      getData: async function (analysis, options) {
        const isMsr = options.assetType == assetTypes.servicing;
        const isDollars = this.name.indexOf('$') >= 0;
        let data = await getResultData(analysis.id, this.collectionName, null, this.aggregateData, this.id);

        if (this.resultType == resultTypes.summary && this.aggregateData) {
          updateTotals(data, options, false);
        }

        if (this.includeTotals && !this.aggregateData) {
          // Get total row
          let totals = await getResultData(analysis.id, this.collectionName, null, true, this.id);
          
          updateTotals(totals, options);

          let msrTotals = totals.find(t => t.cashFlowType == "MSR");
          
          if (msrTotals) {
            // Find first index after MSR
            let idx = data.findIndex(d => d.cashFlowType != "MSR");
            if (idx == -1) {
              data.push(msrTotals);
            } else {
              data.splice(idx, 0, msrTotals, { footer: true });
            }
          }

          // Hedge Total
          if (isMsr && isDollars) {
            let hedgeTotals = totals.find(s => s.cashFlowType == "Hedge");
            if (hedgeTotals) {
              data.push(hedgeTotals);
            }
          } else {
            // Loan Total
            let loanTotals = totals.find(s => s.cashFlowType == "Loan");
            if (loanTotals) {
              data.push(loanTotals);
            }
          }

          // Grand Total
          let grandTotal = totals.find(t => t.cashFlowType == "Total");
          if (grandTotal) {
            data.push({ footer: true }, grandTotal);
          }
        }

        if (this.resultType == resultTypes.msrDecomp) {
          // Limit MSR Decomp report to MSR positions
          return data.filter(d => d.cashFlowType == "MSR");
        } else {
          return data;
        }
      },
      loadColumns: async function (options) { 
        await loadReportColumns(analysisId, this, options, isPools);
      }
    }));
  } else {
    // Load error results
    resultList.items = reports.filter(r => r.resultName == 'Errors' && r.enabled).map(r => new ResultItem({
      id: r.id,
      name: r.name,
      resultType: getResultType(r.name),
      collectionName: r.resultName,
      loadColumns: async function (options) { 
        await loadReportColumns(analysisId, this, options, isPools);
      }
    }));
  }
}

async function getCashflowFiles (analysisId, path) {
  return (await api.listFiles(analysisId, path))
      .filter(d => d.name.startsWith('cashflows'))
      .map(f => ({ name: f.name, folder: path }));
}

export { getResultList };