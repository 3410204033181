import { FilterType, Operator } from '@grapecity/wijmo.grid.filter'
import ODataGrid from './GridWithODataSource'
import api from '../../api'

export default class PortfolioGrid extends ODataGrid {
  constructor (gridElement, options, analysisId) {
    super(gridElement, options);
    this._analysisId = analysisId;
    this._filter.filterChanged.addHandler(() => { 
      options?.dataFiltered()});
  }

  changesPending () {
    return this._collectionView.itemsAdded.length > 0 ||
      this._collectionView.itemsEdited.length > 0 ||
      this._collectionView.itemsRemoved.length > 0;
  }

  clearPendingChanges () {
    this._collectionView.itemsAdded.clear();
    this._collectionView.itemsEdited.clear();
    this._collectionView.itemsRemoved.clear();
  }

  toggleColumns(fields, show) {
    fields.forEach(f => {
      let col = this._grid.getColumn(f);

      if (col) {
        this._grid.getColumn(f).visible = show
      }
      
      if (!show) this.clearFilter(f)
    })
  }

  clearFilter(field) {
    let filterDef = JSON.parse(this._filter.filterDefinition)
    let fieldFilter = filterDef.filters.filter(f => f.binding === field)
    if (fieldFilter.length > 0) {
      filterDef.filters = filterDef.filters.filter(f => f.binding !== field)
      this._filter.filterDefinition = JSON.stringify(filterDef)
    }
  }

  clearAllFilters() {
    let filterDef = JSON.parse(this._filter.filterDefinition)
    filterDef.filters = [];
    this._filter.filterDefinition = JSON.stringify(filterDef)
  }

  quickFilterRateType(filters) {
    const armFields = [
      'firstResetAge',
      'monthsBetweenReset',
      'index',
      'grossMargin',
      'lifeCap',
      'lifeFloor',
      'firstResetCap',
      'firstResetFloor',
      'periodicCap',
      'periodicFloor',
      'paymentChangeCap',
      'paymentResetPeriod',
      'paymentRecastPeriod',
      'currentMinimumPayment',
      'maxNegamPercent',
    ]

    if (filters.length === 0) {
      this.toggleColumns(armFields, true)
      this.clearFilter('rateFixed')
      return
    }

    this.toggleColumns(armFields, filters.includes('ARM'))

    let filterDef = JSON.parse(this._filter.filterDefinition)
    let newFilter = {
      and: false,
      type: 'condition',
      binding: 'rateFixed',
      condition1: {
        operator: filters.includes('Fixed') ? Operator.EQ : null,
        value: filters.includes('Fixed') ? true : ''
      },
      condition2: {
        operator: filters.includes('ARM') ? Operator.EQ : null,
        value: filters.includes('ARM') ? false : ''
      }
    }
    filterDef.filters.push(newFilter)

    this._filter.filterDefinition = JSON.stringify(filterDef)
  }

  quickFilterIssuerType(filters) {
    const nonAgencyFields = [
      'lienPosition',
      'prevDel',
      'monthsSinceDelinq',
      'paymentChangeCap',
      'paymentResetPeriod',
      'paymentRecastPeriod',
      'currentMinimumPayment',
      'maxNegamPercent',
      'numUnits'
    ]

    if (filters.length === 0) {
      this.toggleColumns(nonAgencyFields, true)
      this.clearFilter('issuer')
      return
    }

    this.toggleColumns(nonAgencyFields, filters.includes('Non-Agency'))
    
    let filterDef = JSON.parse(this._filter.filterDefinition)
    let newFilter = {
      type: 'value',
      binding: 'issuer',
      showValues: {}
    }
    if (filters.includes('Agency')) {
      newFilter.showValues['Fannie Mae'] = true
      newFilter.showValues['Freddie Mac'] = true
      newFilter.showValues['Ginnie Mae'] = true
    }
    if (filters.includes('Non-Agency')) {
      newFilter.showValues['Prime (NA)'] = true
      newFilter.showValues['Subprime (NA)'] = true
      newFilter.showValues['Other (NA)'] = true
    }
    filterDef.filters.push(newFilter)

    this._filter.filterDefinition = JSON.stringify(filterDef)
  }

  save () {
    
    let p = [];

    // Add any new positions
    if (this._collectionView.itemsAdded.length > 0) {
      const addedPositions = [...this._collectionView.itemsAdded];
      p.push(api.addPositions(this._analysisId, addedPositions).then(() => {
        this._collectionView.itemsAdded.clear();
        this.refresh();
      }));
    }

    // Update any edited positions
    if (this._collectionView.itemsEdited.length > 0) {
      const editedPositions = [...this._collectionView.itemsEdited];
      p.push(api.updatePositions(this._analysisId, editedPositions)
        .then(() => this._collectionView.itemsEdited.clear())
      );
    }

    // Delete any deleted positions
    if (this._collectionView.itemsRemoved.length > 0) {
      const delPositions = this._collectionView.itemsRemoved.map(i => i.id);
      p.push(api.deletePositions(this._analysisId, delPositions)
        .then(() => this._collectionView.itemsRemoved.clear())
      );
    }

    if (p.length > 0) {
      return Promise.all(p).then(() => {
        this.clearUndoStack();
      });
    } else {
      return Promise.resolve();
    }
  }
}