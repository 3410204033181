<template>
  <div>
    <b-container
      :id="containerId"
      class="collapsible-container"
    >
      <b-row>
        <b-col
          cols="6"
          class="nopadding"
        > 
          <label
            :id="labelId"
            :for="checkboxComponentId"
          >{{ title }}</label>
        </b-col>
        <b-col cols="6">
          <b-form-checkbox
            :id="checkboxComponentId"
            :checked="value"
            switch
            inline
            @input="handleInput"
            @change="$emit('change', $event)"
          />
        </b-col>
      </b-row>
      <b-row class="collapsible-content-row">
        <b-col>
          <b-collapse
            v-if="collapsible"
            :id="bCollapseComponentId"
            v-model="value"
          >
            <div class="collapsible-content">
              <slot />
            </div>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import createId from '../js/mixins/createId'
import { mapMutations } from 'vuex'

export default {
  name: 'CollapsibleOptions',
  mixins: [createId],
  props: {
    title: { type: String, required: true },
    value: { type: Boolean, required: true },
    collapsible: { type: Boolean, required: false, default: false }
  },
  computed: {
    bCollapseComponentId () {
      return this.createId("b-collapse-" + this.title);
    },
    checkboxComponentId () {
      return this.createId("checkbox-" + this.title);
    },
    containerId () {
      return this.createId("collapsible-container-" + this.title);
    },
    labelId () {
      return this.createId("checkbox-label-" + this.title);
    }
  },
  methods: {
    ...mapMutations(["toggleFile"]),

    handleInput (event) {
      this.setFileSelects(event);

      // emit input event
      this.$emit('input', event);
    },
    setFileSelects (enabled) {
      // Find all FileSelectWithCheckbox components
      let files = [];
      this.findFileSelects(this.$children, files);
      
      // Toggle the file to enabled / disabled
      files.forEach(f => {
        this.toggleFile({ fileName: f.fileName, enabled: f.enabled && enabled });
      });
    },
    findFileSelects (children, files) {
      children.forEach(c => {
        if (c.$options.name == "FileSelectWithCheckbox") {
          files.push({ fileName: c.fileName, enabled: c.value });
        }
        this.findFileSelects(c.$children, files);
      });
    }
  }
};
</script>
<style scoped>
.collapsible > span {
  display:inline-block;
}
.collapsible-container {
  margin: 0;
  padding: 0;
}
.collapsible-container .row {
  margin: 0;
  padding: 0;
}
.collapsible-container .col {
  margin: 0;
  padding: 0;
}
.collapsible-content {
  margin: 0px 0px 3px 0px;
  padding: 0px 0px 0px 0px;
}
.collapsible-content-row {
  margin: 0px 15px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.collapse, .collapsing {
  margin: 0 auto;
  padding: 5px 8px 5px 12px;
  width: 90%;
}
.collapse .form-group, .collapsing .form-group {
  margin: 0px 0px 0px 0px;
  padding: 5px 0px 0px 0px;
}
.collapse .form-section {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 2px 0px;
}
.collapse .row, .collapsing .row
.collapse .col, .collapsing .col {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.collapse label, .collapsing label {
  margin: 0px 0px 0px 0px;
  padding: 3px 0px 0px 5px;
  vertical-align: middle
}
.collapse .custom-switch,
.collapsing .custom-switch{
  padding-top: 5px;
}
.dark-theme .collapse, .dark-theme .collapsing {
  border: solid 1px #3C3E4A;
}
.light-theme .collapse, .light-theme .collapsing {
  border: solid 1px #bfc0c9;
}
</style>