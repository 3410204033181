<template>
  <div
    v-if="activeAnalysis"
    class="app-title analysis-header"
  >
    <app-title />

    <div class="analysis-name">
      <b-form
        inline
        @submit.prevent="save"
      >
        <b-form-input
          v-if="editName"
          ref="nameEditor"
          v-model="analysisName"
          class="name-editor"
        />
        <h1 
          v-else
          v-b-tooltip.hover.bottom.v-light 
          class="mr-3 truncate-text" 
          :title="activeAnalysis.name || 'unnamed analysis'" 
          @click="beginEdit" 
        >
          <span class="analysis-name-prefix">Analysis Editor -</span> {{ activeAnalysis.name || 'unnamed analysis' }}
        </h1>

        <icon-button
          v-if="!editName"
          v-b-tooltip.hover.right.v-light
          icon="pen"
          title="Edit Analysis Name"
          @click="beginEdit"
        />
        <icon-button
          v-if="editName"
          v-b-tooltip.hover.bottom.v-light
          icon="check"
          title="Save Analysis Name"
          @click="save"
        />
        <icon-button
          v-if="editName"
          v-b-tooltip.hover.bottom.v-light
          icon="times"
          title="Cancel"
          @click="revert"
        />
      </b-form>
    </div>

    <div style="min-width: 100px;">
      <icon-button
        class="ml-auto"
        icon="folder"
        @click="showBrowser"
      >
        Browse
      </icon-button>
    </div>

    <directory-browser-dialog
      v-if="activeAnalysis"
      ref="browser"
      :analysis-id="activeAnalysis.id"
      :folder="folder"
    />
  </div>
</template>

<script>
import DirectoryBrowserDialog from './DirectoryBrowserDialog.vue'
import IconButton from './IconButton.vue'
import AppTitle from './AppTitle.vue'
import { mapState } from 'vuex'
import api from '../api'

export default {
  props: {
    browse: { type: Boolean, default: false },
    folder: { type: String, default: '' }
  },
  data () {
    return {
      editName: false,
      analysisName: ''
    }
  },
  computed: {
    ...mapState(['activeAnalysis'])
  },
  mounted () {
    if (this.browse) {
      this.showBrowser();
    }
  },
  methods: {
    beginEdit () {
      this.analysisName = this.activeAnalysis.name;
      this.editName = true;
      this.$nextTick(() => {
        this.$refs.nameEditor.select();
      })
    },
    async save () {
      api.updateAnalysis({
        id: this.activeAnalysis.id,
        name: this.analysisName
      });

      this.activeAnalysis.name = this.analysisName;
      this.editName = false;
    },
    revert () {
      this.analysisName = this.activeAnalysis.name;
      this.editName = false;
    },
    showBrowser () {
      this.$refs.browser.show();
    }
  },
  components: {
    DirectoryBrowserDialog,
    IconButton,
    AppTitle
  }
}
</script>

<style scoped>

  .analysis-header {
    height: 100%;
  }

  .analysis-header h1 {
    font-weight: bold;
    margin: 5px 1px;
    display: inline-block;
    font-size: 16px;
  }

  .analysis-name-prefix {
    display: none;
  }

  .name-editor,
  .name-editor:focus {
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #cccccc;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: bold;
    width: 190px!important;
    height: 28px;
  }

  .dark-theme .name-editor,
  .dark-theme .name-editor:focus {
    background-color: #16191C;
    color: #fffffd;
  }

  .truncate-text {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .analysis-name {
    display: flex;
    width: 400px;
    position: absolute;
    left: calc(50% - 180px);
    text-align: center;
  }

  .analysis-name > form {
    margin: auto;
  }

  @media (min-width: 992px) {
    .analysis-header h1 {
      font-size: 16px;
      margin: 5px 2px;
    }
    .analysis-name-prefix {
      display: inline-block;
    }
    .name-editor,
    .name-editor:focus {
      margin-left: -5px;
      margin-right: 0.8rem;
      width: 275px!important;
    }
    .truncate-text {
      max-width: 330px;
    }
    .analysis-name {
      left: calc(50% - 200px);
    }
  }

  @media (min-width: 1200px) {
    .analysis-name {
      width: 450px;
      left: calc(50% - 225px);
    }
    .truncate-text {
      max-width: 350px;
    }
    .name-editor,
    .name-editor:focus {
      width: 350px!important;
    }
  }
</style>