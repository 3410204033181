import GridBase from './GridBase'
import { RestCollectionViewPoints } from './RestCollectionViewPoints'

export default class PointsGrid extends GridBase {
  constructor (gridElement, options) {
    super();
    this._collectionView = new RestCollectionViewPoints(options, { key: 'id' });
    this._collectionView.loaded.addHandler(options.loaded);

    let gridOptions = Object.assign({
      allowSorting: false,
      allowResizing: true,
      allowDragging: false,
      colWidth: 190,
      ageColWidth: 60
    }, options);

    let grid = super._initGrid(gridElement, this._collectionView, gridOptions);

    grid.cellEditEnding.addHandler((g, e) => {
      let newVal = g.activeEditor?.value;
      if (newVal == null || newVal.length == 0) {
        g.setCellData(e.row, e.col, null);
        e.cancel = false;
        e.stayInEditMode = false;
      }
    });

    // Init validators
    super._initValidators(grid, gridOptions);
  }
}