<template>
  <div>
    <modal-dialog
      ref="dlg"
      title="Import Results"
      confirm-button-text="Ok"
      :ok-only="true"
      dialog-size="lg"
    >
      <div class="pl-4 pr-4 mb-3">
        <div v-if="importResults.recordsImported">
          {{ importResults.recordsImported }} {{ importResults.recordsImported.length == 1 ? 'record' : 'records' }} imported successfully.
        </div>
        <div v-if="importResults.recordsFailed">
          <span 
            v-if="importResults.aborted" 
            class="font-weight-bold"
          >Import stopped after reaching {{ importResults.recordsFailed.toLocaleString() }} import failures. 
          </span>
          <span v-else>{{ importResults.recordsFailed }} {{ importResults.recordsFailed == 1 ? 'record' : 'records' }} failed to import.</span>
          <span v-if="importResults.errors && importResults.errors.length > 1000">Showing first 1,000 validation errors.</span>
        </div>
      </div>

      <div
        v-if="importResults.errors && importResults.errors.length"
        class="scrollable theme-item import-results"
      >
        <b-table-lite
          class="theme-item"
          :fields="fields"
          :items="errors"
          sticky-header
        />
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from './ModalDialog.vue'

export default {
  components: {
    ModalDialog
  },
  props: {
    importResults: { type: Object, required: true }
  },
  data() {
    return {
      fields: ['recordNumber', 'errorMessage'],
    };
  },
  computed: {
    errors () {
      return this.importResults.errors ? this.importResults.errors.slice(0, 1000) : [];
    }
  },
  methods: {
    showDialog () {
      this.$refs.dlg.showDialog();
    }
  }
}
</script>

<style scoped>
.import-results {
  max-height: calc(100vh - 300px);
}

.import-results .table > thead > tr > th, 
.import-results .table > tbody > tr > td {
    background-color: #fafafa;
}
</style>