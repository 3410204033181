<template>
  <div>
    <loading-indicator v-show="loading" />
    <flex-grid-editor
      v-show="!loading"
      :collection="getData"
      :grid-options="{ editOptions: { allowAddNew, allowDelete, allowSave: canSave } }"
      :on-save="save"
      @close="close"
    />
  </div>
</template>

<script>
import LoadingIndicator from './LoadingIndicator.vue'
import FlexGridEditor from './FlexGridEditor.vue'
import api from '../api'

export default {
  components: {
    LoadingIndicator,
    FlexGridEditor
  },
  props: {
    analysisId: { type: [Number, String], required: true },
    path: { type: String, required: false },
    gridData: { type: Array, required: false },
    returnPath: { type: [String, Object], required: false },
    allowAddNew: { type: Boolean, default: true },
    allowDelete: { type: Boolean, default: true }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    url () {
      return `analyses/${this.analysisId}/files/${this.path}`;
    },
    canSave () {
      return this.path != null;
    }
  },
  methods: {
    async getData() {
      this.loading = true;
      // If data was provided just return it
      if (this.gridData) {
        return this.gridData;
      }

      // Get data from url
      let data = await api.get(this.url);
      this.loading = false;
      return data;
    },
    async save (data) {
      return await api.postFile(this.url, data);
    },
    close () {
      let path = this.returnPath || `/edit/${this.analysisId}`;
      this.$router.push(path);
    }
  }
}
</script>