<template>
  <div>
    <modal-dialog
      ref="dlg"
      title="License key not found"
      confirm-button-text="Update"
      :value="value" 
      :ok-only="true" 
      :action="update"
      hide-errors
      hide-header-close
      @input="$emit('input', $event)"
    >
      <span>Please select your AD&amp;Co license key file and click update</span>
      <license-key-updater
        ref="licUpdater"
        hide-update-button
        hide-errors
      />
    </modal-dialog>
  </div>
</template>

<script>
import LicenseKeyUpdater from './LicenseKeyUpdater.vue'
import ModalDialog from './ModalDialog.vue'

export default {
  components: { 
    ModalDialog, 
    LicenseKeyUpdater 
  },
  props:{
    value: { type: Boolean }
  },
  data () {
    return {
      errorMessage: 'Error updating license key'
    }
  },
  methods: {
    showDialog () {
      this.$refs.dlg.showDialog();
    },
    async update () {
      let result = await this.$refs.licUpdater.updateLicense();
      
      if (result.error) {
        this.errorMessage = result.message || 'Error updating license key';
        throw "Error updating license key";
      }
    }
  }
}
</script>