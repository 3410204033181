import { CollectionViewNavigator } from '@grapecity/wijmo.input'
import { contains } from '@grapecity/wijmo'

export default class extends CollectionViewNavigator
{
  constructor (element, options, paging) {
    super(element, options);

    this._target = null;

    // new click function with navigate() callback
    this._click = function(e) {
      this._target = e.target;
      if (paging && typeof paging === 'function' ) {
        paging(this.navigate);
      }
    };

    const self = this;
    // function callback to complete paging navigation
    this.navigate = (cancel) => {
      if (cancel === false) {
        return;
      }
  
      if (contains(self._btnFirst, self._target)) {
        self.cv.moveToFirstPage();
      } else if (contains(self._btnPrev, self._target)) {
        self.cv.moveToPreviousPage();
      } else if (contains(self._btnNext, self._target)) {
        self.cv.moveToNextPage();
      } else if (contains(self._btnLast, self._target)) {
        self.cv.moveToLastPage();
      }
    }

    // Rebind click event handler
    super.removeEventListener(super.hostElement, "click");
    super.addEventListener(super.hostElement, "click", this._click.bind(this));
  }
}