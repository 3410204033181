<template>
  <div class="flex-container grid-container">
    <grid-header
      :title="title"
      @close="confirmClose"
    />
    <b-alert
      :show="showError"
      variant="danger"
    >
      Please fix the validation errors shown below
    </b-alert>
    <grid-toolbar>
      <b-button-group size="sm">
        <icon-button
          icon="save"
          theme-class="dark"
          :disabled="!gridOptions.editOptions.allowSave"
          @click="save"
        >
          Save
        </icon-button>
      </b-button-group>
      <b-button-group size="sm">
        <icon-button
          icon="undo"
          theme-class="dark"
          :disabled="!canUndo"
          @click="$options.grid.undo()"
        >
          Undo
        </icon-button>
        <icon-button
          icon="redo"
          theme-class="dark"
          :disabled="!canRedo"
          @click="$options.grid.redo()"
        >
          Redo
        </icon-button>
      </b-button-group>
      <b-button-group
        v-if="gridOptions.editOptions.allowAddNew || gridOptions.editOptions.allowDelete"
        size="sm"
      >
        <icon-button
          v-if="gridOptions.editOptions.allowAddNew"
          icon="plus-square"
          theme-class="dark"
          @click="$options.grid.insertRow()"
        >
          Insert Row
        </icon-button>
        <icon-button
          v-if="gridOptions.editOptions.allowDelete"
          icon="minus-square"
          theme-class="dark"
          @click="$options.grid.deleteSelectedRows()"
        >
          Delete Selected Rows
        </icon-button>
      </b-button-group>
      <b-button-group
        size="sm"
        class="ml-1"
      >
        <icon-button
          icon="copy"
          theme-class="dark"
          @click="$options.grid.copy()"
        >
          Copy
        </icon-button>
        <icon-button
          icon="paste"
          theme-class="dark"
          @click="$options.grid.paste()"
        >
          Paste
        </icon-button>
      </b-button-group>
      <div class="status ml-auto mr-3">
        <div id="grid-pager" />
      </div>
    </grid-toolbar>

    <div id="grid" />

    <b-modal
      id="confirm-discard"
      v-model="showDiscardDialog"
      title="Discard unsaved changes?"
      ok-title="Discard"
      @ok="closeEditor"
    >
      Are you sure you want to close and discard any unsaved changes?
    </b-modal>

    <modal-dialog
      v-model="showDemoDialog"
      title="Maximum Positions Exceeded"
      :prompt="`Demo accounts are limited to a maximum of ${licenseInfo.demoMaxPositions} positions. Please reduce your positions to ${licenseInfo.demoMaxPositions} or less, and then save.`"
      confirm-button-text="OK"
      ok-only
    />

    <modal-dialog
      ref="unsavedChangesDialog"
      title="Unsaved Portfolio Changes"
      :prompt="`Your portfolio has unsaved changes. Would you like to save your changes?`"
    >
      <template #footer="{ ok, cancel }">
        <b-btn
          @click="cancelNavigation(cancel)"
        >
          Cancel
        </b-btn>
        <b-btn
          variant="danger"
          @click="discardChanges(ok)"
        >
          Discard
        </b-btn>
        <b-btn
          variant="primary"
          @click="savePortfolio(ok)"
        >
          Save
        </b-btn>
      </template>
    </modal-dialog>
    <modal-dialog
      ref="unsavedChangesDialogNoSave"
      title="Unsaved Portfolio Changes"
      :prompt="`Your portfolio has unsaved changes with errors. Exiting will discard all of your changes.`"
    >
      <template #footer="{ ok, cancel }">
        <b-btn
          @click="cancelNavigation(cancel)"
        >
          Cancel
        </b-btn>
        <b-btn
          variant="danger"
          @click="discardChanges(ok)"
        >
          Discard
        </b-btn>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import GridHeader from './GridHeader.vue'
import GridToolbar from './GridToolbar.vue'
import IconButton from './IconButton.vue'
import ModalDialog from './ModalDialog.vue'
import { createGrid } from '../js/gridExtensions/createGrid'
import { AllowSorting } from '@grapecity/wijmo.grid';
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    GridHeader,
    GridToolbar,
    IconButton,
    ModalDialog
  },
  props: {
    collection: { type: [String, Array, Function], required: true },
    entityType: { type: String, default: null },
    columns: { type: Array, default: () => [] },
    dataMaps: { type: [Array, Promise], default: null },
    gridOptions: { type: Object, required: true },
    onSave: { type: Function, default: null },
    title: { type: String, default: 'File Editor'},
    itemCreator: { type: Function, default: null }
  },
  data() {
    return {
      saving: false,
      loading: false,
      itemCount: 0,
      filtered: false,
      canUndo: false,
      canRedo: false,
      gridDirty: false,
      showDiscardDialog: false,
      showDemoDialog: false, 
      navigation: null,
      showError: false
    }
  },
  grid: null,
  async mounted () {
    const baseURL = `${localStorage.getItem('kinetics_server')}/odata`;

    if (!this.licenseInfo.hasLicenseKey) {
      this.getLicense();
    }

    if (this.appConfig == null) {
      await this.getAppConfig();
    }
 
    this.$options.grid = createGrid('#grid', {
      baseURL,
      pager: '#grid-pager',
      collection: this.collection,
      entityType: this.entityType,
      columns: this.columns,
      allowAddNew: !!this.gridOptions.editOptions.allowAddNew,
      allowDelete: !!this.gridOptions.editOptions.allowDelete,
      dataMaps: this.dataMaps,
      keys: this.gridOptions.editOptions.keys,
      allowSorting: this.gridOptions.allowSort === false ? AllowSorting.None : AllowSorting.SingleColumn,
      defaultSort: this.gridOptions.defaultSort,
      onSave: this.onSave,
      pageSize: this.appConfig.portfolioPageSize,
      loading: () => { this.loading = true; },
      loaded: () => { this.loading = false; },
      collectionChanged: (count, filtered) => {
        this.itemCount = count;
        this.filtered = filtered;
      },
      canUndo: (enabled) => { this.canUndo = enabled },
      canRedo: (enabled) => { this.canRedo = enabled },
      gridChanged: () => { this.gridDirty = true; this.showError = false; },
      itemCreator: this.itemCreator,
      paging: (navigate) => { this.handlePageChange(navigate) }
    });
  },
  computed: {
    ...mapState(['licenseInfo', 'appConfig']),
    ...mapGetters(['isAdmin'])
  },
  methods: {
    ...mapActions(['getLicense', 'getAppConfig']),

    save () {
      if (!this.$options.grid.isValid()) {
        this.showError = true;
        return;
      } else {
        this.showError = false;
      }

      // Check for demo mode and show dialog if max position count is exceeded
      if ((this.licenseInfo.demoMode && !this.isAdmin) && 
          this.gridOptions.entitySet == 'Positions' && 
          this.$options.grid.itemCount > this.licenseInfo.demoMaxPositions) {
        this.showDemoDialog = true;
        return;
      }

      this.saving = true;
      this.$options.grid._setPagerText('Saving...');

      this.$options.grid.save()
        .then(() => { 
          setTimeout(() => {
            this.saving = false;
            this.gridDirty = false;
            this.$options.grid.refresh();
          }, 200);
        });
    },
    confirmClose () {
      if (this.changesPending() && this.$options.grid.isValid()) {
        this.navigation = this.closeEditor;
        this.$refs.unsavedChangesDialog.showDialog();
      } else if (this.changesPending() && !this.$options.grid.isValid()) {
        this.navigation = this.closeEditor;
        this.$refs.unsavedChangesDialogNoSave.showDialog();
      } else {
        this.closeEditor();
      }
    },
    closeEditor (cancel) {
      if (cancel === false) {
        return;
      }
      this.$options.grid._grid.dispose();
      this.$emit('close');
    },
    savePortfolio (confirm) {
      this.save();
      this.continueNavigation(confirm);
    },
    discardChanges (confirm) {
      this.clearPendingChanges();
      this.$options.grid.refresh();
      this.continueNavigation(confirm);
    },
    cancelNavigation (cancel) {
      if (this.navigation) {
        // Cancel navigation
        this.navigation(false);
      }

      cancel();
    },
    continueNavigation (confirm) {
      if (this.navigation) {
        this.navigation();
      }
      confirm();
    },
    handlePageChange(navigate) {
      if (this.changesPending()) {
        this.navigation = navigate;
        this.$refs.unsavedChangesDialog.showDialog();
      } else {
        navigate();
      }
    },
    changesPending () {
      let cv = this.$options.grid._collectionView;
      
      return cv && (cv.itemsAdded.length > 0 ||
        cv.itemsEdited.length > 0 ||
        cv.itemsRemoved.length > 0);
    },
    clearPendingChanges () {
      let cv = this.$options.grid._collectionView;
      cv.itemsAdded.clear();
      cv.itemsEdited.clear();
      cv.itemsRemoved.clear();
    }
  }
}
</script>

<style scoped>
.grid-container {
  background-color: #f5f6fa;
}

.alert {
  margin-bottom: 0;
}
</style>