const quoteTypeOptions = [
  { value: "Price", text: "Price", label: "Price" },
  { value: "Yield", text: "Yield", label: "Yield" },
  { value: "OAS", text: "OAS", label: "OAS" },
  //{ value: "BEEM", text: "BEEM", label: "Margin" },
  { value: "ZSpread", text: "Z-Spread", label: "Spread" },
  { value: "CouponSpread", text: "Coupon Spread", label: "Spread" },
  { value: "QuoteInFile", text: "Quote In Portfolio" }
]

const quoteTypes = quoteTypeOptions.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

export { quoteTypeOptions, quoteTypes };