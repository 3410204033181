<template>
  <div>
    <msr-assumptions
      :load-assumptions="loadAssumptionSettings"
      :is-defaults="true"
      @create="createSet"
      @delete="deleteSet"
      @update="updateSet"
      @create-update-sets="createOrUpdateSets"
    />
  </div>
</template>

<script>
import api from '../api'
import MsrAssumptions from './MsrAssumptions.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  data () {
    return {
      msrAssumptionsSettings: []
    }
  },
  async mounted () {
    this.setUnsaved();
  },
  methods: {
    ...mapActions(['autoSave']),
    ...mapMutations(['setUnsaved']),

    async loadAssumptionSettings() {
      let data = await api.getServicingSettings();
      return data;
    },
    updateSet (data) {
      this.autoSave({ saveAction: 'updateServicingSettings', isValid: data.isValid, id: null, data: data.selectedAssumption });
    },
    async createSet (data) {
      const model = await api.createServicingSettings(data.selectedAssumption);
      data.selectedAssumption.id = model.servicingSetId;
    },
    async deleteSet (data) {
      await api.deleteServicingSettings(data.selectedAssumption);
    },
    createOrUpdateSets (data) {
      this.autoSave({ 
        saveAction: 'createOrUpdateServicingSettings', 
        isValid: data.isValid, 
        data: { servicingSettings: data.servicingSettings }, 
        onSave: data.onSave
      });
    }
  },
  components: {
    MsrAssumptions
  }
}
</script>