import Vue from 'vue'
import { fileStatus, getInputFileList, fileNames } from '../js/fileInfo'
import { setAuthToken, setServer } from '../httpClient'
import tokenStore from '../js/tokenStore'
import moment from 'moment'
import { statusCodes } from '../js/options/statusCodes'
import { runTypes } from '../js/options/runType'

export default {
  setTheme (state, theme) {
    state.theme = theme;
  },
  setAppTitle (state, title) {
    state.appTitle = title;
  },
  setActiveAnalysis (state, analysis) {
    state.activeAnalysis = {
      id: analysis.id,
      name: analysis.name,
      assetType: analysis.assetType,
      runType: analysis.runType,
      positionFormat: analysis.positionFormat,
      modifiedDate: analysis.modifiedDate ? moment(analysis.modifiedDate).toDate() : moment('1900-01-01').toDate(),
      lastRunDate: analysis.lastRunDate ? moment(analysis.lastRunDate).toDate() : moment('1900-01-01').toDate(),
      settings: analysis.settings,
      status: analysis.status,
      resultsUpdated: false,
      selectedFilters: state.selectedFilters[analysis.id]
    };

    // Add runType to settings
    state.activeAnalysis.settings.runType = analysis.runType;

    // Convert dates
    if(analysis.runType == runTypes.loanDynamics) {
      state.activeAnalysis.settings.firstForecastDate = moment(analysis.settings.firstForecastDate).format("M/D/YYYY");
    } else {
      state.activeAnalysis.settings.tradeDate = moment(analysis.settings.tradeDate).format("M/D/YYYY");
      state.activeAnalysis.settings.settleDate = moment(analysis.settings.settleDate).format("M/D/YYYY");
      state.activeAnalysis.settings.hedgeSettleDate = moment(analysis.settings.hedgeSettleDate).format("M/D/YYYY");
    }
    
    state.activeModule = state.licenseInfo.modules.find(m => m.assetType == analysis.assetType && m.runType == analysis.runType);
    state.isSaved = false;
  },
  setAnalysisSettings (state, settings) {
    state.activeAnalysis.settings = settings;
  },
  setAuth (state, data) {
    if (data.hasOwnProperty("enabled")) {
      state.authentication.enabled = data.enabled;
    }
    
    if (data.jwtToken) {
      state.authentication.token = data.jwtToken;
      tokenStore.setTokens(data);
      setAuthToken(data.jwtToken);
    }

    state.authentication.remoteConnection = data.remoteConnection || false;

    if (data.server) {
      state.authentication.server = data.server;
      setServer(state.authentication.server);
    }
  },
  clearAuth (state) {
    state.authentication.token = null
    state.licenseInfo = { hasLicenseKey: false, modules: [], demoMode: false };
    tokenStore.clearTokens();
  },
  createInputsList (state, inputs) {
    let inputFiles = getInputFileList(state.activeAnalysis);

    state.fileList = {};
    state.fileNames = [];

    inputFiles.forEach(f => {
      let fileInfo = inputs.find(i => i.inputType == f.inputType) || null;
      state.fileNames.push(f.fileName);

      Vue.set(state.fileList, f.fileName, {
        status: fileInfo ? fileStatus.posted : f.optional ? fileStatus.optional : fileStatus.missing,
        recordCount: fileInfo && (fileInfo.recordCount ? fileInfo.recordCount : 0),
        modifiedDate: fileInfo && fileInfo.modified,
        hidden: f.hidden === true,
        folder: f.folder || null,
        dirty: false,
        uploadProgress: 0,
        uploadCancelToken: null,
        ...f
      });

    });
  },
  enableHedges (state, enabled) {
    state.activeAnalysis.settings.useHedgeFile = enabled;
    state.activeAnalysis.modifiedDate = moment().toDate();
    state.fileList[fileNames.hedgeLoans].enabled = enabled;
  },
  enablePayup (state, enabled) {
    state.activeAnalysis.settings.payup = enabled;
    state.activeAnalysis.modifiedDate = moment().toDate();
    state.fileList[fileNames.tbaLoans].enabled = enabled;
  },
  setVolatilityType (state, volatilityType) {
    state.activeAnalysis.settings.volatilityType = volatilityType;
    state.activeAnalysis.modifiedDate = moment().toDate();
  },
  updateFile (state, {fileName, fileInfo}) {
    if (state.fileList[fileName]) {
      Object.assign(state.fileList[fileName], fileInfo);
    }
  },
  toggleFile(state, { fileName, enabled, hidden }) {
    Object.assign(state.fileList[fileName], {
      enabled,
      hidden: hidden == null ? true : hidden
    });
  },
  addValidationStatus (state, status) {
    state.validationStatus.push(status)
  },
  updateValidationStatus (state, status) {
    let item = state.validationStatus.find(s => s.tab == status.tab && s.item == status.item);

    if (item) {
      item.isValid = status.isValid;
      if (item.tab == 'Scenarios') item.invalidScenarios = status.invalidScenarios;
    } else {
      state.validationStatus.push(status);
    }
  },
  beginValidate (state) {
    state.validationStatus = []

    // This will trigger validation in components
    state.isValidating = true;

    if (state.activeAnalysis.runType != runTypes.loanDynamics) {
      // Validate input files
      if (!state.fileNames.length) {
        state.validationStatus.push({
          tab: "Inputs",
          item: null,
          isValid: false
        });
      }

      state.fileNames.forEach(name => {
        let f = state.fileList[name];
  
        f.dirty = f.enabled ? true : false;
  
        if (f.enabled && f.status == fileStatus.missing) {
          state.validationStatus.push({
            tab: f.tab,
            item: f.fileName,
            isValid: f.status != fileStatus.missing
          })
        }
      })
    } else if (state.activeAnalysis.runType == runTypes.loanDynamics && state.activeAnalysis.settings.useCustomTuningFiles) {
      state.fileNames.forEach(name => {
        if (name != fileNames.tuningConditions && name != fileNames.tuningValues) return;
        let f = state.fileList[name];
  
        f.dirty = f.enabled ? true : false;
  
        if (f.enabled && f.status == fileStatus.missing) {
          state.validationStatus.push({
            tab: f.tab,
            item: f.fileName,
            isValid: f.status != fileStatus.missing
          })
        }
      })
    }
  },
  resetValidationStatus (state) {
    state.isValidating = false;
  },
  clearValidationStatus (state) {
    state.isValidating = false;
    state.validationStatus = [];
    state.fileNames = [];
  },
  setSavePending (state) {
    state.savePending = true;
    state.isSaved = true;
  },
  setSaving (state) {
    state.isSaving += 1;
    state.saveError = null;
  },
  doneSaving (state) {
    state.isSaving -= 1;
    state.lastSaved = moment().format('h:mm a');

    if (state.isSaving == 0) {
      state.savePending = false;
    }
  },
  errorSaving (state, error) {
    state.isSaving -= 1;
    state.savePending = false;
    state.isSaved = false;
    state.saveError = error;
  },
  cancelSave (state) {
    state.savePending = false;
    state.isSaved = false;
  },
  clearSaveError (state) {
    state.saveError = null;
  },
  setUnsaved (state) {
    state.isSaved = false;
  },
  setAnalysisModified (state) {
    state.activeAnalysis.modifiedDate = moment().toDate();
  },
  setAnalysisLastRun (state) {
    state.activeAnalysis.lastRunDate = moment().toDate();
  },
  setAnalysisStatus (state, analysisStatus) {    
    // Update progress
    if (analysisStatus.executionStatus == statusCodes.running) {

      if(analysisStatus.currentStep == 0) {
        analysisStatus.statusTitle = 'Starting analysis...';
      }

      if (analysisStatus.totalItems > 0 &&
        analysisStatus.totalSteps > 0) {
        analysisStatus.statusText = `${analysisStatus.stepDesc}`;

        if (analysisStatus.totalItems > 0) {
          analysisStatus.percentComplete = Math.floor(analysisStatus.itemsProcessed / analysisStatus.totalItems * 100);
          analysisStatus.statusTitle = `Running step ${analysisStatus.currentStep} of ${analysisStatus.totalSteps}... ${analysisStatus.percentComplete}% Complete`;
          analysisStatus.statusText += ` (processed ${analysisStatus.itemsProcessed} of ${analysisStatus.totalItems} items)`;
        } else {
          analysisStatus.statusTitle = `Running step ${analysisStatus.currentStep} of ${analysisStatus.totalSteps}...`;
        }
      } else if (analysisStatus.totalItems == 0 &&
          analysisStatus.currentStep > 0 &&
          analysisStatus.stepDesc.startsWith('Importing')) {
            analysisStatus.statusTitle = 'Importing...';
            analysisStatus.statusText = `${analysisStatus.stepDesc}`;
      }
    } 

    // Set status text to error message on error
    if (analysisStatus.executionStatus == statusCodes.error) {
      analysisStatus.statusText = analysisStatus.errorMessage || analysisStatus.statusText;
    }

    let currentStatus = state.activeAnalysis.status || {};
    let newStatus = Object.assign({}, currentStatus, analysisStatus);
    Vue.set(state.activeAnalysis, "status", newStatus);
  },
  setAnalysisResultsUpdated (state, resultsUpdated) {
    state.activeAnalysis.resultsUpdated = resultsUpdated;
  },
  setLicense (state, licenseInfo) {
    state.checkedLicense = true;
    state.licenseInfo = licenseInfo;
    state.licenseInfo.modules = licenseInfo.modules || [];
  },
  addExport (state, exportInfo) {
    // Create an export object that auto-starts the download when export is complete
    let exp = {
      analysisId: exportInfo.analysisId,
      exportError: false,
      exportName: exportInfo.exportName,
      message: exportInfo.exportMessage,
      errorMessage: exportInfo.errorMessage,
      promise: exportInfo.promise
        .then((response) => exp.complete(response))
        .catch((err) => exp.error(err)),
      complete (response) {
        let server = localStorage.getItem('kinetics_server');
        
        //window.open(`${server}/api/analyses/${this.analysisId}/export/${response.data.downloadToken}`, "_blank");
        window.location = `${server}/api/analyses/${this.analysisId}/export/${response.data.downloadToken}`;

        // Remove export from array
        let idx = state.exportList.indexOf(exp);
        state.exportList.splice(idx, 1);
      },
      error (err) {
        this.exportError = true;
        this.message = this.errorMessage;
        console.log(err);
      }
    };

    state.exportList.push(exp);
  },
  updateAnalysisPage(state, currentPage){
    state.analysisPage=currentPage
  },
  updateAnalysisPageScrollPosition(state, position){
    state.analysisPageScrollPosition=position
  },
  removeExport (state, exp) {
    let idx = state.exportList.indexOf(exp);
    state.exportList.splice(idx, 1);
  },
  storeSelectedFilters(state, {analysisId, filters}) {
    if (!state.selectedFilters[analysisId]) {
      state.selectedFilters[analysisId] = filters;
    } else {
      state.selectedFilters[analysisId] = filters.concat(state.selectedFilters[analysisId].slice(filters.length));
    }
  },
  setFieldSelection(state, {analysisId, fieldName, axis, selected}) {
    let fields = state.selectedFields[analysisId] || [];

    // Add field to selected list
    if (selected && !fields.some(f => f == fieldName)) {
      fields.push({ fieldName, axis });
    }

    // Remove unselected item
    if (!selected) {
      let idx = fields.findIndex(f => f.fieldName == fieldName);

      if (idx != -1) {
        fields.splice(idx, 1);
      }
    }

    state.selectedFields[analysisId] = fields;
  },
  setScenarioData (state, {data, forecastGroup }) {
    state.scenarioData[forecastGroup] = data;
    state.scenarioRecordCount = data.length;
  },
  setScenarioPreviewData (state, data) {
    state.scenarioPreviewData = data;
  },
  clearScenarioPreviewData (state) {
    state.scenarioPreviewData = {};
  },
  setScenarios(state, data) {
    state.scenarios = data;
  },
  setPenaltyPointSets(state, data) {
    state.penaltyPointSets = data;
  },
  setPointsData(state, { data }) {
    state.pointsData = data;
  },
  setAuthServer(state, server) {
    state.authentication.server = server;
    setServer(server);
  },
  setPortfolioGridDirty (state, data) {
    state.portfolioGridDirty = data
  },
  setScenarioGridDirty (state, data) {
    state.scenarioGridDirty = data;
  },
  setAppConfig (state, config) {
    state.appConfig = config;
  }
}

